import React from 'react'
import { useOutletContext,useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {FaCloudDownloadAlt} from 'react-icons/fa'  
import { IconContext } from 'react-icons'
import { useTranslation } from 'react-i18next'

export const RapportRoute = ({digit_format,ip_address}) => {
  const {t}=useTranslation()
    const[route,expense,income,refuling,service,filter_choice,laDistance,intervalle]=useOutletContext()
    const profile=useSelector(state=>state.auth.profile)
    const current_user=useSelector(state=>state.auth.user.id)
    const navigate=useNavigate()
    const currency=profile.currency_format
    const odometre_type=useSelector(state=>state.vehicules.item.odometre_type)

    const getRepportDetailed=async(data)=>{
      const request=await fetch(`${ip_address}/generate_rapport_detail`,{
          method:'POST',
          body:data
      })
  
  
              const response=await request.json()
              
  
              switch(response.status){
                  case 200:
                      alert(response.message)
                      navigate('/settings/files')
                      break
                  default:
                      break
              }
            
   
  }
  
  const generateRapportDetailed=async()=>{
      const formdata=new FormData()
      formdata.append('user_id',current_user)
      formdata.append('Total',route)
      formdata.append('Byday',digit_format(route/intervalle))
      formdata.append('ByKilometre',digit_format(route/laDistance?route/laDistance:0))
      formdata.append('alias','Route')
  
      getRepportDetailed(formdata)
  
    }
  
  return (
    <div>
         <div className='row mb-3'>
     <div className='col-sm-10'>
        <h3 style={{fontFamily:'Roboto'}}>{t("Cost")}</h3>
        </div>
        <div className='col-sm-2'>
        <div onClick={()=>generateRapportDetailed()}>
        <IconContext.Provider value={{ style: { fontSize: "35px",marginLeft:10,cursor:'pointer'},color:'#ff5d70', className: "global-class-name" }}>
                   
                   <FaCloudDownloadAlt />
            
             </IconContext.Provider>
             </div>
             </div>
        </div>
        <div className='row'>
        <div className='col-md-4'>
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>
                   
                    <h5 style={{fontFamily:'Roboto'}}>{t("Total")}</h5>
                    <h5 style={{fontSize:15}}>{route?route:0} {t(currency)}</h5>
                </div>
        </div>
        <div className='col-md-4'>
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>
          
                    <h5 style={{fontFamily:'Roboto'}}>{t("Per Day")}</h5>
                    <h5 style={{fontSize:15}}>{route/intervalle?digit_format((route/intervalle)*86400):0} {t(currency)}</h5>
                </div>
        </div>
        <div className='col-md-4'>
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>
               
                    <h5 style={{fontFamily:'Roboto'}}>By {odometre_type}</h5>
                    <h5 style={{fontSize:15}}>{digit_format(route/laDistance?route/laDistance:0)} {t(currency)}</h5>
                </div>
        </div>
        </div>
    </div>
  )
}
