import React, { useState, useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CREATE_MESSAGES, GET_ERRORS } from '../../../../actions/types'
import { useTranslation } from 'react-i18next'

export const EditService = ({ip_address,currentLanguage,id}) => {
    const {t}=useTranslation()
    const mode=useSelector(state=>state.auth.mode)
    const current_vehicule= useSelector(state => state.vehicules.item)
    const service_types = JSON.parse(useSelector(state => state.auth.profile.services))
    const places = JSON.parse(useSelector(state => state.auth.profile.places_choice))
    const reasons = JSON.parse(useSelector(state => state.auth.profile.raison))
    const payment_methods = JSON.parse(useSelector(state => state.auth.profile.payment_method))
    const current_odometre=useSelector(state=>state.vehicules.item.odometre )
    const selected_vehicule_id= useSelector(state => state.vehicules.item.id)
    const dispatch=useDispatch()
    const [service_type, setService_type] = useState('')
    const [reason,setReason]=useState('')
    const [odometre, setOdometre] = useState(0)
    const [changed_items,setChanged_items]=useState({})
    const [valeur, setValeur] = useState(0)
    const [payment_method,setPayment_method]=useState('')
    const [note, setNote] = useState('')
    const [timer,setTimer]=useState('')
   const [place,setPlace]=useState('')
    const [file, setFile] = useState(null)

    useEffect(()=>{
        
        const fetchItem=async()=>{
            const item=await getItem()
           } 
           fetchItem()
    
       },[])

       
       const getItem=async()=>{
        const request=await fetch(`${ip_address}/app/api/services/${id}`,{
           
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
         
           
        },)

        const response=request.status
        switch (response){
            case 200:
                const resp=await request.json()
                setService_type(resp.type)
                setValeur(resp.value)
                setOdometre(resp.odometre)
                setPayment_method(resp.payment_method)
                setTimer(resp.creation_date)
                setFile(resp.attached_file)
                setNote(resp.note)
                setPlace(resp.place)
                setReason(resp.raison)
                break
            case 403:
                dispatch({
                    type:GET_ERRORS,
                    payload:{
                        msg:t("You are not allowed to edit this item"),
                        status:403
                    }
                })
                break
            default:
                alert(t('item not found'))
                break
        }
       }

    const EditService=async(data)=>{

 

        const requestPost=await fetch(`${ip_address}/app/api/services/${id}/`,{
            method:'PATCH',
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            body:data

        },
        
        )
        console.log(requestPost)
        const resp=await requestPost.json()
        if(requestPost.status===200){
          
            dispatch({
                type:CREATE_MESSAGES,
                payload:`${t("Service for ")} ${current_vehicule.model} ${t("Edited successfully")}`,
            })
            setTimeout(()=>{
                dispatch({
                    type:CREATE_MESSAGES,
                    payload:``,
                })},
                4000
               )
            return resp
        }else{
            dispatch({
                type:GET_ERRORS,
                payload:{
                    msg:'please try again',
                    status:requestPost.status
                }
            })
        }
 
    }


    const onSubmit=async(e)=>{
        e.preventDefault()
        const formdata= new FormData()
        formdata.append('vehicule_id',selected_vehicule_id)
        Object.keys(changed_items).forEach((item) => {
            formdata.append(item,changed_items[item])
            console.log(item,changed_items[item])
          })
        
        EditService(formdata)
        setChanged_items({})
    }




    return (
        <section id='serviceForm' className={`all_sections ${currentLanguage.dir || 'ltr'} mt-4`}>

            <div className={`content-${mode} whitewhen${mode}`}>
                <div className='row'>
                    <div className='col m-4'>
                        <div className='title'>
                        <img  src={`${ip_address}/media/icons/wrench.svg`} style={{"width":"30px"}}/> |
                            <span className='text Airstrike'>{t("Edit")} {t("Service")}</span>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className='row mt-5'>

                                <div className='col-md-4'>
                                    <label for="selectService" class="form-label">{t("Service")} {t("type")}</label>
                                    <select class={`form-select ${mode}`} id='selectService' onChange={(e) => {setService_type(e.target.value);changed_items['type']=e.target.value}} value={service_type} required>
                                    
                                        {service_types.map((type, index) => (<option key={index} value={type}>{type}</option>))}
                                    </select>
                                </div>

                                      
                                <div class="col-md-4">
                                    <label for="odometre" class="form-label">{t("Odometer")}</label>

                                    <input type="number" class={`form-control ${mode}`} id="odometre" placeholder={t("Odometre")} onChange={(e) => {setOdometre(e.target.value);changed_items['odometre']=e.target.value}} value={odometre} required />
                                    <div id="emailHelp" class="form-text">{t(`Last odometre`)}{current_odometre}</div>
                                </div>

                                <div class="col-md-4">
                                    <label for="inputZip" class="form-label">{t("Date")}</label>
                                    <input type="datetime-local" class={`form-control ${mode}`} id="inputZip" onChange={(e) => {setTimer(e.target.value);changed_items['creation_date']=e.target.value}} value={timer} />
                                </div>
                                
                            
                                
                            </div>

                            <div className='row mt-3'>
                       
                            <div className='col-md-4'>
                                    <label for="selectPrice" class="form-label">{t("Price")}</label>
                                    <input type='number' class={`form-control ${mode}`} id='selectPrice' onChange={(e) => {setValeur(e.target.value);changed_items['value']=e.target.value}} value={valeur} required />

                                     
                                </div>

                                <div className='col-md-4'>
                                    <label for="selectpayment_method" class="form-label">{t("Payment method")}</label>
                                    <select class={`form-select ${mode}`} id='selectpayment_method' onChange={(e) => {setPayment_method(e.target.value);changed_items['payment_method']=e.target.value}} value={payment_method} required>
                                    
                                        {payment_methods.map((method, index) => (<option key={index} value={method}>{method}</option>))}
                                    </select>
                                </div>

                                <div className='col-md-4'>
                                    <label for="selectplace" class="form-label">{t("Place")}</label>
                                    <select class={`form-select ${mode}`} id='selectplace' onChange={(e) => {setPlace(e.target.value);changed_items['place']=e.target.value}} value={place} required>

                                        {places.map((pla, index) => (<option key={index} value={pla}>{pla}</option>))}
                                    </select>
                                </div>
                               



                            </div>

                            <div className='row mb-3'>
                            <div className='col-md-4'>
                                    <label for="selecReason" class="form-label">{t("Add")} {t("Reason")}</label>
                                    <select class={`form-select-${currentLanguage.dir} ${mode}`} id='selecReason' onChange={(e) => {setReason(e.target.value);;changed_items['raison']=e.target.value}} value={reason} required>
                                    
                                        {reasons.map((raison, index) => (<option key={index} value={raison}>{raison}</option>))}
                                    </select>
                                </div>

                            <div className='col-md-4 mb-3'>
                                    <label for="formFileLg" class="form-label">{t("Attach file")}</label>
                                    <input class={`form-control ${mode}`} id="formFileLg" type="file" onChange={(e) => {setFile(e.target.files[0]);changed_items['attached_file']=e.target.files[0]}} />
                                </div>

                                <div class="col-md-4 mb-3" >
                                    <label for="textarea1" class="form-label">{t("Note")}</label>

                                    <textarea class={`form-control ${mode}`} id="textarea1" rows="1" placeholder='add remark' onChange={(e) => {setNote(e.target.value);changed_items['note']=e.target.value}} value={note}></textarea>
                                </div>

                                    <div class="col-12">
                                <button type="submit" class={`btn form-control`} style={{backgroundColor:"#36001C",color:'white'}}>{t("Edit")} {t("Service")}</button>
                            </div>
                            </div>
                         

     
                        
                        </form>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg'>




                    </div>
                </div>
            </div>


        </section>
    )
}
