import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Styled components for the 404 page
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 4rem;
  margin-bottom: 20px;
  color: #555;
`;

const Description = styled.p`
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #777;
`;

const StyledLink = styled(Link)`
  color: #007bff;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const NotFound = () => {
  return (
    <Container>
      <Title>404 - Not Found</Title>
      <Description>The page you are looking for might be in another castle.</Description>
      <StyledLink to="/timeline">Go to history</StyledLink>
    </Container>
  );
};

export default NotFound;
