import React,{Fragment,useEffect} from 'react'
import { withAlert } from 'react-alert'
import { useAlert } from 'react-alert'
import { useSelector } from 'react-redux'

const Messages = () => {
    const alert=useAlert()
    const message=useSelector(state=>state.messages)
    useEffect(()=>{
if(message){alert.success(message)}
    },[message])

  return (
    <div></div>
  )
}

export default withAlert()(Messages)