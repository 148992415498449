import React,{useState} from 'react'
import { useTranslation } from 'react-i18next'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from "react-icons"
import { Modal2 } from '../../FirstApp/Modals/Modal2'
import { useNavigate } from 'react-router-dom'

export const DriverRow = ({index,mode,lastname,email,phone,vehicule_id,url,delete_driver}) => {
    const {t}=useTranslation()
    const navigate=useNavigate()
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
      };
      
      const handleCloseModal = () => {
        setModalOpen(false);
      };

      const setContent_delete=()=>{
        return( <div className="delete-confirmation-dialog">
        <p>{t("Are you sure you want to delete this item?")}</p>
        <div style={{display:'flex',flexDirection:"row" ,alignItems:'center',justifyContent:'space-around'}}>
          <button className='btn btn-outline-danger' onClick={()=>{delete_driver(vehicule_id,url);handleCloseModal()}}>{t("YES")}</button>
          <button className='btn btn-outline-info' onClick={handleCloseModal}>{t("NO")}</button>
        </div>
  
      </div>)
      }  
    return (
    <tr key={index}>
<td style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start'}}>{lastname}</td>
      <td>{email}</td>
      {/* <td>{phone}</td> */}
  <td>
            <div style={{"display":"flex","justifyContent":"flex-start"}}>
        <IconContext.Provider value={{ style: { color: "red", fontSize: "20px","marginRight":"20px",cursor:"pointer" }, className: "global-class-name" }} >
                          <div onClick={handleOpenModal}>
                          <MdDeleteForever/>
                          </div>
                        </IconContext.Provider>
                        <Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setContent_delete}/>
           </div>
             </td>
</tr>
  )
}
