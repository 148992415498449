import { TOGGLE_MODE } from "./types"

export const toggleMode=()=>{
    return  function(dispatch)
 
    {
        const actual_mode=localStorage.getItem('mode')
        switch(actual_mode){ 
    case 'dark':
        
    dispatch({
        type:TOGGLE_MODE,
        payload:'light'
    })
    break
    default:
        dispatch({
            type:TOGGLE_MODE,
            payload:'dark'
        })
        break
}
     }
     
 }