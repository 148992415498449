import React from 'react'

export const Modal = ({mode,showModal,setContent,modal}) => {
  // console.log("this is showmodal",showModal)


  return (
    <div id={`myModal${modal}`} class="modal2" style={{ "display": `${showModal}` }}>


    <div class={`modal2-content-${mode}`}>
 
        <div class={`modal-body ${mode}`} id='modalContent'>
          {setContent()}
        </div>
    </div>
  </div>
  )
}


export const ModalAd = ({mode,showModal,setContent,modal}) => {

  return (
    <div id={`myModal${modal}`} class="modal2" style={{ "display": `${showModal}` }}>


    <div class={`modalAd-content-${mode}`}>
 
        <div class={`modal-body ${mode}`} id='modalContent'>
          {setContent()}
        </div>
    </div>
  </div>
  )
}