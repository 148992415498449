import React,{useState,useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {GiRoad} from 'react-icons/gi'
import { updateVehicule } from '../../../../actions/rappelActions'
import { CREATE_MESSAGES, GET_ERRORS } from '../../../../actions/types'
import { useTranslation } from 'react-i18next'
import { MdArrowDropDown,MdArrowDropUp } from "react-icons/md";


export const AddRoute = ({ip_address,currentLanguage}) => {
    const [origin,setOrigin]=useState('')
    const [destination,setDestination]=useState('')
    const {t}=useTranslation()
    const [start_odometre,setStart_odometre]=useState(0)
    const [end_odometre,setEnd_odometre]=useState(0)
    const selected_vehicule_id= useSelector(state => state.vehicules.item.id)
    const reasons = JSON.parse(useSelector(state => state.auth.profile.raison))
    const [note,setNote]=useState('')
    const [showOptional, setShowOptional] = useState('none')
    const [buttonTitle, setButtonTitle] = useState('More options')
    const [reason,setReason]=useState('')
    const [price,setPrice]=useState(0.0)
    const [file,setFile]=useState(null)
    const [start_time,setStart_time]=useState('')
    const [end_time,setEnd_time]=useState('')
    const [via_autoroute,setVia_autoroute]=useState(false)
    const user=useSelector(state=>state.auth.user.url)
    // const username=useSelector(state=>state.auth.user.username)
    // const profile=useSelector(stat=>stat.auth.profile)

    const current_odometre=useSelector(state=>state.vehicules.item.odometre )
    const current_vehicule=useSelector(state=>state.vehicules.item)
    const mode=useSelector(state=>state.auth.mode)
    const navigate=useNavigate()
    const dispatch=useDispatch()
    


    const ajouterRappel=async(data)=>{


        const requestPost=await fetch(`${ip_address}/app/api/routings/`,{
            method:'POST',
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            body:data,
    
        },
        
        )
        const resp= await requestPost.json()
        console.log(requestPost)
       
        if(requestPost.status===201){
            if(current_odometre<end_odometre){
                const new_odometre=new FormData()
                new_odometre.append('odometre',end_odometre)
                dispatch(updateVehicule(selected_vehicule_id,new_odometre))
            }
            navigate('/timeline')
            dispatch({
                type:CREATE_MESSAGES,
                payload:`${t("Service")} ${t("created success")}`,
            })
            // dispatch(fetchVehicules())
            return resp
        }else{
            dispatch({
                type:GET_ERRORS,
                payload:{
                    msg:resp.message,
                    status:requestPost.status
                }
            })
        }
 
    }

        //////////////////////////////////// show or hide optional inputs


const toggleShowOptional = () => {
        
    if (showOptional === "none") {
    
      setShowOptional("block")
      setButtonTitle('Less options')
    } else {
      setShowOptional("none")
      setButtonTitle('More options')
    }
  }

//   end

    const onSubmit=async(e)=>{
        e.preventDefault()
        const formdata= new FormData()
        formdata.append('origin',origin)
        formdata.append('destination',destination)
        formdata.append('start_odometre',start_odometre)
        formdata.append('end_odometre',end_odometre)
        formdata.append('start_date',start_time)
        formdata.append('end_date',end_time)
        formdata.append('autoRoute',via_autoroute)
   
        formdata.append('writter',user)
        // formdata.append('username',username)
        if(file){
            formdata.append('attached_file',file)
        }
        if(note){
            formdata.append('note',note)
        }
        if(start_time){
          formdata.append('creation_date',start_time)
      }
      if(end_time){
        formdata.append('creation_date',end_time)
    }
    if(price){
        formdata.append('prixAuto_route',price)
    }
        formdata.append('vehicule',current_vehicule.url)
        ajouterRappel(formdata)
      
     
        setFile(null)
        setNote('')
        // setVehicule('')
    }

    const togglecheckbox=(e)=>{
        if (e.target.checked){
      
            setVia_autoroute(true)
         
        }else if(!e.target.checked){
          setVia_autoroute(false)
        }
      }

    
  return (

    <section id='routeForm' className={`all_sections ${currentLanguage.dir || 'ltr'} mt-4`}>

    <div className={`content-${mode} whitewhen${mode}`}>
        <div className='row'>
            <div className='col m-4'>
                <div className='title'>
                <img src={`https://web.bfdrive.ma/media/icons/road.svg`} style={{"width":"30px","height":"100%"}}/>|
                    <span className='text Airstrike'>{t("Routing")}</span>
                </div>
                <form onSubmit={onSubmit}>
                    <div className='row mt-5'>
                     

                   
                        <div className='col-md-4'>
                            <label for="selectOrigin" class="form-label required">{t("Origin")}</label>
                            <input type='text' class={`form-control ${mode}`} id='selectOrigin' onChange={(e) => setOrigin(e.target.value)} value={origin} required />


                        </div>
                        <div className='col-md-4'>
                            <label for="selectDestination" class="form-label required">{t("Destination")}</label>
                            <input type='text' class={`form-control ${mode}`} id='selectDestination' onChange={(e) => setDestination(e.target.value)} value={destination} required />


                        </div>
                        <div class="col-md-4">
                            <label for="start_odometre" class="form-label required">{t("Start Odometer")}</label>

                            <input type="number" class={`form-control ${mode}`} id="start_odometre" placeholder={t("Start odometer")} onChange={(e) => setStart_odometre(e.target.value)} value={start_odometre} required />
                            {/* <div id="emailHelp" class="form-text">{`last odometre ${current_odometre}`}</div> */}
                        </div>
                       
                       
                    </div>

                    <div className='row mt-3'>

                    <div class="col-md-4">
                            <label for="start_time" class="form-label required">{t("Start time")}</label>
                            <input type="datetime-local" class={`form-control ${mode}`} id="start_time" onChange={(e) => setStart_time(e.target.value)} value={start_time} required/>
                        </div>
                        <div class="col-md-4">
                            <label for="end_odometre" class="form-label required">{t("End Odometer")}</label>

                            <input type="number" class={`form-control ${mode}`} id="end_odometre" placeholder={t("End odometer")} onChange={(e) => setEnd_odometre(e.target.value)} value={end_odometre} required />
                            <div id="emailHelp" class="form-text">{t("Last odometre")}{current_odometre}</div>
                        </div>
                        <div class="col-md-4">
                            <label for="end_time" class="form-label required">{t("End time")}</label>
                            <input type="datetime-local" class={`form-control ${mode}`} id="end_time" onChange={(e) => setEnd_time(e.target.value)} value={end_time} required/>
                        </div>
                    </div>
                    <div style={{fontSize:10}}><span style={{color:'red'}}>(*)</span>: {t("The above options are obligatory")}</div>
                            {/* Optional inputs */}
                            <div style={{display:showOptional}}>
                    <div className='row mb-3'>
                    <div class="col-md-4 dFlex">
                            
                            <div class="form-check">
                                <input class="form-check-input bg-dark" style={{border:"solid #5bc0de 2px"}} type="checkbox" id="gridCheck" onChange={(e)=>togglecheckbox(e)}/>
                                <label class="form-check-label" for="gridCheck">
                                    {t("By highway")} ?
                                </label>
                            </div>
                           

                        </div>
                        {via_autoroute&&<div className='col-md-4 mb-3'>
                            <label for="prix" class="form-label">{t("Highway price")}</label>
                            <input class={`form-control ${mode}`} id="prix" type="number" onChange={(e) => setPrice(e.target.value)} value={price}/>
                        </div>}
                        <div className='col-md-4'>
                                    <label for="selectResaon" class="form-label">{t("Reason")}</label>
                                    <select class={`form-select ${mode}`} id='selectResaon' onChange={(e) => setReason(e.target.value)} value={reason} required>
                                    
                                        {reasons.map((raison, index) => (<option key={index} value={raison}>{raison}</option>))}
                                    </select>
                                </div>

                                <div className='row mb-3'>

                                <div class="col-md-6 mb-3" >
                            <label for="textarea1" class="form-label">{t("Note")}</label>

                            <textarea class={`form-control ${mode}`} id="textarea1" rows="1" placeholder={t('Add remark')} onChange={(e) => setNote(e.target.value)} value={note}></textarea>
                        </div>

                        <div className='col-md-6 mb-3'>
                            <label for="formFileLg" class="form-label">{t("Attach file")}</label>
                            <input class={`form-control ${mode}`} id="formFileLg" type="file" onChange={(e) => setFile(e.target.files[0])} />
                        </div>
                        </div>
                        </div>
                                </div>
                        {/*End optional inputs  */}

                        <a  onClick={toggleShowOptional} style={{cursor:"pointer",fontSize:16,fontWeight:'bold',color:"#1a5276",paddingTop:10,paddingBottom:10}}>{t(buttonTitle)}{buttonTitle==="More options"?<MdArrowDropDown />:<MdArrowDropUp/>}</a>
                                <div className='row mb-3'>
                        <div class="col-12">
                            <button type="submit" class={`btn form-control`} style={{backgroundColor:"#525252",color:"white"}}>{t("Add")} {t("Routing")}</button>
                        </div>
                   


                    </div>




                </form>
            </div>
        </div>
        <div className='row'>
            <div className='col-lg'>




            </div>
        </div>
    </div>


</section>
   
  )
}

