import { Provider } from "react-redux";
import {Provider as AlertProvider} from 'react-alert'
import ALertTemplate from 'react-alert-template-basic'
import { PrivateRoute } from "./components/common/PrivateRoute";
import storeConfig from './store';
import { BrowserRouter } from "react-router-dom";
import AlertTemplate from "react-alert-template-basic";
import Alerts from "./components/common/Alerts";
import cookies from 'js-cookie'
import store from "./store";
import Cookies from "js-cookie";

const options = {

  position: 'top center',
  timeout: 3000,

}

const App=()=> {

 Cookies.set('i18next',"en", { sameSite: 'none',secure:true })

  return (
  
     
      <BrowserRouter>
      <Provider store={store}>

        <AlertProvider template={AlertTemplate} {...options}>
       
      <PrivateRoute />
      </AlertProvider>
 
      </Provider>
      </BrowserRouter>
   


  );
}

export default App;
