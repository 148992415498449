import { AUTH_ERROR, USER_LOADED, USER_LOADING,USER_AUTHENTICATED, UPDATE_PROFILE ,TOGGLE_MODE, USER_UPDATED} from "../actions/types";

const initialState={
    access_token:localStorage.getItem('access_token'),
    refresh_token:localStorage.getItem('refresh_token'),
    isAuthenticated:false,
    isLoading:false,
    user:null,
    profile:null,
    mode:localStorage.getItem('mode')?localStorage.getItem('mode'):'light',
}

export default function(state=initialState ,action){
    switch(action.type){
        case USER_LOADED:
            localStorage.setItem('access_token',action.payload.access_token)
            localStorage.setItem('refresh_token',action.payload.refresh_token)
            console.log(action.payload)
            return {
                ...state,
                user:action.payload.user,
                profile:action.payload.profile,
                access_token:action.payload.access_token,
                refresh_token:action.payload.refresh_token,
                isAuthenticated:true,
                isLoading:false
            }
        case USER_AUTHENTICATED:

            return {
                ...state,
                user:action.payload.user,
                profile:action.payload.profile,
                access_token:localStorage.getItem('access_token'),
                isAuthenticated:true,
                isLoading:false
            }
        case USER_LOADING:
            console.log('user loading')
            return {
                ...state,
                isLoading:true
            }
        case AUTH_ERROR:
            console.log('auth error')
            localStorage.removeItem('choosed_Vehicule')
            localStorage.setItem('googlePic','')
           
            return {
                ...state,
                access_token:localStorage.removeItem('access_token'),
                refresh_token_token:localStorage.removeItem('refresh_token_token'),
                isAuthenticated:false,
                isLoading:false,
                user:null

            }
        case USER_UPDATED:
            console.log('user updated')
        
            
            return {
                ...state,
             
                user:action.payload

            }    
    case UPDATE_PROFILE:
            console.log(action.payload)
            return {
               ...state,
               profile:action.payload 
            } 
            
        case TOGGLE_MODE:
            localStorage.setItem('mode',action.payload)
            console.log(action.payload)
            return {
                ...state,
                mode:action.payload,
            }
        default:
            return state;
    }
}

