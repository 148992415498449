import React from 'react'
import { IconContext } from "react-icons"
import {AiOutlineAreaChart} from 'react-icons/ai'
import {FaGasPump} from 'react-icons/fa'
import {MdOutlineMiscellaneousServices} from 'react-icons/md'
import {GiMountainRoad,GiPayMoney,GiReceiveMoney} from 'react-icons/gi'
import reportRefueling from './repportIcons/Rapport-Refuelling.png'
import reportExpense from './repportIcons/Rapport-Expense.png'
import reportRoute from './repportIcons/Rapport-Routing.png'
import reportService from './repportIcons/Rapport-Service.png'

export const CustomCostSplit = ({ icon, name, pourcentage }) => {
    const setIcon=()=>{
        switch(icon){
            case 'refuling':
                return( 
                // <IconContext.Provider value={{ style: { fontSize: "30px",marginRight:10},color:'orange', className: "global-class-name" }}>
                // <div> <FaGasPump/> </div>
                //   </IconContext.Provider>
                <img src={reportRefueling} style={{"width":"30px","height":"100%",marginRight:10}}/>
                  )
            case 'service':
                return (   <img src={reportService} style={{"width":"30px","height":"100%",marginRight:10}}/>  
            //                <IconContext.Provider value={{ style: { fontSize: "30px",marginRight:10},color:"darkgray", className: "global-class-name" }}>
            //     <div>
            //         <MdOutlineMiscellaneousServices />
            //     </div>
            //   </IconContext.Provider>
              )
            case "expense":
                return(       <img src={reportExpense} style={{"width":"30px","height":"100%",marginRight:10}}/>
            //              <IconContext.Provider value={{ style: { fontSize: "30px",marginRight:10},color:"red", className: "global-class-name" }}>
            //     <div>
            //         <GiPayMoney/>
            //     </div>
            //   </IconContext.Provider>
              ) 
            case "income":
                return (            <IconContext.Provider value={{ style: { fontSize: "30px",marginRight:10}, className: "global-class-name" }}>
                <div>
                   <GiReceiveMoney/>
                </div>
              </IconContext.Provider>) 
            case 'road':
                return (     <img src={reportRoute} style={{"width":"30px","height":"100%",marginRight:10}}/>  
            //              <IconContext.Provider value={{ style: { fontSize: "30px",marginRight:10}, className: "global-class-name" }}>
            //     <div>
            //         <GiMountainRoad/>
            //     </div>
            //   </IconContext.Provider>
              ) 
        }
    }
    return (
        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center',margin:20 }}>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'centre', alignItems: 'center' }}>
            {setIcon()}
            </div>
            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>{name}</div>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>{pourcentage}%</div>
            </div>
        </div>
    )
}
