import React from 'react'
import { IconContext } from "react-icons"
import {AiFillCar,
    AiFillFolderOpen,
    AiFillPieChart,
   } from 'react-icons/ai'
import { FaRoad,
    FaUserTie,
    FaServicestack} from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const DashboradCard= ({icon,title,link,color,mode}) => {
    const setIcon=()=>{
        switch(icon){
            case 'vehicle':
                return  <AiFillCar />
            case 'files':
                return <AiFillFolderOpen />
            case 'storage':
                return <AiFillPieChart />
            case 'routes':
                return <FaRoad />
            case 'account':
                return <FaUserTie />
            case 'services':
                return <FaServicestack />

        }
    }
  return (
    <Link to={link} style={{display:'flex',alignItems:'center',justifyContent:'space-around',border:`1px solid #df1d34`,borderLeft:`4px solid #df1d34`,borderRadius:'10px',cursor:'pointer',textDecoration:'none',color:"gray",backgroundColor:`${mode=='dark'?"black":"white"}`,height:55}}>
        <div className='row' style={{width:"100%"}}>
        <div className='col-4' style={{display:'flex',alignItems:'center',justifyContent:'center',width:'20%'}}>
                    <IconContext.Provider value={{ style: { fontSize: "35px",color:"#df1d34"}, className: "global-class-name" }}>
                    <div>
                      {setIcon()}
                    </div>
                  </IconContext.Provider>
        </div>
        <div className='col-7 dFlex' style={{alignItems:"flex-start",width:"70%"}}>
            <h5 style={{fontFamily:'Airstrike'}}>{title}</h5>
        </div>
        </div>
    </Link>
  )
}
