import React, { useEffect } from 'react';

export const NativeAdReminder = () => {
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle;
        if (adsbygoogle) {
          console.log({ adsbygoogle });
          adsbygoogle.push({});
        } else {
          console.error("adsbygoogle is not defined on window");
        }
      } catch (e) {
        console.error(e);
      }
    };

    pushAd();
  }, []);

  return (
    <ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-9450275345312816"
         data-ad-slot="9587216294"
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>
  );
};
