import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { UPDATE_PROFILE } from '../../../../actions/types'
import { Modal2 } from '../../Modals/Modal2'
import { useTranslation } from 'react-i18next'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from 'react-icons'


export const FuelChoices = ({mode}) => {
  const{t}=useTranslation()
  const [fuel_types, setFuel_types] = useState([])
  const [fuel, setFuel] = useState('')
  const [showModal, setShowModal] = useState("none") 
  const dispatch = useDispatch()
  const current_profile = useSelector((state) => state.auth.profile)
  useEffect(() => {

    setFuel_types(JSON.parse(current_profile.fuel_choice))


  }, [])

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const updateFuel = async (e) => {
    e.preventDefault()
    fuel_types.push(fuel)

    // const data=JSON.stringify(fuel_types)
    const data = {
      'fuel_choice': JSON.stringify(fuel_types)
    }
    console.log('this is', data)

    dispatch(updateProfile(data))
    handleCloseModal()

  }


  const updateProfile = (data) => {

    return function (dispatch) {
      fetch(current_profile.url, {
        headers: {
          'content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        },
        method: 'PATCH',
        body: JSON.stringify(data),

      },

      ).then(resp => resp.json()).then(profile => {
        if (profile.hasOwnProperty('avatar')) {
          dispatch({
            type: UPDATE_PROFILE,
            payload: profile
          })
        } else {
          alert('something went wrong pleaze try again later')
        }
      })
    }
  }

  // const toggleShow = () => {
  //   if (showModal === "none") {
  //     setShowModal("block")
  //   } else {
  //     setShowModal("none")
  //   }
  // }

  const updateFuel2 = async (fuel) => {
    // e.preventDefault()
    const carburants = fuel_types.filter((type) => type !== fuel)
    setFuel_types(carburants)
    // const data=JSON.stringify(fuel_types)
    const data = {
      'fuel_choice': JSON.stringify(carburants)
    }
    console.log('this is', data)

    dispatch(updateProfile(data))



  }

  const setContent_fuel=()=>{
    return(
      <form onSubmit={updateFuel}>
                  <input className='form-control mb-2' type='text' placeholder='name' onChange={(e) => { setFuel(e.target.value) }} value={fuel} />
                  <button type='submit' className='btnOutline form-control'>{t("Submit")}</button>
                </form>
    )
  }

  ////////////////////////////:handle click outside model
////////////////// chooseType is the id of the input to
/////////////////////// avoid hidding the modal when typing in the input




// if (showModal=='block') {
//   document.getElementById('myModalfuel').addEventListener("click",toggleShow);

// }


////////////////////////////////end 




  return (

    <div className='typesChoices'>
      <div className='typesChoices-content'>

        <h3 className={`strike-${mode}`}>{t("Types of Fuel")}</h3 >

        <button className="btnOutline" onClick={() => handleOpenModal()}>{t("Add")} {t("Fuel type")}</button>

        <Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setContent_fuel}/>

        {/* <Modal modal={'fuel'} mode={mode} showModal={showModal} setContent={setContent_fuel}/> */}
      </div>

      <ul className={`list-group mt-5 choices-list-${mode}`} style={{}}>
        {fuel_types.map((type, index) => <li key={index} class={`list-group-item choices-list-${mode}`} style={{ "display": "flex", "justifyContent": "space-between", "alignItems": "center","width":"100%" }}>{t(type)}<span onClick={() => updateFuel2(type)} style={{ "cursor": "pointer" }}>
        <IconContext.Provider value={{ style: { fontSize: "20px",color:"red" }, className: "global-class-name" }}>
                    <div>
                     <MdDeleteForever />
                    </div>
                  </IconContext.Provider>
          </span></li>)}

      </ul>

    </div>
  )
}
