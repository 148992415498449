import React,{useState,useEffect} from 'react'
import { Header } from './Header/Header'
import './First.css'
import { Reminder } from './Reminder/Reminder'
import { useDispatch,useSelector } from "react-redux";
import { fetchVehicules } from '../../actions/rappelActions';
import { fetchNotifications } from '../../actions/notificationActions';
import { Routes, Route,Navigate} from "react-router-dom";
import { SideBare } from './SideBar/SideBare';
import { Rapport } from './Rapport/Rapport';
import { Settings } from './Settings/Settings';
import { Fragment } from 'react';
import { AddReminder } from './Forms/AddReminder/AddReminder';
import { AddRefuling } from './Forms/AddRefuling/AddRefuling';
import { AddIncome } from './Forms/AddIncome/AddIncome';
import { AddExpense } from './Forms/AddExpense/AddExpense';
import { AddService } from './Forms/AddService/AddService';
import { ContentSettings } from './Settings/ContentSettings/ContentSettings';
import { ExpenseChoices } from './Settings/Choices/ExpenseChoices';
import { FuelChoices } from './Settings/Choices/FuelChoices';
import { IncomeChoices } from './Settings/Choices/IncomeChoices';
import { PlaceChoices } from './Settings/Choices/PlaceChoices';
import { ReasonChoices } from './Settings/Choices/ReasonChoices';
import { ServicesChoices } from './Settings/Choices/ServicesChoices';
import { StationsChoices } from './Settings/Choices/StationsChoices';
import { History } from './History/History';
import { MyVehicules } from '../Vehicules/MyVehicules/MyVehicules';
import { MyUsers } from '../Vehicules/MyUsers/MyUsers';
import { MyUsersPerVehicule } from '../Vehicules/MyUsersPerVehicule/MyUsersPerVehicule';
import { UpdateVehiculeWrapper } from '../Vehicules/MyVehicules/UpdateVehiculeWrapper';
import { Profile } from './Profile/Profile';
import { Dashboard } from './Dashboard/Dashboard';
import { ContactUs } from './ContactUs/ContactUs';
import { Formats } from './General/Formats';
import { ReminderSetting} from './General/ReminderSetting';
import { Testing } from './Testings/Testing';
import { AddRoute } from './Forms/AddRoute/AddRoute';
import { EmailConfirm } from '../common/EmailConfirm';
import { DashboardVehicles } from './Dashboard/DashboardVehicles/DashboardVehicles';
import { DashboardStorage } from './Dashboard/DashboardStorage/DashboardStorage'; 
import { DashboardFiles } from './Dashboard/DashboardFiles/DashboardFiles';
import { DashboardRoutes } from './Dashboard/DashboardRoutes/DashboardRoutes';
import { DashboardServices } from './Dashboard/DashboardSevices/DashboardServices';
import { DashboardGeneral } from './Dashboard/DashboardGeneral/DashboardGeneral';
import { DashboardGeneral2 } from './Dashboard/DashboardGeneral2/DashboardGeneral2';
import { AddForm } from './Forms/AddVehicule/AddForm';
import { RapportGeneral } from './Rapport/RapportDetails/RapportGeneral';
import { RapportRefuling } from './Rapport/RapportDetails/RapportRefuling';
import { RapportExpense } from './Rapport/RapportDetails/RapportExpense';
import { RapportIncome } from './Rapport/RapportDetails/RapportIncome';
import { RapportService } from './Rapport/RapportDetails/RapportService';
import { RapportRoute } from './Rapport/RapportDetails/RapportRoute';
import { DashboardGeneral3 } from './Dashboard/DashboardGeneral3/DashboardGeneral3';
import {FooterBar} from './SideBar/FooterBar'
import Alerts from '../common/Alerts';
import cookies from 'js-cookie'
import Messages from '../common/Messages';
import { EditExpenseWrapper } from './EditForms/EditExpense/EditExpenseWrapper';
import { EditIncomeWrapper } from './EditForms/EditIncome/EditIncomeWrapper';
import { EditRefulingWrapper } from './EditForms/EditRefuling/EditRefulingWrapper';
import { EditRouteWrapper } from './EditForms/EditRoute/EditRouteWrapper';
import { EditServiceWrapper } from './EditForms/EditService/EditServiceWrapper';
import { EditReminderWrapper } from './EditForms/EditReminder/EditReminderWrapper';
import ChangePass from './Profile/ChangePass';
import { PrivacyPolicy } from '../Registrations/Privacy/PrivacyPolicy';
import NotFound from '../common/NotFounnd';
import { LoadingWrapper } from './Redirection/LoadingWrapper';
import { NoConfirmobile } from '../common/NoConfirmobile';
import { Confirmobile } from '../common/Confirmobile';
// import { PasswordConfirmationWrapper } from '../Registrations/PasswordConfirmation/PasswordConfirmationWrapper';



export const First = ({languages}) => {

  // const ip_address='http://localhost:8080'
  const ip_address='https://web.bfdrive.ma'

  const auth = useSelector((state) => state.auth.access_token)
  const profile = useSelector((state) => state.auth.profile)
  const profile_url = profile.url
  const digit=profile.digit_number
  console.log(digit)

  const mode=useSelector(state=>state.auth.mode)
  const vehicules = useSelector((state) => state.vehicules)
  


   
  const dispatch =useDispatch()



  useEffect(()=>{
    dispatch(fetchVehicules())
    dispatch(fetchNotifications())


  },[])

  const digit_format=(x)=>{
    switch (digit){
      case 0:
        return (Math.round(x * 10)/ 10).toFixed(0)
      case 2:
        return (Math.round(x * 100) / 100).toFixed(2)
      case 3:
        return (Math.round(x * 1000) / 1000).toFixed(3)

    }

    

  }
  console.log(digit_format(4.7876))

  // hendeling language change and orientaition

  const [currentLanguageCode,setCureentLanguagesCode]=useState(cookies.get('i18next') || 'en')
  const currentLanguage=languages.find(l => l.code===currentLanguageCode)
  useEffect(()=>{
    document.body.dir=currentLanguage.dir || 'ltr'


   },[currentLanguage])

   ////////////////////end



  return (

   

      
     
    <div className={`mode-${mode}`}>
    <Fragment>
    <Header auth={auth} vehicules={vehicules} selected_vehicule={vehicules.choosed_item} brand={vehicules.item.model} ip_address={ip_address} languages={languages} setLanguage={setCureentLanguagesCode} currentLanguage={currentLanguage}/>
    <Alerts />
    <Messages />
    <Routes>
  
        
        <Route path="/timeline" element={<History  ip_address={ip_address} digit_format={digit_format} currentLanguage={currentLanguage}/>}/>
        <Route path="/dashboard" element={<Dashboard  ip_address={ip_address} currentLanguage={currentLanguage}/>}>
            <Route path="services" element={<DashboardServices  ip_address={ip_address}/>} />
            <Route path="vehicles" element={<DashboardVehicles vehicules={vehicules}/>} />
            <Route path="situation" element={<DashboardGeneral ip_address={ip_address}/>} />
            <Route path="Reminders&Routes" element={<DashboardGeneral2 ip_address={ip_address}/>} />
            <Route path="overview" element={<DashboardGeneral3 ip_address={ip_address} digit_format={digit_format}/>} />
            {/* <Route path="storage" element={<DashboardStorage ip_address={ip_address} digit_format={digit_format}/>} /> */}
           
            <Route path="routes" element={<DashboardRoutes ip_address={ip_address}/>} />
            <Route path="myprofile" element={<Profile ip_address={ip_address}/>} />
        </Route>
        <Route path="/reminder" element={<Reminder  ip_address={ip_address} currentLanguage={currentLanguage} />} />
        <Route path="/confirmobile" element={<Confirmobile />} />
        <Route path="/noconfirmobile" element={<NoConfirmobile />} />
        <Route path="/change password" element={<ChangePass  ip_address={ip_address} />} />
        <Route path='/profile' element={<Profile ip_address={ip_address} currentLanguage={currentLanguage}/>}/>
        <Route path="/repport" element={<Rapport  ip_address={ip_address} currentLanguage={currentLanguage}/>} >
          <Route path='all' element={<RapportGeneral ip_address={ip_address}  digit_format={digit_format}/>}/>
          <Route path='refulings' element={<RapportRefuling digit_format={digit_format} ip_address={ip_address}/>}/>
          <Route path='expenses' element={<RapportExpense digit_format={digit_format} ip_address={ip_address}/>}/>
          <Route path='incomes' element={<RapportIncome digit_format={digit_format} ip_address={ip_address}/>}/>
          <Route path='services' element={<RapportService digit_format={digit_format} ip_address={ip_address}/>}/>
          <Route path='routings' element={<RapportRoute digit_format={digit_format} ip_address={ip_address}/>}/>{/* dépenses */}
        </Route>
        <Route path="/contact us" element={<ContactUs  ip_address={ip_address} currentLanguage={currentLanguage}/>} />
        <Route path="/404" element={<NotFound/>} />
        
        <Route path="/testing" element={<Testing />} />
        <Route path="/settings" element={<Settings  ip_address={ip_address} currentLanguage={currentLanguage}/>} >
        
        
          
            
            <Route path='plans' element={<ContentSettings  ip_address={ip_address} currentLanguage={currentLanguage}/>}/>
            <Route path='Expenses' element={<ExpenseChoices  mode={mode} currentLanguage={currentLanguage}/>}/>
            <Route path='formats' element={<Formats  mode={mode} digit_format={digit_format} currentLanguage={currentLanguage}/>}/>
            <Route path='reminders' element={<ReminderSetting  mode={mode} currentLanguage={currentLanguage}/>}/>
            <Route path='fuels' element={<FuelChoices mode={mode} currentLanguage={currentLanguage}/>} />
            <Route path='incomes' element={<IncomeChoices mode={mode} currentLanguage={currentLanguage}/>}/>
            <Route path='places' element={<PlaceChoices mode={mode}  currentLanguage={currentLanguage}/>}/>
            <Route path='reasons' element={<ReasonChoices  mode={mode} currentLanguage={currentLanguage}/>}/>
            <Route path='services' element={<ServicesChoices  mode={mode} currentLanguage={currentLanguage}/>}/>
            <Route path='stations' element={<StationsChoices  mode={mode} currentLanguage={currentLanguage}/>}/>
            <Route path="files" element={<DashboardFiles ip_address={ip_address} digit_format={digit_format} currentLanguage={currentLanguage}/>} />
            <Route path='myUsersPerVehicules' element={<MyUsersPerVehicule    ip_address={ip_address} currentLanguage={currentLanguage}/>}/>
            <Route path="myVehicules" element={<MyVehicules vehicules={vehicules} currentLanguage={currentLanguage}/>} />
            <Route path="privacy policy" element={<PrivacyPolicy/>} />
            <Route path="update_vehicule/:id" element={<UpdateVehiculeWrapper  ip_address={ip_address} currentLanguage={currentLanguage}/>} />
            <Route path="myUsers" element={<MyUsers  selected_vehicule={vehicules.choosed_item} ip_address={ip_address} currentLanguage={currentLanguage}/>} />

            
       </Route>
        <Route path="/reminderform" element={<AddReminder ip_address={ip_address} currentLanguage={currentLanguage}/>} />
        <Route path="/refulingform" element={<AddRefuling  ip_address={ip_address} currentLanguage={currentLanguage} digit_format={digit_format}/>} />
        <Route path="/incomeform" element={<AddIncome  ip_address={ip_address} currentLanguage={currentLanguage}/>} />
        <Route path="/expenseform" element={<AddExpense  ip_address={ip_address} currentLanguage={currentLanguage}/>} />
        <Route path="/vehicleform" element={<AddForm  ip_address={ip_address} currentLanguage={currentLanguage}/>} />
        <Route path="/serviceform" element={<AddService  ip_address={ip_address} currentLanguage={currentLanguage}/>} />
        <Route path="/routeform" element={<AddRoute  ip_address={ip_address} currentLanguage={currentLanguage}/>} />
        <Route path="/edit_expense/:id" element={<EditExpenseWrapper  ip_address={ip_address} currentLanguage={currentLanguage}/>} />
        <Route path="/edit_income/:id" element={<EditIncomeWrapper  ip_address={ip_address} currentLanguage={currentLanguage}/>} />
        <Route path="/edit_refuling/:id" element={<EditRefulingWrapper  ip_address={ip_address} currentLanguage={currentLanguage}/>} />
        <Route path="/edit_route/:id" element={<EditRouteWrapper  ip_address={ip_address} currentLanguage={currentLanguage}/>} />
        <Route path="/edit_service/:id" element={<EditServiceWrapper  ip_address={ip_address} currentLanguage={currentLanguage}/>} />
        <Route path="/edit_reminder/:id" element={<EditReminderWrapper  ip_address={ip_address} currentLanguage={currentLanguage}/>} />
        <Route path="/loading/:token" element={<LoadingWrapper  />} />
       
        
        <Route path="/login" element={<Navigate to="/timeline" />} />
        {/* <Route path="*" element={<Navigate to="/login" />} /> */}
     

    </Routes>
    <SideBare currentLanguage={currentLanguage}/> 
    <FooterBar currentLanguage={currentLanguage}/> 
    </Fragment>
    </div>
  

  )
}
