import React,{useEffect,useState} from 'react'
import { UPDATE_PROFILE } from '../../../../actions/types'
import { useDispatch,useSelector } from 'react-redux'
import { Modal } from '../../Modals/Modal'
import { Modal2 } from '../../Modals/Modal2'
import { useTranslation } from 'react-i18next'
import {MdDeleteForever} from 'react-icons/md'

export const PlaceChoices = ({mode}) => {
  const {t}=useTranslation()
  const [places,setPlaces]=useState([])
  const [place,setPlace]=useState('')
  const [showModal, setShowModal] = useState("none") 
  const dispatch=useDispatch()
  const current_profile = useSelector((state) => state.auth.profile)
  useEffect(() => {

      setPlaces(JSON.parse(current_profile.places_choice))


  }, [])

    ////////////////////////////:handle click outside model
////////////////// chooseType is the id of the input to
/////////////////////// avoid hidding the modal when typing in the input

const [modalOpen, setModalOpen] = useState(false);

const handleOpenModal = () => {
  setModalOpen(true);
};

const handleCloseModal = () => {
  setModalOpen(false);
};




const updateProfile=(data)=>{

  return function(dispatch){
fetch(current_profile.url, {
  headers: {
      'content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`
  },
    method: 'PATCH',
    body:  JSON.stringify(data),
  
},

).then(resp=>resp.json()).then(profile=>{if(profile.hasOwnProperty('avatar')){
          dispatch({
              type:UPDATE_PROFILE,
              payload:profile
          })
}else{
  alert('something went wrong pleaze try again later')
}})
}
}

const updatePlace=async(e)=>{
  e.preventDefault()
  places.push(place)  
 
  // const data=JSON.stringify(fuel_types)
  const data={
      'places_choice':JSON.stringify(places)
  }
 handleCloseModal()
  
  dispatch(updateProfile(data))
  
}



const updatePlace2=async(lieu)=>{
  // e.preventDefault()
  const new_places=places.filter((place)=>place!==lieu)
  setPlaces(new_places)
 
  // const data=JSON.stringify(fuel_types)
  const data={
      'places_choice':JSON.stringify(new_places)
  }
  console.log('this is',data)
  
  dispatch(updateProfile(data))
  
}

const setContent=()=>{
  return( <form onSubmit={updatePlace}>
  <input type='text' placeholder='name' className='form-control mb-2' onChange={(e)=>{setPlace(e.target.value)}} value={place}/>
  <button type='submit' className='btnOutline form-control'>{t("submit")}</button>
  </form>)
}





////////////////////////////////end 
  return (
      <div className='container-fluid m-5'>

        
        <div style={{"width":"90%","display":"flex","flexDirection":"row","justifyContent":"space-between","marginBottom":"50px"}}>
     <h3 className={`strike-${mode}`}>{t("Places")}</h3>

     <button type="button" class="btnOutline" onClick={()=>handleOpenModal()} style={{"width":"150px"}}>
  {t("Add")} 
</button>
</div>

<Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setContent}/>



    <div id='placesChoice' style={{"width":"90%","display":"flex","flexDirection":"row","justifyContent":"flex-start","flexWrap":"wrap","alignContent":"center","alignItems":"flex-start"}}>
        {places.map((lieu,index)=><div className='cases mb-3' key={index} style={{"width":"150px","height":"60px","display":"flex","justifyContent":"space-around","alignItems":"center","margin":"auto","border":"ridge 5px black"}}>{lieu} <span onClick={()=>updatePlace2(lieu)} style={{"cursor":"pointer"}}><MdDeleteForever/></span></div>)}
    </div></div>
  )
}
