import React,{useState} from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {FaPen} from 'react-icons/fa'
import {FaTruck,FaCarSide,FaBus} from 'react-icons/fa'
import{RiMotorbikeFill} from "react-icons/ri"
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from "react-icons"
import { Modal2 } from '../../FirstApp/Modals/Modal2'
import { useNavigate } from 'react-router-dom'
import { deleteVehicule } from '../../../actions/rappelActions'


export const TableRow = ({index,owner,url,nom,type,mode,vehicule_id,brand}) => {
    const {t}=useTranslation()
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpenModal = () => {
        setModalOpen(true);
      };
      
      const handleCloseModal = () => {
        setModalOpen(false);
      };
    const setContent_delete=()=>{
        return( <div className="delete-confirmation-dialog">
        <p>{t("Are you sure you want to delete this item?")}</p>
        <div style={{display:'flex',flexDirection:"row" ,alignItems:'center',justifyContent:'space-around'}}>
          <button className='btn btn-outline-danger' onClick={()=>{dispatch(deleteVehicule(vehicule_id));handleCloseModal()}}>{t("YES")}</button>
          <button className='btn btn-outline-info' onClick={handleCloseModal}>{t("NO")}</button>
        </div>
  
      </div>)
      }
      const setType=(param)=>{
        switch(param){
            case 'Moto':
                return <RiMotorbikeFill/>
                
            case 'Camion':
                return <FaTruck/>
                
            case 'Voiture':
                return <FaCarSide/>
                
            case 'Bus':
                return <FaBus/>
                
            default:
                return param
        }
    }
  return (
    <tr className={owner===url?'text-center':'text-center table-warning'}>
    <th scope="row" key={index}>{index+1}</th>
    <td>{nom}</td>
    <td>{brand}</td>
    <td>{setType(type)}</td>
    <td>
        <div style={{"display":"flex","justifyContent":"center"}}>
    <IconContext.Provider value={{ style: { color: "red", fontSize: "20px","marginRight":"20px",cursor:"pointer" }, className: "global-class-name" }} >
                      <div onClick={handleOpenModal}>
                      <MdDeleteForever/>
                      </div>
                    </IconContext.Provider>
                    <Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setContent_delete}/>
        <IconContext.Provider value={{ style: { color: "green", fontSize: "20px",cursor:"pointer" }, className: "global-class-name" }}>
                      <div onClick={()=>navigate(`/settings/update_vehicule/${vehicule_id}`)}>
                      <FaPen/>
                      </div>
                    </IconContext.Provider>
       </div>
         </td>
  </tr>
  )
}
