import React, { useRef, useEffect } from 'react';

export const Modal2 = ({mode,isOpen,onClose,setContent}) => {
  const modalRef = useRef();

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {//
      console.log(modalRef.current)
      console.log(!modalRef.current.contains(e.target))
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      console.log("added")
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;
  return (
    <div className="">
    <div id="myModal" class="modal1"  >


    <div class={`modal1-content-${mode} `}>         
    {/* ${currentLanguage.dir || 'ltr'} */}
 
      
        <div class={`modal-body ${mode}`} ref={modalRef}>
          {setContent()}
        </div>
    </div>
  </div>
  </div>
  )
}