import React from 'react'
import { useTranslation } from 'react-i18next'
import {MdDeleteForever} from 'react-icons/md'
import {BiPencil} from 'react-icons/bi'

export const Details = ({mode,Last_updated,place,reason,payment_method,owner,attached_file,note,value,handleOpenModal,redirect,start_odometre,start_date}) => {
    const { t } = useTranslation()
    const getFilename=(url)=>{
      if (url){
        const parts = url.split('/');
        const fileName = parts[parts.length - 1];
        return fileName
      }else{
        return ''
      }
    }
  return (
    <table class={`table table-${mode} mt-0`} >  
    {/* style={{padding:"0 !important"}} */}
  <thead>
    <tr>
      
      <th scope="col">{t("item")}</th>
      <th scope="col">{t("Detail")}</th>
   
    </tr>
  </thead>
  <tbody>
    <tr>
 
      <td>{t("Last Updated")}</td>
      <td>{Last_updated}</td>
 
    </tr>
    <tr>

      <td>{t("Place")}</td>
      <td>{place}</td>

    </tr>
    <tr>

<td>{t("Reason")}</td>
<td>{reason}</td>

</tr>
{start_odometre!=="none"&& <>
<tr>

<td>{t("Start odometer")}</td>
<td>{start_odometre}</td>

</tr>
<tr>

<td>{t("Start date")}</td>
<td>{start_date}</td>

</tr></> }
    {attached_file&&<tr>

<td>{t("attached file")}</td>
<td><a href={attached_file} target='_blank'>{getFilename(attached_file)}</a></td>

</tr>}
{note&&<tr>

<td>{t("Note")}</td>
<td> <p className='text-muted' style={{width:200,wordWrap:'break-word'}}>{note}</p></td>

</tr>}

<tr>

<td>{t("Actions")}</td>
<td>
  <div className='row'>
<div className='col-6' style={{cursor:'pointer',color:"red"}} onClick={handleOpenModal}>
                       
                       <MdDeleteForever/>
                        </div>
                        <div className='col-2' style={{cursor:'pointer',color:"green"}} onClick={redirect}>
                       
                       <BiPencil/>
                        </div>
                        </div>
</td>

</tr>
  </tbody>
</table>
  )
}
