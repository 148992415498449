import React,{useEffect,useState} from 'react'
import { useOutletContext } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { DashboardCard2 } from '../DashboardCard2'
import { DashboardGraph } from '../DashboardGraph'
import { useTranslation } from 'react-i18next'
import { DashboardLineChart } from '../DashboardLineChart'

export const DashboardGeneral3 = ({ip_address,digit_format}) => {
    const {t}=useTranslation()
    const[start_time,end_time,start_time1,end_time1]=useOutletContext() 
    const current_day=Date.now()/1000
    const vehicule_id=useSelector(state=>state.vehicules.item.id)
    const mode= useSelector(state=>state.auth.mode)
    const [balance,setBalance]=useState(0)
    const [route,setRoute]=useState()
    const [expense,setExpense]=useState({})
    const [distance,setDistance]=useState(0)
    const [cost,setCost]=useState(0)
    const [temps,setTemps]=useState((end_time-start_time)/86400)
    const [income,setIncome]=useState({})
    const [refuling,setRefuling]=useState({})
    const [service,setService]=useState({})
    const total_cost=expense.total+route+refuling.total+service.total
  



    const getDistance=(array)=>{
        console.log(array)
    
        switch(true){
          case array.length>1:
            const start=Object.keys(array[0])[0]
            const end=Object.keys(array[array.length-1])[0]
       
            const debut=array[0][start]['odometre']
            const fin=array[array.length-1][end]['odometre']
            if (distance<fin-debut){
                setDistance(fin-debut)
                break
            }

         
          case array.length==1:
            const start1=Object.keys(array[0])[0]
            const debut1=array[0][start1]['odometre']
            return {'temps':start1,'distance':debut1}
     
        }
    
      }

      
  const calculateObjectSum=(obj)=> {
    let sum = 0;
    
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'number') {
          sum += obj[key];
        }
      }
    }
    
    return sum;
  }

      useEffect(()=>{
        const getData=async()=>{
            setTemps((end_time-start_time)/86400)
            setCost(total_cost)
          const formdata=new FormData()
          formdata.append("start_time",start_time)//(Date.parse(new Date())-(start_time/1000))
          formdata.append("end_time",end_time)
          formdata.append("pk",vehicule_id)
          const data=await fetchData(formdata)
          console.log('here goes the data',data)
          const expense_data=data.general_expense
          expense_data.total=calculateObjectSum(data.general_expense)
        getDistance(data.expense)
          expense_data.all=data.expense
          console.log('expense_data',expense_data)
          setExpense(expense_data)
          const income_data=data.general_income
          income_data.total=calculateObjectSum(data.general_income)
          getDistance(data.income)
 
          income_data.all=data.income
          console.log('income_data',income_data)
          setIncome(income_data)
          const refuling_data=data.general_refuling
          refuling_data.total=calculateObjectSum(data.general_refuling)
      
          getDistance(data.refuling)
          refuling_data.all=data.refuling
          console.log('refuling_data',refuling_data)
          setRefuling(refuling_data)
          const service_data=data.general_service
          service_data.total=calculateObjectSum(data.general_service)

          setRoute(data.route_cost)
          service_data.all=data.service
          console.log('service_data',service_data)
          setService(service_data)
          getDistance(data.service)
          setBalance(total_cost-income.total)
          
          
        }
        getData()
      },[vehicule_id,start_time,end_time])

      const fetchData=async(data)=>{
        const request=await fetch(`${ip_address}/api/dashboard`, {
          method: 'POST',
          headers:{
              "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
          },
          body: data,
         
      },)
      
      console.log(request)
      const data1=await request.json()
      return data1
      }



    return (
        <div>
            <div className='row'>
                {/* <div className='col-md-8'> */}
                    <div style={{fontFamily:"AirStrike",fontSize:20}}>{t("Overview")}</div>
                    {/* <div className='row'> */}
                    <div className='col-md-4 m-auto'>
                 <DashboardCard2 titre={t('Bilan')} donnee1={income.total-total_cost} donnee2={temps?digit_format((total_cost-income.total)/temps):(total_cost-income.total)} donnee3={distance?digit_format((total_cost-income.total)/distance):(total_cost-income.total)}/>     
                    </div>
                    <div className='col-md-4 m-auto'>
                    <DashboardCard2 titre={t('Cost')} donnee1={total_cost} donnee2={temps?digit_format((total_cost)/temps):total_cost} donnee3={distance?digit_format((total_cost)/distance):total_cost}/>
                    </div>
                    {/* </div> */}
                    <div className='col-md-4 m-auto'>
                    <DashboardCard2 titre={t('Income')} donnee1={income.total} donnee2={temps?digit_format(income.total/temps):income.total} donnee3={distance?digit_format(income.total/distance):income.total}/>
                    </div>
                    </div>

                    <div className='row'>
                {/* <div className='col-md-8'> */}
                    {/* <div style={{fontFamily:"AirStrike",fontSize:20}}>{t("Overview")}</div> */}
                    {/* <div className='row'> */}
                    <div className='col-md-4 m-auto'>
                    <DashboardCard2 titre={t('Refueling')} donnee1={refuling.total} donnee2={temps?digit_format(refuling.total/temps):refuling.total} donnee3={distance?digit_format(refuling.total/distance):refuling.total}/>

                    </div>
                    <div className='col-md-4 m-auto'>
                    <DashboardCard2 titre={t('Expense')} donnee1={expense.total} donnee2={temps?digit_format((expense.total)/temps):expense.total} donnee3={distance?digit_format((expense.total)/distance):expense.total}/>
                    </div>
                    {/* </div> */}
                    <div className='col-md-4 m-auto'>
                    <DashboardCard2 titre={t('Service')} donnee1={service.total} donnee2={temps?digit_format((service.total)/temps):service.total} donnee3={distance?digit_format((service.total)/distance):service.total}/>
                    </div>
                    </div>



                    <div style={{fontFamily:"AirStrike",fontSize:20,marginTop:60}}>{t("Graphes")}</div>

                    <div className='row'>




                    <div className={`col-md-8`}>
                    <DashboardLineChart donnée_refuling={refuling.total} donnée_expense={expense.total} donnée_service={service.total} donnée_income={income.total} mode={mode} labels={['bar']} title='Cost comparision' startTime={start_time1} endTime={end_time1}/>
                    </div>
                    <div className={`col-md-4`}>
                    <DashboardGraph donnée={[refuling.total,expense.total,service.total,income.total]} labels={[t('Refueling'),t('Expense'),t('Service'),t('Income')]} label={`cost in Dhs`} title='Cost comparision' mode={mode}  startTime={start_time1} endTime={end_time1}/>
                    </div>
                    </div>
                {/* </div> */}
                {/* <div className='col-md-4' style={{display:'flex',flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                
               </div> */}
            </div>
       
    )
}
