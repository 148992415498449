import React,{useState,useEffect} from 'react'
import '../../stylings/Styles.css'
import { useSelector } from 'react-redux'
import { Link,useNavigate,useLocation } from 'react-router-dom'
import {AiFillDashboard} from 'react-icons/ai'
import { Modal } from '../Modals/Modal'
import addService from '../Reminder/Add_Service.png'
import { useTranslation } from 'react-i18next'
import dashboardLight from './icons/Light/dashboard.png'
import historyLight from './icons/Light/history.png'
import reportLight from './icons/Light/report.png'
import reminderLight from './icons/Light/reminder.png'
import settingsLight from './icons/Light/settings.png'
import dashboardDark from './icons/Dark/dashboard.png'
import historyDark from './icons/Dark/history.png'
import reportDark from './icons/Dark/report.png'
import reminderDark from './icons/Dark/reminder.png'
import settingsDark from './icons/Dark/settings.png'


// import 'boxicons'

export const SideBare = ({currentLanguage}) => {
  const location= useLocation()
  const {t}=useTranslation()
    const navigate=useNavigate()
    const [showModal, setShowModal] = useState("none")
    const mode=useSelector(state=>state.auth.mode)
    const [screenSize, setScreenSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
  
    useEffect(() => {

      console.log("location",location)
      // Add event listener to handle window resize
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [])



    // show or hide the modal

    const toggleShow = () => {
        
        if (showModal === "none") {
        
          setShowModal("block")
        } else {
          setShowModal("none")
        }
      }

      // hide modal after choice
      const surf=(url)=>{
        navigate(url)
        setShowModal('none')

      }

    
        if (showModal=='block') {
          document.getElementById('myModalMan').addEventListener("click",toggleShow);
        }
        //  else {
        //   document.removeEventListener("click", toggleShow);
        // }
      
 

      const setContent_add=()=>{
        return(<ul className="list-group ">
          <li className={` ${mode}`} style={{display:'block' ,padding:"0.5rem 1rem"}}><a onClick={()=>surf('/refulingform')} className="modalButtons"><img src={`https://web.bfdrive.ma/media/icons/gas-pump.svg`} style={{"width":"30px","height":"100%",marginRight:5}}/><span className={`ModalForms-${mode}`} style={{marginRight:5}}>{t("Refueling")}</span></a></li>
          <li className={` ${mode}`} style={{display:'block' ,padding:"0.5rem 1rem"}}><a onClick={()=>surf('/serviceform')} className="modalButtons" ><img src={`https://web.bfdrive.ma/media/icons/wrench.svg`} style={{"width":"30px","height":"100%",marginRight:5}}/><span className={`ModalForms-${mode}`} style={{marginRight:5}}>{t("Service")}</span></a></li>
          <li className={` ${mode}`} style={{display:'block' ,padding:"0.5rem 1rem"}}><a onClick={()=>surf('/expenseform')} className="modalButtons"><img src={`https://web.bfdrive.ma/media/icons/credit-card.svg`} style={{"width":"30px","height":"100%",marginRight:5}}/><span className={`ModalForms-${mode}`} style={{marginRight:5}}>{t("Expense")}</span></a></li>
          <li className={` ${mode}`} style={{display:'block' ,padding:"0.5rem 1rem"}}><a onClick={()=>surf('/incomeform')} className="modalButtons"><img src={`https://web.bfdrive.ma/media/icons/wallet.svg`} style={{"width":"30px","height":"100%",marginRight:5}}/><span className={`ModalForms-${mode}`} style={{marginRight:5}}>{t("Income")}</span></a></li>
          <li className={` ${mode}`} style={{display:'block' ,padding:"0.5rem 1rem"}}><a onClick={()=>surf('/routeform')} className="modalButtons"><img src={`https://web.bfdrive.ma/media/icons/road.svg`} style={{"width":"30px","height":"100%",marginRight:5}}/><span className={`ModalForms-${mode}`} style={{marginRight:5}}>{t("Route")}</span></a></li>
          <li className={` ${mode}`} style={{display:'block' ,padding:"0.5rem 1rem"}}><a onClick={()=>surf('/reminderForm')} className="modalButtons"><img src={`https://web.bfdrive.ma/media/icons/stopwatch.svg`} style={{"width":"30px","height":"100%",marginRight:5}}/><span className={`ModalForms-${mode}`} style={{marginRight:5}}>{t("Reminder")}</span></a></li>
        </ul>)
      }
    return (
        <nav className={`sidebar_${currentLanguage.dir || 'ltr'} sidebar sidebar-${mode}`} >


            <header >
                {screenSize.width > 1000?<div>
                    <span className='image'>
                    <img src='/media/logo.png' style={ {
  width: '200px',
  marginTop: '-65px',
  marginBottom: "-90px",
}}/>{/* changed for the hosting puposes */}
                    </span>

                    
                </div>:<div className='image-text'>
                    <span className='image'>
                    <img src='/media/Logo.svg' />{/* changed for the hosting puposes */}
                    </span>

                    <div className='text header-text'>
                        <span className={`brand-${mode}`}>{screenSize.width}</span>


                    </div>
                </div>}

            </header>
            <div className='menu-bar'>
                <div className='menu'>
                    <ul className='menu-links'>
                    <li className='nav-link icons'><Link to='/dashboard/overview'><img src={mode==='light'?dashboardLight:dashboardDark} className='bx' style={{"width":"25px","height":"25px",marginRight:10}}/><span className='nav-text' style={{marginRight:5}}>{t("Dashboard")}</span></Link></li>
                    
                        <li className='nav-link icons'><Link to='/timeline'><img src={mode==='light'?historyLight:historyDark} className='bx' style={{"width":"30px","height":"30px",marginRight:10}}/><span className='nav-text' style={{marginRight:5}}>{t("History")}</span></Link></li>
                        <li className='nav-link icons'><Link to='/reminder'><img src={mode==='light'?reminderLight:reminderDark} className='bx' style={{"width":"30px","height":"30px",marginRight:10}}/><span className='nav-text' style={{marginRight:5}}>{t("Reminder")}</span></Link></li>
                        <li className='nav-link icons'><Link to='/repport/all'><img src={mode==='light'?reportLight:reportDark} className='bx' style={{"width":"30px","height":"30px",marginRight:10}}/><span className='nav-text' style={{marginRight:5}}>{t("Repport")}</span></Link></li>
                        <li className='nav-link icons'><a onClick={()=>toggleShow()} style={{ "cursor": "pointer" }}><img src={addService}  style={{width:"35px" ,height:"35px",marginRight:10}}/><span className='nav-text' style={{marginRight:5}}>{t("Add")} {t("Service")}</span></a> </li>
                        <hr style={{color:"#BD3136",height:3}}/>







                        <li className='nav-link icons'><Link to='/settings/plans'><img src={mode==='light'?settingsLight:settingsDark} className='bx' style={{"width":"30px","height":"30px",marginRight:10}}/><span className='nav-text' style={{marginRight:5}}>{t("Settings")}</span></Link></li>
                    </ul>
                </div>

            </div>

            <Modal modal={'Man'} mode={mode} showModal={showModal} setContent={setContent_add}/>
        </nav>
    )


}
