import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut,Pie } from "react-chartjs-2";
import { convertBytes } from '../../Settings/Settings';
import { convertBytesDigits } from '../../Settings/Settings';
import { useTranslation } from 'react-i18next';




export const DashboardStorage = ({ip_address,changed,mode}) => {

   const [list_size,setList_size]= useState([])
   const [chart,setChart]=useState('pie')
   const [boutton,setBoutton]=useState('Doughnut')
   const {t}=useTranslation()
   

  useEffect(()=>{
    const getSise=async()=>{
      const datum=await fetchSize()
      switch(datum){
        case 'no data':
          alert('there is no data from the server')
          break
        default:
          setList_size([convertBytesDigits(datum.data),convertBytesDigits(datum.available_storage)])
          break
      }
    }
    getSise()
  },[changed])

  const fetchSize=async()=>{
    const request=await fetch(`${ip_address}/app/api/storage/`, {

      headers: {
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
      }
    }
    )

    switch(request.status){
      case 200:
        const data=await request.json()
        return data
      default:
        return 'no data'
    }


  }

  const chartNature=(data)=>{
    switch(chart){
      case 'pie':
        return <Pie data={data} />
      case 'doughnut':
        return <Doughnut data={data} />
   
    }
  }


  const toggleChart=()=>{
    switch(chart){
      case 'Pie':
        setChart('Doughnut')
        setBoutton('Pie')
        break
      case 'Doughnut':
        setChart('Pie')
        setBoutton('Doughnut')
        break
    }
  }


  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = {
    labels: [
      t('Consommation in Kb'),
      t('Allowed storage in Kb'),
    
    ],
    datasets: [{
      label: 'My First Dataset',
      data: list_size,
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',

      ],
      hoverOffset: 4
    }]
  };
  return (
    <div className={`graphs_${mode} dFlex`}>
      {chartNature(data)}
      <button className='btnOutline m-4' onClick={()=>toggleChart()}>{t(boutton)}</button>
    </div>
  )
}
