import { logoutUser } from "../../../actions/auth";
import { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { settingVehicule } from "../../../actions/rappelActions"
import { toggleMode } from "../../../actions/modeActions";
import { useSelector } from "react-redux";
import { updateOwnVehicule } from "../../../actions/rappelActions";
import { deleteNotifications } from "../../../actions/notificationActions";
import './Header.css'
import { AddForm } from "../Forms/AddVehicule/AddForm";
import { Link, useNavigate } from "react-router-dom";
import { MdDarkMode , MdLightMode } from 'react-icons/md'
import {FaCrown,FaGooglePlay,FaAppStore} from 'react-icons/fa'
import { GiSteeringWheel,GiCheckMark } from "react-icons/gi"
import { SiHuawei } from "react-icons/si";
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from "react-icons"
import i18next from "i18next";
import {TbWorld} from 'react-icons/tb';
import us from './flags/usa.png'
import ma from './flags/maroc.png'
import fr from './flags/france.png'
import '../../../../node_modules/flag-icons/css/flag-icons.min.css'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { GET_MESSAGES,GET_ERRORS,CREATE_MESSAGES,READ_NOTIFICATION } from "../../../actions/types";
import cars from '../../../cars.json'
// webSockets
import WebSocket from 'websocket';






export const Header = ({ auth, vehicules, selected_vehicule,brand, ip_address,languages,setLanguage,currentLanguage}) => {


  const {t}=useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [notifi_reminders, setNotifi_reminders] = useState([]);
  const [alarms,setAlarms]=useState([])
  const mode = useSelector(state => state.auth.mode)
  const avatar = useSelector(state => state.auth.profile.avatar)
  const [notifications,setNotifications] = useState([])
  const utilisateur=useSelector(state => state.auth.user)
  const user_url = utilisateur.url
  const is_super = utilisateur.is_superuser
  const user_id=utilisateur.id
  const profile_url = useSelector(state => state.auth.profile.url)
  const plan_name = useSelector(state => state.auth.profile.plan_name)
  const [show, setShow] = useState("none")
  const [seen, setSeen] = useState(false)
  const [unread_notifications,setUnread_notifications]=useState(0)
  const [unread_alarms,setUnread_alarms]=useState(0)
  const [countryCode,setCountryCode]=useState(currentLanguage.code)


  // notification reminders
  
  useEffect(() => {
    const getAlarms=async()=>{
      const alarms_=await fetchAlarms()
      const notifications_=await fetchNotifications()
      console.log(alarms_)
      console.log(notifications_)
      setNotifications(notifications_.results)
      setAlarms(alarms_.results)
      console.log(alarms_.results)
      setUnread_notifications((notifications_.results.filter(item=>item.read=='false')).length)
      setUnread_alarms((alarms_.results.filter(item=>!item.read)).length)


    }
    
    getAlarms()


    const socket = new WebSocket.w3cwebsocket(`ws://localhost:8080/ws/notifications/${user_id}/`);

    socket.onmessage = (event) => {
      const notifi_reminder = JSON.parse(event.data)
      console.log(notifi_reminder);
      setNotifi_reminders(prevNotifications => [...prevNotifications, notifi_reminder.message]);
      console.log(notifi_reminder)
    };

    return () => {
      socket.close();
    };
 
    
    
  }, [notifi_reminders]);

  const fetchAlarms=async()=>{
      const requette=await fetch(`${ip_address}/app/api/alarms`, {

        headers: {
          "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
      switch(requette.status){
        case 200:
          return await requette.json()
        default :
          break
   
      }
  }

  const fetchNotifications=async()=>{
    const requette=await fetch(`${ip_address}/app/api/ownNotifications/`, {

      headers: {
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
      }
    })
    switch(requette.status){
      case 200:
        return await requette.json()
      default :
        break
 
    }
}
  // set ALarms to read

  const readAlarms=async(id)=>{
    const formData=new FormData()
    formData.append('read',true)
    const requette=await fetch(`${ip_address}/app/api/alarms/${id}/`, {

      headers: {
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
      },
      method:'PATCH',
      body: formData,
    })
    console.log(requette.status)
    switch(requette.status){
      case 200:
        console.log('alarm ',id,'read')
          break
        default:
          console.log('alarm ',id,'not read')
          break
 
    }
}

const readNotification=async(id)=>{
  const formData=new FormData()
  formData.append('read','true')
      const action=await fetch(`${ip_address}/app/api/ownNotifications/${id}/`,{
          headers:{
              "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
          },
          method:'PATCH',
          body: formData,
       })
       const status=action.status
       switch(status){
        case 200:
          console.log('notification ',id,'read')
          break
        default:
          console.log('notification ',id,'not read')
          break
           
}}

//

// all notifications are read
const allNotificationsRead=()=>{
  for(let notif in notifications){
      readNotification(notifications[notif].id)
  }
  for ( let ala in alarms){
    readAlarms(alarms[ala].id)
  }

} 
//            


  const deleteAlarms=async(id)=>{
    const requette=await fetch(`${ip_address}/app/api/alarms/${id}`, {

      headers: {
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
      },
      method:'DELETE'

    })
    switch(requette.status){
      case 204:
        dispatch({
          type:CREATE_MESSAGES,
          payload:t(`Alarm deleted successfully`),
      })
      setAlarms(alarms.filter(alarm=>alarm.id!=id))
        break
      default :
      dispatch({
        type:GET_ERRORS,
        payload:{
            msg:"something went wrong",
            status:requette.status
        }
    })
        break
 
    }
}

  console.log(notifi_reminders)
  const toggleIcon = () => {

    switch (mode) {

      case 'light':
        return <MdDarkMode />
      case 'dark':
        return <MdLightMode />
    }
  }

// hendeling language change and orientaition

  // const [currentLanguageCode,setCureentLanguagesCode]=useState(cookies.get('i18next') || 'en')
  // const currentLanguage=languages.find(l => l.code===currentLanguageCode)
  // useEffect(()=>{
  //   document.body.dir=currentLanguage.dir || 'ltr'
  //   localStorage.setItem('orientation',currentLanguage.dir || 'ltr')

  //  },[currentLanguage])

   ////////////////////end

  const toggleShow = () => {
    switch (show) {

      case "none":
        setShow("block")
        setSeen(true)
        break
      case "block":

        setShow("none")
        break
    }
  }

  // setting the flag

  const flag=(variable)=>{
switch (variable) {
  case 'us':
    return us
    case 'fr':
      return fr  
      case 'ma':
        return ma

}
  }

  const accepte_request = (id, notification_id) => {
    const formdata = new FormData()
    formdata.append("driver", 'accept')
    formdata.append("driver", profile_url)
    dispatch(updateOwnVehicule(id, formdata))
    dispatch(deleteNotifications(notification_id))
  }

  const declineRequest = async (noti) => {
    const formdata = new FormData()
    formdata.append('owner', user_url)
    // formdata.append('to_user',noti.owner)
    formdata.append('alias', `your request to ${noti.owner} N ${noti.id}  has been rejected`)
    formdata.append('text', `this is rejection notification ${noti.id}`)
    formdata.append('requested_vehicule_id', noti.requested_vehicule_id)
    formdata.append('declined', false)

    const action = await fetch(`${ip_address}/app/api/ownNotifications/`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
      },
      method: 'POST',
      body: formdata
    })
    const status = action.status

    switch (status) {
      case 201:

        dispatch(deleteNotifications(noti.id))
        //     break
        // case 403:
        //     alert('this is not your vehicule')
        break
      default:
        alert('something went wrong')
    }
  }




  return (
    <nav class={`navbar navbar-expand-lg navbar-${mode} bg-${mode} navbar-${currentLanguage.dir || 'ltr'}`}>

      <div class="container">
        {selected_vehicule?<a class="navbar-brand dropdown-toggle" href="#" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false"><img src={cars[selected_vehicule]} style={{width:40}}/><span className={`dropdown-text-${mode}`} style={{fontFamily:"Gruppo",fontSize:30,fontWeight:"bold"}}>{brand}</span></a>:<button type="button" className="btn btn-danger" onClick={()=>navigate('/vehicleform')}>
            <span className={`dropdown-text-${mode} text-white`}  style={{fontWeight:"bold"}}>{t("New vehicule")}</span>
          </button>}
        <ul class={`dropdown-menu bg-${mode} addVehicle-${currentLanguage.dir || 'ltr'}`} aria-labelledby="navbarDropdown1">

         
          {vehicules.items.map((item) => (<li><a class="dropdown-item" onClick={() => dispatch(settingVehicule(item))}><img src={cars[item.vehicule_name]} style={{width:30}}/><span className={`dropdown-text-${mode}`} style={{fontWeight:"bold"}}>{item.owner === user_url ? item.model : `${item.model} (driver)`}</span></a></li>))}
          <hr class="dropdown-divider" />
          <li><button type="button" class="dropdown-item" onClick={()=>navigate('/vehicleform')}>
            <span className={`dropdown-text-${mode}`}  style={{fontWeight:"bold"}}>{t("New vehicule")}</span>
          </button>
          </li>
        </ul>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item" style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
              <a class="nav-link" onClick={() => dispatch(toggleMode())}>
                <IconContext.Provider value={{ style: { color: mode === 'light' ? "darkgray" : "darkorange", fontSize: "30px" }, className: "global-class-name" }}>
                  {toggleIcon()}
                </IconContext.Provider>
              </a>
            </li>
            <li class="nav-item" style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
              <Link class="nav-link" to={'/settings/plans'} style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                <IconContext.Provider value={{ style: { color: mode === 'light' ? "#ffdb58" : "#FFD700", fontSize: "30px",paddingTop:10 }, className: "global-class-name" }}>
                <FaCrown />
           <p style={{fontSize:8}}>{plan_name}</p>
                </IconContext.Provider>
              </Link>
            </li>

            <li className='dropdown nav-item' style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
          <a data-bs-toggle="dropdown" className="dropdown-toggle nav-link" style={{"cursor":"pointer"}}><img src={flag(currentLanguage.country_code)} style={{"width":"30px","height":"30px"}}/></a>  
            <ul className='dropdown-menu'>
              <div  style={{display: 'flex',flexDirection: 'column',alignItems: 'flex-start',padding: '10px'}}>
            {languages.map(({ code, name, country_code }) => (
                <li key={country_code} style={{marginBottom:10}}>
                  <a  onClick={() => {
                    i18next.changeLanguage(code);
                    setLanguage(code)
                    }} style={{"cursor":"pointer",margin:5}}>
                      <img src={flag(country_code)} style={{"width":"25px","height":"25px"}}/> {name}
                  </a>
                </li>
              ))}</div>
            </ul>
          </li>
            <li class="nav-item" style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
              <a class="nav-link active" aria-current="page" >
                <div onClick={() => {toggleShow();allNotificationsRead();setUnread_alarms(0);setUnread_notifications(0)}}>
                  <img src={`${ip_address}/media/reminder.png`} style={{ "width": "30px" }} /></div>
               {(unread_alarms+unread_notifications)? <span className="badge">{unread_alarms+unread_notifications}</span>:<></>}
                <div class={currentLanguage.dir==="ltr"?"notification-container":"notification-containerRTL"} style={{ "display": show }} >

                  <ul class="list-group">
                    {notifi_reminders.length+alarms.length+unread_alarms+unread_notifications>0?<li class="list-group-item list-group-item-primary">
                    <div className="dFlexRow">
                        <div style={{fontWeight:"bold",fontSize:10}}>{t("Notifications")}</div>
                        {/* <div><button className="btn btn-primary notificationClose" onClick={() => setShow("none")}>{t("Close")}</button></div> */}
                      </div>
                      {/* <a onClick={() => setShow("none")}>{t("close")}</a> */}
                    </li>:<li class="list-group-item list-group-item-primary">
                      <div className="dFlexRow">
                        <div style={{fontWeight:"bold",fontSize:10}}>{t("You dont have any notification")}</div>
                        {/* <div><button className="btn btn-primary notificationClose" onClick={() => setShow("none")}>{t("Close")}</button></div> */}
                      </div>
                      </li>}
                    {/* reals time alarms for reminders through channels */}
                  {notifi_reminders.map((notifivation)=><li className="list-group-item list-group-item-danger">{notifivation}</li>)}
                    {/* request drivers notifications */}
                    {notifications.map((notification, index) =>
                      <li class={notification.declined ? "list-group-item list-group-item-danger" : "list-group-item list-group-item-info"} key={index} style={{ "display": "flex", "justifyContent": "space-between", "alignItems": "center" }}>
                        <div className="notification-content">
                          <span className="notification_fonts">{notification.alias}</span><br />
                          {!notification.declined && <div style={{ "display": "flex", "justifyContent": "space-between", "alignItems": "center"}}> <button className="btn btn-success" onClick={() => accepte_request(notification.requested_vehicule_id, notification.id)}> <GiCheckMark/></button>
                            <button className="btn btn-danger" onClick={() => declineRequest(notification)}>X</button></div>}
                        </div>

                        <div className="text-danger" onClick={() => dispatch(deleteNotifications(notification.id))} style={{fontSize:30}}>
                        <MdDeleteForever/>
                        </div>
                      </li>)}


{/* Notification testing ////////////////////////////////////////// */}

{/* End Notification testing ///////////////////////////////////////// */}



                      {/* alarms for reminders through useEffect hook */}
                      {alarms.map((alarm, index) =>
                      <li class={alarm.read ? "list-group-item list-group-item-danger" : "list-group-item list-group-item-warning"} key={index} style={{ "display": "flex", "justifyContent": "space-between", "alignItems": "center" }}>
                        <div className="notification-content">
                          <span className="notification_fonts">{alarm.notification}</span><br />
                         
                        </div>

                        <div className="text-danger" onClick={() => deleteAlarms(alarm.id)} style={{fontSize:30}}>
                        <MdDeleteForever/>
                        </div>
                      </li>)}

<li class="list-group-item list-group-item-primary">
                      <div className="dFlexRow">
                        {/* <div style={{fontWeight:"bold",fontSize:10}}>{t("You dont have any notification")}</div> */}
                        <div><button className="btn btn-primary notificationClose" onClick={() => setShow("none")}>{t("Close")}</button></div>
                      </div>
                      </li>
                    
                    
                  </ul>



                </div>
                {/* notification container end */}
                { }

              </a>
            </li>

            <li class={currentLanguage.dir?(currentLanguage.dir==="ltr"?`nav-item dropstart`:`nav-item dropend`):`nav-item dropstart`} style={{ "display": "flex", "flexDirection": "column", "justifyContent": "center", "alignItems": "center"}}>
              <a class="nav-link" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={localStorage.getItem('googlePic')?localStorage.getItem('googlePic'): avatar} className="rounded-circle" style={{ "width": "50px" }} />
              </a>
              <ul class={currentLanguage.dir?`dropdown-menu bg-${mode} dropdown${currentLanguage.dir}`:`dropdown-menu bg-${mode}`} aria-labelledby="navbarDropdown" style={{textAlign:"center"}}>

                <li><Link class="dropdown-item" to={"/profile"}><span className={`dropdown-text-${mode}`}>{t("Profile")}</span></Link></li>
                <li><Link class="dropdown-item" to={"/contact us"}><span className={`dropdown-text-${mode}`}>{t("Contact us")}</span></Link></li>
                <li><a class="dropdown-item" onClick={() => {
                  dispatch(logoutUser(auth))
                  navigate('/backToLogin')
                }} ><span className={`dropdown-text-${mode}`}>{t("Logout")}</span></a></li>
               <li><hr class="dropdown-divider" /></li>
                {is_super && <li><a class="dropdown-item" href="/bfadminpartie"><span className={`dropdown-text-${mode}`}>{t("Admin")}</span></a></li>}
                <li>
                  <div  style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
                  <a class="dropdown-item" href="https://appgallery.huawei.com/app/C110599383" style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} target="_blank"> 
                  <IconContext.Provider value={{ style: { color: mode === 'light' ? "darkgray" : "lightgray", fontSize: "30px" }, className: "global-class-name" }}>
                  <SiHuawei />
                  </IconContext.Provider>
                  </a>
                  <a class="dropdown-item" href="https://play.google.com/store/apps/details?id=com.bfdrive.mcar" style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} target="_blank"> 
                  <IconContext.Provider value={{ style: { color: mode === 'light' ? "darkgray" : "lightgray", fontSize: "30px" }, className: "global-class-name" }}>
                  <FaGooglePlay />
                  </IconContext.Provider>
                  </a>

                  </div>
                </li>
              </ul>
            </li>

       

          </ul>
          {/* <div class="d-flex">

      </div> */}
        </div>
      </div>




      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class={`modal-content  bg-${mode}`}>
            <div class="modal-header">
              <h1 class={`modal-title fs-5 ${mode}`} id="exampleModalLabel">Add vehicule</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <AddForm mode={mode} ip_address={ip_address} />
            </div>
            {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div> */}
          </div>
        </div>
      </div>



    </nav>

  )
}
