import React,{useState} from 'react'
import {FaPencilAlt} from "react-icons/fa"
import {GrFacebook,GrInstagram,GrTwitter} from "react-icons/gr"
import { useSelector,useDispatch } from 'react-redux';
import { UPDATE_PROFILE } from '../../../actions/types';
import { updateUser } from '../../../actions/rappelActions';
import { useTranslation } from 'react-i18next'
import './Profile.css'
import { Link, useNavigate } from 'react-router-dom';
import { CREATE_MESSAGES } from '../../../actions/types';
import { logoutUser } from '../../../actions/auth';

export const Profile = ({ ip_address,currentLanguage }) => {
  const {t}=useTranslation()
    const dispatch=useDispatch()
    const navigate = useNavigate()
    const mode=useSelector(state=>state.auth.mode)
    const user_url=useSelector(state=>state.auth.user.url)
    const user=useSelector(state=>state.auth.user)
    const profile=useSelector(state=>state.auth.profile)
    const[url,setUrl]=useState(profile.avatar)

    const[image,setImage]=useState(null)
    const [username, setUsername]=useState('')
    const [name, setName]=useState('')
    const [firstname, setFirstname]=useState('')
    const [lastname, setLastame]=useState('')
    const [show, setShow]=useState(false)
    const [show2, setShow2]=useState(false)
    const [show3, setShow3]=useState(false)
    const [email,setEmail]=useState('')
    const [phone,setPhone]=useState('')

    const readURL= async (e)=>{
        if (e.target.files && e.target.files[0]) {
            console.log(e.target.files[0])
            
            var reader = new FileReader();
            reader.onload = function(e) {
              
                setUrl(e.target.result)
            }
            reader.readAsDataURL(e.target.files[0]);
            setImage(e.target.files[0])
        }
    }
    const toggleShow=()=>{
      switch(show){
        case true:
          setShow(false)
          break
        case false:
          setShow(true)
          break
      }
    }

    const toggleShow2=()=>{
      switch(show2){
        case true:
          setShow2(false)
          break
        case false:
          setShow2(true)
          break
      }
    }

    const toggleShow3=()=>{
      switch(show3){
        case true:
          setShow3(false)
          break
        case false:
          setShow3(true)
          break
      }
    }
    const onSubmitProfile=(e)=>{
        e.preventDefault()
        const formdata= new FormData()
        if (firstname){
        formdata.append('firstname',firstname)}
        if (lastname){
          formdata.append('lastname',lastname)}
        if (phone){
        formdata.append('phone',phone)
                  }
        if   (image){   
      //  formdata.append('email',email)
        formdata.append('avatar',image)}
        dispatch(updateProfile(formdata))
        setShow(false)
        setShow2(false)
    }


    const onSubmitUser=(e)=>{
      e.preventDefault()
      const formdata= new FormData()
      if (username){
      formdata.append('username',username)}
        if (email){
          formdata.append('email',email)
        }
      dispatch(updateUser(user_url,formdata))
      setUsername('')
      setEmail('')
  
  }


    const updateProfile = (data) => {

        return function (dispatch) {
          fetch(profile.url, {
            headers: {
              // 'content-Type': 'multipart/form-data;boundary=------border',
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            method: 'PATCH',
            body: data,
    
          },
    
          ).then(resp => resp.json()).then(profile => {
            if (profile.hasOwnProperty('avatar')) {
              dispatch({
                type: UPDATE_PROFILE,
                payload: profile
              })
            } else {
              alert('something went wrong pleaze try again later')
            }
          })
        }
      }

      // deleting my profile

      const deleteMyAccount=async()=>{
        const request=await fetch(user_url, {
          method: 'DELETE',
          headers: {
          //   'Content-Type': 'application/json',
            "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
          },
      
        })

        const status=request.status
        switch(status){
          case 204:
            dispatch({
              type:CREATE_MESSAGES,
              payload:`User Deleted Successfully`,
          })

          dispatch(logoutUser(localStorage.getItem('access_token')))
          break

          default:
            alert('something is wrong')
            break
        }
      }
    
    
    return (


      <section className={`all_sections mt-4 ${currentLanguage.dir || 'ltr'}`}>
      <div className={`content-${mode} whitewhen${mode}`}  style={{marginBottom:50}}>
     <div className='row' style={{width:'100%',display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
                <div className='col-lg-12 col-sm-12 mb-1 me-1 p-0' id={`account-user-card-${mode}`} >
                  <form onSubmit={onSubmitProfile}>
                <div class="card" style={{border:"0px"}}>     
                <div className='primary-overlay'>
        <div className="avatar-upload">
            <div className="avatar-edit">
            
                <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" onChange={(e)=>readURL(e)}/>
                <label for="imageUpload" style={{"textAlign":"center"}} ><FaPencilAlt/></label>
            </div>
            <div className="avatar-preview">
                <div id="imagePreview" style={{"background-image": `url(${localStorage.getItem('googlePic')?localStorage.getItem('googlePic'): url})`}}>
                </div>
            </div>
        </div></div>       
                 
                        <div class="card-body text-center m-5">

                        <p class={`card-text text-${mode}`}>{t("Plan")} : <Link to={'/settings/plans'}>{profile.plan_name}</Link></p> 
                            {/* <img src={profile.avatar} alt="" class="img-fluid rounded-circle w-50" style={{"height":"100px"}} /> */}
   
                            {/* <div style={{"marginBottom":"0px"}}><span style={{"fontSize":"20px"}}>{profile.firstname}</span><span style={{"fontSize":"10px","backgroundColor":"white","padding":"5px","borderRadius":"50%","color":"black","cursor":"pointer"}} onClick={()=>toggleShow()}><FaPencilAlt/></span></div> */}
                            {/* {show && */}
                                <input type="text" class="form-control mt-2" placeholder={profile.firstname} aria-label="firstname" onChange={(e)=>setFirstname(e.target.value)} value={firstname}/>
                                {/* } */}
                                 {/* <div style={{"marginBottom":"0px"}}><span style={{"fontSize":"20px"}}>{profile.lastname}</span><span style={{"fontSize":"10px","backgroundColor":"white","padding":"5px","borderRadius":"50%","color":"black","cursor":"pointer"}} onClick={()=>toggleShow2()}><FaPencilAlt/></span></div> */}
                            {/* {show2 && */}
                                <input type="text" class="form-control mt-2" placeholder={profile.lastname} aria-label="lastname" onChange={(e)=>setLastame(e.target.value)} value={lastname}/>
                                {/* } */}
                                <input type="email" class="form-control mt-2" placeholder={profile.email} aria-label="email"  disabled/>
                             {/* <div style={{"marginBottom":"0px"}}><span style={{"fontSize":"20px"}}>{profile.phone}</span><span style={{"fontSize":"10px","backgroundColor":"white","padding":"5px","borderRadius":"50%","color":"black","cursor":"pointer"}} onClick={()=>toggleShow3()}><FaPencilAlt/></span></div> */}
                            {/* {show3 && */}
                                <input type="tel" class="form-control mt-2" placeholder={profile.phone} aria-label="Phone number" onChange={(e)=>setPhone(e.target.value)} value={phone}/>
                                {/* } */}
                                <p class="card-text"><Link to={'/change password'}>{t("Change password")}</Link></p>
          
                            {/* <div class="d-flex justify-content-center">
                                <a href="#" class="m-4">
                                   <div><GrTwitter /></div> 
                                </a>
                                <a href="#" class="m-4">
                                    <GrFacebook />
                                </a>
                                <a href="#" class="m-4">
                                    <GrInstagram />
                                </a>
                            </div> */}
                            <button className='btnOutline form-control mt-3'>{t("Submit changes")}</button>

                            
                            
                          
                        </div>
                    </div>
                    </form>

                    {/* <button onClick={} className=>{t("Delete My Account")}</button> */}

                    
<button type="button" class='btnOutline form-control mt-3' data-bs-toggle="modal" data-bs-target="#deleteUserModal">
{t("Delete My Account")}
</button>


<div class="modal fade" id="deleteUserModal" tabindex="-1" aria-labelledby="deleteUserModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
   
      <div class="modal-body">
        {t("Are you sure you want to delete this Account")}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t("Close")}</button>
        <button type="button" class="btn btn-danger" onClick={deleteMyAccount}>{t("Delete My Account")}</button>
      </div>
    </div>
  </div>
</div>

                </div>
                
                {/* <div className='col-lg-7 p-3 ' id={`account-form-${mode}`}  >
                    <div className='account-form-header mb-2' style={{"display":"flex","alignItems":"center","justifyContent":"space-between"}}>
                        <div className='myaccount'>
                            My account
                        </div>
                        <Link className='btn btn-dark' to={`/paramètres/content`}>settings</Link>
                    </div>
                    <div className='account-form-formulaire'>
                        <div className='user-info'>
                        <div className='user-info-text m-3'>
                            <span className='text-muted'>user information</span>
                        </div>
                        </div>
                        <div className='formulaire' style={{"display":"flex","alignItems":"center","justifyContent":"center"}}>
                            <form style={{"display":"flex","flexDirection":"column","alignItems":"center","justifyContent":"center"}} onSubmit={onSubmitUser}>
                            <div class="row " >
  <div class="col-lg-12 mb-2">
    <input type="text" class="form-control" placeholder={user.username} aria-label="Username" onChange={(e)=>setUsername(e.target.value)} value={username}/>
  </div>
  <div class="col-lg-12 mb-2">
    <input type="email" class="form-control" placeholder={user.email} aria-label="Email" onChange={(e)=>setEmail(e.target.value)} value={email}/>
  </div>

  <div class="col-lg-12">
  <button className='btn btn-block btn-dark form-control'>submit</button>
  </div>

</div>

                            </form>
                        </div>
                    </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}
