import {USER_LOADING,AUTH_ERROR,USER_AUTHENTICATED,USER_LOADED,LOGOUT_VEHICULE } from "./types";


// const ip_address='http://localhost:8080'
const ip_address='https://web.bfdrive.ma'

// ////////////////////////////////////////////////////////////////////////////////////////
// check TOKEN NAD load USER

export const loadUser=(auth)=>{

   

    return function(dispatch)
       
    {console.log('checking user')




    

    const config={
        headers:{
            'content-Type':'application/json'
        }
    }

    if (auth || auth==!"undefined"){
        config.headers['Authorization']=`Bearer ${auth}`
        console.log(config)



   fetch(`${ip_address}/api/auth/user`,config)
     .then(res=>res.json())

     .then(user=>
        {if(user.hasOwnProperty('user')){
            console.log("disoatching user is loautheticated")
            dispatch({
                type:USER_AUTHENTICATED,
                payload:user

            })
        }else{

            dispatch({
                type:AUTH_ERROR
            })
            
       
        } }
        )}else{
    dispatch({
            type:AUTH_ERROR
        })
   
     }
     }
}
// //////////////////////////////////////////////////////////////////////////////////////////////////////
// Logout USER


export const logoutUser=(auth)=>{

          

    return function(dispatch)
       
    {

        console.log(auth)

        const config={
            method:'GET',
            headers:{
                'content-Type':'application/json'
            }
        }
    
        if (auth || auth==!"undefined"){
            console.log(auth)
            config.headers['Authorization']=`Bearer ${auth}`
    
       fetch(`${ip_address}/api/auth/logout`,
       config)
         
    
         .then(()=>{
            
                dispatch({
                    type:AUTH_ERROR
                })
                dispatch({
                    type:LOGOUT_VEHICULE
                })
                // setTimeout(function(){ window.location.href= '/';}, 100)
            
            })
            } }
            
}
// google loginUser

export const googleLogin=(accesstoken,email)=>{

    return async function(dispatch)
 
    {
        const formdata=new FormData()
        formdata.append('token',accesstoken)
        // formdata.append('grant_type','convert_token')
        // formdata.append('backend','google-oauth2')
        formdata.append('app_name','normal_login')
        formdata.append('email',email)
        // formdata.append('client_secret','AnzHdLPJz4laR74eso7nGPy0IMcchPFEuJsEtMbutRFOO4qZlmaZF3AcpLbVxpBsO84SWOMrqa3c0tZHq9sgjXxEoK0AteDn8agjrrzjHzkjszDld0wl5kAsdvFx8xLB')
    const request=await fetch(`${ip_address}/api/auth/googleLogin`,{
        method:'POST',
        body: formdata,
       
 
     })

     console.log(request)
    const res=await request.json()
    console.log(res)
     switch(request.status){
        case 200:
            dispatch({
         type:USER_LOADED,
         payload:res
     })
     break
     default:
        alert('user does not exist')
        break
     }

    
     }
     
 }


// google loginUser

export const googleRegister=(accesstoken,email,username)=>{

    return async function(dispatch)
 
    {
        const formdata=new FormData()
        formdata.append('token',accesstoken)
        formdata.append('username',username)
        formdata.append('app_name','google')
        formdata.append('email',email)
       
    const request=await fetch(`${ip_address}/api/auth/googleRegister`,{
        method:'POST',
        body: formdata,
       
 
     })

     console.log(request)
    const res=await request.json()
    console.log(res)
     switch(request.status){
        case 200:
            dispatch({
         type:USER_LOADED,
         payload:res
     })
     break
     default:
        alert(res[0])
        break
     }

    
     }
     
 }

//  Delete user

export const deleteUser=(auth,id)=>{

          

    return function(dispatch)
       
    {

        console.log(auth)

        const config={
            method:'DELETE',
            headers:{
                'content-Type':'application/json'
            }
        }
    
        if (auth || auth==!"undefined"){
            console.log(auth)
            config.headers['Authorization']=`Bearer ${auth}`
    
       fetch(`${ip_address}/app/users/${id}/`,
       config)
         
    
         .then(()=>{
            
                dispatch({
                    type:AUTH_ERROR
                })
                // setTimeout(function(){ window.location.href= '/';}, 100)
            
            })
            } }
            
}