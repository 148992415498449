import React from 'react'
import { useParams } from 'react-router-dom'
import { Loading } from './Loading'

export const LoadingWrapper = () => {
    const {token}=useParams()
  return (
    <Loading token={token}/>
  )
}
