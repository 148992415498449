import React, { useEffect } from 'react';

export const NativeAdHistory = () => {
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle;
        if (adsbygoogle) {
          console.log({ adsbygoogle });
          adsbygoogle.push({});
        } else {
          console.error("adsbygoogle is not defined on window");
        }
      } catch (e) {
        console.error(e);
      }
    };

    pushAd();
  }, []);

  return (
<ins class="adsbygoogle"
     style={{ display: 'block',textAlign:"center" }}
     data-ad-layout="in-article"
     data-ad-format="fluid"
     data-ad-client="ca-pub-9450275345312816"
     data-ad-slot="2301420546"></ins>
  );
};
