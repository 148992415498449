import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider,signInWithRedirect } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDlcsHsVasaMjtba-EGNJbZUDrJt-GgEpw",

    authDomain: "fireapp-1c09f.firebaseapp.com",
  
    projectId: "fireapp-1c09f",
  
    storageBucket: "fireapp-1c09f.appspot.com",
  
    messagingSenderId: "712513845682",
  
    appId: "1:712513845682:web:66073ab3c710a90fa14cab",
  
    measurementId: "G-NKVG7FFTBF"
};const app = initializeApp(firebaseConfig);
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();
  
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
    prompt : "select_account "
});export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithRedirect(auth, provider);