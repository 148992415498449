import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from "react-icons"
import {FaPen} from "react-icons/fa"
import './MyUsers.css'
import { useTranslation } from 'react-i18next'


export const MyUsers = ({ip_address}) => {
 const {t}=useTranslation()
  const [utilisateurs,setUtilisateurs]=useState([])
  const mode=useSelector(state=>state.auth.mode)
  const vehicles=useSelector(state=>state.vehicules.items)
    useEffect(()=>{
        const fetchDrivers=async()=>{
          
        const drivers=await getProfile()
      
          setUtilisateurs(drivers)
          console.log(drivers)
    
        }
      
        fetchDrivers()
        
       },[])
       const getProfile=async()=>{
     
        const reminders=await fetch(`${ip_address}/app/api/owndrivers/`,{
          
        headers:{
            "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
        }
    })
      const data=await reminders.json()
      console.log("drivers",data.results)
      return data.results
       }

       const vehicle=(url)=>{
        for(let voiture in vehicles){
          console.log(vehicles[voiture].driver,url,vehicles[voiture].model)
          if (vehicles[voiture].driver.includes(url)){
            return vehicles[voiture].model
          }
        }
       }
  return (
    <div id='myUsers' className={` graphs_${mode} p-1`}>
        <div className='row m-3'>
        <div className='col'>
        <table className={`table text-${mode}`}>
  <thead>
    <tr>
      
      <th scope="col"><span className={`table text-${mode}`}>{t("Full name")}</span></th>
      <th scope="col"><span className={`table text-${mode}`}>{t("Email")}</span></th>
      {/* <th scope="col"><span className={`table text-${mode}`}>{t("phone")}</span></th> */}
      <th scope="col"><span className={`table text-${mode}`}>{t("Vehicle")}</span></th>
    </tr>
  </thead>
  <tbody>
   {utilisateurs.map((utilisat,index)=><tr key={index}>
   
      <td style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>{utilisat.lastname}</td>
      <td>{utilisat.email}</td>
      {/* <td>{utilisat.phone}</td> */}
      <td>
            <div style={{"display":"flex","justifyContent":"flex-start",alignItems:"center"}}>
              <div value={{ style: { color: "red", fontSize: "20px","marginRight":"20px",cursor:"pointer" }, className: "global-class-name" }} >
                <div >
                {vehicle(utilisat.url)}
                </div>
              </div>
           </div>
             </td>
    </tr>) }
  </tbody>
</table>
    </div>
    </div>
</div>
  )
}
