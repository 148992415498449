import React, { useState, useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import '../../../stylings/Styles.css'
import { useNavigate } from 'react-router-dom'
import { updateVehicule } from '../../../../actions/rappelActions'
import { CREATE_MESSAGES, GET_ERRORS } from '../../../../actions/types'
import { useTranslation } from 'react-i18next'
import { MdArrowDropDown,MdArrowDropUp } from "react-icons/md";


export const AddExpense = ({ip_address,currentLanguage}) => {
    const {t}=useTranslation()
    const mode=useSelector(state=>state.auth.mode)
    const user=useSelector(state=>state.auth.user.url)
    const profile=useSelector(state => state.auth.profile)
   
    const current_odometre=useSelector(state=>state.vehicules.item.odometre )
    const selected_vehicule= useSelector(state => state.vehicules.item.url)
    const selected_vehicule_id= useSelector(state => state.vehicules.item.id)
    const expense_types = JSON.parse(profile.expense)
    const places = JSON.parse(profile.places_choice)
    const reasons = JSON.parse(profile.raison)
    const payment_methods = JSON.parse(profile.payment_method)
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const [vehicule, setVehicule] = useState('')
    const [expense_type, setExpense_type] = useState('')
    const [showOptional, setShowOptional] = useState('none')
    const [buttonTitle, setButtonTitle] = useState('More options')
    const [odometre, setOdometre] = useState(0)
    const current_time=Date.now()/1000
    const [valeur, setValeur] = useState(0)
    const [note, setNote] = useState('')
    const [timer,setTimer]=useState('')
    const [payment_method,setPayment_method]=useState('')
    const [reason,setReason]=useState('')
    const [place,setPlace]=useState('')
    const vehicule_id = useSelector((state) => state.vehicules.item.id)
    const [file, setFile] = useState(null)
    const [sorted_items, setSorted_items] = useState([])

// useEffect 

    useEffect(()=>{
        
        setVehicule(selected_vehicule)
        if(expense_types.length>0){
            setExpense_type(expense_types[0])
        }
        if(payment_methods.length>0){
            setPayment_method(payment_methods[0])
        }
        if(reasons.length>0){
            setReason(reasons[0])
        }
        if(places.length>0){
            setPlace(places[0])
        }else{
            setPlace(t('no choices available'))
        }
        const fetchMyObjects = async () => {
            if (vehicule_id !== 'undefined') {
              const myItems = await getMyObjects(vehicule_id)
              console.log(vehicule_id)
              setSorted_items(myItems)
            }
          }
      
          fetchMyObjects()
       },[selected_vehicule])

    // End useEffect

    ////////////////////////////////// fetching objects

       const getMyObjects = async (vehicule_id) => {
        const raw_data = await fetch(`${ip_address}/app/api/ownobjects/${vehicule_id}/`, {
    
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
          }
        })
        const data = await raw_data.json()
        const liste = data.data
        liste.sort(function (a, b) {
          return Number(Object.keys(b)[0]) - Number(Object.keys(a)[0]);
        });
        console.log(liste)
        return liste
      }
////////////////////////////////////////////////////

//////////////////////////////////////////   prepare to filter the items 

    const dateToTimestamp=(dateString)=>{
        const timestamp = Date.parse(new Date(dateString))/1000;
        return timestamp.toString();
    }

   //////////// //applying the filter in the front end where liste is the sorted items and new_obj_time is the timer

    const getFilter=(liste,new_obj_time)=>{
    const new_liste=new Array()
    const tems=new Object()
    
    for(let obj in liste){
        
        if(Object.values(liste[obj])[0].odometre)
        {new_liste.push(Object.keys(liste[obj])[0])
            tems[Object.keys(liste[obj])[0]]=Object.values(liste[obj])[0].odometre}
    }

    
    new_liste.push(dateToTimestamp(new_obj_time))


    tems[`${dateToTimestamp(new_obj_time)}`]=parseFloat(odometre)
    new_liste.sort(function (a, b) {
        return Number(a) - Number(b);
      });

    const index=new_liste.indexOf(dateToTimestamp(new_obj_time))
console.log(index)
    switch(index){
        case 0:
            if(odometre <tems[new_liste[index+1]]){
                console.log('true for index 0')
                return true
            }else{
                console.log('false for index 0')
                return false
            }
        case (new_liste.length -1):
            console.log(new_liste.length)
            if(odometre > tems[new_liste[new_liste.length-2]]){
                console.log('true for index',new_liste.length -1)
                return true
            }else{
                console.log('false for index',new_liste.length -1)
                return false
            }
        default:
            const av_item=tems[new_liste[index-1]]
            const ap_item=tems[new_liste[index+1]]
        
        
            if (odometre > av_item && odometre < ap_item){
                console.log('ouiiiiiiiiiiiiiiiiii')
                return true
            }else{
                console.log('noooooooooooooooooooooooooon')
                return false
            }
    }
}

    ///////////////////////////////////// end foront filter

    //////////////////////////////////// show or hide optional inputs


const toggleShowOptional = () => {
        
    if (showOptional === "none") {
    
      setShowOptional("block")
      setButtonTitle('Less options')
    } else {
      setShowOptional("none")
      setButtonTitle('More options')
    }
  }

    //////////////////////////////////////////// Add expense

    const ajouterExpense=async(data)=>{

       if(Date.parse(new Date(timer))/1000 <= current_time){ const requestPost=await fetch(`${ip_address}/app/api/depense/`,{
            method:'POST',
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            body:data,
           
        },
        
        ) 
        const resp=await requestPost.json()
        if(requestPost.status===201){
            
            
            if(current_odometre<odometre){
                const new_odometre=new FormData()
                new_odometre.append('odometre',odometre)
                dispatch(updateVehicule(selected_vehicule_id,new_odometre))
            }
            navigate('/timeline')
            dispatch({
                type:CREATE_MESSAGES,
                payload:`${t("Expense")} ${t("created success")}`,
            })
            return resp
        }else{
            dispatch({
                type:GET_ERRORS,
                payload:{
                    msg:resp.message,
                    status:requestPost.status
                }
            })
        }}else{
            dispatch({
                type:GET_ERRORS,
                payload:{
                    msg:t("future events are not allowed"),
                    status:400
                }
            })
        }
 
    }

    //////////////////////////////// End Add expense


    /////////////////////////////////////// Onsubmit function
    const onSubmit=async(e)=>{
        e.preventDefault()
        const formdata= new FormData()
        formdata.append('type',expense_type)
        formdata.append('odometre',odometre)
        formdata.append('value',valeur)
        formdata.append('writter',user)
        formdata.append('vehicule',vehicule)
        if(timer){
            formdata.append('creation_date',timer)
        }
        if(file){
            formdata.append('attached_file',file)
        }
        if(note){
            formdata.append('note',note)
        }
        if(payment_method){
            formdata.append('payment_method',payment_method)
        }
        if(reason){
            formdata.append('raison',reason)
        }

        if(place){
            formdata.append('place',place)
        }
        
        ajouterExpense(formdata)
      

    }



    return (
        <section className={`all_sections ${currentLanguage.dir || 'ltr'} mt-4`} id='expenseForm'>

            <div className={`content-${mode} whitewhen${mode}`}>
                <div className='row'>
                    <div className={`col m-4`}>
                        <div className='title'>
                        <img src={`https://web.bfdrive.ma/media/icons/credit-card.svg`} style={{"width":"30px","height":"100%"}}/> |
                            <span className='text Airstrike' >{t("Expense")}</span>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className='row mt-5'>
                                <div className='col-md-4'>
                                    <label for="selectService" class="form-label required"> {t("Expense")} {t("Type")}</label>
                                    <select class={`form-select ${mode}`} id='selectService' onChange={(e) => setExpense_type(e.target.value)} value={expense_type} required>
                                    
                                        {expense_types.map((type, index) => (<option key={index} value={type}>{type}</option>))}
                                    </select>
                                </div>
                                <div class="col-md-4">
                                    <label for="odometre" class="form-label required">{t("Odometer")}</label>

                                    <input type="number" class={`form-control ${mode}`} id="odometre" placeholder="odometre" onChange={(e) => setOdometre(e.target.value)} value={odometre} required />
                                    <div id="emailHelp" class="form-text">{t(`Last odometre`)} {current_odometre} km</div>
                                </div>
                                <div class="col-md-4">
                                    <label for="inputZip" class="form-label required"> {t("Expense date")}</label>
                                    <input type="datetime-local" class={`form-control ${mode}`} id="inputZip" onChange={(e)=>setTimer(e.target.value)} value={timer}/>
                                </div>

                    
                            </div>

                            <div className='row mt-3'>

                            <div className='col-md-6'>
                                    <label for="selectPrice" class="form-label required"> {t("Price")}</label>
                                    <input type='number' class={`form-control ${mode}`} id='selectPrice' onChange={(e) => setValeur(e.target.value)} value={valeur} required />

                                     
                                </div>
                            <div className='col-md-6'>
                                    <label for="selectpayment_method" class="form-label required"> {t("Payment method")}</label>
                                    <select class={`form-select ${mode}`} id='selectpayment_method' onChange={(e) => setPayment_method(e.target.value)} value={payment_method} required>
                                    
                                        {payment_methods.map((method, index) => (<option key={index} value={method}>{method}</option>))}
                                    </select>
                                </div>

                                
                             
                               
                            </div> 
                            <div style={{fontSize:10}}><span style={{color:'red'}}>(*)</span>: {t("The above options are obligatory")}</div>
                            <div style={{display:showOptional}}>
                            <div className='row mb-3'>

                            <div className='col-md-6'>
                                    <label for="selectplace" class="form-label"> {t("Place")}</label>
                                    <select class={`form-select ${mode}`} id='selectplace' onChange={(e) => setPlace(e.target.value)} value={place} required>
                                    
                                        {places.map((pla, index) => (<option key={index} value={pla}>{pla}</option>))}
                                    </select>
                                </div>
                       
                                <div className='col-md-6'>
                                    <label for="selectResaon" class="form-label">{(t("Add"))} {t("Reason")}</label>
                                    <select class={`form-select ${mode}`} id='selectResaon' onChange={(e) => setReason(e.target.value)} value={reason} required>
                                    
                                        {reasons.map((raison, index) => (<option key={index} value={raison}>{raison}</option>))}
                                    </select>
                                </div>

                                </div>
                                <div className='row mb-3'>

                            
                                <div class="col-md-6 mb-3" >
                                    <label for="textarea1" class="form-label">{t("Note")}</label>

                                    <textarea class={`form-control ${mode}`} id="textarea1" rows="1" placeholder={t('Add remark')} onChange={(e) => setNote(e.target.value)} value={note}></textarea>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <label for="formFileLg" class="form-label">{t("Attach file")}</label>
                                    <input class={`form-control ${mode}`} id="formFileLg" type="file" onChange={(e) => setFile(e.target.files[0])} />
                                </div>
                               
                            </div>
                            </div>
                            <a  onClick={toggleShowOptional} style={{cursor:"pointer",fontSize:16,fontWeight:'bold',color:"#1a5276",paddingTop:10,paddingBottom:10}}>{t(buttonTitle)}{buttonTitle==="More options"?<MdArrowDropDown />:<MdArrowDropUp/>}</a>
                            <div className='row mb-3'>
                       


                               
                              

                                    <div class="col-12">
                                <button type="submit" class={`btn form-control`} style={{backgroundColor:"#DE0000" ,color:"white"}}>{t("Add")} {t("Expense")}</button>
                            </div>
                            </div>
                         

     
                        
                        </form>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg'>




                    </div>
                </div>
            </div>


        </section>
    )
}
