import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchVehicules } from '../../../../actions/rappelActions'
import { CREATE_MESSAGES, GET_ERRORS } from '../../../../actions/types'
import { useTranslation } from 'react-i18next'

export const EditRefuling = ({ip_address,currentLanguage,id}) => {
    const {t}=useTranslation()

    // const vehicules = useSelector(state => state.vehicules.items)
    const current_odometre=useSelector(state=>state.vehicules.item.odometre )
    const selected_vehicule= useSelector(state => state.vehicules.item.url)
    const fuels = JSON.parse(useSelector(state => state.auth.profile.fuel_choice))
    const reasons = JSON.parse(useSelector(state => state.auth.profile.raison))
    const places = JSON.parse(useSelector(state => state.auth.profile.places_choice))
    const mode=useSelector(state=>state.auth.mode)
    const user=useSelector(state=>state.auth.user.url)
    const username=useSelector(state=>state.auth.user.username)
    const current_vehicule=useSelector(state=>state.vehicules.item)
    const selected_vehicule_id= useSelector(state => state.vehicules.item.id)
    const payment_methods = JSON.parse(useSelector(state => state.auth.profile.payment_method))
    const stations = JSON.parse(useSelector(state => state.auth.profile.gasStation))
    const own= useSelector(state => state.auth.profile.url)
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const [vehicule, setVehicule] = useState('')
    const [fuel_type, setFuel_type] = useState('')
    const [reason, setReason] = useState('')
    const [odometre, setOdometre] = useState(0)
    const [price, setPrice] = useState(0)
    const [quantité, setQuantité] = useState(0)
    const [unit_price, setUnit_price] = useState(0)
    // const [driver, setDriver] = useState('')
    // const [drivers, setDrivers] = useState([])
    const [note, setNote] = useState('')
    const [changed_items,setChanged_items]=useState({})
    const [timer,setTimer]=useState('')
    const [payment_method,setPayment_method]=useState('')
    const [is_filling,setIs_filling]=useState(false)
    const [file, setFile] = useState(null)
    const [place,setPlace]=useState('')
    const [station,setStation]=useState('')


//////////////////////////////////////////////////////////// chargement des parametres avec useEffect


    useEffect(()=>{
        
        const fetchItem=async()=>{
            const item=await getItem()
           } 
           fetchItem()
    
       },[selected_vehicule])

       
       const getItem=async()=>{
        const request=await fetch(`${ip_address}/app/api/refuling/${id}`,{
           
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
         
           
        },)

        const response=request.status
        switch (response){
            case 200:
                const resp=await request.json()
                setFuel_type(resp.type)
                setOdometre(resp.odometre)
                setPrice(resp.price)
                setStation(resp.station)
                setNote(resp.note)
                setPlace(resp.place)
                setQuantité(resp.quantité)
                setReason(resp.raison)
                setUnit_price(resp.price/resp.quantité)
                setPayment_method(resp.payment_method)
                setTimer(resp.creation_date.split('.')[0])
                break
            case 403:
                dispatch({
                    type:GET_ERRORS,
                    payload:{
                        msg:t("You are not allowed to edit this item"),
                        status:403
                    }
                })
                break
            default:
                alert(t('item not found'))
                break
        }
       }


////////  la requette fetch pour ajouter un ravitaillement

    const editCarburant=async(data)=>{

       

        const requestPost=await fetch(`${ip_address}/app/api/refuling/${id}/`,{
            method:'PATCH',
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            body:data,
           
        },
        
        )
        console.log(requestPost)
        const resp=await requestPost.json()
        if(requestPost.status===200){

            dispatch({
                type:CREATE_MESSAGES,
                payload:`${t("Refuling for ")} ${current_vehicule.model} ${t("edited successfully")}`,
            })
            // dispatch(fetchVehicules())
            setTimeout(()=>{
                dispatch({
                    type:CREATE_MESSAGES,
                    payload:``,
                })},
                4000
               )
            return resp
        }else{
            dispatch({
                type:GET_ERRORS,
                payload:{
                    msg:t(resp.message),
                    status:requestPost.status
                }
            })
        }
 
    }

///////////////////// onSubmit pour soumettre le formulaire

    const onSubmit=async(e)=>{
        e.preventDefault()
        const formdata= new FormData()
        formdata.append("vehicule_id",selected_vehicule_id)
        // formdata.append('driver',driver)
        Object.keys(changed_items).forEach((item) => {
            formdata.append(item,changed_items[item])
            console.log(item,changed_items[item])
          })
        
        editCarburant(formdata)
      
        setChanged_items({})
  
    }


////////////////////////////// fonction pour calculer le prix du carburant // la quantité // prix total


    // select quantite function
    const computeTotalPrice=(e)=>{
        setQuantité(e.target.value)
        changed_items['quantité']=e.target.value
        if(unit_price){
     
            setPrice(Math.round(unit_price*e.target.value*100)/100)
        }
    }

    // select unit_price function
    const computeUnitPrice=(e)=>{
        setPrice(e.target.value)
        changed_items["price"]=e.target.value
     
            setUnit_price(Math.round(e.target.value*1.0*100/quantité)/100)
       
    }

    // select unit_price function
    const computePrice=(e)=>{
        setUnit_price(e.target.value)
        
        
            setPrice(Math.round(e.target.value*quantité*100)/100)
            changed_items["price"]=Math.round(e.target.value*quantité*100)/100
        
    }

//////////////////////////////////// chargement des drivers lors du chois du vehicule


    // const setVehiculesDrivers=async(e)=>{
    //     setVehicule(e.target.value)

    //     const config={
    //         headers:{
    //             'content-Type':'application/json',
    //             'Authorization':`Token ${token}`
    //         }
    //     }
    //     const fetchDrivers=await fetch(vehicule,config)

    //     const data=await fetchDrivers.json()

    //     console.log(data)
    //     setDrivers(data.driver)
    //     if(data.driver.length>0){
    //         setDriver(data.driver[0])
    //     }else{
    //         setDriver(own)
    //     }
        
    // }




////////////////////////////// est ceque c'est un plein??

    const togglecheckbox=(e)=>{
        if (e.target.checked){
      
            setIs_filling(true)
         
        }else if(!e.target.checked){
          setIs_filling(false)
        }
      }

//////////////////////////////////// the actual componenet      
    return (
        <section id='refulingForm' className={`all_sections ${currentLanguage.dir || 'ltr'} mt-4`}>

            <div className={`content-${mode} whitewhen${mode}`}>
                <div className='row'>
                    <div className='col m-4'>
                        <div className='title'>
                        <img  src={`${ip_address}/media/icons/gas-pump.svg`} style={{"width":"30px"}}/> |
                            <span className='text Airstrike'>{t("Edit")} {t("Refueling")}</span>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className='row mt-5'>
                                {/* <div className='col-md-4'>
                                    <label for="selectVehicule" class="form-label">select vehicule</label>
                                    <select class={`form-select-${currentLanguage.dir} bg-${mode}`} id='selectVehicule' onChange={(e) =>setVehiculesDrivers(e) } value={vehicule} required>

                                        {vehicules.map((auto,index) => (index===0?<option key={index} value={auto.url} selected>{auto.vehicule_name}</option>:<option key={index} value={auto.url}>{auto.vehicule_name}</option>))}
                                    </select>
                                </div> */}
                                <div className='col-md-4'>
                                    <label for="selectService" class="form-label">{t("Fuel")} {t("type")}</label>
                                    <select class={`form-select-${currentLanguage.dir} ${mode}`} id='selectService' onChange={(e) => {setFuel_type(e.target.value);changed_items['type']=e.target.value}} value={fuel_type} required>
                                    
                                        {fuels.map((type, index) => (<option key={index} value={type}>{type}</option>))}
                                    </select>
                                </div>

                                <div className='col-md-4'>
                                    <label for="selectstation" class="form-label">{t("Gas Station")}</label>
                                    <select class={`form-select-${currentLanguage.dir} ${mode}`} id='selectstation' onChange={(e) => {setStation(e.target.value);;changed_items['station']=e.target.value}} value={station} required>
                                    
                                        {stations.map((sta, index) => (<option key={index} value={sta}>{sta}</option>))}
                                    </select>
                                </div>

                                <div class="col-md-4">
                                    <label for="odometre" class="form-label">{t("Odometer")}</label>

                                    <input type="number" class={`form-control ${mode}`} id="odometre" placeholder="odometre" onChange={(e) => {setOdometre(e.target.value);changed_items['odometre']=e.target.value}} value={odometre} required />
                                    <div id="emailHelp" class="form-text">{t(`Last odometre`)}{current_odometre}</div>
                                </div>



                             
                              

                                {/* <div className='col-md-4'>
                                    <label for="selectDriver" class="form-label">Select Driver</label>
                                    <select class={`form-select-${currentLanguage.dir} ${mode}`} id='selectDriver' onChange={(e) => setDriver(e.target.value)} value={driver} required>

                                        {drivers?drivers.map((driver, index) => (<option key={index} value={driver}>{driver}</option>)):<option key={1} value={'no driver'}>no driver</option>}
                                    </select>

                                </div> */}
                            </div>

                            <div className='row mt-3'>

                            <div class="col-md-4">
                                    <label for="inputZip" class="form-label">{t("Refueling")} {t("Date")}</label>
                                    <input type="datetime-local" class={`form-control ${mode}`} id="inputZip" onChange={(e)=>{setTimer(e.target.value);changed_items['creation_date']=e.target.value}} value={timer}/>
                                </div>
                                
                                <div className='col-md-4'>
                                    <label for="selectPrice" class="form-label">{t("Total Amount")}</label>
                                    <input type='number' class={`form-control ${mode}`} id='selectPrice' onChange={(e) => computeUnitPrice(e)} value={price} required />

                                     
                                </div>
                                <div className='col-md-4'>
                                    <label for="selectquantité" class="form-label">{t("Unit price")}</label>
                                    <input type='number' class={`form-control ${mode}`} id='selectquantité' onChange={(e) => computePrice(e)} value={unit_price} required/>


                                </div>


                               

                
                            </div>

                            <div className='row mb-3'>
                            <div className='col-md-4'>
                                    <label for="selectquantité" class="form-label">{t("Quantity")}</label>
                                    <input type='number' class={`form-control ${mode}`} id='selectquantité' onChange={(e) => computeTotalPrice(e)} value={quantité} required/>


                                </div>

                                <div className='col-md-4'>
                                    <label for="selectplace" class="form-label">{t("Place")}</label>
                                    <select class={`form-select-${currentLanguage.dir} ${mode}`} id='selectplace' onChange={(e) => {setPlace(e.target.value);;changed_items['place']=e.target.value}} value={place} required>
                                    
                                        {places.map((pla, index) => (<option key={index} value={pla}>{pla}</option>))}
                                    </select>
                                </div>

                                <div className='col-md-4'>
                                    <label for="selectpayment_method" class="form-label">{t("Payment method")}</label>
                                    <select class={`form-select-${currentLanguage.dir} ${mode}`} id='selectpayment_method' onChange={(e) => {setPayment_method(e.target.value);;changed_items['payment_method']=e.target.value}} value={payment_method} required>
                                    
                                        {payment_methods.map((method, index) => (<option key={index} value={method}>{method}</option>))}
                                    </select>
                                </div>
                              

                               

 
                            </div>
                            <div className='row mb-3'>
                           
                            <div className='col-md-4'>
                                    <label for="selecReason" class="form-label">{t("Reason")}</label>
                                    <select class={`form-select-${currentLanguage.dir} ${mode}`} id='selecReason' onChange={(e) => {setReason(e.target.value);;changed_items['raison']=e.target.value}} value={reason} required>
                                    
                                        {reasons.map((raison, index) => (<option key={index} value={raison}>{raison}</option>))}
                                    </select>
                                </div>
                            <div class="col-md-4 mb-3"  >
                                    <label for="textarea1" class="form-label">{t("Note")}</label>

                                    <textarea class={`form-control ${mode}`} id="textarea1" rows="1" placeholder='add remark' onChange={(e) => {setNote(e.target.value);changed_items['note']=e.target.value}} value={note}></textarea>
                                </div>

                                <div className='col-md-4 mb-3'>
                                    <label for="formFileLg" class="form-label">{t("File")}</label>
                                    <input class={`form-control ${mode}`} id="formFileLg" type="file" onChange={(e) => {setFile(e.target.files[0]);changed_items['attached_file']=e.target.files[0]}} />
                                </div>
</div>

                                {/* <div className='row mb-3'>
                                <div class="col ">
                                    <div class="form-check">
                                        <input class="form-check-input bg-dark" type="checkbox" id="gridCheck" onChange={(e)=>togglecheckbox(e)}/>
                                        <label class="form-check-label" for="gridCheck">
                                            {t("Are you filling the tank?")}
                                        </label>
                                    </div>
                                </div>
                                </div> */}
                            <div class="col-12">
                                <button type="submit" class="btn form-control" style={{backgroundColor:"#FFAE20",color:"white"}}>{t("Edit")} {t("Refueling")}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg'>




                    </div>
                </div>
            </div>


        </section>
    )
}
