import React from 'react'
import { IconContext } from "react-icons"
import {AiFillCar,
    AiFillFolderOpen,
    AiFillPieChart,
   } from 'react-icons/ai'
import { FaRoad,
    FaUserTie,
    FaServicestack,FaCrown} from 'react-icons/fa'

import { GiSteeringWheel } from "react-icons/gi";    


export const ListCard= ({icon,title,color,mode,clicked}) => {
    const setIcon=()=>{
        switch(icon){
            case 'Drivers':
                return  <GiSteeringWheel />
            case 'Plans':
                return <FaCrown />
            case 'storage':
                return <AiFillPieChart />
            case 'routes':
                return <FaRoad />
            case 'account':
                return <FaUserTie />
            case 'Services':
                return <FaServicestack />

        }
    }
  return (
    <a class="nav-link  settings1" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
 style={{padding:0}}>
        <div className='row'>
        {/* <div className='col-4' style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <IconContext.Provider value={{ style: { fontSize: "35px",color:color}, className: "global-class-name" }}>
                    <div>
                      {setIcon()}
                    </div>
                  </IconContext.Provider>
        </div> */}
        <div className='col'>
            {/* <h5 className={`text-${mode}`} style={{fontFamily:'Roboto',textAlign:"center",color:color}}>{title}</h5> */}

            {clicked?<h5 style={{fontFamily:'Roboto',textAlign:"center",color:'#df1d34'}}>{title}</h5>:
            <h5 className={`text-${mode} settingsBar`} style={{fontFamily:'Roboto',textAlign:"center"}}>{title}</h5>}
        </div>
        </div>
    </a>
  )
}
