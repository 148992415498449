import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FaPen,FaUser,FaEnvelope } from 'react-icons/fa'
import {MdSubject} from 'react-icons/md'
import './ContactUs.css'
import { useTranslation } from 'react-i18next'



export const ContactUs = ({ip_address,currentLanguage}) => {
    const {t}=useTranslation()
    const mode=useSelector(state=>state.auth.mode)
    const writter=useSelector(state=>state.auth.user.url)
    const current_profile=useSelector(state=>state.auth.profile)
    const current_user=useSelector(state=>state.auth.user)
    // const [sender_name,setSender_name]=useState('')
    // const [ email,setEmail]=useState('')
    const [ subject,setSubject]=useState('')
    const [message,setMessage]=useState('')

    const onSubmit=async(e)=>{
        e.preventDefault()
        const formdata= new FormData()
        formdata.append('sender_name',`${current_profile.firstname}  ${current_profile.lastname}`)
        formdata.append("email",current_user.email)
        formdata.append("message",message)
        formdata.append("writter",writter)
        formdata.append("subject",subject)
        sendContact(formdata)
    }


    const sendContact=async(data)=>{
        const request=await fetch(`${ip_address}/api/contact`,{
            method:'post',
            body:data
        })

        switch(request.status){
            case 201:
                const response=await request.json() 
                alert(response.message)
                break

            case 400:
                alert('message is not sent \n please try again')
                break
        }
    }
  return (
    <section  className={`all_sections mt-4 ${currentLanguage.dir || 'ltr'}`}>
    <div className={`content-${mode} whitewhen${mode}`} style={{marginBottom:160}}>
        <div className="row m-4" style={{width:'50%'}}>
            <div className="col">
                <h3 className={`strike-${mode} mb-4`} >{t("Get in touche")}</h3>
                <form onSubmit={onSubmit}>
                <div className="input-group mb-4 mr-sm-2">
                <span className="input-group-text" id="basic-addon1">< FaUser/></span>
                    <input type="text" className={`form-control ${mode}`} id="inlineFormInputGroupUsername2" placeholder={`${current_profile.firstname}  ${current_profile.lastname}`} disabled/>
                </div>
               
                <div className="input-group mb-4 mr-sm-2">
                <span className="input-group-text" id="basic-addon1">< FaEnvelope/></span>
                    <input type="email" className={`form-control ${mode}`} id="inlineFormInputGroupUsername2" placeholder={current_user.email} disabled/>
                </div>
                <div className="input-group mb-4 mr-sm-2">
                <span className="input-group-text" id="basic-addon1">< MdSubject/></span>
                    <input type="text" className={`form-control ${mode}`} id="inlineFormInputGroupUsername2" placeholder="object" onChange={(e)=>setSubject(e.target.value)} value={subject}  required/>
                </div>
                <div className="input-group mb-4 mr-sm-2">
                <span className="input-group-text" id="basic-addon1">< FaPen/></span>
                    
                    <textarea className={`form-control ${mode}`} placeholder="message" onChange={(e)=>setMessage(e.target.value)} value={message}  required />
                </div>
                <button className="btn btn-danger form-control">{t("Submit")}</button>
                </form>
            </div>
            {/* <div className="col-lg-4 align-self-center">
                <img src={`${ip_address}/media/Logo.svg`} alt="" className="img-fluid rounded-circle" />
            </div> */}
        </div>
    </div>
</section>
  )
}
