import React,{useState} from 'react'
import { useSelector } from 'react-redux';
import dateFormat from 'dateformat'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Doughnut,Pie,Bar } from "react-chartjs-2";
import { useTranslation } from 'react-i18next'
import { translateDate } from '../General/Formats';

export const DashboardLineChart = ({donnée_refuling,donnée_service,donnée_expense,donnée_income,labels,title,mode,startTime,endTime}) => { // data est une liste de données ,labels est la lmiste des no,s de chaque part ,label est le nom de toute la graphe
 

  const {t}=useTranslation()
  const time_format = useSelector(state => state.auth.profile.time_format)

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
    const data = {
        labels: labels,
        datasets: [{
          label: t('Refueling'),
          data: [donnée_refuling],  //data ets une liste
          backgroundColor:'#FFAE20',
        },
        {
            label: t('Expense'),
            data: [donnée_expense],  //data ets une liste
            backgroundColor:'#DE0000',
          },
          {
            label: t('Service'),
            data: [donnée_service],  //data ets une liste
            backgroundColor:'#36001C',
          }
          ,
          {
            label: t('Income'),
            data: [donnée_income],  //data ets une liste
            backgroundColor:'#00FF48',
          }]
      };
  return (
    <div className={`graphs_${mode} p-1`} style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",borderRadius:10,border:"solid lightgray 1px"}}>
        <Bar data={data} />
        <h6 style={{fontFamily:'AirStrike',marginTop:10,textDecoration:"underline"}}>{t(title+' statistics')}</h6>
        <h6 style={{margin:0,textDecoration:"underline",fontSize:12}}><span style={{fontWeight:"bold"}}>{t("From")}</span> {translateDate(dateFormat(startTime, `${time_format}  HH:MM`),t,time_format)} <span style={{fontWeight:"bold"}}>{t("To")}</span>  {translateDate(dateFormat(endTime, `${time_format}  HH:MM`),t,time_format)}</h6>
    </div>
  )
}
