import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {MdDeleteForever} from 'react-icons/md'

export const ContentSettings = ({ip_address}) => {
    const {t}=useTranslation()
    const [plans,setPlans]=useState([])
    const currency=useSelector(state=>state.auth.profile.currency_format)
    const plan_name = useSelector(state => state.auth.profile.plan_name)


    useEffect(()=>{
        const fetchPlans=async()=>{
        
          const plans=await getPlans()
      
          setPlans(plans.results)
          console.log(plans)
    
        }
        fetchPlans()
    
       },[]) 


       const getPlans=async()=>{
   
        const plans=await fetch(`${ip_address}/app/api/plans/`,{
          
        headers:{
            "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
        }
    })
      const data=await plans.json()
      return data
       }


    return (
       
            <div id='plans'>
                <div className="row m-4">
  {plans.map((plan,index)=>(
    <div key={index} className="col">
    <div className="card2 text-center my-4" style={plan.plan_name==plan_name?{"width":"350px",boxShadow: '8px 8px 15px rgba(246, 0, 0, 0.4)'}:{"width":"350px"}}>
                        <h4 className="card-header bg-danger text-white">{plan.plan_name}</h4>
                        <div className="card-body text-secondary">
                            <h3 className="card-title">{plan.prix}DH /{t("year")}</h3>
                            <p className="card-text ">
                               {plan.description}
                            </p>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">{plan.vehicule_number} {t("allowed car")}</li>
                                <li className="list-group-item">{plan.allowed_users} {t("allowed users")}</li>
                                <li className="list-group-item">{plan.allowed_storage/1048576}Mb {t("allowed storage")}</li>
                                {/* <li className="list-group-item">{t("A third item")}</li> */}
                            </ul>
                            <a href="#" className="btn btn-danger btn-block mt-3">{t("Get It")}</a>
                        </div>
                        <div className="card-footer" style={{color:'black'}}>
                            1 {t("Year")} {t("Plan")}
                        </div>
                    </div>
  </div>
  ))}
            </div>
            </div>
      
    )
}
