import React,{useEffect,useState} from 'react'
import { useSelector } from 'react-redux'
import { OneReminder } from './OneReminder'
import { Link } from 'react-router-dom'
import "../../stylings/Styles.css"
import { useTranslation } from 'react-i18next'
import noReminder from './Reminders.png'
import addService from './Add_Service.png'
import { NativeAdReminder } from '../../ads/NativeAdReminder'






export const Reminder = ({ip_address,currentLanguage}) => {
  const {t}=useTranslation()
  
  const reminders_id = useSelector((state) => state.vehicules.item.id)
  const profile = useSelector((state) => state.auth.profile)
  const mode=useSelector(state=>state.auth.mode)
  const [reminders,setReminders]=useState([])


    





  useEffect(()=>{
    const fetchReminders=async()=>{
    
      const rappels=await getReminders(reminders_id)
  
      setReminders(rappels)
      console.log(reminders)

    }
    fetchReminders()

   },[reminders_id]) // useEffect renders whenever reminders_id changes

  
   const getReminders=async(reminders_id)=>{
     console.log(reminders_id)
     const reminders=await fetch(`${ip_address}/app/api/ownreminders/${reminders_id}/`,{
       
     headers:{
         "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
     }
 })
   const data=await reminders.json()
   return data
    }

    // delete one reminder

    const deleteRimeder=async (url)=>{
      const request =await fetch(url,{
        headers:{
            "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
        },
        method:'DELETE',
     })
     const status=request.status
     switch(status){
        case 204:
          console.log('reminder deleted')
          const new_items=reminders.filter(item=>item.url !== url)
           setReminders(new_items)
            break
        case 403:
            alert('this is not your item')
            break
     
  }  
  
  
    }
 


  return (
    <section className={`all_sections mt-4 ${currentLanguage.dir || 'ltr'}`}>
       <div className={`${mode}-text mb-3`} style={{fontFamily:"AirStrike",fontSize:20,width:"85%"}}>{t("Reminders")}</div>
        <div className={`graphs_${mode} p-1`} style={{width:"90%"}}>
        <div className='row'>
          <div className='col'>
            {/* <div className='title'>
              <i className='bx bx-stopwatch icon' ></i> |
              <span className={`text-${mode}`}>{t("Reminder")}</span>
            </div> */}
           
            {reminders.length ?  <OneReminder reminders={reminders} time_format={profile.time_format} mode={mode} deleteRimeder={deleteRimeder}/>: <div className='user_reminders'>
              <img src={noReminder} />
              <span className={`text-${mode} m-3`}>{t("You don't have any reminder")}</span>
            </div>}

         <NativeAdReminder/>

            <div className='boutons'>
              <div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}} >
                <div className='btn btnOutline mb-3'>
                <Link to='/reminderform' style={{width:50,height:50 ,textDecoration:"none",color:"inherit"}}>{t("Add")} {t("Reminder")}</Link>
              </div>
              </div>
             
            </div>
       </div>
        </div>
        <div className='row'>
          <div className='col-lg'>




          </div>
        </div>
      </div>


    </section>
  )
}



export default Reminder