
import { AUTH_ERROR,
     FETCH_VEHICULES,
      SET_VEHICULE,
      USER_LOADED,
      ADD_VEHICULE,
      DELETE_VEHICULE,
    UPDATE_VEHICULE,
    UPDATE_OWN_VEHICULE,
USER_UPDATED,
CREATE_MESSAGES,
USER_LOADING} from "../actions/types";


    // const ip_address='http://localhost:8080'
    const ip_address=''

// ///////////////////////////////////////////////////////////////////////////////
// fetching vehicules

export const fetchVehicules=()=>{
   return async function(dispatch)

   {console.log('testing') 
        const data=await fetch(`${ip_address}/app/api/ownvehicules/`,{
      
        headers:{
            "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
        }
    })
    
        switch(data.status){
            case 200:
                console.log('here')
               dispatch({
                    type:FETCH_VEHICULES,
                    payload: await data.json()
                })
                break

        }
    }
    
}
// ///////////////////////////////////////////////////////////////////////////////
// seting vehicules
export const settingVehicule=(data)=>{
    return function(dispatch)
 
    {console.log(data) 
    
    dispatch({
        type:SET_VEHICULE,
        payload:data
    })
     }
     
 }
// ///////////////////////////////////////////////////////////////////////////////
// deletting vehicules
export const deleteVehicule=(id)=>{
    return async function(dispatch)
 
    {console.log(id) 
        const action=await fetch(`${ip_address}/app/api/vehicules/${id}/`,{
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            method:'DELETE',
         })
         const status=action.status

         switch(status){
            case 204:
                dispatch({
                    type:DELETE_VEHICULE,
                    payload:id
                })
                break
            case 403:
                alert('this is not your vehicule')
                break
         }
     }  
 }

// ///////////////////////////////////////////////////////////////////////////////
// update vehicule
export const updateVehicule=(id,data)=>{
    return async function(dispatch)
 
    {console.log(id) 
        const action=await fetch(`${ip_address}/app/api/vehicules/${id}/`,{
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            method:'PATCH',
            body: data,
         })
         const status=action.status

         switch(status){
            case 200:
                dispatch({
                    type:UPDATE_VEHICULE,
                    payload:await action.json()
                })
                break
            case 403:
                alert('this is not your vehicule')
                break
         }
     }  
 }

 export const updateVehicule_2=(id,data)=>{
    return async function(dispatch)
 
    {console.log(id,data) 
        const action=await fetch(`${ip_address}/app/api/vehicules/${id}/`,{
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            method:'PATCH',
            body: data,
         })
         const status=action.status
         console.log(status)
         switch(status){
            case 200:
                dispatch({
                    type:UPDATE_VEHICULE,
                    payload:await action.json()
                })
                
                break
            case 403:
                alert('this is not your vehicule')
                break
         }
     }  
 }
 // ///////////////////////////////////////////////////////////////////////////////
// update vehicule
export const updateOwnVehicule=(id,data)=>{
    return async function(dispatch)
 
    {console.log(id) 
        const action=await fetch(`${ip_address}/app/api/ownvehicules/${id}/`,{
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            method:'PATCH',
            body: data,
         })
         const status=action.status

         switch(status){
            case 200:
                dispatch({
                    type:UPDATE_OWN_VEHICULE,
                    payload:await action.json()
                })
                break
                case 201:
                  alert('your request is sent')
                    break
            case 403:
                alert('this is not your vehicule')
                break
            case 402:
                alert("You exeeded your maximum drivers ,you can upgrade your membership")
                break
            case 404:
                alert("this user does not existe")
                break
         }
     }  
 }
// ///////////////////////////////////////////////////////////////////////////////
// fetching user

export const fetchUser=(data,navigate)=>{
    return async function(dispatch)
 
    {console.log(data) 
   
    const request=await fetch(`${ip_address}/api/auth/login`,{
        method:'POST',
        body: data,
       
 
     })
//changes
// const request=await fetch(`${ip_address}/auth/token`,{
//     method:'POST',
//     body: data,
   

//  })

    const res=await request.json()
    console.log(res)
     switch(request.status){
        case 200:
            dispatch({
         type:USER_LOADED,
         payload:res
     })
     dispatch({
        type:CREATE_MESSAGES,
        payload:"login success",
    })

     break
     default:
        alert('wrong credentials')
        navigate('/login')
        break
     }

    
     }
     
 }



 // ///////////////////////////////////////////////////////////////////////////////
// updating user

export const updateUser=(url,data)=>{
    return async function(dispatch)
 
    {console.log(data) 
    const request=await fetch(url,{
        headers:{
            "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
        },
        method:'PATCH',
        body: data,
     })
    
     switch(request.status){
        case 200:
            dispatch({
         type:USER_UPDATED,
         payload:await request.json()
     })
     break
     default:
        alert('wrong credentials')
        break
     }

    
     }
     
 }

// ///////////////////////////////////////////////////////////////////////////////
// register user


 export const registerUser=(data)=>{
    return async function(dispatch)
 
    {console.log(data) 
   const requset =await fetch(`${ip_address}/api/auth/register`,{
        method:'POST',
        body: data,
       
 
     })

     switch(requset.status){
        case 200:
            console.log(requset.status)
            dispatch({
                           type:USER_LOADED,// ici creer un global state of messages and errors
                           payload:await requset.json()
                    })

        case 400:
            console.log(requset.status)
            dispatch({
                        type:AUTH_ERROR,
                        payload:await requset.json()
                    })
     }
    //  .then(res=>res.json())
    //  .then(
    //     user=>
    //     {   if(user.hasOwnProperty('username')){dispatch({
    //         type:AUTH_ERROR,
    //         payload:user
    //     })}else{
    //        dispatch({
    //            type:USER_LOADED,// ici creer un global state of messages and errors
    //            payload:user
    //     })}}
    //  )
     }
     
 }

// ///////////////////////////////////////////////////////////////////////////////
// adding vehicules using asynchronous request

export const addVehicule=(vehicule,navigate)=>{
    return async function(dispatch)
 
    {console.log('adding vehicule') 
     const data= await fetch(`${ip_address}/app/api/vehicules/`,{
        method:'POST',
        headers:{
             "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
         },
        body:vehicule,
     })
     
        switch( data.status){
            case 201:
                dispatch({
                    type:ADD_VEHICULE,
                    payload:await data.json()
                })
                navigate('/timeline')
                break
            case 400:
               alert('400 error') 
               break
            case 402:
                alert('402 payement error')
                break
        }
     
    
     
 }}