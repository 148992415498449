import React,{useState,useEffect} from 'react'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from "react-icons"
import {RxEyeOpen} from 'react-icons/rx'
import { useSelector } from 'react-redux'
import { DashboardStorage } from '../DashboardStorage/DashboardStorage'
import { useTranslation } from 'react-i18next'
import { convertBytes } from '../../Settings/Settings'
import { CREATE_MESSAGES,GET_ERRORS } from '../../../../actions/types'
import { useDispatch } from 'react-redux'
import { TableRowFile } from './TableRowFile'
 

export const DashboardFiles = ({ip_address,digit_format}) => {
  const dispatch=useDispatch()
    const {t}=useTranslation()
    const [files,setFiles]=useState({})
    const [changed,setChanged]=useState(0)
    const auth=useSelector(state=>state.auth)
    const mode=auth.mode
    const username=auth.user.username
    const email=auth.user.email
    const link=`${username}_${email}`

    useEffect(()=>{
      const getFiles=async()=>{
        const files=await fetchFiles()
        console.log(files)
        setFiles(files)
      }
      getFiles()
    },[])

    const fetchFiles=async()=>{
      const request=await fetch(`${ip_address}/app/api/files/`,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
      const files=await request.json()
      return files
    }


 

  return (
    <div>
       <h5 style={{margin:'auto',fontFamily:'AirStrike'}}>{t("Manage my files")}</h5>
      <table class={`table text-${mode} graphs_${mode}`}>
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">{t('Filename')}</th>
        <th scope="col">{t("Size")}</th>
        <th scope="col">{t("Actions")}</th>
      </tr>
    </thead>
    <tbody>
    {Object.keys(files).map((item,index)=> <TableRowFile index={index} filename={item} mode={mode}
     size={convertBytes(files[item])} ip_address={ip_address} files={files} setFiles={setFiles}
     setChanged={setChanged}/>
      )}
    </tbody>
  </table>
  <div className='dFlex'>
  <DashboardStorage ip_address={ip_address} changed={changed} mode={mode}/>
  </div>
  </div>
  )
}
