import React,{useState} from 'react'
import { useSelector } from 'react-redux';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut,Pie } from "react-chartjs-2";
import dateFormat from 'dateformat'
import { translateDate } from '../General/Formats';
import { useTranslation } from 'react-i18next';

export const DashboardGraph = ({donnée,labels,label,title,mode,startTime,endTime}) => { // data est une liste de données ,labels est la lmiste des no,s de chaque part ,label est le nom de toute la graphe
 
const {t}=useTranslation()
const time_format = useSelector(state => state.auth.profile.time_format)

 ChartJS.register(ArcElement, Tooltip, Legend);
    const data = {
        labels: labels,
        datasets: [{
          label: label,
          data: donnée,  //data ets une liste
          backgroundColor: [
            '#a9dfbf',
            '#c39bd3 ',
            '#a9cce3 ',
            '#edbb99',
          ],
          hoverOffset: 4
        }]
      };
  return (
    <div className={`graphs_${mode} p-1`} style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
        <Doughnut data={data} />
        <h6 style={{fontFamily:'AirStrike',margin:13,textDecoration:"underline"}}>{t(title+' statistics')}</h6>
        <h6 style={{margin:0,textDecoration:"underline",fontSize:12,textAlign:"center",width:"90%"}}><span style={{fontWeight:"bold"}}>{t("From")}</span> {translateDate(dateFormat(startTime, `${time_format}  HH:MM`),t,time_format)} <span style={{fontWeight:"bold"}}>{t("To")}</span>  {translateDate(dateFormat(endTime, `${time_format}  HH:MM`),t,time_format)}</h6>
    </div>
  )
}
