import { FETCH_NOTIFICATION,DELETE_NOTIFICATION, READ_NOTIFICATION } from "./types";


// const ip_address='http://localhost:8080'
const ip_address=''

export const fetchNotifications=()=>{
    return async function(dispatch)
 
    {console.log('fetching notifications')
        const action=await fetch(`${ip_address}/app/api/ownNotifications/`,{
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
         })
         const status=action.status

         switch(status){
            case 200:
                const data=await action.json()
                dispatch({
                    type:FETCH_NOTIFICATION,
                    payload:data.results
                })
            //     break
            // case 403:
            //     alert('this is not your vehicule')
            //     break
         }
     }  
 }

 export const deleteNotifications=(id)=>{
    return async function(dispatch)
 
    {console.log('fetching notifications')
        const action=await fetch(`${ip_address}/app/api/ownNotifications/${id}/`,{
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            method:'DELETE'
         })
         const status=action.status

         switch(status){
            case 204:
                
                dispatch({
                    type:DELETE_NOTIFICATION,
                    payload:id
                })
            //     break
            // case 403:
            //     alert('this is not your vehicule')
            //     break
         }
     }  
 }

//  export const updateNotifications=(id)=>{
//     return async function(dispatch)
 
//     {console.log('fetching notifications')
//     const formData=new FormData()
//     formData.append('read',true)
//         const action=await fetch(`${ip_address}/app/api/ownNotifications/${id}/`,{
//             headers:{
//                 "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
//             },
//             method:'PATCH',
//             body: formData,
//          })
//          const status=action.status

//          switch(status){
//             case 200:
                
//                 dispatch({
//                     type:READ_NOTIFICATION,
//                     payload:id
//                 })
//             //     break
//             // case 403:
//             //     alert('this is not your vehicule')
//             //     break
//          }
//      }  
//  }

//  export const declineNotifications=(data)=>{
//     return async function(dispatch)
 
//     {console.log('fetching notifications')
//         const action=await fetch(`localhost:8080/app/api/notifications/${id}/`,{
//             headers:{
//                 "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
//             },
//             method:'POST',
//             body:data
//          })
//          const status=action.status

//          switch(status){
//             case 201:
                
//                 dispatch({
//                     type:DELETE_NOTIFICATION,
//                     payload:id
//                 })
//             //     break
//             // case 403:
//             //     alert('this is not your vehicule')
//             //     break
//          }
//      }  
//  }