import React from 'react'
import { Header } from './Registrations/HeaderRegis/Header';
import { LoginIn } from './Registrations/Login/LoginIn'
import { Register } from './Registrations/Register/Register'
import { Routes, Route,Navigate} from "react-router-dom";
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Accueil } from './Registrations/Accueil/Accueil';
import { PasswordConfirmationWrapper } from './Registrations/PasswordConfirmation/PasswordConfirmationWrapper'; 
import ResetPasswordRequest from './Registrations/ResetPasswordRequest/ResetPasswordRequest';
import { PrivacyPolicy } from './Registrations/Privacy/PrivacyPolicy';
import { Waiting } from './Registrations/Waiting/Waiting';
import GoogleSignIn from './Registrations/Login/Login2';
import { ContactUs } from './Registrations/ContactUs/ContactUs';
import { GoogleOAuthProvider,GoogleLogin,useGoogleLogin } from '@react-oauth/google';
import { LoadingWrapper } from './FirstApp/Redirection/LoadingWrapper';
import { LoginPage } from './FirstApp/LoginPage/LoginPage';
import NotFound from './FirstApp/LoginPage/NotFound';
import { Confirmobile } from './common/Confirmobile';
import { NoConfirmobile } from './common/NoConfirmobile';

export const Landing = ({languages}) => {
    // const ip_address='http://localhost:8080'
    const ip_address='https://web.bfdrive.ma'
    
    const mode=useSelector(state=>state.auth.mode)

 
    return (
<div className={`mode-${mode}`}>
        <Fragment>
   
    {/* <Header mode={mode}/> */}
    {/* <GoogleOAuthProvider clientId="901171606422-gs4vgig6f37bl8pmpmat1agjjjaviaim.apps.googleusercontent.com"> */}
    <Routes>
  
    {/* <Route path="/" element={<Accueil ip_address={ip_address} languages={languages}/>}/> */}
        {/* <Route path="/registraions"/> */}
        {/* <Route path="/login" element={<LoginIn ip_address={ip_address} languages={languages}/>} />
        <Route path="/login2" element={<GoogleSignIn/>} /> */}
        <Route path="/loading/:token" element={<LoadingWrapper />} />
        <Route path="/confirmobile" element={<Confirmobile />} />
        <Route path="/noconfirmobile" element={<NoConfirmobile />} />

        <Route path="/backToLogin" element={<NotFound />} />
        {/* <Route path="/register" element={<Register ip_address={ip_address} languages={languages}/>} />
        <Route path="/register1" element={<Waiting text={'Subscription in progress...'}/>} />
        <Route path="/timeline" element={<Waiting text={'Login in progress...'}/>} />
        <Route path="/contact" element={<ContactUs ip_address={ip_address} languages={languages}/>} />
        <Route path="/resetPasswordForm" element={<ResetPasswordRequest ip_address={ip_address} languages={languages}/>} />
        <Route path="/change_password/:token" element={<PasswordConfirmationWrapper  ip_address={ip_address} languages={languages}/>} /> */}
        <Route path="/" element={<Navigate to="/backToLogin" />} />
     

    </Routes>
    {/* </GoogleOAuthProvider> */}
  </Fragment>
  
  </div>
  
    );
}

  