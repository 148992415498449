import { combineReducers } from "redux";
import userReducer from "./userReducer";
import vehiculesReducer from './vehiculesReducer'
import notificationReducer from "./notificationReducer";
import errors from './errors'
import messages from "./messages";



export default combineReducers({
    vehicules:vehiculesReducer,
    auth:userReducer,
    notifications:notificationReducer,
    errors:errors,
    messages:messages,
})