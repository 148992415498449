import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './AddRefuling.css'
import { updateVehicule } from '../../../../actions/rappelActions'
import { CREATE_MESSAGES, GET_ERRORS } from '../../../../actions/types'
import { useTranslation } from 'react-i18next'
import { MdArrowDropDown,MdArrowDropUp } from "react-icons/md";



export const AddRefuling = ({ip_address,currentLanguage,digit_format}) => {
    const {t}=useTranslation()

    // const vehicules = useSelector(state => state.vehicules.items)
    const current_odometre=useSelector(state=>state.vehicules.item.odometre )
    const selected_vehicule= useSelector(state => state.vehicules.item.url)
    const selected_vehicule_id= useSelector(state => state.vehicules.item.id)
    const fuels = JSON.parse(useSelector(state => state.auth.profile.fuel_choice))
    const places = JSON.parse(useSelector(state => state.auth.profile.places_choice))
    const reasons = JSON.parse(useSelector(state => state.auth.profile.raison))
    const mode=useSelector(state=>state.auth.mode)
    const user=useSelector(state=>state.auth.user.url)
    const username=useSelector(state=>state.auth.user.username)
    const current_time=Date.now()/1000
    // const conducteurs= useSelector(state => state.vehicules.item.driver)
    const payment_methods = JSON.parse(useSelector(state => state.auth.profile.payment_method))
    const stations = JSON.parse(useSelector(state => state.auth.profile.gasStation))
    const own= useSelector(state => state.auth.profile.url)
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const [vehicule, setVehicule] = useState('')
    const [fuel_type, setFuel_type] = useState('')
    const [odometre, setOdometre] = useState(0)
    const [price, setPrice] = useState(0)
    const [quantité, setQuantité] = useState(0)
    const [unit_price, setUnit_price] = useState(0)
    const [showOptional, setShowOptional] = useState('none')
    const [buttonTitle, setButtonTitle] = useState('More options')
    
    // const [driver, setDriver] = useState('')
    // const [drivers, setDrivers] = useState([])
    const [note, setNote] = useState('')
    const [reason,setReason]=useState('')
    const [timer,setTimer]=useState('')
    const [payment_method,setPayment_method]=useState('')
    const [is_filling,setIs_filling]=useState(false)
    const [file, setFile] = useState(null)
    const [place,setPlace]=useState('')
    const [station,setStation]=useState('')


//////////////////////////////////////////////////////////// chargement des parametres avec useEffect


    useEffect(()=>{
        
        setVehicule(selected_vehicule)
        if(fuels.length>0){
            setFuel_type(fuels[0])
        }else{
            setFuel_type('no choices available')
        }
        // if (conducteurs){
        //     setDrivers(conducteurs)
        //     setDriver(conducteurs[0])
        // }else{
        //     setDrivers([own])
        // }
        if(payment_methods.length>0){
            setPayment_method(payment_methods[0])
        }
        if(stations.length>0){
            setStation(stations[0])
        }
        if(places.length>0){
            setPlace(places[0])
        }else{
            setPlace(t('no choices available'))
        }
    
       },[selected_vehicule])


////////  la requette fetch pour ajouter un ravitaillement

    const ajouterCarburant=async(data)=>{

       

        if(Date.parse(new Date(timer))/1000 <= current_time)
        {const requestPost=await fetch(`${ip_address}/app/api/refuling/`,{
            method:'POST',
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            body:data,
           
        },
        
        )
        console.log(requestPost)
        const resp=await requestPost.json()
        if(requestPost.status===201){
            if(current_odometre<odometre){
                const new_odometre=new FormData()
                new_odometre.append('odometre',odometre)
                dispatch(updateVehicule(selected_vehicule_id,new_odometre))
            }
            navigate('/timeline') // ne pas oublier de creer la page de history
            dispatch({
                type:CREATE_MESSAGES,
                payload:`${t("Refuling")} ${t("created success")}`,
            })
            // dispatch(fetchVehicules())
            return resp
        }else{
            dispatch({
                type:GET_ERRORS,
                payload:{
                    msg:resp.message,
                    status:requestPost.status
                }
            })
        }}else{
            dispatch({
                type:GET_ERRORS,
                payload:{
                    msg:t("future events are not allowed"),
                    status:400
                }
            })
        }
 
    }

///////////////////// onSubmit pour soumettre le formulaire

    const onSubmit=async(e)=>{
        e.preventDefault()
        const formdata= new FormData()
        // formdata.append('driver',driver)
        formdata.append('type',fuel_type)
        formdata.append('odometre',odometre)
        formdata.append('price',price)
        formdata.append('quantité',quantité)
        formdata.append('vehicule',vehicule)
        formdata.append('writter',user)
        // formdata.append('username',username)
        if(payment_method){
            formdata.append('payment_method',payment_method)
        }
        if(reason){
            formdata.append('raison',reason)
        }
        if(station){
            formdata.append('station',station)
        }
        if(timer){
            formdata.append('creation_date',timer)
        }
        formdata.append('is_full',is_filling)
        if(file){
            formdata.append('attached_file',file)
        }
        if(note){
            formdata.append('note',note)
        }
        if(place){
            formdata.append('place',place)
        }
        
        ajouterCarburant(formdata)
      
        // ajouterRappel(formdata)
        // setReminder_choice('')
        // setReminder_type('')
        // setOdometre('')
        // setFile(null)
        // setNote('')
        // setVehicule('')
    }


////////////////////////////// fonction pour calculer le prix du carburant // la quantité // prix total


    // select quantite function
    const computeTotalPrice=(e)=>{
        setQuantité(e.target.value)
        if(unit_price && !price){
     
            setPrice(Math.round(unit_price*e.target.value*100)/100)
        }else if(price && !unit_price){
            setUnit_price(price/e.target.value)
        }else if(price && unit_price){
            setPrice(Math.round(unit_price*e.target.value*100)/100)
        } 

    }

    // select whole_price function
    const computeUnitPrice=(e)=>{
        setPrice(e.target.value)
    
        if(unit_price){
        setQuantité(digit_format(e.target.value/unit_price))
       }
    }

    // select unit_price function
    const computePrice=(e)=>{
        setUnit_price(e.target.value)
        
        if(price)
            {setQuantité(digit_format(price/e.target.value))}
   
    }

//////////////////////////////////// show or hide optional inputs


const toggleShowOptional = () => {
        
    if (showOptional === "none") {
    
      setShowOptional("block")
      setButtonTitle('Less options')
    } else {
      setShowOptional("none")
      setButtonTitle('More options')
    }
  }



////////////////////////////// est ceque c'est un plein??

    const togglecheckbox=(e)=>{
        if (e.target.checked){
      
            setIs_filling(true)
         
        }else if(!e.target.checked){
          setIs_filling(false)
        }
      }

//////////////////////////////////// the actual componenet      
    return (
        <section id='refulingForm' className={`all_sections ${currentLanguage.dir || 'ltr'} mt-4`}>

            <div className={`content-${mode} whitewhen${mode}`}>
                <div className='row'>
                    <div className='col m-4'>
                        <div className='title'>
                        <img src={`https://web.bfdrive.ma/media/icons/gas-pump.svg`} style={{"width":"30px","height":"100%"}}/>|
                            <span className='text Airstrike'>{t("Refueling")}</span>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className='row mt-5'>
                                {/* <div className='col-md-4'>
                                    <label for="selectVehicule" class="form-label">select vehicule</label>
                                    <select class={`form-select-${currentLanguage.dir} bg-${mode}`} id='selectVehicule' onChange={(e) =>setVehiculesDrivers(e) } value={vehicule} required>

                                        {vehicules.map((auto,index) => (index===0?<option key={index} value={auto.url} selected>{auto.vehicule_name}</option>:<option key={index} value={auto.url}>{auto.vehicule_name}</option>))}
                                    </select>
                                </div> */}
                                <div className='col-md-6'>
                                    <label for="selectService" class="form-label required">{t("Fuel type")}</label>
                                    <select class={`form-select-${currentLanguage.dir} ${mode}`} id='selectService' onChange={(e) => setFuel_type(e.target.value)} value={fuel_type} required>
                                    
                                        {fuels.map((type, index) => (<option key={index} value={type}>{type}</option>))}
                                    </select>
                                </div>
                                <div className='col-md-6'>
                                    <label for="selectstation" class="form-label required">{t("Gas Station")}</label>
                                    <select class={`form-select-${currentLanguage.dir} ${mode}`} id='selectstation' onChange={(e) => setStation(e.target.value)} value={station} required>
                                    
                                        {stations.map((sta, index) => (<option key={index} value={sta}>{sta}</option>))}
                                    </select>
                                </div>
                               

                               
                                

                                {/* <div className='col-md-4'>
                                    <label for="selectDriver" class="form-label">Select Driver</label>
                                    <select class={`form-select-${currentLanguage.dir} ${mode}`} id='selectDriver' onChange={(e) => setDriver(e.target.value)} value={driver} required>

                                        {drivers?drivers.map((driver, index) => (<option key={index} value={driver}>{driver}</option>)):<option key={1} value={'no driver'}>no driver</option>}
                                    </select>

                                </div> */}
                            </div>
                            <div className='row mt-3'>
                            <div class="col-md-6">
                                    <label for="odometre" class="form-label required">{t("Odometer")}</label>

                                    <input type="number" class={`form-control ${mode}`} id="odometre" placeholder={t("Odometer")} onChange={(e) => setOdometre(e.target.value)} value={odometre} required />
                                    <div id="emailHelp" class="form-text">{t(`Last odometre`)} {current_odometre}</div>
                                </div>
                            <div class="col-md-6">
                                    <label for="inputZip" class="form-label required">{t("Refueling date")}</label>
                                    <input type="datetime-local" class={`form-control ${mode}`} id="inputZip" onChange={(e)=>setTimer(e.target.value)} value={timer}/>
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className='col-md-4'>
                                    <label for="selectPrice" class="form-label required">{t("Total amaount")}</label>
                                    <input type='number' class={`form-control ${mode}`} id='selectPrice' onChange={(e) => computeUnitPrice(e)} value={price} required />

                                     
                                </div>
                                <div className='col-md-4'>
                                    <label for="selectquantité" class="form-label required">{t("Unit price")}</label>
                                    <input type='number' class={`form-control ${mode}`} id='selectquantité' onChange={(e) => computePrice(e)} value={unit_price} required/>


                                </div>


                                <div className='col-md-4'>
                                    <label for="selectquantité" class="form-label required">{t("Quantity")}</label>
                                    <input type='number' class={`form-control ${mode}`} id='selectquantité' onChange={(e) => computeTotalPrice(e)} value={quantité} required/>


                                </div>

                              
                            </div>
                            <div style={{fontSize:10}}><span style={{color:'red'}}>(*)</span>: {t("The above options are obligatory")}</div>
                            <div style={{display:showOptional}}>

                            <div className='row mb-3'>
                                  

                                <div className='col-md-4'>
                                    <label for="selectplace" class="form-label">{t("Place")}</label>
                                    <select class={`form-select-${currentLanguage.dir} ${mode}`} id='selectplace' onChange={(e) => setPlace(e.target.value)} value={place} required>
                                    
                                        {places.map((pla, index) => (<option key={index} value={pla}>{pla}</option>))}
                                    </select>
                                </div>
                               
                                
                                <div className='col-md-4'>
                                    <label for="selectpayment_method" class="form-label">{t("Payment method")}</label>
                                    <select class={`form-select-${currentLanguage.dir} ${mode}`} id='selectpayment_method' onChange={(e) => setPayment_method(e.target.value)} value={payment_method} required>
                                    
                                        {payment_methods.map((method, index) => (<option key={index} value={method}>{method}</option>))}
                                    </select>
                                </div>
                                <div className='col-md-4'>
                                    <label for="selectResaon" class="form-label">{t("Reason")}</label>
                                    <select class={`form-select-${currentLanguage.dir} ${mode}`} id='selectResaon' onChange={(e) => setReason(e.target.value)} value={reason} required>
                                    
                                        {reasons.map((raison, index) => (<option key={index} value={raison}>{raison}</option>))}
                                    </select>
                                </div>

 
                            </div>
                            <div className='row mb-3'>
                           
                           
                               
                            <div class="col-md-6 mb-3"  >
                                    <label for="textarea1" class="form-label">{t("Note")}</label>

                                    <textarea class={`form-control ${mode}`} id="textarea1" rows="1" placeholder={t('Add remark')} onChange={(e) => setNote(e.target.value)} value={note}></textarea>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <label for="formFileLg" class="form-label">{t("File")}</label>
                                    <input class={`form-control ${mode}`} id="formFileLg" type="file" onChange={(e) => setFile(e.target.files[0])} />
                                </div>

</div>

                                {/* <div className='row mb-3'>
                                <div class="col ">
                                    <div class="form-check">
                                        <input class="form-check-input bg-dark" type="checkbox" id="gridCheck" onChange={(e)=>togglecheckbox(e)}/>
                                        <label class="form-check-label" for="gridCheck">
                                            {t("Are you filling the tank?")}
                                        </label>
                                    </div>
                                </div></div> */}
                                
                                </div>
                                <a  onClick={toggleShowOptional} style={{cursor:"pointer",fontSize:16,fontWeight:'bold',color:"#1a5276",paddingTop:10,paddingBottom:10}}>{t(buttonTitle)}{buttonTitle==="More options"?<MdArrowDropDown />:<MdArrowDropUp/>}</a>
                            <div class="col-12">
                                <button type="submit" class="btn form-control" style={{backgroundColor:"#FFAE20",color:"white"}}>{t("Add Refueling")}</button>
                            </div>
                        </form>
                        
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg'>




                    </div>
                </div>
            </div>


        </section>
    )
}
