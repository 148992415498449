import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CREATE_MESSAGES, GET_ERRORS } from '../../../../actions/types'


export const EditReminder = ({ ip_address, currentLanguage, id }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [reminder_choice, setReminder_choice] = useState('')
  const [reminder_type, setReminder_type] = useState('')
  // const [vehicule,setVehicule]=useState('')
  const [odometre, setOdometre] = useState('')
  const [note, setNote] = useState('')
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(true)
  const [bydistance, setBydistance] = useState(true)
  const [bydate, setBydate] = useState(true)
  const [every, setEvery] = useState(false)
  const [service_clicked, setService_clicked] = useState("not_clicked")
  const [expense_clicked, setExpense_clicked] = useState("not_clicked")
  const [changed_items, setChanged_items] = useState({})
  const [file, setFile] = useState(null)
  const [timer, setTimer] = useState('')
  const [odometre_rappel, setOdometre_rappel] = useState(0)
  const [distance, setDistance] = useState(0)
  const [time_amount, setTime_amount] = useState(0)
  const [time_space,setTime_space]=useState(0)
  const [warning_message, setWarning_message] = useState(false)
  const [selected_engin, setSelected_engin] = useState()
  const warn_text = 'be carfull of editing the vehicule'
  const user = useSelector(state => state.auth.user.url)
  // const vehicules=useSelector(state=>state.vehicules.items)
  const current_vehicule = useSelector(state => state.vehicules.item)
  const mode = useSelector(state => state.auth.mode)
  const current_odometre = useSelector(state => state.vehicules.item.odometre)
  const reminder_types = JSON.parse(useSelector(state => state.auth.profile.services))
  const expense_types = JSON.parse(useSelector(state => state.auth.profile.expense))
  const navigate = useNavigate()
  const [choix, setChoix] = useState([])
  const periods = ['day', 'month', 'year']


  useEffect(() => {


    const fetchItem = async () => {
      const item = await getItem()
    }
    setShow1(true)
    fetchItem()
    //  if(selected_engin!=current_vehicule.url){
    //   alert(warn_text)
    changed_items['vehicule'] = current_vehicule.url
    //  }


  }, [current_vehicule])


  const getItem = async () => {
    const request = await fetch(`${ip_address}/app/api/rappels/${id}`, {

      headers: {
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
      },


    },)

    const response = request.status
    switch (response) {
      case 200:
        const resp = await request.json()
        setShow(true)
        setReminder_choice(resp.rappel)
        console.log(resp.rappel)
        switch (resp.rappel) {
          case 'Service':
            setService_clicked("clicked")
            setExpense_clicked("not_clicked")
            setChoix(reminder_types)
            break
          case 'Expense':
            setExpense_clicked("clicked")
            setService_clicked("not_clicked")
            setChoix(expense_types)
            break
        }
        setSelected_engin(resp.vehicule)
        setReminder_type(resp.type)
        setOdometre_rappel(resp.odometre_rappel)
        setEvery(resp.repeat_every)
        if (resp.distance) {
          setBydistance(true)
          setDistance(resp.distance)
        }
        if (resp.rappel_date) {
          setTimer(resp.rappel_date.split('.')[0])
          setBydate(true)
          setTime_amount(resp.time_amount.split('X')[1])
          setTime_space(resp.time_amount.split('X')[0])
        }
        // setTimer(resp.creation_date.split('.')[0])
        setNote(resp.note)
        setFile(resp.attached_file)
        console.log(resp.attached_file)
        break
      case 403:
        dispatch({
            type:GET_ERRORS,
            payload:{
                msg:t("You are not allowed to edit this item"),
                status:403
            }
        })
        break
      default:
        alert(t('item not found'))
        break
    }
  }

  const chooseForm1 = () => {
    setReminder_choice('Service')
    changed_items['rappel'] = "Service"
    setChoix(reminder_types)
    // setShow1(false)
    setService_clicked("clicked")
    setExpense_clicked("not_clicked")
    // setShow(true)
    // setChoix(reminder_types)
  }

  const chooseForm2 = () => {
    setReminder_choice('Expense')
    setChoix(expense_types)
    changed_items['rappel'] = "Expense"
    // setShow1(false)
    setService_clicked("not_clicked")
    setExpense_clicked("clicked")
    // setShow(true)
    // setChoix(expense_types)
  }

  const editRappel = async (data) => {



    const requestPost = await fetch(`${ip_address}/app/api/rappels/${id}/`, {
      method: 'PATCH',
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: data,

    },

    )
    const resp = await requestPost.json()
    console.log(requestPost)
    if (requestPost.status === 200) {
      navigate('/reminder')
      dispatch({
        type: CREATE_MESSAGES,
        payload: `${t("Reminder for ")} ${current_vehicule.model} ${t("edited successfully")}`,
      })
      setTimeout(()=>{
        dispatch({
            type:CREATE_MESSAGES,
            payload:``,
        })},
        4000
       )
      return resp
    } else {
      dispatch({
        type: GET_ERRORS,
        payload: {
          msg: resp.message,
          status: requestPost.status
        }
      })
    }

  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const formdata = new FormData()
    Object.keys(changed_items).forEach((item) => {
      formdata.append(item, changed_items[item])
      console.log(item, changed_items[item])
    })
    editRappel(formdata)

    setChanged_items({})
  }


  // toggle checkBox

  const togglecheckbox1 = (e) => {
    if (e.target.checked) {

      setBydate(true)


    } else if (!e.target.checked) {
      setBydate(false)
    }
  }
  const togglecheckbox2 = (e) => {
    if (e.target.checked) {

      setBydistance(true)

    } else if (!e.target.checked) {
      setBydistance(false)
    }
  }


  return (

    <section id='reminderForm' className={`all_sections ${currentLanguage.dir || 'ltr'} mt-4`}>

      <div className={`content-${mode}`}>
        <div className='row' style={{ "width": "100%" }}>
          <div className='col'>
            <div className='title'>
              <img src={`${ip_address}/media/icons/stopwatch.svg`} style={{ "width": "30px" }} /> |
              <span className='text' style={{ fontFamily: "Airstrike" }}>{t("Edit")} {t("Reminder")}</span>
            </div>
            <form id='reminderForm' onSubmit={onSubmit}>
              <div className='reminderChoices'>
                <div className='row'>
                  <div className='col-lg-6 col-md-12'>
                    <div className='choices m-1' onClick={() => chooseForm1()} ><span className={`reminderChoice ${service_clicked}`} style={{ color: (service_clicked == 'clicked' && mode=='light') || mode=='dark' ? "rgba(255,255,255,.55)" : "rgba(0,0,0,.55)" }}>{t("Service")}</span></div>
                  </div>
                  <div className='col-lg-6 col-md-12'>
                    <div className='choices m-1' onClick={() => chooseForm2()} ><span className={`reminderChoice ${expense_clicked}`} style={{ color: (expense_clicked == 'clicked' && mode=='light') || mode=='dark' ? "rgba(255,255,255,.55)" : "rgba(0,0,0,.55)" }}>{t("Expense")}</span></div>
                  </div>



                </div>
              </div>
              {show && <div className='formContent'>
                <div className='row mb-3'>
                  <div className='col-12'>
                    <label for="selectService" class="form-label">{t("Select")} {reminder_choice}</label>
                    <select class={`form-select ${mode}`} id='selectService' onChange={(e) => {
                      setReminder_type(e.target.value)
                      changed_items['type'] = e.target.value
                      setShow1(true)
                    }} value={reminder_type} required>

                      {choix.map((type, index) => (<option key={index} value={type}>{type}</option>))}
                    </select>
                  </div>
                </div>
              </div>}
              {show1 && (<div className='formContent'>
                {/* choose between just one time or Repeat every */}
                <div className='row mb-3'>
                  <div class="col-lg-6">
                    <div class={`form-control ${mode}`} style={{ backgroundColor: every ? 'transparent' : '#00587d', borderLeftColor: every ? '#00587d' : '#f8f9fa',color: (!every && mode=='light') || mode=='dark' ? "rgba(255,255,255,.55)" : "rgba(0,0,0,.55)"  }} onClick={() => { setEvery(false); changed_items['repeat_every'] = false }}>{t("Just one time")}</div>
                  </div>
                  <div className='col-lg-6'>

                    <div class={`form-control ${mode}`} style={{ backgroundColor: every ? '#00587d' : 'transparent', borderLeftColor: every ? '#f8f9fa' : '#00587d' ,color: (every && mode=='light') || mode=='dark' ? "rgba(255,255,255,.55)" : "rgba(0,0,0,.55)" }} onClick={() => { setEvery(true); changed_items['repeat_every'] = true }}>{t("Repete every")}</div>
                  </div>

                </div>
                {/* distnace entry */}
                <div className='row mb-3'>
                  <div class="col-lg-4">
                  <label for='by_odo' class="form-label">{t("Reminder by odometer")}</label>
                    <div class={`form-control ${mode}`} id='by_odo'>
                      <input class={`form-check-input ${mode}`} type="checkbox" style={{border:"solid #5bc0de 2px"}} id="gridCheck" onChange={(e) => togglecheckbox2(e)} />
                      <label class="form-check-label" for="gridCheck">
                        {t("distance")} Km
                      </label>
                    </div>
                  </div>
                  {bydistance && <><div className='col-lg-4'>
                  <label for='odometre_rappel' class="form-label">{t("Select the odometer reminder")}</label>
                    <input class={`form-control ${mode}`} id='odometre_rappel' type="text" onChange={(e) => { setOdometre_rappel(e.target.value); changed_items['rappel_date'] = e.target.value }} value={odometre_rappel} placeholder={t('Odometre')} required />
                    <div id="emailHelp" class="form-text">{t(`Last odometre`)}{current_odometre}</div>
                    {/* <input type="number" class={`form-control ${mode}`} id="odometre" placeholder="odometre" onChange={(e) => setOdometre(e.target.value)} value={odometre} required />
                    <div id="emailHelp" class="form-text">{`last odometre ${current_odometre}`}</div> */}
                  </div>
                    {every && <div className='col-lg-4'>
                    <label for='repeat_each' class="form-label">{t("Repeat each")}</label>
                      <input class={`form-control ${mode}`} id='repeat_each' type="text" onChange={(e) => { setDistance(e.target.value); changed_items['distance'] = e.target.value }} value={distance} placeholder={t('Distance')} required />

                    </div>}</>}
                </div>
                {/* time entry */}
                <div className='row mb-3'>
                  <div class="col-lg-4">
                  <label for='by_date' class="form-label">{t("Reminder by date")}</label>
                    <div class={`form-control ${mode}`} id='by_date'>
                      <input class={`form-check-input ${mode}`} type="checkbox" style={{border:"solid #5bc0de 2px"}} id="gridCheck1" onChange={(e) => togglecheckbox1(e)} />
                      <label class="form-check-label" for="gridCheck1">
                        {t("Date")}
                      </label>
                    </div>
                  </div>

                  {bydate && <><div class="col-lg-4">
                  <label for='start' class="form-label">{t("Start date")}</label>
                    <input type="datetime-local" class={`form-control ${mode}`} iid="start" onChange={(e) => { setTimer(e.target.value); changed_items['rappel_date'] = e.target.value }} value={timer} />
                  </div>{every && <div className='col-lg-4'>
                  <label for='repeat_each' class="form-label">{t("Repeat each")}</label>
                  <input class={`form-control ${mode}`} id='repeat_each' type="number"  onChange={(e)=>{setTime_space(e.target.value);changed_items['time_amount']=`${e.target.value}X${time_amount}`}} value={time_space} placeholder={t('Interval')} required/>

                  
                    </div>}</>}

                </div>

                {bydate && <>{
                  every&&  <div className='row mb-3'>
                  <div class="col-lg-4">
                  <label for='period' class="form-label">{t("Periods")}</label>
                  <select class={`form-select ${mode}`} id='period' onChange={(e)=>{setTime_amount(e.target.value);changed_items['time_amount']=`${time_space}X${e.target.value}`}} value={time_amount} placeholder={t('period')} required>

{periods.map((period, index) => (<option key={index} value={period}>{t(period)}</option>))}
</select>
                  </div>
                  </div>
                }</>}
                <div className='row mb-3'>
                  <div class="col mb-3" >
                    <label for="textarea1" class="form-label">{t("Edit")} {t("text")}</label>
                    <textarea class={`form-control ${mode}`} id="textarea1" rows="1" placeholder='add remark' onChange={(e) => { setNote(e.target.value); changed_items['note'] = e.target.value }} value={note}></textarea>
                  </div>
                </div>


                <div className='col-12 mb-3'>
                  <button className='btn  form-control' style={{ color: 'white',backgroundColor:"#00587d" }} type='submit'>{t("Edit")} {t("Reminder")}</button>
                </div></div>)

              }






            </form>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg'>




          </div>
        </div>
      </div>


    </section>



  )
}
