import React, { useState, useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import '../../../stylings/Styles.css'
import { useNavigate } from 'react-router-dom'
import { fetchVehicules } from '../../../../actions/rappelActions'
import { CREATE_MESSAGES, GET_ERRORS } from '../../../../actions/types'
import { createMessage } from '../../../../actions/creatMessage'
import { useTranslation } from 'react-i18next'


export const EditIncome = ({ip_address,currentLanguage,id}) => {
    const {t}=useTranslation()
    const mode=useSelector(state=>state.auth.mode)
    const user=useSelector(state=>state.auth.user.url)
    const username=useSelector(state=>state.auth.user.username)
    // const vehicules = useSelector(state => state.vehicules.items)
    const profile=useSelector(state => state.auth.profile)
    const current_odometre=useSelector(state=>state.vehicules.item.odometre )
    const selected_vehicule= useSelector(state => state.vehicules.item.url)
    const selected_vehicule_id= useSelector(state => state.vehicules.item.id)
    const income_types = JSON.parse(profile.income)
    const places = JSON.parse(profile.places_choice)
    const reasons = JSON.parse(profile.raison)
    const payment_methods = JSON.parse(profile.payment_method)
    // const conducteurs= useSelector(state => state.vehicules.item.driver)
    const own= useSelector(state => state.auth.profile.url)
    // const drivers = ['http://localhost:8080/api/profiles/1/', 'http://localhost:8080/api/profiles/2/']
    const token = useSelector(state => state.auth.token)
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const [vehicule, setVehicule] = useState('')
    const [income_type, setIncome_type] = useState('')
    const [odometre, setOdometre] = useState(0)
    const [changed_items,setChanged_items]=useState({})
    const [valeur, setValeur] = useState(0)
    // const [driver, setDriver] = useState('')
    // const [drivers, setDrivers] = useState('')
    const [note, setNote] = useState('')
    const [timer,setTimer]=useState('')
    const [payment_method,setPayment_method]=useState('')
    const [reason,setReason]=useState('')
    const [place,setPlace]=useState('')
    const current_vehicule=useSelector(state=>state.vehicules.item)
   
    const [file, setFile] = useState(null)

    useEffect(()=>{
       const fetchItem=async()=>{
        const item=await getItem()
       } 
       fetchItem()
    
    
       },[selected_vehicule])


       const getItem=async()=>{
        const request=await fetch(`${ip_address}/app/api/revenue/${id}`,{
           
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
         
           
        },)

        const response=request.status
        switch (response){
            case 200:
                const resp=await request.json()
                setIncome_type(resp.type)
                setValeur(resp.value)
                setOdometre(resp.odometre)
                setPayment_method(resp.payment_method)
                setReason(resp.raison)
                setTimer(resp.creation_date.split('.')[0])
                setNote(resp.note)
                setPlace(resp.place)
                break
            case 403:
                dispatch({
                    type:GET_ERRORS,
                    payload:{
                        msg:t("You are not allowed to edit this item"),
                        status:403
                    }
                })
                break
            default:
                alert(t('item not found'))
                break
        }
       }

    const editIncome=async(data)=>{

      

        const requestPost=await fetch(`${ip_address}/app/api/revenue/${id}/`,{
            method:'PATCH',
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            body:data,
           
        },
        
        ) 
        const resp=await requestPost.json()
        console.log(requestPost)
        if(requestPost.status===200){
  
            dispatch({
                type:CREATE_MESSAGES,
                payload:`${t("Income")} ${current_vehicule.vehicule_name} ${t("edited successfully")}`,
            })
           
            // dispatch(fetchVehicules())
            return resp
        }else{
            dispatch({
                type:GET_ERRORS,
                payload:{
                    msg:resp.message,
                    status:requestPost.status
                }
            })
        }
 
    }


    const onSubmit=async(e)=>{
        e.preventDefault()
        const formdata= new FormData()
        formdata.append('vehicule_id',selected_vehicule_id)
        Object.keys(changed_items).forEach((item) => {
            formdata.append(item,changed_items[item])
            console.log(item,changed_items[item])
          })
        
        editIncome(formdata)
      
        setChanged_items({})
    }




    return (
        <section className={`all_sections ${currentLanguage.dir || 'ltr'} mt-4`} id='incomeForm'>

            <div className={`content-${mode} whitewhen${mode}`}>
                <div className='row'>
                    <div className={`col m-4`}>
                        <div className='title'>
                        <img  src={`${ip_address}/media/icons/wallet.svg`} style={{"width":"30px"}}/> |
                            <span className='text Airstrike' >{t("Edit")} {t("Income")}</span>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className='row mt-5'>
           
                                <div className='col-md-4'>
                                    <label for="selectService" class="form-label"> {t("Income")} {t("type")}</label>
                                    <select class={`form-select ${mode}`} id='selectService' onChange={(e) => {setIncome_type(e.target.value) ;changed_items["type"]=e.target.value}} value={income_type} >
                                    
                                        {income_types.map((type, index) => (<option key={index} value={type}>{type}</option>))}
                                    </select>
                                </div>

                                <div class="col-md-4">
                                    <label for="odometre" class="form-label">{t("Odometer")}</label>

                                    <input type="number" class={`form-control ${mode}`} id="odometre" placeholder="odometre" onChange={(e) => {setOdometre(e.target.value);changed_items["odometre"]=e.target.value}} value={odometre}  />
                                    <div id="emailHelp" class="form-text">{t(`Last odometre`)}{current_odometre}</div>
                                </div>

                                <div class="col-md-4">
                                    <label for="inputZip" class="form-label"> {t("Service ate")}</label>
                                    <input type="datetime-local" class={`form-control ${mode}`} id="inputZip" onChange={(e)=>{setTimer(e.target.value);changed_items["creation_date"]=e.target.value}} value={timer}/>
                                </div>
                             

                         


                            </div>

                            <div className='row mt-3'>

                            <div className='col-md-4'>
                                    <label for="selectPrice" class="form-label"> {t("Price")}</label>
                                    <input type='number' class={`form-control ${mode}`} id='selectPrice' onChange={(e) => {setValeur(e.target.value);changed_items["value"]=e.target.value}} value={valeur}  />

                                     
                                </div>

                                <div className='col-md-4'>
                                    <label for="selectpayment_method" class="form-label"> {t("Payment method")}</label>
                                    <select class={`form-select ${mode}`} id='selectpayment_method' onChange={(e) => {setPayment_method(e.target.value);changed_items["payment_method"]=e.target.value}} value={payment_method} >
                                    
                                        {payment_methods.map((method, index) => (<option key={index} value={method}>{method}</option>))}
                                    </select>
                                </div>
                              
                              
                                <div className='col-md-4'>
                                    <label for="selectplace" class="form-label"> {t("Place")}</label>
                                    <select class={`form-select ${mode}`} id='selectplace' onChange={(e) => {setPlace(e.target.value);changed_items["place"]=e.target.value}} value={place} >
                                    
                                        {places.map((pla, index) => (<option key={index} value={pla}>{pla}</option>))}
                                    </select>
                                </div>
                            
                               



                            </div>

                            
                            <div className='row mb-3'>
                     

                         
                             

                                <div className='col-md-4'>
                                    <label for="selectResaon" class="form-label">{t("Add")} {t("Reason")}</label>
                                    <select class={`form-select ${mode}`} id='selectResaon' onChange={(e) => {setReason(e.target.value);changed_items["raison"]=e.target.value}} value={reason} >
                                    
                                        {reasons.map((raison, index) => (<option key={index} value={raison}>{raison}</option>))}
                                    </select>
                                </div>
                                <div class="col-md-4 mb-3" >
                                    <label for="textarea1" class="form-label">{t("Note")}</label>

                                    <textarea class={`form-control ${mode}`} id="textarea1" rows="1" placeholder='add remark' onChange={(e) => {setNote(e.target.value);changed_items["note"]=e.target.value}} value={note}></textarea>
                                </div>
  
                                <div className='col-md-4 mb-3'>
                                    <label for="formFileLg" class="form-label">{t("Attach ile")}</label>
                                    <input class={`form-control ${mode}`} id="formFileLg" type="file" onChange={(e) => {setFile(e.target.files[0]);changed_items["attached_file"]=e.target.files[0]}} />
                                </div>
                               
                            </div>

                            <div className='row mb-3'>
                       


                               
                              

                                    <div class="col-12">
                                <button type="submit" class={`btn form-control`} style={{backgroundColor:"#00FF48",color:"white"}}>{t("Edit")} {t("Income")}</button>
                            </div>
                            </div>
                         

     
                        
                        </form>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg'>




                    </div>
                </div>
            </div>


        </section>
    )
}
