export const FETCH_VEHICULES='FETCH_VEHICULES';
export const NEW_RAPPEL='NEW_RAPPEL';
export const FETCH_USER='FETCH_USER';
export const USER_LOADED='USER_LOADED';
export const USER_LOADING='USER_LOADING';
export const AUTH_ERROR='AUTH_ERROR';
export const USER_AUTHENTICATED='USER_AUTHENTICATED';
export const USER_SUBSCRIBED='USER_SUBSCRIBED';
export const SET_VEHICULE='SET_VEHICULE';
export const DELETE_VEHICULE='DELETE_VEHICULE';
export const ADD_VEHICULE='ADD_VEHICULE';
export const UPDATE_PROFILE='UPDATE_PROFILE';
export const TOGGLE_MODE='TOGGLE_MODE';
export const UPDATE_VEHICULE='UPDATE_VEHICULE';
export const UPDATE_OWN_VEHICULE='UPDATE_OWN_VEHICULE';
export const DELETE_NOTIFICATION='DELETE_NOTIFICATION';
export const READ_NOTIFICATION='READ_NOTIFICATION';
export const FETCH_NOTIFICATION='FETCH_NOTIFICATION';
export const USER_UPDATED='USER_UPDATED';
export const VEHICULE_PARTIAL_UPDATED='VEHICULE_PARTIAL_UPDATED';
export const PROFILE_PARTIAL_UPDATED='PROFILE_PARTIAL_UPDATED';
export const DELETE_ROUTE='DELETE_ROUTE';
export const GET_ERRORS='GET_ERRORS';
export const GET_MESSAGES='GET_MESSAGES';
export const CREATE_MESSAGES='CREATE_MESSAGES'
export const LOGOUT_VEHICULE='LOGOUT_VEHICULE'



