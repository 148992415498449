
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { UPDATE_PROFILE } from '../../../../actions/types'
import { Modal } from '../../Modals/Modal'
import { Modal2 } from '../../Modals/Modal2'
import { useTranslation } from 'react-i18next'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from 'react-icons'

export const IncomeChoices = ({mode}) => {
  const {t}=useTranslation()
  const [income_types, setIncome_types] = useState([])
  const [income, setIncome] = useState('')
  const dispatch = useDispatch()
  const current_profile = useSelector((state) => state.auth.profile)
  useEffect(() => {

    setIncome_types(JSON.parse(current_profile.income))


  }, [])

  ////////////////////////////:handle click outside model
////////////////// chooseType is the id of the input to
/////////////////////// avoid hidding the modal when typing in the input

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };


  // ///////////////////////////////////// UPDATE EXPENSE


  const updateIncome = async (e) => {
    e.preventDefault()
    income_types.push(income)
    const data = {
      'income': JSON.stringify(income_types)
    }
    console.log('this is', data)
    handleCloseModal()
    dispatch(updateProfile(data))

  }


    // ///////////////////////////////////// UPDATE EXPENSE


    const updateIncome2 = async (income) => {
     
      const incomes = income_types.filter((type) => type !== income)
      setIncome_types(incomes)
      const data = {
        'income': JSON.stringify(incomes)
      }

      dispatch(updateProfile(data))
  
    }

  // ///////////////////////////////////// UPDATE Profile

  const updateProfile = (data) => {

    return function (dispatch) {
      fetch(current_profile.url, {
        headers: {
          'content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        },
        method: 'PATCH',
        body: JSON.stringify(data),

      },

      ).then(resp => resp.json()).then(profile => {
        if (profile.hasOwnProperty('avatar')) {
          dispatch({
            type: UPDATE_PROFILE,
            payload: profile
          })
        } else {
          alert('something went wrong pleaze try again later')
        }
      })
    }
  }
const setContent_income=()=>{
  return(<form onSubmit={updateIncome}>
  <input type='text' placeholder='name' className='form-control mb-2' onChange={(e) => { setIncome(e.target.value) }} />
  <button type='submit' className='btnOutline form-control'>{t("submit")}</button>
</form>)
}






////////////////////////////////end 

  return (

    <div className='typesChoices'>
      <div className='typesChoices-content'>
        <h3 className={`strike-${mode}`}>{t("Types of Income")}</h3>




        <button className="btnOutline" onClick={() => handleOpenModal()}>{t("Add")} {t("Income")}</button>

        <Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setContent_income}/>
        {/* <Modal modal={'income'}  mode={mode} showModal={showModal} setContent={setContent_income}/> */}
      </div>



      <ul class={`list-group mt-5 choices-list-${mode}`}  style={{}}>
        {income_types.map((type, index) => <li key={index} class={`list-group-item choices-list-${mode}`} style={{ "display": "flex", "justifyContent": "space-between", "alignItems": "center" ,"width":"100%"}}>{type}<span onClick={() => updateIncome2(type)} style={{ "cursor": "pointer" }}>
        <IconContext.Provider value={{ style: { fontSize: "20px",color:"red" }, className: "global-class-name" }}>
                    <div>
                     <MdDeleteForever />
                    </div>
                  </IconContext.Provider>
          </span></li>)}

      </ul>

    </div>
  )
}
