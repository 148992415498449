import React from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { logoutUser } from '../../actions/auth';
import { useTranslation } from 'react-i18next'; 
import { useNavigate } from 'react-router-dom';

export const EmailConfirm = () => {

  const {t}=useTranslation()

    const email=useSelector(state=>state.auth.user.email)
    const navigate=useNavigate()
    const dispatch=useDispatch()
    function redirectToEmailAccount(email) {
        // Determine the email service provider based on the email domain
        const domain = email.split('@')[1].toLowerCase();
        
        // Define the URL for each email service provider
        const emailProviders = {
          'gmail.com': 'https://mail.google.com',
          'yahoo.com': 'https://mail.yahoo.com',
          'outlook.com': 'https://outlook.live.com',
          // Add more email service providers as needed
        };
        
        // Check if the email service provider is supported
        if (domain in emailProviders) {
          const emailUrl = emailProviders[domain];
          window.location.href = emailUrl;
        } else {
          console.error(t("Email service provider not supported"));
          // Handle the error or provide fallback behavior
        }
      }
    const resendConfirmationEmail=async()=>{
        const request=await fetch('/app/api/send-confirmation-email/',{
            method:'POST',
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
         })
         switch(request.status){
            case 201:
                alert(t("confirmation email is send"))
                redirectToEmailAccount(email)
                break
            default:
                alert(t("something went wrong"))
                break

         }
    }
  return (
    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'700px',backgroundColor: '#f2f2f2'}}>
    <div class="email_container" style={{
  maxWidth: '500px',
  backgroundColor: '#ffffff',
  borderRadius: '5px',
  padding: '20px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
}}>

    <div className='row'>
        <div className='col-8'>
            <h1 style={{fontFamily: 'Airstrike'}}>{t("Confirm Subscription")}</h1>
        </div>
        <div className='col-4' style={{}}>
            <img src={`/media/Logo.svg`} id='logo' style={{width:'100px',marginTop:'10px'}}/>
        </div>
    </div>
    
    <p style={{     marginBottom: '10px',
      lineHeight: '1.5'}}>{t("Please check your email for a confirmation link to complete your subscription.")}</p>
    <p>{t("If you haven't received the email, you can click the button below to resend it.")}</p>
    <button type="button" onClick={()=>resendConfirmationEmail()} style={{
      display: 'block',
      marginTop: '20px',
      padding: '10px 20px',
      backgroundColor: '#007bff',
      color: '#ffffff',
      border: 'none',
      borderRadius: '4px',
      fontSize: '16px',
      cursor: 'pointer',
    }}>{t("Resend Confirmation Email")}</button>
    <button className='btn btn-warning' onClick={()=>{
      dispatch(logoutUser(localStorage.getItem('access_token')))
      navigate('/register')
    }}>back to subscribe</button>
  </div>
  </div>
  )
}
