
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { UPDATE_PROFILE } from '../../../../actions/types'
import { Modal } from '../../Modals/Modal'
import { Modal2 } from '../../Modals/Modal2'
import { useTranslation } from 'react-i18next'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from 'react-icons'


export const ReasonChoices = ({mode}) => {
  const{t}=useTranslation()
  const [reason_types, setReason_types] = useState([])
  const [reason, setReason] = useState('')
  const [showModal, setShowModal] = useState("none")
  const dispatch = useDispatch()
  const current_profile = useSelector((state) => state.auth.profile)
  useEffect(() => {

    setReason_types(JSON.parse(current_profile.raison))


  }, [])

    ////////////////////////////:handle click outside model
////////////////// chooseType is the id of the input to
/////////////////////// avoid hidding the modal when typing in the input

const [modalOpen, setModalOpen] = useState(false);

const handleOpenModal = () => {
  setModalOpen(true);
};

const handleCloseModal = () => {
  setModalOpen(false);
};
  // ///////////////////////////////////// UPDATE EXPENSE


  const updateReason = async (e) => {
    e.preventDefault()
    reason_types.push(reason)
    const data = {
      'raison': JSON.stringify(reason_types)
    }
    console.log('this is', data)
   handleCloseModal()
    dispatch(updateProfile(data))

  }


    // ///////////////////////////////////// UPDATE EXPENSE


    const updateReason2 = async (reason) => {
     
      const reasons = reason_types.filter((type) => type !== reason)
      setReason_types(reasons)
      const data = {
        'raison': JSON.stringify(reasons)
      }

      dispatch(updateProfile(data))
  
    }

  // ///////////////////////////////////// UPDATE Profile

  const updateProfile = (data) => {

    return function (dispatch) {
      fetch(current_profile.url, {
        headers: {
          'content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        },
        method: 'PATCH',
        body: JSON.stringify(data),

      },

      ).then(resp => resp.json()).then(profile => {
        if (profile.hasOwnProperty('avatar')) {
          dispatch({
            type: UPDATE_PROFILE,
            payload: profile
          })
        } else {
          alert('something went wrong pleaze try again later')
        }
      })
    }
  }
const setContent_reason=()=>{
  return(<form onSubmit={updateReason}>
  <input type='text' placeholder='name' className='form-control mb-2' onChange={(e) => { setReason(e.target.value) }} />
  <button type='submit' className='btnOutline form-control'>{t("submit")}</button>
</form>)
}



////////////////////////////////end 


  return (

    <div className='typesChoices'>
      <div className='typesChoices-content'>
        <h3 className={`strike-${mode}`}>{t("Types of reasons")}</h3>




        <button className="btnOutline" onClick={() => handleOpenModal()}>{t("Add")} {t("Reason")}</button>


        <Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setContent_reason}/>
      </div>



      <ul class={`list-group mt-5 choices-list-${mode}`}  style={{}}>
        {reason_types.map((type, index) => <li key={index} class={`list-group-item choices-list-${mode}`} style={{ "display": "flex", "justifyContent": "space-between", "alignItems": "center" ,"width":"100%"}}>{type}<span onClick={() => updateReason2(type)} style={{ "cursor": "pointer" }}>
        <IconContext.Provider value={{ style: { fontSize: "20px",color:"red" }, className: "global-class-name" }}>
                    <div>
                     <MdDeleteForever />
                    </div>
                  </IconContext.Provider>
          </span></li>)}

      </ul>

    </div>
  )
}
