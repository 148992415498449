import React, { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {MdFormatColorFill,MdPolicy} from 'react-icons/md'
import {FcAlarmClock} from 'react-icons/fc'
import './Settings.css'
import { useTranslation } from 'react-i18next'
import { DashboradCard } from '../Dashboard/DashboradCards'
import { ListCard } from './ListCard'



const bitsToBytes = bits => bits / 8;
const bitsToKilobytes = bits => bits / 8000;
const bitsToMegabytes = bits => bits / 8000000;

export const convertBytes = (bytes) => {
  if (bytes < 1024) {
    return bytes + ' bytes';
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes < 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  } else {
    return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
  }
};

export const convertBytesDigits = (bytes) => {
  return (bytes / 1024).toFixed(2) ;
};

export const convertBits = (bits) => {
  if (bits < 0) {
    return "Invalid input: Negative value";
  }

  if (bits < 8) {
    return bits;
  } else if (bits < 8000) {
    const bytes = bitsToBytes(bits);
    return bytes;
  } else if (bits < 8000000) {
    const kilobytes = bitsToKilobytes(bits);
    return kilobytes;
  } else {
    const megabytes = bitsToMegabytes(bits);
    return megabytes;
  }
};




export const Settings = ({currentLanguage}) => {
  const {t}=useTranslation()
  const mode=useSelector(state=>state.auth.mode)
  const [clicked1,setClicked1]=useState(true)
  const [clicked2,setClicked2]=useState(false)
  const [clicked3,setClicked3]=useState(false)
  const [clicked4,setClicked4]=useState(false)

  const setPlan=()=>{
    setClicked1(true)
    setClicked2(false)
    setClicked3(false)
    setClicked4(false)
  }

  const setGeneral=()=>{
    setClicked1(false)
    setClicked2(true)
    setClicked3(false)
    setClicked4(false)
  }

  const setDrivers=()=>{
    setClicked1(false)
    setClicked2(false)
    setClicked3(true)
    setClicked4(false)
  }

  const setServices=()=>{
    setClicked1(false)
    setClicked2(false)
    setClicked3(false)
    setClicked4(true)
  }
  return (
    <section className={`all_sections ${currentLanguage.dir || 'ltr'}`}>
    <div id='paramnav' style={{width:"90%"}}>
 


        <div  className='row m-4' style={{width:"100%" ,backgroundColor:mode==="light"?"#FFF":"#212529",borderRadius:10,margin:10}}>
          {/* <ul class="navbar-nav "> */}
          <div className='col-md-3 p-2 settingsMenu'  style={{borderBottom:clicked1?"4px solid #df1d34":"0px"}}>
              {/* <Link to={'plans'} className='settings1'><i class='bx bxs-crown' style={{'color':"yellow"}} ></i>
             
              <span className={`${mode}-text`}>BRDRIVE  {t("Plans")}</span>
              </Link> */}

{/* <DashboradCard link={'plans'} icon={'Drivers'} title={t('Plans')} color={'yellow'} mode={mode}/> */}
{/* style={{display:'flex',alignItems:'center',justifyContent:'space-around',border:`1px solid gray`,borderLeft:`4px solid ${"yellow"}`,borderRadius:'2px',cursor:'pointer',textDecoration:'none',color:"gray",backgroundColor:`${mode=='dark'?"black":"white"}`,height:55}} */}

<Link to={'plans'} style={{textDecoration:"none",}} onClick={setPlan} >
  
        <div className='row'>
       
        <div className='col'>
            {clicked1?<h5 style={{fontFamily:'Roboto',textAlign:"center",color:'#df1d34'}}>{t('Plans')}</h5>:
            <h5 className={`text-${mode} settingsBar`} style={{fontFamily:'Roboto',textAlign:"center"}}>{t('Plans')}</h5>}
        </div>
        </div>
    </Link>

    
            </div>

            <div className='col-md-3 p-2 settingsMenu' style={{borderBottom:clicked2?"4px solid #df1d34":"0px"}}>
              {/* <a class="nav-link dropdown-toggle settings1" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
               <span className={`${mode}-text`}>{t("General")}</span> 
              </a> */}

              <ListCard icon={'vehicle'} title={t("General")} color={'green'} mode={mode} clicked={clicked2}/>

              <ul class={`dropdown-menu bg-${mode}`} style={{padding:10}} >
                <li ><Link to={'files'} className='settings' onClick={setGeneral}><i class='bx bxs-file'></i><span className='setting-text'>{t("Manage")} {t("Files")}</span></Link></li>
                <li ><Link to={'formats'} className='settings' onClick={setGeneral}><MdFormatColorFill style={{"fontSize":"20px","marginRight":"10px"}}/><span className='setting-text'>{t("Formats")}</span></Link></li>
                <li ><Link to={'reminders'} className='settings' onClick={setGeneral}><FcAlarmClock style={{"fontSize":"20px","marginRight":"10px"}}/><span className='setting-text'>{t("Reminders")}</span></Link></li>
                {/* <li ><Link to={'privacy policy'} className='settings' onClick={()=>setClicked2(true)}><MdPolicy style={{"fontSize":"20px","marginRight":"10px"}}/><span className='setting-text'>{t("Privacy policy")}</span></Link></li> */}
              </ul>
            </div>
            <div className='col-md-3 p-2 settingsMenu' style={{borderBottom:clicked3?"4px solid #df1d34":"0px"}}>
              {/* <a class="nav-link dropdown-toggle settings1" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span className={`${mode}-text`}>{t("Manage")} {t("Drivers")}</span>
                
              </a> */}
              <ListCard icon={'vehicle'} title={t("Drivers")} color={'blue'} mode={mode} clicked={clicked3}/>
              <ul class={`dropdown-menu bg-${mode}`} style={{padding:10}} >
                <li ><Link to={'myVehicules'} className='settings' onClick={setDrivers}><i class='bx bxs-car' ></i><span className='setting-text'>{t("Vehicules")}</span></Link></li>
                <li ><Link to={'myUsers'} className='settings' onClick={setDrivers}><i class='bx bxs-user-account' ></i><span className='setting-text'>{t("Utilisateurs")}</span></Link></li>
                <li ><Link to={'myUsersPerVehicules'} className='settings' onClick={setDrivers}><i class='bx bxs-id-card'></i><span className='setting-text'>{t("Véhicule")}/{t("Utilisateur")}</span></Link></li>
              </ul>
            </div>
            <div className='col-md-3 p-2 settingsMenu' style={{borderBottom:clicked4?"4px solid #df1d34":"0px"}}>
              {/* <a class="nav-link dropdown-toggle  settings1" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span className={`${mode}-text`}>{t("Manage")} {t("Services")}</span>
                
              </a> */}
               <ListCard icon={'vehicle'} title={t("Services")} color={'gray'} mode={mode} clicked={clicked4}/>
              <ul class={`dropdown-menu bg-${mode}`} style={{padding:10}}>
                <li><Link to={'fuels'} className='settings' onClick={setServices}><i class='bx bxs-gas-pump' ></i><span className='setting-text'>{t("Fuel")}</span></Link></li>
                <li><Link to={'stations'} className='settings' onClick={setServices}><i class='bx bxs-map'></i><span className='setting-text'>{t("Stations")}</span></Link></li>
                <li><Link to={'services'} className='settings' onClick={setServices}><i class='bx bxs-wrench' ></i><span className='setting-text'>{t("Type")} {t("D'entretiens")}</span></Link></li>
                <li ><Link to={'places'} className='settings' onClick={setServices}><i class='bx bxs-ev-station'></i><span className='setting-text'>{t("Lieu")}</span></Link></li>
                <li ><Link to={'expenses'} className='settings' onClick={setServices}><i class='bx bxs-wallet'></i><span className='setting-text'>{t("Type")} {t("De dépense")}</span></Link></li>
                <li ><Link to={'incomes'} className='settings' onClick={setServices}><i class='bx bxl-pocket' ></i><span className='setting-text'>{t("Type")} {t("De revenus")}</span></Link></li>
                <li ><Link to={'reasons'} className='settings' onClick={setServices}><i class='bx bxs-briefcase' ></i><span className='setting-text'>{t("Raisons")}</span></Link></li>
              </ul>
            </div>
          {/* </ul> */}
        </div>

    
     
        <div className={`content-${mode}`}>


          <div className='row' style={{width:"90%"}}>
            <div className='col' id='settings_outlet'>
              <Outlet />
            </div>

          </div>

        </div>

      
    </div>
    </section>
  )
}
