import React, { useEffect,useState } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { UPDATE_PROFILE } from '../../../../actions/types'
import { Modal2 } from '../../Modals/Modal2'
import { useTranslation } from 'react-i18next'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from 'react-icons'


export const StationsChoices = ({mode}) => {
  const{t}=useTranslation()
    const [stations,setStations]=useState([])
    const [station,setStation]=useState('')
    const [showModal, setShowModal] = useState("none")
    const dispatch=useDispatch()
    const current_profile = useSelector((state) => state.auth.profile)
    useEffect(() => {

        setStations(JSON.parse(current_profile.gasStation))


    }, [])


      ////////////////////////////:handle click outside model
////////////////// chooseType is the id of the input to
/////////////////////// avoid hidding the modal when typing in the input

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const updateStation=async(e)=>{
        e.preventDefault()
        stations.push(station)  
       
        // const data=JSON.stringify(fuel_types)
        const data={
            'gasStation':JSON.stringify(stations)
        }
        handleCloseModal()
        dispatch(updateProfile(data))
        
  }

  const updateStation2=async(station)=>{

    const new_stations = stations.filter((type) => type !== station)
    setStations(new_stations)
   
    // const data=JSON.stringify(fuel_types)
    const data={
        'gasStation':JSON.stringify(new_stations)
    }
    console.log('this is',data)
    
    dispatch(updateProfile(data))
    
}

  
  const updateProfile=(data)=>{

    return function(dispatch){
  fetch(current_profile.url, {
    headers: {
        'content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    },
      method: 'PATCH',
      body:  JSON.stringify(data),
    
  },

  ).then(resp=>resp.json()).then(profile=>{if(profile.hasOwnProperty('avatar')){
            dispatch({
                type:UPDATE_PROFILE,
                payload:profile
            })
  }else{
    alert('something went wrong pleaze try again later')
  }})
}
  }

 const setContent_station=()=>{
    return(
      <form onSubmit={updateStation}>
      <input type='text' placeholder='name' className='form-control mb-2' onChange={(e) => { setStation(e.target.value) }} value={station} />
      <button type='submit' className='btnOutline form-control'>{t("Submit")}</button>
    </form>
    )
  }




////////////////////////////////end 

  

  return (
    
    <div className='typesChoices'>
    <div className='typesChoices-content'>

      <h3 className={`strike-${mode}`}> {t("Station")}</h3>

      <button className="btnOutline" onClick={() => handleOpenModal()}>{t("Add")} {t("Station")}</button>


      <Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setContent_station}/>

    </div>

    <ul class={`list-group mt-5 choices-list-${mode}`}  style={{}}>
      {stations.map((type, index) => <li key={index} class={`list-group-item choices-list-${mode}`} style={{ "display": "flex", "justifyContent": "space-between", "alignItems": "center","width":"100%" }}>{type}<span onClick={() => updateStation2(type)} style={{ "cursor": "pointer" }}>
      <IconContext.Provider value={{ style: { fontSize: "20px",color:"red" }, className: "global-class-name" }}>
                    <div>
                     <MdDeleteForever />
                    </div>
                  </IconContext.Provider>
        </span></li>)}

    </ul>

  </div>
  )
}
