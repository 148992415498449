import React,{useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'

import { loadUser } from '../../actions/auth'
import { First } from './First'
import { EmailConfirm } from '../common/EmailConfirm'



export const MainApp = ({access_token,languages}) => {
    const is_confirmed = useSelector((state) => state.auth.profile.is_email_confirmed)

    const dispatch=useDispatch()
    useEffect(()=>{
     dispatch(loadUser(access_token))
     },[])
  return (
<div>
{is_confirmed ?<First languages={languages}/> : <EmailConfirm/>}
   </div>
     

)}
