import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CREATE_MESSAGES, GET_ERRORS } from '../../../../actions/types'
import './AddReminder.css'

export const AddReminder = ({ ip_address, currentLanguage }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [reminder_choice, setReminder_choice] = useState('')
  const [reminder_type, setReminder_type] = useState('')
  // const [vehicule,setVehicule]=useState('')
  const [odometre, setOdometre] = useState('')
  const [note, setNote] = useState('')
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [bydistance, setBydistance] = useState(false)
  const [bydate, setBydate] = useState(false)
  const [every, setEvery] = useState(false)
  const [service_clicked, setService_clicked] = useState("not_clicked")
  const [expense_clicked, setExpense_clicked] = useState("not_clicked")
  const [file, setFile] = useState(null)
  const [timer, setTimer] = useState('')
  const [odometre_rappel,setOdometre_rappel]=useState(0)
  const [time_space,setTime_space]=useState(0)
  const [label_date,setLabel_date]=useState('Reminder date')
  const [label_odometer,setLabel_odometer]=useState('Odometer')
  const [distance,setDistance]=useState(0)
  const current_time=Date.now()/1000
  const [time_amount,setTime_amount]=useState(0)
  const user = useSelector(state => state.auth.user.url)
  // const vehicules=useSelector(state=>state.vehicules.items)
  const current_vehicule = useSelector(state => state.vehicules.item)
  const mode = useSelector(state => state.auth.mode)
  const current_odometre = useSelector(state => state.vehicules.item.odometre)
  const reminder_types = JSON.parse(useSelector(state => state.auth.profile.services))
  const expense_types = JSON.parse(useSelector(state => state.auth.profile.expense))
  const token = useSelector(state => state.auth.token)
  const navigate = useNavigate()
  const [choix, setChoix] = useState([])
  const periods=['day','month','year']
  const correspondings={'day':86400,'month':2629800 ,'year':31556952}
 

  useEffect(() => {
    switch (reminder_choice) {
      case 'Service':
        // setReminder_type(reminder_types[0])
        setShow(true)
        reminder_types.unshift('-- Select option --')
        setChoix(reminder_types)
        break
      case 'Expense':
        // setReminder_type(expense_types[0])
        setShow(true)
        expense_types.unshift('-- Select option --')
        setChoix(expense_types)
        break
      default:
        setReminder_type('')
        setShow(false)
        break
    }
    setOdometre('')
    setFile(null)
    setNote('')

  }, [reminder_choice, current_vehicule])

  const chooseForm1 = () => {
    setReminder_choice('Service')
    setShow1(false)
    setService_clicked("clicked")
    setExpense_clicked("not_clicked")
  }

  const chooseForm2 = () => {
    setReminder_choice('Expense')
    setShow1(false)
    setService_clicked("not_clicked")
    setExpense_clicked("clicked")
  }

// ToggleDate

const toggleDate=()=>{
  switch(label_date){
    case 'Reminder date':
      setLabel_date('Reminder start date')
      setLabel_odometer('Start odometre')
      setEvery(true)
      break
    case 'Reminder start date':
      setLabel_date('Reminder date')
      setLabel_odometer('Odometer')
      setEvery(false)
      break
  }
}

  const ajouterRappel = async (data) => {

    if(timer){if(Date.parse(new Date(timer))/1000 >= current_time)

    {const requestPost = await fetch(`${ip_address}/app/api/rappels/`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: data,

    },

    )
    const resp = await requestPost.json()
    console.log(requestPost)
    if (requestPost.status === 201) {
      navigate('/reminder')
      dispatch({
        type: CREATE_MESSAGES,
        payload: `${t("Service")} ${t("created success")}`,
      })
      return resp
    } else {
      dispatch({
        type: GET_ERRORS,
        payload: {
          msg: resp.message,
          status: requestPost.status
        }
      })
    }}else{
      dispatch({
          type:GET_ERRORS,
          payload:{
              msg:t("Past Reminders are not allowed"),
              status:400
          }
      })
  }
}else{
  const requestPost = await fetch(`${ip_address}/app/api/rappels/`, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
    },
    body: data,

  },

  )
  const resp = await requestPost.json()
  console.log(requestPost)
  if (requestPost.status === 201) {
    navigate('/reminder')
    dispatch({
      type: CREATE_MESSAGES,
      payload: `${t("Service")} ${t("created success")}`,
    })
    return resp
  } else {
    dispatch({
      type: GET_ERRORS,
      payload: {
        msg: resp.message,
        status: requestPost.status
      }
    })
  }
}
  }

  const onSubmit = async (e) => {
    e.preventDefault()
   if(reminder_type !== "-- Select option --" ){ const formdata = new FormData()
    formdata.append('rappel', reminder_choice)
    formdata.append('type', reminder_type)
    // formdata.append('odometre', odometre)
    if(bydistance && odometre_rappel)
    {formdata.append('odometre_rappel', odometre_rappel)}
    formdata.append('writter', user)
    if (file) {
      formdata.append('attached_file', file)
    }
    if (note) {
      formdata.append('note', note)
    }
    if (every) {
      formdata.append('repeat_every',true)
      if(bydistance){
      formdata.append('distance', distance)
    }
    if(bydate){
      formdata.append('time_amount',`${time_space}X${time_amount}` )
    }
  }
    if (timer && bydate) {
      formdata.append('rappel_date', timer)
    }
    formdata.append('vehicule', current_vehicule.url)
    ajouterRappel(formdata)
    // setReminder_choice('')
    // setReminder_type('')
    // setOdometre('')
    // setFile(null)
    // setNote('')
  }else{

    }
    // setVehicule('')
  }


  // toggle checkBox

  const togglecheckbox1=(e)=>{
    if (e.target.checked){
  
        setBydate(true)
     
    }else if(!e.target.checked){
      setBydate(false)
    }
  }
  const togglecheckbox2=(e)=>{
    if (e.target.checked){
  
        setBydistance(true)
     
    }else if(!e.target.checked){
      setBydistance(false)
    }
  }


  return (

    <section id='reminderForm' className={`all_sections ${currentLanguage.dir || 'ltr'} mt-4`}>

      <div className={`content-${mode} whitewhen${mode}`}>
        <div className='row' style={{ "width": "100%" }}>
          <div className='col'>
            <div className='title'>
            <img src={`https://web.bfdrive.ma/media/icons/stopwatch.svg`} style={{"width":"30px","height":"100%"}}/> |
              <span className='text' style={{ fontFamily: "Airstrike" }}>{t("Reminder")}</span>
            </div>
            <form id='reminderForm' onSubmit={onSubmit}>
              <div className='reminderChoices'>
                <div className='row'>
                  <div className='col-lg-6 col-md-12'>
                    <div className='choices m-1' onClick={() => chooseForm1()} ><span className={`reminderChoice ${service_clicked}`} style={{ color: (service_clicked == 'clicked' && mode=='light') || mode=='dark' ? "rgba(255,255,255,.55)" : "rgba(0,0,0,.55)" }}>{t("Service")}</span></div>
                  </div>
                  <div className='col-lg-6 col-md-12'>
                    <div className='choices m-1' onClick={() => chooseForm2()} ><span className={`reminderChoice ${expense_clicked}`} style={{ color: (expense_clicked == 'clicked' && mode=='light')|| mode=='dark' ? "rgba(255,255,255,.55)" : "rgba(0,0,0,.55)"}}>{t("Expense")}</span></div>
                  </div>



                </div>
              </div>
              {show && <div className='formContent'>
                <div className='row mb-3'>
                  <div className='col-12'>
                    <label for="selectService" class="form-label">{t("Select")} {t(reminder_choice)}</label>
                    <select class={`form-select ${mode}`} id='selectService' onChange={(e) => {
                      
                      if(e.target.value !== "-- Select option --" ){setReminder_type(e.target.value);setShow1(true)}else{setReminder_type(e.target.value);setShow1(false)}
                    }} value={reminder_type} required>
                    
                      {choix.map((type, index) => (<option key={index} value={type}>{t(type)}</option>))}
                     
                    </select>
                  </div>
                </div>
              </div>}
              {show1 && (<div className='formContent'>
                {/* choose between just one time or Repeat every */}
                <div className='row mb-3'>
                  <div class="col-lg-6">
                    <div class={`form-control ${mode}`} style={{backgroundColor:every?'transparent':'#00587d',borderLeftColor:every?'#00587d':'transparent',color: (!every && mode=='light') || mode=='dark' ? "rgba(255,255,255,.55)" : "rgba(0,0,0,.55)" }} onClick={()=>toggleDate()}>{t("Just one time")}</div>
                  </div>
                  <div className='col-lg-6'>

                  <div class={`form-control ${mode}`} style={{backgroundColor:every?'#00587d':'transparent',borderLeftColor:every?'transparent':'#00587d',color: (every && mode=='light') || mode=='dark' ? "rgba(255,255,255,.55)" : "rgba(0,0,0,.55)"}} onClick={()=>toggleDate()}>{t("Repete every")}</div>
                  </div>
                 
                </div>
                 {/* distnace entry */}
                <div className='row mb-3'>
                <div class="col-lg-4">
                <label for='by_odo' class="form-label">{t("Reminder by odometer")}</label>
                <div class={`form-control ${mode}`} id='by_odo'>
                    <input class={`form-check-input `} type="checkbox" style={{border:"solid #00587d 2px"}} id="gridCheck" onChange={(e)=>togglecheckbox2(e)}/>
                    <label class="form-check-label" for="gridCheck">
                    {t("distance")} Km
                    </label>
                    </div>
                  </div>
                  {bydistance && <><div className='col-lg-4'>
                  <label for='odometre_rappel' class="form-label">{t(label_odometer)}</label>
                  <input class={`form-control ${mode}`} id='odometre_rappel' type="text"  onChange={(e)=>setOdometre_rappel(e.target.value)} value={odometre_rappel} placeholder={t('Odometre')} required/>
                  <div id="emailHelp" class="form-text">{t(`Last odometre`)}{current_odometre}</div>
                    {/* <input type="number" class={`form-control ${mode}`} id="odometre" placeholder="odometre" onChange={(e) => setOdometre(e.target.value)} value={odometre} required />
                    <div id="emailHelp" class="form-text">{`last odometre ${current_odometre}`}</div> */}
                  </div>
                  {every && <div className='col-lg-4'>
                  <label for='repeat_each' class="form-label">{t("Repeat each")}</label>
                    <input class={`form-control ${mode}`} id='repeat_each' type="text"  onChange={(e)=>setDistance(e.target.value)} value={distance} placeholder={t('Distance')} required/>
                    <div id="emailHelp" class="form-text">{t("Distance")}</div>
                    </div>}</>}
                </div>
              {/* time entry */}
                <div className='row mb-3'>
                <div class="col-lg-4">
                <label for='by_date' class="form-label">{t("Reminder by date")}</label>
                <div class={`form-control ${mode}`} id='by_date'>
                    <input class={`form-check-input `} type="checkbox" style={{border:"solid #00587d 2px"}} id="gridCheck1" onChange={(e)=>togglecheckbox1(e)}/>
                    <label class="form-check-label" for="gridCheck1">
                    {t("Date")}
                    </label>
                  </div>
                  </div>

                  {bydate && <><div class="col-lg-4">
                  <label for='start' class="form-label">{t(label_date)}</label>
                    <input type="datetime-local" class={`form-control ${mode}`} id="start" onChange={(e) => setTimer(e.target.value)} value={timer}/>
                    
                  </div>{every && <div className='col-lg-4'>
                  <label for='repeat_each' class="form-label">{t("Repeat each")}</label>
                  <input class={`form-control ${mode}`} id='repeat_each' type="number"  onChange={(e)=>setTime_space(e.target.value)} value={time_space} placeholder={t('Interval')} required/>

                  
                    </div>}</>}

                </div>
                {bydate && <>{
                  every&&  <div className='row mb-3'>
                  <div class="col-lg-4">
                  <label for='period' class="form-label">{t("Periods")}</label>
                  <select class={`form-select ${mode}`} id='period' onChange={(e)=>setTime_amount(e.target.value)} value={time_amount} placeholder={t('period')} required>

{Object.keys(correspondings).map((period, index) => (<option key={index} value={period}>{t(period)}</option>))}
</select>
                  </div>
                  </div>
                }</>}
              
                <div className='row mb-3'>
                  <div class="col mb-3" >
                    <label for="textarea1" class="form-label">{t("Add")} {t("Text")}</label>
                    <textarea class={`form-control ${mode}`} id="textarea1" rows="1" placeholder={t('Add remark')} onChange={(e) => setNote(e.target.value)} value={note}></textarea>
                  </div>
                </div>


                <div className='col-12 mb-3'>
                  <button className='btn  form-control' style={{ color: "white",backgroundColor:"#00587d" }} type='submit'>{t("Add")} {t("Reminder")}</button>
                </div></div>)

              }






            </form>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg'>




          </div>
        </div>
      </div>


    </section>



  )
}
