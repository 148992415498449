import React,{useEffect} from 'react'
import { useOutletContext,useNavigate } from 'react-router-dom'
import { useSelector} from 'react-redux'
import {FaCloudDownloadAlt} from 'react-icons/fa' 
import { IconContext } from 'react-icons'
import { useTranslation } from 'react-i18next'

export const RapportExpense = ({digit_format,ip_address}) => {
  const {t}=useTranslation()
  const[route,expense,income,refuling,service,filter_choice,laDistance,intervalle]=useOutletContext()
  const profile=useSelector(state=>state.auth.profile)
  const current_user=useSelector(state=>state.auth.user.id)
  const navigate=useNavigate()
  const currency=profile.currency_format
  const odometre_type=useSelector(state=>state.vehicules.item.odometre_type)

//   useEffect(()=>{
 
// },[intervalle])

  const getRepportDetailed=async(data)=>{
    const request=await fetch(`${ip_address}/generate_rapport_detail`,{
        method:'POST',
        body:data
    })
            switch(request.status){
                case 200:
                  const response=await request.json()
                    alert(response.message)
                    navigate('/settings/files')
                    break
                default:
                    break
            }
          
 
}

const generateRapportDetailed=async()=>{
    const formdata=new FormData()
    formdata.append('user_id',current_user)
    formdata.append('Total',expense.total)
    formdata.append('Byday',digit_format(expense.total/intervalle))
    formdata.append('ByKilometre',digit_format(expense.total/laDistance?expense.total/laDistance:0))
    formdata.append('alias','Expense')

    getRepportDetailed(formdata)

  }


return (
  <div>
    
      <div className='row mb-3'>
        <div className='col-md-10'>
      <h3 style={{fontFamily:'Roboto'}}>{t("Cost")}</h3>
      </div>
      <div className='col-md-2'>
      <div onClick={()=>generateRapportDetailed()}>
      <IconContext.Provider value={{ style: { fontSize: "35px",marginLeft:10,cursor:'pointer'},color:'#ff5d70', className: "global-class-name" }}>
                   
                   <FaCloudDownloadAlt />
            
             </IconContext.Provider>
             </div>
             </div>
      </div>
      <div className='row'>
      <div className='col-md-4'>
      <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>
                 
                  <h5 style={{fontFamily:'Roboto'}}>{t("Total")}</h5>
                  <h5 style={{fontSize:15}}>{expense.total} {currency}</h5>
              </div>
      </div>
      <div className='col-md-4'>
      <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>
        
                  <h5 style={{fontFamily:'Roboto'}}>{t("Per Day")}</h5>
                  <h5 style={{fontSize:15}}>{digit_format((expense.total/intervalle)*86400)} {currency}</h5>
              </div>
      </div>
      <div className='col-md-4'>
      <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>
             
                  <h5 style={{fontFamily:'Roboto'}}>{t("By")} {odometre_type}</h5>
                  <h5 style={{fontSize:15}}>{digit_format(expense.total/laDistance?expense.total/laDistance:0)} {currency}</h5>
              </div>
      </div>
      </div>
  </div>
)
}
