import React,{Fragment,useEffect} from 'react'
import { withAlert } from 'react-alert'
import { useAlert } from 'react-alert'
import { useSelector } from 'react-redux'

const Alerts = () => {
    const alert=useAlert()
    const erreur=useSelector(state=>state.errors)

    useEffect(()=>{
       if(erreur.msg){ alert.error(erreur.msg)}
    },[erreur])


  return (
    <div></div>
  )
}

export default withAlert()(Alerts)
