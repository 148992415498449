import React,{useState} from 'react'
import {FaPen} from 'react-icons/fa'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from "react-icons"
import dateFormat from "dateformat"
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Modal2 } from '../Modals/Modal2'
import { translateDate } from '../General/Formats'


export const TableRowReminder = ({index,mode,type,creation_date,odometre_rappel,url,deleteRimeder,time_format,rappel_date}) => {
    const navigate=useNavigate()
    const {t}=useTranslation()
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpenModal = () => {
        setModalOpen(true);
      };
      
      const handleCloseModal = () => {
        setModalOpen(false);
      };

      const dat=(url)=>{
        const data=url.split('/')
        return `/edit_reminder/${data[data.length -2]}`
    
    
      } 

    const setContent_delete=()=>{
        return( <div className="delete-confirmation-dialog">
        <p>{t("Are you sure you want to delete this item?")}</p>
        <div style={{display:'flex',flexDirection:"row" ,alignItems:'center',justifyContent:'space-around'}}>
          <button className='btn btn-outline-danger' onClick={()=>{deleteRimeder(url);handleCloseModal()}}>{t("YES")}</button>
          <button className='btn btn-outline-info' onClick={handleCloseModal}>{t("NO")}</button>
        </div>
  
      </div>)
      }
  return (
    <tr>
        <td scope="row">{index}</td>
        <td>{type}</td>
        <td className='removable'>{translateDate(dateFormat(rappel_date,`${time_format}  HH:MM`),t,time_format)}</td>
        
        <td className='removable'>{odometre_rappel?odometre_rappel: '-'}</td>
        <td >{rappel_date?translateDate(dateFormat(rappel_date,`${time_format}  HH:MM`),t,time_format):'-'}</td>
        <td >
       <div style={{display:'flex'}}>
       <IconContext.Provider value={{ style: { color: "red", fontSize: "20px","marginRight":"20px",cursor:"pointer" }, className: "global-class-name" }} >
                          <div onClick={handleOpenModal}>
                          <MdDeleteForever/>
                          </div>
                        </IconContext.Provider>
                        <Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setContent_delete}/>

            <IconContext.Provider value={{ style: { color: "green", fontSize: "20px",cursor:"pointer" }, className: "global-class-name" }}>
                          <div onClick={()=>navigate(dat(url))}>
                          <FaPen/>
                          </div>
                        </IconContext.Provider>
       </div>
        </td>
      </tr>
  )
}
