import React, { useEffect, useState } from 'react'
import { CustomCostSplit } from '../CustomCostSplit'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {FaCloudDownloadAlt} from 'react-icons/fa' 
import { IconContext } from 'react-icons'
import { useTranslation } from 'react-i18next'


export const RapportGeneral = ({digit_format,ip_address}) => {

    const{t}=useTranslation()
    const[route,expense,income,refuling,service,filter_choice,laDistance,intervalle]=useOutletContext()
    const [balance,setBalance]=useState(0)
    const [cost,setCost]=useState(0)
    const[revenue,setRevenue]=useState(0)
    const [route_pourcentage,setRoute_pourcentage]=useState(0)
    const [expense_pourcentage,setExpense_pourcentage]=useState(0)
    const [refuling_pourcentage,setRefuling_pourcentage]=useState(0)
    const [service_pourcentage,setService_pourcentage]=useState(0)
    const navigate=useNavigate()
    const profile=useSelector(state=>state.auth.profile)
    const current_user=useSelector(state=>state.auth.user.id)
    const currency=profile.currency_format
    const total_cost=expense.total+route+refuling.total+service.total
    const vehicule_id=useSelector(state=>state.vehicules.item.id)

    useEffect(()=>{
        const setData=()=>{
            // if (total_cost){
                console.log(route)//,expense,income,refuling,service
                setCost(total_cost)
            // }
            // if (income.total){
                setRevenue(income.total)
            // }
            // if (income.total || total_cost){
                setBalance(income.total-total_cost)
            // }

            // if (expense.total && total_cost){
                setExpense_pourcentage((expense.total/total_cost)*100)
            // }
            // if (refuling.total && total_cost){
                setRefuling_pourcentage((refuling.total/total_cost)*100)
            // }
            // if (service.total && total_cost){
                setService_pourcentage((service.total/total_cost)*100)
            // }
            // if (route && total_cost){
                setRoute_pourcentage((route/total_cost)*100)
            // } 
        }
        setData()
    },[vehicule_id,route,expense,income,refuling,service])

    const getRepport=async(data)=>{
        const request=await fetch(`${ip_address}/generate_rapport`,{
            method:'POST',
            body:data
        }) 
        switch(request.status){
            case 200:
                const response=await request.json()
                alert(response.message)
                navigate('/settings/files')
                break
            default:
                break
        }
              
     
    }

    const generateRapport=async()=>{
        const formdata=new FormData()
        formdata.append('user_id',current_user)
        formdata.append('balance',balance)
        formdata.append('income',revenue)
        formdata.append('cost',cost)
        formdata.append('refuling',digit_format(refuling_pourcentage))
        formdata.append('service',digit_format(service_pourcentage))
        formdata.append('route',digit_format(route_pourcentage))
        formdata.append('expense',digit_format(expense_pourcentage))
        getRepport(formdata)

      }



    return (
        <div>
            <div onClick={()=>generateRapport()} style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-end"}}>
            <IconContext.Provider value={{ style: { fontSize: "35px",marginLeft:10,cursor:'pointer'},color:'#ff5d70', className: "global-class-name" }}>
                   
                   <FaCloudDownloadAlt />
            
             </IconContext.Provider>
             </div>
        <div className='row' style={{
           borderBottom: "1px solid rgb(212, 212, 212)"
        }}>
            <div className='col-md-4'>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>
                    <div >
                        <h5 style={{fontFamily:'Roboto'}}>{t("Balance")}</h5>
                        </div>
                    <h5 style={{color:balance<0?'red':'#00FF48'}}>{balance} {currency}</h5>
                    <h6><span style={{fontSize:15}}>{digit_format((balance/intervalle)*86400)}</span> {t("Per Day")}</h6>
                    <h6><span style={{fontSize:15}}>{digit_format((balance/laDistance))}</span>{t("By Km")}</h6>
                </div>
            </div>
            <div className='col-md-4'>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>
                <h5 style={{fontFamily:'Roboto'}}>{t("Cost")}</h5>
                    <h5 style={{color:"red"}}>{cost?cost:0} {currency}</h5>
                    <h6><span style={{fontSize:15}}>{digit_format((cost/intervalle)*86400)}</span> {t("Per Day")}</h6>
                    <h6><span style={{fontSize:15}}>{digit_format((cost/laDistance))}</span> {t("By Km")}</h6>
                </div>
            </div>
            <div className='col-md-4'>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>
                <h5 style={{fontFamily:'Roboto'}}>{t("Income")}</h5>
                    <h5 style={{color:"#00FF48"}}>{revenue?revenue:0}  {currency}</h5>
                    <h6><span style={{fontSize:15}}>{digit_format((revenue/intervalle)*86400)}</span> {t("Per Day")}</h6>
                    <h6><span style={{fontSize:15}}>{digit_format((revenue/laDistance))}</span> {t("By Km")}</h6>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col'>
                <h4 style={{fontFamily:'AirStrike'}}>{t("Cost")} {t("Split")}</h4>
                </div>
        </div>
        <div className='row' style={{
           borderBottom: "1px solid rgb(212, 212, 212)"
        }}>
            <div className='col-md-3'>
                <CustomCostSplit icon={'refuling'} name={t('Refueling')} pourcentage={digit_format(refuling_pourcentage?refuling_pourcentage:0)} />
            </div>
            <div className='col-md-3'>
                <CustomCostSplit icon={'service'} name={t('Service')} pourcentage={digit_format(service_pourcentage?service_pourcentage:0)} />
            </div>
            <div className='col-md-3'>
                <CustomCostSplit icon={'expense'} name={t('Expense')} pourcentage={digit_format(expense_pourcentage?expense_pourcentage:0)} />
            </div>
            <div className='col-md-3'>
                <CustomCostSplit icon={'road'} name={t('Route')} pourcentage={digit_format(route_pourcentage?route_pourcentage:0)} />
            </div>
        </div>
        <div className='row'>
            <div className='col-6'>  <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <h4 style={{fontFamily:'AirStrike'}}>{t("Distance")}</h4>
                <h5 style={{fontFamily:"Roboto"}}>{t("Total")}</h5>
                <div style={{fontSize:15}}>{laDistance} Km</div>
                <div style={{fontSize:15}}>{digit_format((laDistance/intervalle)*86400)}km {t("Per Day")}</div>
            </div></div>
        </div>
        <div className='row'>
            <div className='col-6'></div>
            <div className='col-6'></div>
            <div className='col-6'></div>
            <div className='col-6'></div>
        </div>
        </div>
    )
}





