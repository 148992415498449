import React,{useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './MyVehicules.css'

import { deleteVehicule } from '../../../actions/rappelActions'
import { useTranslation } from 'react-i18next'
import { TableRow } from './TableRow'

export const MyVehicules = ({vehicules}) => {
  const {t}=useTranslation()
  const [modalOpen, setModalOpen] = useState(false);
  const mode=useSelector(state=>state.auth.mode)
    const user_url=useSelector(state=>state.auth.user.url)
    const dispatch=useDispatch()
  

    // handle are you sure model

    const handleOpenModal = () => {
      setModalOpen(true);
    };
    
    const handleCloseModal = () => {
      setModalOpen(false);
    };
    const setContent_delete=(vehicule_id)=>{
      return( <div className="delete-confirmation-dialog">
      <p>{t("Are you sure you want to delete this item?")}</p>
      <div style={{display:'flex',flexDirection:"row" ,alignItems:'center',justifyContent:'space-around'}}>
        <button className='btn btn-outline-danger' onClick={()=>{dispatch(deleteVehicule(vehicule_id));handleCloseModal()}}>{t("YES")}</button>
        <button className='btn btn-outline-info' onClick={handleCloseModal}>{t("NO")}</button>
      </div>

    </div>)
    }

   
  return (
    <section id='myvehicules' className={` graphs_${mode} p-1`}>
     
        <div className='row m-3' style={{'width':"90%"}}>
            <div className='col'>
        <table className={`table text-${mode}`}>
    <thead>
      <tr>
        <th scope="col" className='text-center'>#</th>
        <th scope="col" className='text-center'>{t("Name")}</th>
        <th scope="col" className='text-center'>{t("Brand")}</th>
        <th scope="col" className='text-center'>{t("Type")}</th>
        <th scope="col" className='text-center'>{t("Actions")}</th>
      </tr>
    </thead>
    <tbody>
     {vehicules.items.map((vehicule,index)=><TableRow index={index} owner={vehicule.owner} url={user_url} nom={vehicule.model} 
     type={vehicule.vehicule_type} mode={mode} vehicule_id={vehicule.id} brand={vehicule.vehicule_name}/> 
    )}
    </tbody>
  </table>
  </div>
  
  </div>

  </section>
  )
}
