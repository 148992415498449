import React,{useState,useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchVehicules } from '../../../../actions/rappelActions'
import { CREATE_MESSAGES, GET_ERRORS } from '../../../../actions/types'
import { useTranslation } from 'react-i18next'


export const EditRoute = ({ip_address,currentLanguage,id}) => {
    const [origin,setOrigin]=useState('')
    const [destination,setDestination]=useState('')
    const {t}=useTranslation()
    const [start_odometre,setStart_odometre]=useState(0)
    const [end_odometre,setEnd_odometre]=useState(0)
    const [note,setNote]=useState('')
    const [price,setPrice]=useState(0.0)
    const [file,setFile]=useState(null)
    const [changed_items,setChanged_items]=useState({})
    const [start_time,setStart_time]=useState('')
    const [end_time,setEnd_time]=useState('')
    const [reason,setReason]=useState('')
    const [via_autoroute,setVia_autoroute]=useState(false)
    const current_odometre=useSelector(state=>state.vehicules.item.odometre )
    const reasons = JSON.parse(useSelector(state => state.auth.profile.raison))
    const selected_vehicule_id= useSelector(state => state.vehicules.item.id)
    const current_vehicule=useSelector(state=>state.vehicules.item)
    const mode=useSelector(state=>state.auth.mode)
    const dispatch=useDispatch()
    
    useEffect(()=>{
        const fetchItem=async()=>{
         const item=await getItem()
        } 
        fetchItem()
     
     
        },[])
 
 
        const getItem=async()=>{
         const request=await fetch(`${ip_address}/app/api/routings/${id}`,{
            
             headers:{
                 "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
             },
          
            
         },)
 
         const response=request.status
         switch (response){
             case 200:
                
                 const resp=await request.json()
                 console.log(resp)
                 setOrigin(resp.origin)
                 setDestination(resp.destination)
                 setStart_odometre(resp.start_odometre)
                 setEnd_odometre(resp.end_odometre)
                 setStart_time(resp.start_date.split('.')[0])
                 setEnd_time(resp.end_date.split('.')[0])
                 setPrice(resp.prixAuto_route)
                 setNote(resp.note)
                 setVia_autoroute(resp.autoRoute)
                 setReason(resp.rasion)
                 setFile(resp.attached_file)
                 console.log(resp.attached_file)
                 break
            case 403:
                dispatch({
                    type:GET_ERRORS,
                    payload:{
                        msg:t("You are not allowed to edit this item"),
                        status:403
                    }
                })
                break
             default:
                 alert(t('item not found'))
                 break
         }
        }
 

    const EditRappel=async(data)=>{


        const requestPost=await fetch(`${ip_address}/app/api/routings/${id}/`,{
            method:'PATCH',
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            body:data,
    
        },
        
        )
        const resp= await requestPost.json()
        console.log(requestPost)
        if(requestPost.status===200){
    
            dispatch({
                type:CREATE_MESSAGES,
                payload:`${t("Route for ")} ${current_vehicule.model} ${t("edited successfully")}`,
            })
            setTimeout(()=>{
                dispatch({
                    type:CREATE_MESSAGES,
                    payload:``,
                })},
                4000
               )
            // dispatch(fetchVehicules())
            return resp
        }else{
            dispatch({
                type:GET_ERRORS,
                payload:{
                    msg:resp.message,
                    status:requestPost.status
                }
            })
        }
 
    }

    const onSubmit=async(e)=>{
        e.preventDefault()
        const formdata= new FormData()
        formdata.append('vehicule_id',selected_vehicule_id)
        Object.keys(changed_items).forEach((item) => {
            formdata.append(item,changed_items[item])
            console.log(item,changed_items[item])
          })
        // formdata.append('vehicule',current_vehicule.url)
        EditRappel(formdata)
      
     
        setChanged_items({})
        // setVehicule('')
    }

    const togglecheckbox=(e)=>{
        if (e.target.checked){
      
            setVia_autoroute(true)
            changed_items['autoRoute']=true
         
        }else if(!e.target.checked){
          setVia_autoroute(false)
          changed_items['autoRoute']=false 
        }
      }

    
  return (

    <section id='routeForm' className={`all_sections ${currentLanguage.dir || 'ltr'} mt-4`}>

    <div className={`content-${mode} whitewhen${mode}`}>
        <div className='row'>
            <div className='col m-4'>
                <div className='title'>
                <img  src={`${ip_address}/media/icons/road.svg`} style={{"width":"30px"}}/> |
                    <span className='text Airstrike'>{t("Edit")} {t("Route")}</span>
                </div>
                <form onSubmit={onSubmit}>
                    <div className='row mt-5'>
                     

                   
                        <div className='col-md-4'>
                            <label for="selectOrigin" class="form-label">{t("Origin")}</label>
                            <input type='text' class={`form-control ${mode}`} id='selectOrigin' onChange={(e) => {setOrigin(e.target.value);changed_items['origin']=e.target.value}} value={origin} />


                        </div>
                        <div className='col-md-4'>
                            <label for="selectDestination" class="form-label">{t("Destination")}</label>
                            <input type='text' class={`form-control ${mode}`} id='selectDestination' onChange={(e) => {setDestination(e.target.value);changed_items['destination']=e.target.value}} value={destination} />


                        </div>
                        <div class="col-md-4">
                            <label for="start_odometre" class="form-label">{t("Start odometer")}</label>

                            <input type="number" class={`form-control ${mode}`} id="start_odometre" placeholder={t("Startodometer")} onChange={(e) => {setStart_odometre(e.target.value);changed_items['start_odometre']=e.target.value}} value={start_odometre} />
                            
                        </div>
                       
                    </div>

                    <div className='row mt-3'>
                    <div class="col-md-4">
                            <label for="start_time" class="form-label">{t("Start date")}</label>
                            <input type="datetime-local" class={`form-control ${mode}`} id="start_time" onChange={(e) => {setStart_time(e.target.value);changed_items['start_date']=e.target.value}} value={start_time} required/>
                        </div>
             
                        <div class="col-md-4">
                            <label for="end_odometre" class="form-label">{t("End odometer")}</label>

                            <input type="number" class={`form-control ${mode}`} id="end_odometre" placeholder="end_odometre" onChange={(e) => {setEnd_odometre(e.target.value);changed_items['end_odometre']=e.target.value}} value={end_odometre} />
                            <div id="emailHelp" class="form-text">{t(`Last odometre`)}{current_odometre}</div>
                        </div>

                        
                        <div class="col-md-4">
                            <label for="end_time" class="form-label">{t("End date")}</label>
                            <input type="datetime-local" class={`form-control ${mode}`} id="end_time" onChange={(e) => {setEnd_time(e.target.value);changed_items['end_date']=e.target.value}} value={end_time} required/>
                        </div>
                    </div>

                    <div className='row mb-3'>
                    {via_autoroute&&<div className='col-md-4 mb-3'>
                            <label for="prix" class="form-label">{t("Highway price")}</label>
                            <input class={`form-control ${mode}`} id="prix" type="number" onChange={(e) => {setPrice(e.target.value);changed_items['prixAuto_route']=e.target.value}} value={price}/>
                        </div>}
                        <div class="col-md-4 dFlex">
                            
                                    <div class="form-check">
                                        <input class="form-check-input bg-dark" style={{border:"solid #5bc0de 2px"}} type="checkbox" id="gridCheck" onChange={(e)=>togglecheckbox(e)}/>
                                        <label class="form-check-label" for="gridCheck">
                                            {t("by highway")} ?
                                        </label>
                                    </div>
                                </div>

                                
                                <div className='col-md-4'>
                                    <label for="selectResaon" class="form-label">{t("Add")} {t("Reason")}</label>
                                    <select class={`form-select ${mode}`} id='selectResaon' onChange={(e) => {setReason(e.target.value);changed_items["raison"]=e.target.value}} value={reason} >
                                    
                                        {reasons.map((raison, index) => (<option key={index} value={raison}>{raison}</option>))}
                                    </select>
                                </div>
                                <div class="col-md-4 mb-3" >
                            <label for="textarea1" class="form-label">{t("Note")}</label>

                            <textarea class={`form-control ${mode}`} id="textarea1" rows="1" placeholder='add remark' onChange={(e) => {setNote(e.target.value);changed_items['note']=e.target.value}} value={note}></textarea>
                        </div>

                        <div className='col-md-4 mb-3'>
                            <label for="formFileLg" class="form-label">{t("Attach file")}</label>
                            <input class={`form-control ${mode}`} id="formFileLg" type="file" onChange={(e) => {setFile(e.target.files[0]);changed_items["attached_file"]=e.target.files[0]}} />
                        </div>




                      
                       
                          

                        <div class="col-12">
                            <button type="submit" class={`btn form-control`} style={{backgroundColor:"#525252",color:"white"}}>{t("Edit")} {t("Route")}</button>
                        </div>
                   


                    </div>




                </form>
            </div>
        </div>
        <div className='row'>
            <div className='col-lg'>




            </div>
        </div>
    </div>


</section>
   
  )
}

