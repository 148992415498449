import React,{useState} from 'react'
import { useTranslation } from 'react-i18next'
import {FaPen} from 'react-icons/fa'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from "react-icons"
import {ImArrowRight} from 'react-icons/im'
import { Modal2 } from '../../Modals/Modal2'
import { useNavigate } from 'react-router-dom'


export const TableRowRoute = ({index,mode,origin,destination,end_odometre,start_odometre,autoRoute,prixAuto_route,url,deleteRoute}) => {
    const {t}=useTranslation()
    const navigate=useNavigate()
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
      };
      
      const handleCloseModal = () => {
        setModalOpen(false);
      };

      const setContent_delete=()=>{
        return( <div className="delete-confirmation-dialog">
        <p>{t("Are you sure you want to delete this item?")}</p>
        <div style={{display:'flex',flexDirection:"row" ,alignItems:'center',justifyContent:'space-around'}}>
          <button className='btn btn-outline-danger' onClick={()=>{deleteRoute(url);handleCloseModal()}}>{t("YES")}</button>
          <button className='btn btn-outline-info' onClick={handleCloseModal}>{t("NO")}</button>
        </div>
  
      </div>)
      }
    return (
    <tr >
        <th scope="row" key={index}>{origin}<ImArrowRight/>{destination}</th>
        <td>{end_odometre - start_odometre}</td>
        <td className='removable'>{autoRoute?`par autoroute à ${prixAuto_route} DH`:'par voie normale'}</td>
        <td>
            <div style={{"display":"flex","justifyContent":"center"}}>
        <IconContext.Provider value={{ style: { color: "red", fontSize: "20px","marginRight":"20px",cursor:"pointer" }, className: "global-class-name" }} >
                          <div onClick={handleOpenModal}>
                          <MdDeleteForever/>
                          </div>
                        </IconContext.Provider>
                        <Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setContent_delete}/>
            <IconContext.Provider value={{ style: { color: "green", fontSize: "20px",cursor:"pointer" }, className: "global-class-name" }}>
                          <div onClick={()=>navigate(`/edit_route/${((url).split('/'))[((url).split('/')).length -2]}`)}>
                          <FaPen/>
                          </div>
                        </IconContext.Provider>
           </div>
             </td>
      </tr>
  )
}
