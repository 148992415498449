import React,{useEffect,useState} from 'react'
import { useSelector, useDispatch  } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateVehicule } from '../../../actions/rappelActions'
import { CREATE_MESSAGES,GET_ERRORS } from '../../../actions/types'
import { Link } from 'react-router-dom'
import cars from '../../../cars.json'
import { Modal } from '../../FirstApp/Modals/Modal'
import {TbPlayerTrackPrevFilled} from 'react-icons/tb'

export const UpdateVehicules = ({id,ip_address}) => {
    const mode = useSelector(state => state.auth.mode)
    const types = ['Moto', 'Voiture', 'Bus', 'Camion']
    const [choosen_brand, setChoosen_brand] = useState([])
    const [type, setType] = useState('Moto')
    const [model, setModel] = useState('')
    const [showModal1, setShowModal1] = useState("none")
    const [fuel_type, setFuel_type] = useState('')
    const [year, setYear] = useState('')
    const [tank_capacity, setTank_capacity] = useState()
    const [disabled_button, setDisabled_button] = useState(false)
    const [odometre_type, setOdometre_type] = useState('Km')
    const [matricule, setMatricule] = useState('')
    const [n_chassi, setN_chassi] = useState()
    const [brand, setBrand] = useState('')
    const [odometre, setOdometre] = useState()
    const dispatch=useDispatch()
    const navigate=useNavigate()
    useEffect(()=>{
        const fetchAll=async()=>{
            const data1=await fetchVehicule()

         

        }
        fetchAll()

    },[])
const fetchVehicule=async()=>{
    const requette=await fetch(`${ip_address}/app/api/vehicules/${id}`,{
           
        headers:{
            "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
        },
     
       
    },)

    const stat=requette.status

    switch(stat){
        case 200:
            setDisabled_button(false)
            const vehicule=await requette.json()
            setBrand(vehicule.vehicule_name)
            setType(vehicule.vehicule_type)
            setFuel_type(vehicule.fuel_type)
            setOdometre(vehicule.odometre)
            setModel(vehicule.model)
            setYear(vehicule.year)
            setTank_capacity(vehicule.tank_capacity)
            setMatricule(vehicule.matricule)
            setN_chassi(vehicule.n_chassi)
            break
        case 403:
            setDisabled_button(true)
            const response=await requette.json()
            dispatch({
                type:GET_ERRORS,
                payload:{
                    msg:response.detail,
                    status:stat
                }
            })
            break
        default:
            alert('Something went wrong')
            break
    }
    
    
    
}

const onSubmit = async (e) => {
    e.preventDefault()
    const formdata = new FormData()
    if(brand){
        formdata.append("vehicule_name", brand)
    }
    if(type){
        formdata.append("vehicule_type", type)
    }
    if (odometre){
        formdata.append("odometre", odometre)
    }
    
    if(model){
        formdata.append("model", model)
    }
    if(fuel_type){
        formdata.append("fuel_type", fuel_type)
    }
    if(year){
        formdata.append("year", year)
    }
    if(tank_capacity){
        formdata.append("tank_capacity", tank_capacity)
    }
    if(odometre_type){
        formdata.append("odometre_type", odometre_type)
    }
    if(matricule){
        formdata.append("matricule", matricule)
    }
    if(n_chassi){
        formdata.append("n_chassi", n_chassi)
    }
        
        // formdata.append("driver",driver)
    
        dispatch(updateVehicule(id,formdata))
        navigate('/settings/myVehicules')
        
        
      }


      const toggleShow = () => {
        if (showModal1 === "none") {
          setShowModal1("block")
        } else {
          setShowModal1("none")
        }
      }



      const setContent_brands=()=>{
        return(<div class={`${mode}`} id="brandDataList" placeholder="vehicule brand" style={{ "height": "400px", "overflowY": "scroll" }}>
        <ul>
            {Object.keys(cars).map((car, index) => (<li key={index} ><div onClick={() => { setChoosen_brand(`${ip_address}${cars[car]}`);setBrand(car); toggleShow() }} style={{cursor:"pointer"}} ><img src={`${ip_address}${cars[car]}`} style={{ "width": "50px" }} />{car}</div></li>))}
        </ul>
    </div>)
      }
  return (
    <div  className={`content-${mode} `}>
    <form onSubmit={onSubmit} id='vehicleForm'>
  

            <div className='row p-2'>
                <div className='col-md-4'>
                    {/* <label for="brandDataList" class={`form-label ${mode}`}>vehicule brand</label> */}
                    {/* <input class={`form-control bg-${mode}`} list="datalistOptions1" id="brandDataList" placeholder="vehicule brand" onChange={(e)=>setBrand(e.target.value)} value={brand}/> */}
                  
                  <div className='form-control btn btn-lg btn-danger' onClick={() => toggleShow()}>{choosen_brand && <img src={choosen_brand}  style={{ "width": "50px" }} />}{brand ? brand : 'choose brand'}</div>
                  <Modal modal={'cars'}  mode={mode} showModal={showModal1}  setContent={setContent_brands}/>
                </div>
                <div className='col-md-4'>
                    {/* <label for="typeDataList1" class={`form-label ${mode}`}>vehicule type</label> */}
                    {/* <input class={`form-control ${mode}`} list="datalistOptions2" id="typeDataList" placeholder="vehicule type" onChange={(e)=>setType(e.target.value)} value={type}/> */}
                    <select class={`form-control ${mode}`} id="typeDataList1" onChange={(e) => setType(e.target.value)} value={type}>
                        {types.map((typer, index) => (<option key={index} value={typer}>{typer}</option>))}
                    </select>
                </div>
                <div className='col-md-4'>
                    {/* <label for="newVehiculesOdometre" class={`form-label ${mode}`}>enter Odometre</label> */}
                    {/* <input class={`form-control ${mode}`} list="datalistOptions2" id="typeDataList" placeholder="vehicule type" onChange={(e)=>setType(e.target.value)} value={type}/> */}
                    <input class={`form-control ${mode}`}id='newVehiculesOdometre' onChange={(e) => setOdometre(e.target.value)} value={odometre} placeholder='odometre' />
                </div> 
                <div className='col-md-4'>
                    {/* <label for="fuel_type" class={`form-label ${mode}`}>fuel</label> */}
                    <input  type="text" readonly className={`form-control ${mode}`}id="fuel_type" onChange={(e)=>setFuel_type(e.target.value)} value={fuel_type} placeholder='fuel type'/>
                </div>
                <div className='col-md-4'>
                    {/* <label for="model" class={`form-label ${mode}`}>model</label> */}
                    <input type="text" readonly className={`form-control ${mode}`}id="model" onChange={(e)=>setModel(e.target.value)} value={model} placeholder='model'/>
                </div>
                <div className='col-md-4'>
                    {/* <label for="year" class={`form-label ${mode}`}>year</label> */}
                    <input type="text" readonly className={`form-control ${mode}`}id="year" onChange={(e)=>setYear(e.target.value)} value={year} placeholder='year'/>
                </div>
                <div className='col-md-4'>
                {/* <label for="tank_capacity" class={`form-label ${mode}`}>tank_capacity</label> */}
                    <input type="number" readonly className={`form-control ${mode}`}id="tank_capacity" onChange={(e)=>setTank_capacity(e.target.value)} value={tank_capacity} placeholder='tank capacity'/>
                </div>
                <div className='col-md-4'>
                        <select class={`form-control ${mode}`} id="odometre_type" onChange={(e) => setOdometre_type(e.target.value)} value={odometre_type} placeholder='odometre type'>
                        <option key={0} value='KM' >KM</option>
                        <option key={1} value='MILE'>MILE</option>
                    </select>
                </div>
                <div className='col-md-4'>
                {/* <label for="matricule" class={`form-label ${mode}`}>matricule</label> */}
                    <input type="text" readonly className={`form-control ${mode}`}id="matricule" onChange={(e)=>setMatricule(e.target.value)} value={matricule} placeholder='matricule'/>
                </div>
                <div className='col-md-4'>
                {/* <label for="n_chassi" class={`form-label ${mode}`}>n_chassi</label> */}
                    <input type="number" readonly className={`form-control ${mode}`}id="n_chassi" onChange={(e)=>setN_chassi(e.target.value)} value={n_chassi} placeholder='N de chassi'/>
                </div>
            </div>
            <button type="submit" class="btn form-control " style={{ "backgroundColor": "#d10019", "color": "white" }} disabled={disabled_button}>Submit</button>
        </form>
        <Link to={'/settings/myVehicules'} style={{textDecoration:'none'}}> <TbPlayerTrackPrevFilled/> <span >go back</span></Link>
        </div>
      


  )
}
