import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {FaPen,FaTruck,FaCarSide,FaBus} from 'react-icons/fa'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from "react-icons"
import{RiMotorbikeFill} from "react-icons/ri"
import { deleteVehicule } from '../../../../actions/rappelActions'

export const DashboardVehicles = ({vehicules}) => {



  const navigate=useNavigate()
  const mode=useSelector(state=>state.auth.mode)
    const user_url=useSelector(state=>state.auth.user.url)
    const dispatch=useDispatch()

  const setType=(param)=>{
    switch(param){
        case 'Moto':
            return <RiMotorbikeFill/>
            
        case 'Camion':
            return <FaTruck/>
            
        case 'Voiture':
            return <FaCarSide/>
            
        case 'Bus':
            return <FaBus/>
            
        default:
            return param
    }
}
  return (
    <div>
      <h5 style={{margin:'auto',fontFamily:'AirStrike'}}>manage vehicules</h5>
       <table className={`table text-${mode}`}>
        
    <thead>
      <tr>
        <th scope="col" className='text-center'>#</th>
        <th scope="col" className='text-center'>Name</th>
        <th scope="col" className='text-center'>Type</th>
        <th scope="col" className='text-center'>Actions</th>
      </tr>
    </thead>
    <tbody>
     {vehicules.items.map((vehicule,index)=> <tr className={vehicule.owner===user_url?'text-center':'text-center table-warning'}>
        <th scope="row" key={index}>{index+1}</th>
        <td>{vehicule.vehicule_name}</td>
        <td>{setType(vehicule.vehicule_type)}</td>
        <td>
            <div style={{"display":"flex","justifyContent":"center"}}>
        <IconContext.Provider value={{ style: { color: "red", fontSize: "20px","marginRight":"20px",cursor:"pointer" }, className: "global-class-name" }} >
                          <div onClick={()=>dispatch(deleteVehicule(vehicule.id))}>
                          <MdDeleteForever/>
                          </div>
                        </IconContext.Provider>
             
            <IconContext.Provider value={{ style: { color: "green", fontSize: "20px",cursor:"pointer" }, className: "global-class-name" }}>
                          <div onClick={()=>navigate(`/paramètres/update_vehicule/${vehicule.id}`)}>
                          <FaPen/>
                          </div>
                        </IconContext.Provider>
           </div>
             </td>
      </tr>)}
    </tbody>
  </table>
    </div>
  )
}
