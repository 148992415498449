
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export const DashboardCard2 = ({titre,donnee1,donnee2,donnee3}) => {
  const {t}=useTranslation()
  const mode=useSelector(state=>state.auth.mode)
    const currency=useSelector(state=>state.auth.profile.currency_format)
    const cardStyle_dark = {
      backgroundColor: '#d10019',
      borderRadius: '10px',
      // padding: '5px',
      margin:5,
      border:'solid 1px #d10019',
      color: 'gray',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      // width: '200px',
      textAlign: 'center',
    };
    const cardStyle_light = {
      backgroundColor: '#d10019',
      borderRadius: '10px',
      // padding: '5px',
      margin:5,
      border:'solid 1px #d10019',
      color: 'white',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      // width: '200px',
      textAlign: 'center',
    };

    const cardContent_dark={padding:5,
      display:'flex',
      flexDirection:"column",
      justifyContent:"flex-start",
      alignItems:"flex-start",
      borderRadius:'0px 0px 10px 10px',
      backgroundColor:'black',
      color:'#ff5d70'}
    const cardContent_light={padding:5,
      display:'flex',
      flexDirection:"column",
      justifyContent:"flex-start",
      borderRadius:'0px 0px 10px 10px',
      alignItems:"flex-start",
      backgroundColor:'white',
      color:'#d10019'}
  return (
//     <div style={{width:"100%"}}>
//        <div className="card text-white bg-info mb-3" style={{width:"80%",margin:"auto"}} >
//   <div className="card-header">{titre}</div>
//   <div className="card-body bg-warning">
//     <p className="card-title">{donnee1} {currency}</p>
//     <p>{donnee2}/day</p>
//     <p>{donnee3}/Km</p>
//   </div>
// </div>
//     </div>

<div style={mode==='dark'?cardStyle_dark:cardStyle_light}>
<h2 style={{fontFamily:'Airstrike',fontSize:'20px',marginBottom:0}}>{titre}</h2>
<div style={mode==='light'?cardContent_light:cardContent_dark}>
<div><span style={{fontWeight:'bolder',marginInline:10}}>{t('Value')}   :</span><span style={{fontWeight:10,fontWeight:'bold',fontFamily:"Roboto"}}>{donnee1}</span></div>
<div><span style={{fontWeight:'bolder',marginInline:10}}>{t('Per Day')} :</span><span style={{fontWeight:10,fontWeight:'bold',fontFamily:"Roboto"}}>{donnee2}</span></div>
<div><span style={{fontWeight:'bolder',marginInline:10}}>{t("By Km")}   :</span><span style={{fontWeight:10,fontWeight:'bold',fontFamily:"Roboto"}}>{donnee3}</span></div>
</div>

</div>
  )
}
