import { useSelector } from 'react-redux'
import { useState } from 'react'
import { BsFillFuelPumpFill, BsCalendarDate } from "react-icons/bs"
import { ImLocation } from "react-icons/im"
import { TfiDashboard } from "react-icons/tfi"
import { MdOutlinePayments } from "react-icons/md"
import { GiAutoRepair } from "react-icons/gi"
import {FaUserEdit, FaUserTie} from "react-icons/fa"
import { IconContext } from "react-icons"
import {GoArrowRight} from "react-icons/go"
import { GiCheckeredFlag } from "react-icons/gi";
import { GiFinishLine } from "react-icons/gi";
// import {MdDeleteForever} from 'react-icons/md'
import { MdPayment } from "react-icons/md";
import { useTranslation } from 'react-i18next'
import {BiPencil} from 'react-icons/bi'
import { Modal2 } from '../Modals/Modal2'
import { BsFillAlarmFill } from "react-icons/bs"
import './Testing.css'
import { useNavigate } from 'react-router-dom'
import { Details } from './Details'
import { FaRoute } from "react-icons/fa";
import { getLastTwoWords } from '../History/History'
import { FaDollarSign } from "react-icons/fa";
import info from './info.png'
import { translateDate } from '../General/Formats'


export const Testing = ({index,item,owner,icon,kind,type,payment_method,reason,real_time,odometre,place,station,mode,ip_address,deleteService,url,currentLanguage,attached_file,note,value,Last_updated,writter,origin,destination,prixAuto_route,start_odometre,start_date}) => {

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);
  const time_format = useSelector(state => state.auth.profile.time_format)

  const handleOpenModal1 = () => {
    setModalOpen1(true);
  };
  
  const handleCloseModal1 = () => {
    setModalOpen1(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const{t}=useTranslation()
  const navigate=useNavigate()
//   const deleteService=async(url,service_items)=>{
   
 
    
//     const action=await fetch(url,{
//         headers:{
//             "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
//         },
//         method:'DELETE',
//      })
//      const status=action.status

//      switch(status){
//         case 204:
          
//           const new_items=service_items.filter(item=>Object.values(item)[0].url!==url)
//            setSorted_items(new_items)
//             break
//         case 403:
//             alert('this is not your item')
//             break
     
//  }  
// }
    
    const proprietaire=useSelector(state=>state.vehicules.item.owner)

    const redirect_url=()=>{
      const data=url.split('/')
      // console.log(data[data.length -2])
      switch (kind){
        case 'expense':
          return `/edit_expense/${data[data.length -2]}`
        case 'income':
          return `/edit_income/${data[data.length -2]}`
        case 'refuling':
          return `/edit_refuling/${data[data.length -2]}`
        case 'route':
          return `/edit_route/${data[data.length -2]}`
        case 'service':
          return `/edit_service/${data[data.length -2]}`
        case 'reminder':
          return `/edit_reminder/${data[data.length -2]}`
      }

    }


    const setContent_delete=()=>{
      return( <div className="delete-confirmation-dialog">
      <p>{t("Are you sure you want to delete this item?")}</p>
      <div style={{display:'flex',flexDirection:"row" ,alignItems:'center',justifyContent:'space-around'}}>
        <button className='btn btn-outline-danger' onClick={()=>{deleteService(url,item);handleCloseModal()}}>{t("YES")}</button>
        <button className='btn btn-outline-info' onClick={handleCloseModal}>{t("NO")}</button>
      </div>

    </div>)
    }

    const setContent_read=()=>{
      return( <div className="delete-confirmation-dialog">
      
      <Details mode={mode} Last_updated={translateDate(Last_updated,t,time_format)} place={place} reason={reason} payment_method={payment_method} value={value} owner={owner} attached_file={attached_file} note={note} start_date={start_date} start_odometre={start_odometre} handleOpenModal={()=>{handleCloseModal1();handleOpenModal()}} redirect={()=>navigate(redirect_url())}/>
      <button className='btn btnOutline' onClick={()=>handleCloseModal1()} style={{width:"60%",marginInline:"20%"}}>{t("Close")}</button>

    </div>)
    }
    const driver_or_owner=()=>{
        switch(writter===proprietaire){
            case true:
                return  <div className='row'>
                <div className='col-2'>
                  <IconContext.Provider value={{ style: { fontSize: "20px",color:"#AE8625" }, className: "global-class-name" }}>
                    <div>
                      <FaUserTie />
                    </div>
                  </IconContext.Provider>

                </div>
                <div className='col-8'><span className={`history-items-text-${mode}`} style={{fontSize:12}}>{t("Owner")}</span></div>
              </div>
            default:
                return <div className='row'>
                <div className='col-2'>
                  <IconContext.Provider value={{ style: { fontSize: "20px" }, className: "global-class-name" }}>
                    <div>
                      <FaUserEdit />
                    </div>
                  </IconContext.Provider>

                </div>
                <div className='col-10'><span className={`history-items-text-${mode}`} style={{fontSize:12}}>{getLastTwoWords(owner)[0]}</span></div>
              </div>
        }

    }
  return (
    <div key={index} >
    <div style={{"display":"flex","marginTop": "-2px"}}>
        <div >
            
            <img src='/media/line.png' style={currentLanguage.dir?(currentLanguage.dir==="ltr"?{"width":"50px","height":"100%"}:{display:"none"}):{"width":"50px","height":"100%"}}/>
            <img  src={`${ip_address}/media/icons/${icon}.svg`} style={currentLanguage.dir?(currentLanguage.dir==="ltr"?{"width":"35px","marginLeft":"-43px"}:{position:"relative",top:"60px","width":"30px","marginTop":"-100px","marginLeft":"-40px"}):{"width":"30px","marginTop":"-100px","marginLeft":"-40px"}}/>
        </div>
       
        <div className={`row one-history-item-${mode}`} style={{"width":"90%","margin":"20px","borderRadius":"5px",cursor:'pointer'}} onClick={handleOpenModal1}>
           <div className='row' style={{}}>
                      <div className='col-1'>
                      {/* <IconContext.Provider value={{ style: { color: "green",fontSize:"30px" }, className: "global-class-name" }}>
                          <div>
                          <GiAutoRepair />
                          </div>
                        </IconContext.Provider> */}

<img src={info} className= "global-class-name" style={{"width":"25px","height":"25px"}}/>
                        
                        </div>
                      <div className='col-10'>
                        <span className={`history-items-${mode}`} >{t(kind)}</span>
                        {type && <span className={`type-style-${mode}`} style={{fontSize:12}}><GoArrowRight/>{type}</span>}
                        </div>
                        {/* <div className='col-1' style={{cursor:'pointer',color:"red"}} onClick={()=>handleOpenModal()}>
                       
                       <MdDeleteForever/>
                        </div>
                        <div className='col-1' style={{cursor:'pointer',color:"green"}} onClick={()=>navigate(redirect_url())}>
                       
                       <BiPencil/>
                        </div> */}

                    </div>
            <div className="col-6">
           
            
                    {driver_or_owner()}
                         {/* ///////////// odometer */}
            {}
                     {/* /////////////////////// payment method */}
               
                  {/* real event time  */}
                   {kind==="route" ?
                   <>
                   <div className='row'>
                   <div className='col-2'>

                     <IconContext.Provider value={{fontSize:"25px", className:`type-style-${mode}` }}>
                       <div>
                         <GiCheckeredFlag />
                       </div>
                     </IconContext.Provider>
                   </div>
                   <div className='col-8'><span className={`history-items-text-${mode}`} style={{fontSize:12}}>{origin}</span></div>
                 </div>
                 <div className='row'>
                   <div className='col-2'>

                     <IconContext.Provider value={{fontSize:"25px", className:`type-style-${mode}` }}>
                       <div>
                         <GiFinishLine />
                       </div>
                     </IconContext.Provider>
                   </div>
                   <div className='col-8'><span className={`history-items-text-${mode}`} style={{fontSize:12}}>{destination}</span></div>
                 </div>
                 
                 </>
                   :<><div className='row'>
                      <div className='col-2'>

                        <IconContext.Provider value={{fontSize:"25px", className:`type-style-${mode}` }}>
                          <div>
                            {(kind==='reminder')?<BsFillAlarmFill />:<BsCalendarDate />}
                          </div>
                        </IconContext.Provider>
                      </div>
                      <div className='col-8'><span className={`history-items-text-${mode}`} style={{fontSize:12}}>{translateDate(real_time,t,time_format)}</span></div>
                    </div>
                    <div className='row'>
                    <div className='col-2'>
                    <IconContext.Provider value={{fontSize:"25px", className:`type-style-${mode}` }}>
                        <div>
                          <TfiDashboard />
                        </div>
                      </IconContext.Provider>
                      
                      </div>
                    <div className='col-8'><span className={`history-items-text-${mode}`} style={{fontSize:12}}>{odometre}</span></div>
                  </div></>}
                    {/* ///////////// */}
            </div>
            <div className="col-6">
         
                  {/* ///////// place */}
                    {/* {kind !=='reminder' && <div className='row'>
                      <div className='col-2'>
                      <IconContext.Provider value={{fontSize:"25px", className:`type-style-${mode}` }}>
                          <div>
                            {kind === 'route'?<FaRoute />:<ImLocation />}
                          </div>
                        </IconContext.Provider>
                        
                        </div>
                      <div className='col-8'><span className={`history-items-text-${mode}`} style={{fontSize:12}}>{place}</span></div>
                    </div>} */}

                    {/* /////// Value or price */}

                    {kind!=="reminder"&&<div className='row'>
                      <div className='col-2'>

                        <IconContext.Provider value={{fontSize:"25px", className:`type-style-${mode}` }}>
                          <div>
                            <FaDollarSign />
                          </div>
                        </IconContext.Provider>
                      </div>
                      <div className='col-8'><span className={`history-items-text-${mode}`} style={{fontSize:12}}>{kind==="route"?prixAuto_route:value}</span></div>
                    </div>}
                      {/* //////////////// station */}
                   
       {kind==="route" && 
       <>
       <div className='row'>
     <div className='col-2'>
     <IconContext.Provider value={{fontSize:"25px", className:`type-style-${mode}` }}>
         <div>
           <TfiDashboard />
         </div>
       </IconContext.Provider>
       
       </div>
     <div className='col-8'><span className={`history-items-text-${mode}`} style={{fontSize:12}}>{odometre}</span></div>
   </div>
       <div className='row'>
       <div className='col-2'>

         <IconContext.Provider value={{fontSize:"25px", className:`type-style-${mode}` }}>
           <div>
            <BsCalendarDate />
           </div>
         </IconContext.Provider>
       </div>
       <div className='col-8'><span className={`history-items-text-${mode}`} style={{fontSize:12}}>{translateDate(real_time,t,time_format)}</span></div>
     </div>
     </>}
                    {kind === 'refuling' && <div className='row'>
                    
                      <div className='col-2'>

                      <IconContext.Provider value={{fontSize:"25px", className:`type-style-${mode}` }}>
                          <div>
                            <BsFillFuelPumpFill />
                          </div>
                        </IconContext.Provider>
                      </div>
                      <div className='col-8'><span className={`history-items-text-${mode}`} style={{fontSize:12}}>{station}</span></div>
                    </div>}

                    {(kind !== 'reminder'  && payment_method)&& (
  <div className='row'>
    <div className='col-2'>
      <IconContext.Provider value={{fontSize:"25px", className:`type-style-${mode}` }}>
        <div>
          <MdPayment />
        </div>
      </IconContext.Provider>
    </div>
    <div className='col-8'>
      <span className={`history-items-text-${mode}`} style={{fontSize: "12px"}}>
        {payment_method}
      </span>
    </div>
  </div>
)}

            </div>
        </div>
        <Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setContent_delete}/>
        <Modal2   mode={mode} isOpen={modalOpen1} onClose={handleCloseModal1}  setContent={setContent_read}/>
    </div>

    
    </div>

  )
}
