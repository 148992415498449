import React, { useEffect, useState } from 'react'
import { CustomCostSplit } from '../Rapport/CustomCostSplit'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {FcDownload} from 'react-icons/fc' 
import { IconContext } from 'react-icons'
import { useTranslation } from 'react-i18next'
import { NativeAdHistory } from '../../ads/NativeAdHistorySide'

export const SideStatistics = ({ip_address,digit_format}) => {
    const{t}=useTranslation()
    const current_day=Date.now()/1000
    // const[route,expense,income,refuling,service,filter_choice,laDistance,intervalle]=useOutletContext()
    const [route,setRoute]=useState()
    const [expense,setExpense]=useState({})
    const [income,setIncome]=useState({})
    const [refuling,setRefuling]=useState({})
    const [service,setService]=useState({})
    const [laDistance,setLadistance]=useState(0)
    const [start_time,setStart_time]=useState(current_day-2629800)
    const [end_time,setEnd_time]=useState(current_day)
    // const [income.total-total_cost,setBalance]=useState(0)
    // const [cost,setCost]=useState(0)
    // const[income.total,setRevenue]=useState(0)
    // const [route_pourcentage,setRoute_pourcentage]=useState(0)
    // const [expense_pourcentage,setExpense_pourcentage]=useState(0)
    // const [refuling_pourcentage,setRefuling_pourcentage]=useState(0)
    // const [service_pourcentage,setService_pourcentage]=useState(0)
    const navigate=useNavigate()
    const profile=useSelector(state=>state.auth.profile)
    const current_user=useSelector(state=>state.auth.user.id)
    const currency=profile.currency_format
    const total_cost=expense.total+route+refuling.total+service.total
    const vehicule_id=useSelector(state=>state.vehicules.item.id)

      /// function calculating the sum of the state objects

  const calculateObjectSum=(obj)=> {
    let sum = 0;
    
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'number') {
          sum += obj[key];
        }
      }
    }
    
    return sum;
  }
   // a tester
//    const getTimeDistance=(array)=>{
//     console.log(array)

//     switch(true){
//       case array.length>1:
//         const start=Object.keys(array[0])[0]
//         const end=Object.keys(array[array.length-1])[0]
//         const temps=start-end
//         const debut=array[0][start]['odometre']
//         const fin=array[array.length-1][end]['odometre']
//         const distance=debut-fin
//         return {'temps':temps/86400,'distance':distance}
//       case array.length==1:
//         const start1=Object.keys(array[0])[0]
//         const debut1=array[0][start1]['odometre']
//         return {'temps':start1/86400,'distance':debut1}
//       default:
//         return{'temps':0,'distance':0}
//     }

//   }

const getDistance=(data)=>{
  
    const listeDistance=new Array()
    // exepense
    const array1=data.expense
    console.log(array1)
    for (let obj in array1){
      console.log(Object.values(array1[obj])[0].odometre)

      listeDistance.push(Object.values(array1[obj])[0].odometre)
    }
    // income
    const array2=data.income
    console.log(array2)
    for (let obj in array2){
      console.log(Object.values(array2[obj])[0].odometre)

      listeDistance.push(Object.values(array2[obj])[0].odometre)
    }
      // service
      const array3=data.service
      console.log(array3)
      for (let obj in array3){
        console.log(Object.values(array3[obj])[0].odometre)
  
        listeDistance.push(Object.values(array3[obj])[0].odometre)
      }
          // refuling
          const array4=data.refuling
          console.log(array4)
          for (let obj in array4){
            console.log(Object.values(array4[obj])[0].odometre)
      
            listeDistance.push(Object.values(array4[obj])[0].odometre)
          }
    console.log('ceci est la distance',listeDistance)

    const dis=Math.max.apply(null, listeDistance)-Math.min.apply(null, listeDistance)
    console.log(dis)
    switch (true){
      case listeDistance.length>1:
        const dis=Math.max.apply(null, listeDistance)-Math.min.apply(null, listeDistance)
        return dis
      case laDistance.length === 1:
        return listeDistance[0]
      default:
        return 1
    }

  }

    useEffect(()=>{
        const getData=async()=>{
            const distances=new Array()
            const formdata=new FormData()
            formdata.append("start_time",start_time)//(Date.parse(new Date())-(start_time/1000))
            formdata.append("end_time",end_time)
            formdata.append("pk",vehicule_id)
            const data=await fetchData(formdata)
            
            const expense_data=data.general_expense
            expense_data.total=calculateObjectSum(data.general_expense)
            // const timeAndDate_expense=getTimeDistance(data.expense)
            // expense_data.distance=timeAndDate_expense.distance
            // expense_data.temps=timeAndDate_expense.temps
            // distances.push(timeAndDate_expense.distance)
            // console.log('expense_data',expense_data)
            setExpense(expense_data)
            const income_data=data.general_income
            income_data.total=calculateObjectSum(data.general_income)
            // const timeAndDate_income=getTimeDistance(data.income)
            // distances.push(timeAndDate_income.distance)
            // console.log('income_data',income_data)
            setIncome(income_data)
            const refuling_data=data.general_refuling
            refuling_data.total=calculateObjectSum(data.general_refuling)
            // const timeAndDate_refuling=getTimeDistance(data.refuling)
            // refuling_data.distance=timeAndDate_refuling.distance
            // refuling_data.temps=timeAndDate_refuling.temps
            // distances.push(timeAndDate_refuling.distance)
            // console.log('refuling_data',refuling_data)
            setRefuling(refuling_data)
            const service_data=data.general_service
            service_data.total=calculateObjectSum(data.general_service)
            // const timeAndDate_service=getTimeDistance(data.service)
            // service_data.distance=timeAndDate_service.distance
            // service_data.temps=timeAndDate_service.temps
            // distances.push(timeAndDate_service.distance)
            setRoute(data.route_cost)
            setLadistance(getDistance(data))
            console.log('laDIstance',laDistance)
            console.log('current_day',current_day)
            console.log('intervalle',start_time,end_time)
            setService(service_data)
          }
        getData()
    },[vehicule_id])

    // fetching data

    const fetchData=async(data)=>{
        const request=await fetch(`${ip_address}/api/dashboard`, {
          method: 'POST',
          headers:{
              "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
          },
          body: data,
         
      },)
      
      console.log(request)
      const data1=await request.json()
      return data1
      }






    return (
        <div>
            {/* <div onClick={()=>generateRapport()}>
            <IconContext.Provider value={{ style: { fontSize: "25px",marginLeft:10,cursor:'pointer'},color:'rgba(0,0,0,.55)', className: "global-class-name" }}>
                   
                   <FcDownload />
            
             </IconContext.Provider>
             </div> */}
             <div className='row'>
            <div className='col'>
                <h4 style={{fontFamily:'AirStrike'}}>{t("Last")} {t("Month")}</h4>
                </div>
        </div>
        <div className='row' style={{
           borderBottom: "1px solid rgb(212, 212, 212)"
        }}>
            {/* <div className='col-md-4'>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>
                    <div >
                        <h5 style={{fontFamily:'Airstrike'}}>{t("Balance")}</h5>
                        </div>
                    <h5 style={{color:income.total-total_cost<0?'red':'green'}}>{income.total-total_cost}{currency}</h5>
                    <h6><span style={{fontSize:15}}>{digit_format(income.total-total_cost/30)}</span> {t("per Day")}</h6>
                    <h6><span style={{fontSize:15}}>{digit_format((income.total-total_cost/laDistance))}</span>{t("By Km")}</h6>
                </div>
            </div> */}
            <div className='col-6'>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>
                <h5 style={{fontFamily:'Roboto'}}>{t("Cost")}</h5>
                    <h5 style={{color:"red"}}>{total_cost?total_cost:0} {currency}</h5>
                    <h6><span style={{fontSize:15}}>{digit_format(total_cost/30)}</span> {t("Per Day")}</h6>
                    <h6><span style={{fontSize:15}}>{digit_format((total_cost/laDistance))}</span> {t("By Km")}</h6>
                </div>
            </div>
            <div className='col-6'>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'centre', alignItems: 'center' }}>
                <h5 style={{fontFamily:'Roboto'}}>{t("Income")}</h5>
                    <h5 style={{color:"#00FF48"}}>{income.total?income.total:0} {currency}</h5>
                    <h6><span style={{fontSize:15}}>{digit_format(income.total/30)}</span> {t("Per Day")}</h6>
                    <h6><span style={{fontSize:15}}>{digit_format((income.total/laDistance))}</span> {t("By Km")}</h6>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col'>
                <h4 style={{fontFamily:'Airstrike'}}>{t("Cost")} {t("Split")}</h4>
                </div>
        </div>
        <div className='row' style={{
           borderBottom: "1px solid rgb(212, 212, 212)"
        }}>
            <div className='col-6'>
                <CustomCostSplit icon={'refuling'} name={t('Refueling')} pourcentage={digit_format(total_cost?(refuling.total/total_cost)*100:0)} />
            </div>
            <div className='col-6'>
                <CustomCostSplit icon={'service'} name={t('Service')} pourcentage={digit_format(total_cost?(service.total/total_cost)*100:0)} />
            </div>
            <div className='col-6'>
                <CustomCostSplit icon={'expense'} name={t('Expense')} pourcentage={digit_format(total_cost?(expense.total/total_cost)*100:0)} />
            </div>
            <div className='col-6'>
                <CustomCostSplit icon={'road'} name={t('Route')} pourcentage={digit_format(total_cost?(route/total_cost)*100:0)} />
            </div>
        </div>
        <div className='row' style={{
           borderBottom: "1px solid rgb(212, 212, 212)"
        }}>
            <div className='col-6'>  <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <h4 style={{fontFamily:'AirStrike'}}>{t("Distance")}</h4>
                <h5 style={{fontFamily:"Roboto"}}>{t("Total")}</h5>
                <div style={{fontSize:15}}>{laDistance} Km</div>
                <div style={{fontSize:15}}>{digit_format(laDistance/30)}km {t("Per Day")}</div>
            </div></div>
        </div>
        <div className='row' style={{
           margin:30
        }}>
           <Link to={'/repport/all'} className='btn btnOutline'><span>{t("General Report")}</span></Link>
        </div>
        <div className='row'>
          <NativeAdHistory />
        </div>
        </div>
    )
}





