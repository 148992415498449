import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dateFormat from 'dateformat'
import {MdDeleteForever} from 'react-icons/md'
import { FaPen } from 'react-icons/fa'
import { IconContext } from "react-icons"
import {ImArrowRight} from 'react-icons/im'

export const DashboardServices = ({ip_address}) => {
    const mode=useSelector(state=>state.auth.mode)
  const vehicule_id = useSelector((state) => state.vehicules.item.id)
  const time_format=useSelector(state=>state.auth.profile.time_format)
  const [sorted_items, setSorted_items] = useState([])

  useEffect(() => {
    const fetchMyObjects = async () => {
      const myItems = await getMyObjects(vehicule_id)
      setSorted_items(myItems)
   
    }

    fetchMyObjects()

  }, [vehicule_id])

  const getMyObjects = async (vehicule_id) => {
    const raw_data = await fetch(`${ip_address}/app/api/ownobjects/${vehicule_id}/`, {

      headers: {
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
      }
    })
    const data = await raw_data.json()
    const liste = data.data
    liste.sort(function (a, b) {
      return Number(Object.keys(b)[0]) - Number(Object.keys(a)[0]);
    });
    console.log(liste)
    return liste
  }

  const deleteService=async(url,service_items)=>{
   
 
    
    const action=await fetch(url,{
        headers:{
            "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
        },
        method:'DELETE',
     })
     const status=action.status

     switch(status){
        case 204:
          
          const new_items=service_items.filter(item=>Object.values(item)[0].url!==url)
           setSorted_items(new_items)
            break
        case 403:
            alert('this is not your item')
            break
     
 }  
}

  return (
    <div>
        <h5 style={{margin:'auto',fontFamily:'AirStrike'}}>manage my services</h5>
        <table class={`table text-${mode}`}>
  <thead>
    <tr>
      <th scope="col">Service tcreator</th>
      <th scope="col">nature</th>
      <th scope="col">created at</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
  {sorted_items.map((index,item)=> <tr >
        <th scope="row" key={index}>{Object.values(sorted_items[item])[0].done_by}</th>
        <td>{Object.values(sorted_items[item])[0].kind}<ImArrowRight/>{Object.values(sorted_items[item])[0].type}</td>
        <td>{(dateFormat(Object.values(sorted_items[item])[0].real_time,`${time_format}  HH:MM`))}</td>
        <td>
            <div style={{"display":"flex","justifyContent":"center"}}>
        <IconContext.Provider value={{ style: { color: "red", fontSize: "20px","marginRight":"20px",cursor:"pointer" }, className: "global-class-name" }} >
                          <div onClick={()=>
                            deleteService(Object.values(sorted_items[item])[0].url,sorted_items)
                            }>
                          <MdDeleteForever/>
                          </div>
                        </IconContext.Provider>
             
            <IconContext.Provider value={{ style: { color: "green", fontSize: "20px",cursor:"pointer" }, className: "global-class-name" }}>
                          <div onClick={()=>alert('to do later')}>
                          <FaPen/>
                          </div>
                        </IconContext.Provider>
           </div>
             </td>
      </tr>)}
  </tbody>
</table>
    </div>
  )
}
