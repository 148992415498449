import React,{useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {FaPen} from 'react-icons/fa'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from "react-icons"
import { Modal2 } from '../../Modals/Modal2'
import { useNavigate } from 'react-router-dom'
import dateFormat from "dateformat"

export const TableRowReminder = ({index,type,date,odometre,expires,url,reminders,setReminders,mode}) => {
    const {t}=useTranslation()
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const profile=useSelector(state=>state.auth.profile)
    const time_format=profile.time_format
    const [modalOpen, setModalOpen] = useState(false);


    function calculateTimeDifference(targetDate) {
        var now = new Date();
        var target = new Date(targetDate);
      
        // Calculate the difference in milliseconds
        var difference = target - now;
        var isPast = difference < 0;
      
        // Calculate remaining time if target date is in the future
        if (!isPast) {
          var remainingMilliseconds = difference;
          var remainingSeconds = Math.floor(remainingMilliseconds / 1000) % 60;
          var remainingMinutes = Math.floor(remainingMilliseconds / (1000 * 60)) % 60;
          var remainingHours = Math.floor(remainingMilliseconds / (1000 * 60 * 60)) % 24;
          var remainingDays = Math.floor(remainingMilliseconds / (1000 * 60 * 60 * 24));
      
          var remainingTime = `${remainingDays}days,
          ${remainingHours}hours,
          ${remainingMinutes}minutes,` 
      
          return <span style={{color:"green"}}>{remainingTime}</span>;
        }
      
        // Calculate elapsed time if target date is in the past
        var elapsedMilliseconds = Math.abs(difference);
        var elapsedSeconds = Math.floor(elapsedMilliseconds / 1000) % 60;
        var elapsedMinutes = Math.floor(elapsedMilliseconds / (1000 * 60)) % 60;
        var elapsedHours = Math.floor(elapsedMilliseconds / (1000 * 60 * 60)) % 24;
        var elapsedDays = Math.floor(elapsedMilliseconds / (1000 * 60 * 60 * 24));
      
        var elapsedTime =`${elapsedDays}days ${elapsedHours}hours${elapsedMinutes}minutes` 
          
        
        
      
        return <span style={{color:"red",fontSize:"10px"}}>{elapsedTime}</span>;
      }
      
    const dat=(url)=>{
        const data=url.split('/')
        return `/edit_reminder/${data[data.length -2]}`
      } 
      
    const handleOpenModal = () => {
        setModalOpen(true);
      };
      
      const handleCloseModal = () => {
        setModalOpen(false);
      };

      const deleteRimeder=async (url)=>{
        const request =await fetch(url,{
          headers:{
              "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
          },
          method:'DELETE',
       })
       const status=request.status
       switch(status){
          case 204:
            console.log('reminder deleted')
            const new_items=reminders.filter(item=>Object.values(item)[0].url !== url)
             setReminders(new_items)
              break
          case 403:
              alert('this is not your item')
              break
       
      }  

      
      
      
      }

      const setContent_delete=()=>{
        return( <div className="delete-confirmation-dialog">
        <p>{t("Are you sure you want to delete this item?")}</p>
        <div style={{display:'flex',flexDirection:"row" ,alignItems:'center',justifyContent:'space-around'}}>
          <button className='btn btn-outline-danger' onClick={()=>{deleteRimeder(url);handleCloseModal()}}>{t("YES")}</button>
          <button className='btn btn-outline-info' onClick={handleCloseModal}>{t("NO")}</button>
        </div>
  
      </div>)
      }
  return (
    <tr key={index}>
        
        <td>{type}</td>
        <td  className='removable'>{(dateFormat(date,`${time_format}  HH:MM`))}</td>
        
        <td className='removable'>{odometre?odometre:'-'}</td>
        <td>{expires?calculateTimeDifference(expires):'-'}</td>
        <td>
            {/* <button className='btn btn-danger'>delete</button>
            <button className='btn btn-success'>modify</button> */}

<div style={{"display":"flex","justifyContent":"center"}}>
        <IconContext.Provider value={{ style: { color: "red", fontSize: "20px","marginRight":"20px",cursor:"pointer" }, className: "global-class-name" }} >
                          <div onClick={handleOpenModal}>
                          <MdDeleteForever/>
                          </div>
                        </IconContext.Provider>
                        <Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setContent_delete}/>
            <IconContext.Provider value={{ style: { color: "green", fontSize: "20px",cursor:"pointer" }, className: "global-class-name" }}>
                          <div onClick={()=>navigate(dat(url))}>
                          <FaPen/>
                          </div>
                        </IconContext.Provider>
           </div>
        </td>
      </tr>
  )
}
