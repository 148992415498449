import React,{useState,useEffect} from 'react'
import { useSelector } from 'react-redux'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from "react-icons"
import {FaPen} from "react-icons/fa"
import { updateOwnVehicule } from '../../../actions/rappelActions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DriverRow } from './DriverRow'

export const MyUsersPerVehicule = ({ip_address}) => {
  const {t}=useTranslation()
  const dispatch=useDispatch()
  const mode=useSelector(state=>state.auth.mode)
    const vehicule_id = useSelector((state) =>  state.vehicules.item.id)

    const drivo = useSelector((state) => state.vehicules.item.driver)
    const [utilisateurs,setUtilisateurs]=useState([])

    const [url,setUrl]=useState('')

    useEffect(()=>{
        const fetchDrivers=async()=>{
          
        const drivers=await getProfile(vehicule_id)
     
       
          setUtilisateurs(drivers)
          console.log(drivers)
    
        }
      
        fetchDrivers()
        
       },[vehicule_id,drivo])

       const getProfile=async(vehicule_id)=>{
     
        const reminders=await fetch(`${ip_address}/app/api/owndrivers/${vehicule_id}/`,{
          
        headers:{
            "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
        }
    })

    switch(reminders.status){
      case 200:
          const data=await reminders.json()
          return data
      case 404:
        setUtilisateurs([])
        break


    }
      
       }

       const delete_driver=async(id,url)=>{
        const formdata=new FormData()
        formdata.append("driver",'delete')
        formdata.append("driver",url)
        dispatch(updateOwnVehicule(id,formdata))
        setUtilisateurs(utilisateurs.filter(utilisat=>utilisat.url!==url))
       }


       const add_driver=async(e)=>{
        e.preventDefault()
        const formdata=new FormData()
        formdata.append("driver",'request')
        formdata.append("driver",url) 
        dispatch(updateOwnVehicule(vehicule_id,formdata)) 
           
       }

  return (
    <div id='myUsersPerVehicule' className={`graphs_${mode} p-1`}>
    <div className='row m-3'>
    <div className='col'>
    <table className={`table text-${mode}`}>
<thead>
<tr>
      
      <th scope="col"><span className={`table text-${mode}`}>{t("Full name")}</span></th>
      <th scope="col"><span className={`table text-${mode}`}>{t("Email")}</span></th>
      {/* <th scope="col"><span className={`table text-${mode}`}>{t("phone")}</span></th> */}
      <th scope="col"><span className={`table text-${mode}`}>{t("Actions")}</span></th>
    </tr>
</thead>
{utilisateurs?<tbody>
{utilisateurs.map((utilisat,index)=><DriverRow index={index} mode={mode} lastname={utilisat.lastname}
email={utilisat.email} phone={utilisat.phone} vehicule_id={vehicule_id} url={utilisat.url} delete_driver={delete_driver}/>
) }

</tbody>:<h1 style={{"width":"100%"}}>{t("NOT YOUR VEHICULE")}</h1>}
</table>
</div>
</div>
<div style={{display:'flex',justifyContent:'center',alignItems:'center',margin:10,padding:10}}>

  {utilisateurs&&<button className={`btn btnOutline`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
    {t("Add")} a {t("driver")}
  </button>}
</div>
<div class="collapse" id="collapseExample">
  <div class={`card card-body`} style={{width:"100%",backgroundColor:"transparent",border:"0px"}}>
    <form onSubmit={add_driver}>
    <label for="brandDataList" class="form-label">{t("Add a driver by entering his email")}</label>
{/* <input class={`card card-body bg-${mode}`} list="datalistOptions1" id="brandDataList" placeholder="vehicule brand" onChange={(e)=>setBrand(e.target.value)} value={brand}/> */}
{/* <select class={`form-select bg-${mode}`} id="brandDataList" placeholder="vehicule brand" onChange={(e)=>setUrl(e.target.value)} value={url}>
    {all_profiles.map((profile,index)=>(<option key={index} value={profile.url}>{profile.name}</option>))}
</select> */}
<input type='email' className={`form-control ${mode}`} onChange={(e)=>setUrl(e.target.value)} style={{backgroundColor:"transparent"}} value={url}  placeholder={t('Email')}></input>
<button type="submit" class="btn btnOutline mt-2" >{t("Submit")}</button>

    </form>
  </div>
</div>
</div>
  )
}
