import React,{useState,useEffect} from 'react'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from "react-icons"
import {RxEyeOpen} from 'react-icons/rx'
import { useSelector } from 'react-redux'
import { CREATE_MESSAGES,GET_ERRORS } from '../../../../actions/types'
import { useTranslation } from 'react-i18next'
import { convertBytes } from '../../Settings/Settings'
import { useDispatch } from 'react-redux'
import { Modal2 } from '../../Modals/Modal2'


export const TableRowFile = ({index,mode,filename,size,ip_address,files,setFiles,setChanged}) => {
   
const dispatch=useDispatch()
const {t}=useTranslation()

    const auth=useSelector(state=>state.auth)
    const username=auth.user.username
    const email=auth.user.email
    const link=`${email}`
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpenModal = () => {
        setModalOpen(true);
      };
      
      const handleCloseModal = () => {
        setModalOpen(false);
      };

      
    const delete_file=async(id,email,filename)=>{
        const formdata=new FormData()
        formdata.append('user_id',id)
        formdata.append('filename',filename)
        formdata.append('email',email)
        const request_delete=await fetch(`${ip_address}/delete_file`,{
          method:'POST',
          // headers:{
          //     "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
          // },
          body:formdata,
  
      },)
  
      const responseStatus=request_delete.status
      if (responseStatus===200){
        const response=await request_delete.json()
        switch(response.message){
         
          case 'file removed successfully':
            console.log(response.message)
            dispatch({
              type:CREATE_MESSAGES,
              payload:`${t("File removed successfully")}`,
          })
          const new_files=Object.keys(files).filter((type) => type !== filename)
          console.log(new_files)
          const new_obj={}
          for(let file in new_files){
            // console.log(new_files[file],files[new_files[file]])
            new_obj[new_files[file]]=files[new_files[file]]
          }
          // console.log(new_obj)
          setFiles(new_obj)
          setChanged(new_files.length)
            break
          default:
            dispatch({
              type:GET_ERRORS,
              payload:{
                  msg:response.message,
                  status:405
              }
          })
            break
      
  
        }
      }
  
  
      }
    const setContent_delete=()=>{
        return( <div className="delete-confirmation-dialog">
        <p>{t("Are you sure you want to delete this item?")}</p>
        <div style={{display:'flex',flexDirection:"row" ,alignItems:'center',justifyContent:'space-around'}}>
          <button className='btn btn-outline-danger' onClick={()=>{delete_file(auth.user.id,email,filename);handleCloseModal()}}>{t("YES")}</button>
          <button className='btn btn-outline-info' onClick={handleCloseModal}>{t("NO")}</button>
        </div>
  
      </div>)
      }

  return (<tr>
    <th scope="row" key={index}>{index+1}</th>
    <td>{filename}</td>
    <td>{size}</td>
    <td>
        <div style={{"display":"flex","justifyContent":"flex-start"}}>

         
        <IconContext.Provider value={{ style: { color: "green", fontSize: "20px",cursor:"pointer" }, className: "global-class-name" }}>
                      <a href={`${ip_address}/media/files/${link}/${filename}`} target='_blank'>
                      <RxEyeOpen/>
                      </a>
                    </IconContext.Provider>
                    <Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setContent_delete}/>
        <IconContext.Provider value={{ style: { color: "red", fontSize: "20px",cursor:"pointer" }, className: "global-class-name" }}>
                     <div onClick={handleOpenModal}>
                      <MdDeleteForever/>
                      {/* here */}
                     </div>
                    </IconContext.Provider>
       </div>
         </td>
  </tr>
  )
}
