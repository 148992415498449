

import { DELETE_NOTIFICATION,FETCH_NOTIFICATION,READ_NOTIFICATION} from "../actions/types";

const initialState={
    items:[]
}

export default function(state=initialState ,action){
    switch(action.type){
        case DELETE_NOTIFICATION:
            
            
            
            return {
                ...state,
               
                items:state.items.filter(item=>item.id!==action.payload),
            }

        case FETCH_NOTIFICATION:
            
            
            
            return {
                ...state,
               
                items:action.payload,
            }
            case READ_NOTIFICATION:
            
            
            
            return {
                ...state,
               
                items:[],
            }


            default:
                return state;

            }
        }
