import React, { useEffect, useState } from 'react'
import { addVehicule } from '../../../../actions/rappelActions'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cars from '../../../../cars.json'
import { useNavigate } from 'react-router-dom'
import { ModalAd } from '../../Modals/Modal'
import { Autocomplete } from '../../Modals/Autocmplete'


export const AddForm = ({ip_address,currentLanguage}) => {
    const {t}=useTranslation()
    console.log(currentLanguage,ip_address)
    const mode = useSelector(state => state.auth.mode)
    const fuels = JSON.parse(useSelector(state => state.auth.profile.fuel_choice))
    const types = ['Moto', 'Voiture', 'Bus', 'Camion']
    const [choosen_brand, setChoosen_brand] = useState([])
    const [orientat,setOrientat]=useState()
    const [type, setType] = useState('Moto')
    const [model, setModel] = useState('')
    const [showModal1, setShowModal1] = useState("none")
    const [fuel_type, setFuel_type] = useState('')
    const [year, setYear] = useState('')
    const [tank_capacity, setTank_capacity] = useState()
    const [odometre_type, setOdometre_type] = useState('Km')
    const [matricule, setMatricule] = useState('')
    const [n_chassi, setN_chassi] = useState()
    const [brand, setBrand] = useState('')
    const [odometre, setOdometre] = useState()
    // 
    const [filteredSuggestions, setFilteredSuggestions] = useState(Object.keys(cars));
    const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(true);
    const handleInputChange = (e) => {
        const value = e.target.value;
        setChoosen_brand('')
        setBrand(value);
    
        // Filter the suggestions based on the input value
        const filtered = Object.keys(cars).filter((suggestion) =>
          suggestion.toLowerCase().includes(value.toLowerCase())
        );
    
        setFilteredSuggestions(filtered);
        setIsSuggestionsVisible(true);
      };
      const handleSuggestionClick = (suggestion) => {
       
        setBrand(suggestion);
        setChoosen_brand(`${ip_address}${cars[suggestion]}`)
        setIsSuggestionsVisible(false);
        toggleShow()
      };
    
      const renderSuggestions = () => {
        if (isSuggestionsVisible && filteredSuggestions.length > 0) {
          return (
            <ul className="suggestions">
              {filteredSuggestions.map((suggestion, index) => (
                // <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                //   {suggestion}
                // </li>
<li key={index} style={{display:"block"}}><div onClick={() => handleSuggestionClick(suggestion)} style={{cursor:"pointer"}} ><img src={`${ip_address}${cars[suggestion]}`} style={{ "width": "50px" }} />{suggestion}</div></li>
                
              ))}
            </ul>
          );
        }
        return null;
      };
    // 
    const dispatch = useDispatch()
    const navigate=useNavigate()




    // const fetchDrivers=async()=>{
    //     const requette=await fetch('http://localhost:8080/api/profiles/',{
    //         headers:{
    //             "Authorization":`Token ${localStorage.getItem('token')}`,
    //         }
    //     })
    //     const drivers=await requette.json()
    //     console.log(drivers)
    //     return drivers
    // }


    useEffect(()=>{
        setOrientat(currentLanguage?currentLanguage.dir:'ltr')
     

    },[currentLanguage])

    const onSubmit = async (e) => {
        e.preventDefault()
        const formdata = new FormData()
        formdata.append("vehicule_name", brand)
        formdata.append("vehicule_type", type)
        formdata.append("odometre", odometre)
        if(model){
            formdata.append("model", model)
        }
        if(fuel_type){
            formdata.append("fuel_type", fuel_type)
        }
        if(year){
            formdata.append("year", year)
        }
        if(tank_capacity){
            formdata.append("tank_capacity", tank_capacity)
        }
        if(odometre_type){
            formdata.append("odometre_type", odometre_type)
        }
        if(matricule){
            formdata.append("matricule", matricule)
        }
        if(n_chassi){
            formdata.append("n_chassi", n_chassi)
        }
        // formdata.append("driver",driver)

        dispatch(addVehicule(formdata,navigate))


    }

      // //////////////////////////////////////////////toggle show

  const toggleShow = () => {
    if (showModal1 === "none") {
      setShowModal1("block")
    } else {
      setShowModal1("none")
    }
  }



  /////////////// handle brands


    const setContent_brands=()=>{
        return(
          <>
        <div class={`${mode}`} placeholder="Vehicule brand" style={{ "height": "300px", "overflowY": "scroll"}}>
        {/* <ul>
            {Object.keys(cars).map((car, index) => ())}
        </ul> */}
         <div className="autocomplete">
      <input
      className='form-control'
        type="text"
        value={brand}
        onChange={handleInputChange}
        placeholder={t("Search brand")}
      />
      {renderSuggestions()}
    </div>
  
    </div>
    <button className='btn btn-danger' onClick={toggleShow} style={{width:200,marginTop:10}}>{t("Close")}</button>
    </>
    )
      }

    ////////////////////////////:handle click outside model
////////////////// chooseType is the id of the input to
/////////////////////// avoid hidding the modal when typing in the input

// 

  
  // if (showModal1=='block') {
  // // console.log(document.getElementById('myModalMan'))
  //   document.getElementById('myModalMan').addEventListener("click",toggleShow);
  
  // }


  
  
  ////////////////////////////////end 


  ////////////////////////// AutoComplete






  //////////////////////////////



    return (
<section id='vehicleForm' className={`all_sections ${orientat} mt-4`}>
    <div className={`content-${mode} whitewhen${mode}`}>
        <form onSubmit={onSubmit} id='addV' className='m-5'>

            <div className='row p-2'>
                <div className='col-md-4'>
                    <label for="brandDataList" class={`form-label required`}>{t("Vehicule brand")}</label>
                    {/* <input class={`form-control bg-${mode}`} list="datalistOptions1" id="brandDataList" placeholder="vehicule brand" onChange={(e)=>setBrand(e.target.value)} value={brand}/> */}
                  
                  <div className='form-control btn btn-lg' onClick={() => toggleShow()} style={{backgroundColor:"#ff5d70",color:"white",textAlign:"center"}}>{choosen_brand && <img src={choosen_brand}  style={{ "width": "50px" }} />}{brand ? brand : t('Choose brand')}</div>
                  
               {/* <Autocomplete suggestions={Object.keys(cars)}/> */}
                </div>
                <div className='col-md-4'>
                    <label for="typeDataList1" class={`form-label required`}>{t("Vehicle type")}</label>
                    {/* <input class={`form-control ${mode}`} list="datalistOptions2" id="typeDataList" placeholder="vehicule type" onChange={(e)=>setType(e.target.value)} value={type}/> */}
                    <select class={`form-control ${mode}`} id="typeDataList1" onChange={(e) => setType(e.target.value)} value={type}>
                        {types.map((typer, index) => (<option key={index} value={typer}>{typer}</option>))}
                    </select>
                </div>
                <div className='col-md-4'>
                    <label for="newVehiculesOdometre" class={`form-label required`}>{t("Odometer")}</label>
                    {/* <input class={`form-control ${mode}`} list="datalistOptions2" id="typeDataList" placeholder="vehicule type" onChange={(e)=>setType(e.target.value)} value={type}/> */}
                    <input className={`form-control ${mode} required-input`}id='newVehiculesOdometre' onChange={(e) => setOdometre(e.target.value)} value={odometre} placeholder={t('Odometer')} required/>
                    <span class="asterisk_required_field"></span>
                </div> 
                <div className='col-md-4'>
                    <label for="fuel_type" class={`form-label required`}>{t("Fuel")}</label>


                    <select class={`form-select ${mode}`} id='selectService' onChange={(e) => setFuel_type(e.target.value)} value={fuel_type} required>
                                    
                                        {fuels.map((type, index) => (<option key={index} value={type}>{type}</option>))}
                                    </select>


                    {/* <input  type="text" readonly className={`form-control ${mode}`}id="fuel_type" onChange={(e)=>setFuel_type(e.target.value)} value={fuel_type} placeholder={t("Fuel type")} required/> */}
                </div>
                <div className='col-md-4'>
                    <label for="model" class={`form-label required`}>{t("Vehicle name")}</label>
                    <input type="text" readonly className={`form-control ${mode}`}id="model" onChange={(e)=>setModel(e.target.value)} value={model} placeholder={t('Vehicle name')} required/>
                </div>
                <div className='col-md-4'>
                    <label for="year" class={`form-label required`}>{t("Year")}</label>
                    <input type="text" readonly className={`form-control ${mode}`}id="year" onChange={(e)=>setYear(e.target.value)} value={year} placeholder={t("Year")} required/>
                </div>
                <div className='col-md-4'>
                <label for="tank_capacity" class={`form-label`}>{t("Tank capacity")}</label>
                    <input type="number" readonly className={`form-control ${mode}`}id="tank_capacity" onChange={(e)=>setTank_capacity(e.target.value)} value={tank_capacity} placeholder={t('Tank capacity')}/>
                </div>

                <div className='col-md-4'>
                <label for="matricule" class={`form-label`}>{t("Matricule")}</label>
                    <input type="text" readonly className={`form-control ${mode}`}id="matricule" onChange={(e)=>setMatricule(e.target.value)} value={matricule} placeholder={t('Matricule')}/>
                </div>
                <div className='col-md-4'>
                <label for="n_chassi" class={`form-label`}>{t("Chassis number")}</label>
                    <input type="number" readonly className={`form-control ${mode}`}id="n_chassi" onChange={(e)=>setN_chassi(e.target.value)} value={n_chassi} placeholder={t('Chassis number')}/>
                </div>
            </div>
            {/* <div style={{fontSize:10}}><span style={{color:'red'}}>(*)</span>: {t("Required fields")}</div> */}
            <div className='dFlex'>
            <button type="submit" class="adbtnOutline form-control " >{t("Submit")}</button>
            </div>
        </form>
       
        </div>
        <ModalAd modal={'Add'}  mode={mode} showModal={showModal1} setContent={setContent_brands}/>
        {/* <Modal2  mode={mode} isOpen={showModal1} onClose={toggleShow} setContent={setContent_brands}/> */}
        </section>
    )
}
