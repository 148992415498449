import React, {useState, useEffect } from 'react'
import { DashboardGraph } from '../DashboardGraphDetails'
import { useOutletContext } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export const DashboardGeneral = ({ip_address}) => {

    const {t}=useTranslation()
    const[start_time,end_time,start_time1,end_time1]=useOutletContext() 
    const [income_data,setIncome_data]=useState([])
    const [income_label,setIncome_label]=useState([])
    const [expnese_data,setExpnese_data]=useState([])
    const [expnese_label,setExpnese_label]=useState([])
    const [refuling_data,setRefuling_data]=useState([])
    const [refuling_label,setRefuling_label]=useState([])
    const [service_data,setService_data]=useState([])
    const [service_label,setService_label]=useState([])
    const vehicule_id=useSelector(state=>state.vehicules.item.id)

    const mode= useSelector(state=>state.auth.mode)
    
    const profile=useSelector(state=>state.auth.profile)
    const currency=profile.currency_format


    useEffect(()=>{
        const getDashboardItems=async()=>{
          const formdata=new FormData()
          formdata.append("start_time",start_time)
          formdata.append("end_time",end_time)
          formdata.append("pk",vehicule_id)
          const data=await fetchDashboradItems(formdata)
          setExpnese_data(Object.values(data.general_expense))
          setExpnese_label(Object.keys(data.general_expense))
          setIncome_data(Object.values(data.general_income))
          setIncome_label(Object.keys(data.general_income))
          setRefuling_data(Object.values(data.general_refuling))
          setRefuling_label(Object.keys(data.general_refuling))
          setService_data(Object.values(data.general_service))
          setService_label(Object.keys(data.general_service))
          console.log(data,"this is ip     :",ip_address)
    
        }
    
        getDashboardItems()
    
      },[vehicule_id,start_time,end_time])
    
      const fetchDashboradItems=async(data)=>{
        console.log("this is ip     :",ip_address)
        const request=await fetch(`/api/dashboard`, {
          method: 'POST',
          headers:{
              "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
          },
          body: data,
         
      },)
    
      console.log(request)
      const data1=await request.json()
      return data1
      }




    const labels_and_data=(obj,stat)=>{

    }

    //console.log('this labels',Object.keys(general_data.general_refuling),'and data',Object.values(general_data.general_refuling))
    const data=[34,55]
    const labels=[t("first label"),t("second label")]
    const label=t("some string")
  return (
    <div className='row' style={{width:"90%"}}>
        {income_data.length?<div className='col-md-4 text-center mb-2'>
            <DashboardGraph donnée={income_data} labels={income_label} label={`cost in ${currency}`} mode={mode} title='Income' startTime={start_time1} endTime={end_time1}/>
        </div>:<></>}
        {refuling_data.length?<div className='col-md-4 text-center mb-2'>
            <DashboardGraph donnée={refuling_data} labels={refuling_label} label={`cost in ${currency}`} mode={mode} title='Refueling' startTime={start_time1} endTime={end_time1}/>
        </div>:<></>}
        {expnese_data.length?<div className='col-md-4 text-center mb-2'>
            <DashboardGraph donnée={expnese_data} labels={expnese_label} label={`cost in ${currency}`} mode={mode} title='Expense' startTime={start_time1} endTime={end_time1}/>
        </div>:<></>}
        {service_data.length?<div className='col-md-4 text-center mb-2'>
            <DashboardGraph donnée={service_data} labels={service_label} label={`cost in ${currency}`} mode={mode} title='Service' startTime={start_time1} endTime={end_time1}/>
        </div>:<></>}
       
    </div>
  )
}
