import React,{useState,useEffect} from 'react'
import "./Rapport.css"
import { useSelector } from 'react-redux'
import { Link, Outlet } from 'react-router-dom'
import { IconContext } from "react-icons"
import {AiOutlineAreaChart} from 'react-icons/ai'
import {FaFilter} from 'react-icons/fa'
import {FaGasPump} from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import {MdOutlineMiscellaneousServices} from 'react-icons/md'
import {GiMountainRoad,GiPayMoney,GiReceiveMoney} from 'react-icons/gi'
import { Modal2 } from '../Modals/Modal2'
import reportRefueling from './repportIcons/Rapport-Refuelling.png'
import reportExpense from './repportIcons/Rapport-Expense.png'
import reportRoute from './repportIcons/Rapport-Routing.png'
import reportService from './repportIcons/Rapport-Service.png'
import rapportIncome from './repportIcons/Rapport-Income.png'
import rapportGeneral from './repportIcons/Rapport-General.png'
import { NativeAdReport } from '../../ads/NativeAdReport'
// import { RapportService } from './RapportDetails/RapportService'


export const Rapport = ({ip_address,currentLanguage}) => {
  const {t}=useTranslation()
  const periode_choice={'last month':2629800,'last three months':7889400 ,'last year':31556952}
  const current_day=Date.now()/1000
  const vehicule_id=useSelector(state=>state.vehicules.item.id)
const [durée,setDurée]=useState('last month')
  const [filter_choice,setFilter_choice]=useState(periode_choice['last month'])
  const [route,setRoute]=useState()
  const [expense,setExpense]=useState({})
  const [income,setIncome]=useState({})
  const [refuling,setRefuling]=useState({})
  const [service,setService]=useState({})
  const [modal_content,setModal_content]=useState('choices')
  const [start_time,setStart_time]=useState(current_day-periode_choice['last month'])
  const [end_time,setEnd_time]=useState(current_day)
  const [title,setTitle]=useState('Genaral')
  const [laDistance,setLadistance]=useState(0)
  const mode=useSelector(state=>state.auth.mode)
  const [clicked1,setClicked1]=useState(true)
  const [clicked2,setClicked2]=useState(false)
  const [clicked3,setClicked3]=useState(false)
  const [clicked4,setClicked4]=useState(false)
  const [clicked5,setClicked5]=useState(false)
  const [clicked6,setClicked6]=useState(false)


  const setGeneral=()=>{
    setClicked1(true)
    setClicked2(false)
    setClicked3(false)
    setClicked4(false)
    setClicked5(false)
    setClicked6(false)
  }
  const setRefueling=()=>{
    setClicked1(false)
    setClicked2(true)
    setClicked3(false)
    setClicked4(false)
    setClicked5(false)
    setClicked6(false)
  }
  const setExpenses=()=>{
    setClicked1(false)
    setClicked2(false)
    setClicked3(true)
    setClicked4(false)
    setClicked5(false)
    setClicked6(false)
  }

  const setIncomes=()=>{
    setClicked1(false)
    setClicked2(false)
    setClicked3(false)
    setClicked4(true)
    setClicked5(false)
    setClicked6(false)
  }

  const setServices=()=>{
    setClicked1(false)
    setClicked2(false)
    setClicked3(false)
    setClicked4(false)
    setClicked5(true)
    setClicked6(false)
  }

  const setRoad=()=>{
    setClicked1(false)
    setClicked2(false)
    setClicked3(false)
    setClicked4(false)
    setClicked5(false)
    setClicked6(true)
  }







    const [modalOpen, setModalOpen] = useState(false);

  const intervalle=end_time-start_time

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  /// function calculating the sum of the state objects

  const calculateObjectSum=(obj)=> {
    let sum = 0;
    
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'number') {
          sum += obj[key];
        }
      }
    }
    
    return sum;
  }


  // a tester
  // const getTimeDistance=(array)=>{
  //   console.log(array)

  //   switch(true){
  //     case array.length>1:
  //       const start=Object.keys(array[0])[0]
  //       const end=Object.keys(array[array.length-1])[0]
  //       const temps=start-end
  //       const debut=array[0][start]['odometre']
  //       const fin=array[array.length-1][end]['odometre']
  //       const distance=debut-fin
  //       return {'temps':temps/86400,'distance':distance}
  //     case array.length==1:
  //       const start1=Object.keys(array[0])[0]
  //       const debut1=array[0][start1]['odometre']
  //       return {'temps':start1/86400,'distance':debut1}
  //     default:
  //       return{'temps':0,'distance':0}
  //   }

  // }

  const getDistance=(data)=>{
  
    const listeDistance=new Array()
    // exepense
    const array1=data.expense
    console.log(array1)
    for (let obj in array1){
      console.log(Object.values(array1[obj])[0].odometre)

      listeDistance.push(Object.values(array1[obj])[0].odometre)
    }
    // income
    const array2=data.income
    console.log(array2)
    for (let obj in array2){
      console.log(Object.values(array2[obj])[0].odometre)

      listeDistance.push(Object.values(array2[obj])[0].odometre)
    }
      // service
      const array3=data.service
      console.log(array3)
      for (let obj in array3){
        console.log(Object.values(array3[obj])[0].odometre)
  
        listeDistance.push(Object.values(array3[obj])[0].odometre)
      }
          // refuling
          const array4=data.refuling
          console.log(array4)
          for (let obj in array4){
            console.log(Object.values(array4[obj])[0].odometre)
      
            listeDistance.push(Object.values(array4[obj])[0].odometre)
          }
    console.log('ceci est la distance',listeDistance)

    const dis=Math.max.apply(null, listeDistance)-Math.min.apply(null, listeDistance)
    console.log(dis)
    switch (true){
      case listeDistance.length>1:
        const dis=Math.max.apply(null, listeDistance)-Math.min.apply(null, listeDistance)
        return dis
      case laDistance.length === 1:
        return listeDistance[0]
      default:
        return 1
    }

  }


  useEffect(()=>{
    const getData=async()=>{
      const distances=new Array()
      const formdata=new FormData()
      formdata.append("start_time",start_time)//(Date.parse(new Date())-(start_time/1000))
      formdata.append("end_time",end_time)
      formdata.append("pk",vehicule_id)
      const data=await fetchData(formdata)
      
      const expense_data=data.general_expense
      expense_data.total=calculateObjectSum(data.general_expense)
      // const timeAndDate_expense=getTimeDistance(data.expense)
      // expense_data.distance=timeAndDate_expense.distance
      // expense_data.temps=timeAndDate_expense.temps
      // distances.push(timeAndDate_expense.distance)
      // console.log('expense_data',expense_data)
      setExpense(expense_data)
      const income_data=data.general_income
      income_data.total=calculateObjectSum(data.general_income)
      // const timeAndDate_income=getTimeDistance(data.income)
      // distances.push(timeAndDate_income.distance)
      // console.log('income_data',income_data)
      setIncome(income_data)
      const refuling_data=data.general_refuling
      refuling_data.total=calculateObjectSum(data.general_refuling)
      // const timeAndDate_refuling=getTimeDistance(data.refuling)
      // refuling_data.distance=timeAndDate_refuling.distance
      // refuling_data.temps=timeAndDate_refuling.temps
      // distances.push(timeAndDate_refuling.distance)
      // console.log('refuling_data',refuling_data)
      setRefuling(refuling_data)
      const service_data=data.general_service
      service_data.total=calculateObjectSum(data.general_service)
      // const timeAndDate_service=getTimeDistance(data.service)
      // service_data.distance=timeAndDate_service.distance
      // service_data.temps=timeAndDate_service.temps
      // distances.push(timeAndDate_service.distance)
      setRoute(data.route_cost)
      setLadistance(getDistance(data))
      console.log('laDIstance',laDistance)
      console.log('current_day',current_day)
      console.log('intervalle',start_time,end_time)
      setService(service_data)
    }
    getData()
  },[vehicule_id,start_time])

const fetchData=async(data)=>{
  const request=await fetch(`${ip_address}/api/dashboard`, {
    method: 'POST',
    headers:{
        "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
    },
    body: data,
   
},)

console.log("CurrentLanguage",currentLanguage.dir)
const data1=await request.json()
return data1
}

const decoration=()=>{
  switch(currentLanguage.dir){
    case 'rtl':
      return 'Right'
    default:
      return 'Left'
  }
}

const setCentent_filter=()=>{
  switch (modal_content){
      case 'choices':
        return <div  style={{listStyle:'none'}}>{Object.keys(periode_choice).map((periode,index)=><li key={index} style={{borderBottom:"solid lightgray 1px",paddingInline:30,marginBottom:5,backgroundColor:'#ff5d70',borderRadius:20,display:'flex',flexDirection:"row",alignItems:"center",justifyContent:'center'}}>
          <button className='btn' style={{width:"100%" ,fontFamily:"Airstrike"}} onClick={()=>{setStart_time(current_day-periode_choice[periode]);setDurée(periode);handleCloseModal()}}>{t(periode)}</button> </li>)}
        <li style={{borderBottom:"solid lightgray 1px",paddingInline:30,marginBottom:5,backgroundColor:'#ff5d70',borderRadius:20,display:'flex',flexDirection:"row",alignItems:"center",justifyContent:'center'}}><button className='btn' style={{width:"100%" ,fontFamily:"Airstrike"}} onClick={()=>{setModal_content('custom')}}>{t('Custom')}</button> </li>
        <button className='btn' style={{width:"50%" ,fontFamily:"Airstrike",backgroundColor:"#df1d34",borderRadius:20,marginLeft:"25%",color:"white"}} onClick={()=>{handleCloseModal()}}>{t('CLOSE')}</button> 
        </div>
      case 'custom':
        return    <div className='row m-4'>
        <div class="col-md-12">
          <label for="inputZip" class="form-label">{t("Start time")}</label>
           <input type="datetime-local" class={`form-control text-${mode}`} id="start_time" onChange={(e)=>setStart_time(Date.parse(e.target.value)/1000)} style={{backgroundColor:mode==="dark"?"gray":"#FFF"}}/>
          </div>
          <div class="col-md-12">
          <label for="inputZip" class="form-label">{t("End time")}</label>
           <input type="datetime-local" class={`form-control text-${mode}`} id="end_time" onChange={(e)=>setEnd_time(Date.parse(e.target.value)/1000)} style={{backgroundColor:mode==="dark"?"gray":"#FFF"}}/>
          </div>
          <div class="col-md-12 dFlex mt-2" >
         <div className='btn btnOutline' onClick={()=>setModal_content('choices')}>{t("Go back to choises")}</div>
          </div>
        </div>
  }
}






  return (
    <section className={`all_sections mt-4 ${currentLanguage.dir || 'ltr'}`}>
        <div className={`content-${mode}`}>
      <div className={`row  graphs_${mode}`}  style={{width:"90%"}}>
      <div className='col-3' style={currentLanguage.dir==='ltr'?{borderRight:"3px solid gray"}:{borderLeft:"3px solid gray"}}>
      <div id='report-sidebar' dir={`${currentLanguage.dir}`}>
        <Link to={'/repport/all'}  className={clicked1?` reportHoverBorder${decoration()}Clicked text-${mode}`:` reportHoverBorder${decoration()} text-${mode}`} style={{display: 'flex', flexDirection: "row", justifyContent: 'flex-start', alignItems: 'center',width:"100%",marginTop:'30px',textDecoration:"none" }} onClick={()=>{setTitle(t('General'));setGeneral()}}>
          <div className='report-sidebar-icon'>

          <img src={rapportGeneral} style={{"width":"30px","height":"100%",marginRight:10}}/>

            {/* <IconContext.Provider value={{ style: { fontSize: "25px"}, className: "global-class-name" }}>
                    <div>
                    <AiOutlineAreaChart />
                    </div>
                  </IconContext.Provider> */}

            </div>
          <div className='report-sidebar-title' style={{marginRight:5}}>{t("Genaral")}</div>
        </Link>
        <Link to={'/repport/refulings'} className={clicked2?` reportHoverBorder${decoration()}Clicked text-${mode}`:` reportHoverBorder${decoration()} text-${mode}`} style={{display: 'flex', flexDirection: "row", justifyContent: 'flex-start', alignItems: 'center',width:"100%",marginTop:'30px',textDecoration:"none" }}  onClick={()=>{setTitle(t('Refueling'));setRefueling()}}>
        <div className='report-sidebar-icon'>

        {/* <IconContext.Provider value={{ style: { fontSize: "25px"}, className: "global-class-name" }}>
                    <div>
                    <FaGasPump/>
                    </div>
                  </IconContext.Provider> */}
        <img src={reportRefueling} style={{"width":"30px","height":"100%",marginRight:10}}/>
        </div>
          <div className='report-sidebar-title' style={{marginRight:5}}>{t("Refueling")}</div>
        </Link>
        <Link to={'/repport/expenses'} className={clicked3?` reportHoverBorder${decoration()}Clicked text-${mode}`:` reportHoverBorder${decoration()} text-${mode}`} style={{display: 'flex', flexDirection: "row", justifyContent: 'flex-start', alignItems: 'center',width:"100%",marginTop:'30px',textDecoration:"none" }}   onClick={()=>{setTitle(t('Expense'));setExpenses()}}>
        <div className='report-sidebar-icon'>
        
        <img src={reportExpense} style={{"width":"30px","height":"100%",marginRight:10}}/>

          {/* <IconContext.Provider value={{ style: { fontSize: "25px"}, className: "global-class-name" }}>
                    <div>
                    <GiPayMoney/>
                    </div>
                  </IconContext.Provider> */}
          
          </div>
          <div className='report-sidebar-title' style={{marginRight:5}}>{t("Expense")}</div>
        </Link>
        <Link to={'/repport/incomes'} className={clicked4?` reportHoverBorder${decoration()}Clicked text-${mode}`:` reportHoverBorder${decoration()} text-${mode}`} style={{display: 'flex', flexDirection: "row", justifyContent: 'flex-start', alignItems: 'center',width:"100%",marginTop:'30px',textDecoration:"none" }}  onClick={()=>{setTitle(t('Income'));setIncomes()}}>
        <div className='report-sidebar-icon'>
        {/* <IconContext.Provider value={{ style: { fontSize: "25px"}, className: "global-class-name" }}>
                    <div>
                    <GiReceiveMoney/>
                    </div>
                  </IconContext.Provider> */}
                  <img src={rapportIncome} style={{"width":"30px","height":"100%",marginRight:10}}/>
          
          </div>
          <div className='report-sidebar-title' style={{marginRight:5}}>{t("Income")}</div>
        </Link>
        <Link to={'/repport/services'} className={clicked5?` reportHoverBorder${decoration()}Clicked text-${mode}`:` reportHoverBorder${decoration()} text-${mode}`} style={{display: 'flex', flexDirection: "row", justifyContent: 'flex-start', alignItems: 'center',width:"100%",marginTop:'30px',textDecoration:"none" }}  onClick={()=>{setTitle(t('Service'));setServices()}}>
        <div className='report-sidebar-icon'>
          
        {/* <IconContext.Provider value={{ style: { fontSize: "25px"}, className: "global-class-name" }}>
                    <div>
                    <MdOutlineMiscellaneousServices />
                    </div>
                  </IconContext.Provider>*/}
<img src={reportService} style={{"width":"30px","height":"100%",marginRight:10}}/>


                  </div> 

          <div className='report-sidebar-title' style={{marginRight:5}}>{t("Service")}</div>
        </Link>
        <Link to={'/repport/routings'} className={clicked6?` reportHoverBorder${decoration()}Clicked text-${mode}`:` reportHoverBorder${decoration()} text-${mode}`} style={{display: 'flex', flexDirection: "row", justifyContent: 'flex-start', alignItems: 'center',width:"100%",marginTop:'30px',textDecoration:"none" }}  onClick={()=>{setTitle(t('Road'));setRoad()}}>
        <div className='report-sidebar-icon'>
          
        {/* <IconContext.Provider value={{ style: { fontSize: "25px"}, className: "global-class-name" }}>
                    <div>
                    <GiMountainRoad/>
                    </div>

                  </IconContext.Provider> */}
                  <img src={reportRoute} style={{"width":"30px","height":"100%",marginRight:10}}/>

                  </div>
          <div className='report-sidebar-title' style={{marginRight:5}}>{t("Road")}</div>
        </Link>
      </div>
      </div>
      <div className='col-8'>
      <div className='row' style={{
               borderBottom: "1px solid rgb(212, 212, 212)"
            }}>
                <div className='col-6' style={{}}>
                    <div style={{display: 'flex', flexDirection: "row", justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div style={{display: 'flex', flexDirection: "column", justifyContent: 'flex-start', alignItems: 'center' }}>
                       <h3 style={{fontFamily:'AirStrike',marginBottom:-5}}>{t(title)}</h3>
                       <p  style={{marginBottom:0}}>{`${t("Last ")}${Math.floor(intervalle/86400)}${t(" Days")}`}</p>
                       </div>
                      {/* <div onClick={()=>handleOpenModal()}>
                        <IconContext.Provider value={{ style: { fontSize: "25px",marginLeft:10}, className: `global-class-name` }}>
                    
                        <FaFilter />
                    
                  </IconContext.Provider>
                   </div> */}
                    </div>
                    <Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setCentent_filter}/>
                </div>
                <div className='col-6' >
                  <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-end', alignItems: 'center',marginTop:10 }}>
                  <div onClick={()=>handleOpenModal()}>
                        <IconContext.Provider value={{ style: { fontSize: "25px",marginLeft:10}, className: `global-class-name` }}>
                    
                        <FaFilter />
                    
                  </IconContext.Provider>
                   </div>
                       </div></div>
                
            </div>
            <NativeAdReport />
            <Outlet context={[route,expense,income,refuling,service,filter_choice,laDistance,intervalle]} /> 
      </div>
      </div>
     </div>

    </section>
  )
}

