import React,{useEffect} from 'react';
import './NotFound.css'; // Import CSS file for styling
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const {t}=useTranslation()
    useEffect(() => {
        const gotoloinPage=()=>{
            window.location.href = "https://accounts.bfdrive.ma"
        }
      setTimeout(gotoloinPage(),6000)
      }, [])
  return (
    <div className="not-found-container">
      <div className="not-found-content">
      <img src={`https://web.bfdrive.ma/media/logo.png`} id='logo' style={{width:"70%",height:"70%",marginBottom:-180,marginTop:-150}}/>
        <h1 style={{fontFamily:"Airstrike"}}>404</h1>
        <h4  style={{fontFamily:"Airstrike"}}>{t("Oops! The page you're looking for cannot be found.")}</h4>
        <p>{t("It seems like you're lost in cyberspace...")}</p>
        {/* <a href="/">Go back to homepage</a> */}
      </div>
    </div>
  );
};

export default NotFound;
