import React,{useState} from 'react'
import dateFormat from "dateformat"
import { useDispatch,useSelector } from 'react-redux'
import { UPDATE_PROFILE } from '../../../actions/types'
import { useTranslation } from 'react-i18next'
import { Modal2 } from '../Modals/Modal2'


export const ReminderSetting = () => {
  const {t}=useTranslation()
  const dispatch= useDispatch()
  const current_profile = useSelector((state) => state.auth.profile)
  const current_dateFormat = useSelector((state) => state.auth.profile.time_format)
  const [distance,setDistance]=useState()
  const [days,setDays]=useState()
  const mode = useSelector(state => state.auth.mode)


//   const float_formats={0:"without digits",
//                         2:"add two decimal digits",
//                       3:"three decimal degitis"}
  const updateProfile = (data) => {

    return function (dispatch) {
      fetch(current_profile.url, {
        headers: {
          'content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        },
        method: 'PATCH',
        body: JSON.stringify(data),

      },

      ).then(resp => resp.json()).then(profile => {
        if (profile.hasOwnProperty('avatar')) {
          dispatch({
            type: UPDATE_PROFILE,
            payload: profile
          })
        } else {
          alert('something went wrong pleaze try again later')
        }
      })
    }
  }

  const setDaysInAdvance=(e)=>{
    e.preventDefault()
    const data = {
      'days_in_advance': days
    }
  
    dispatch(updateProfile(data))
    // handleCloseModal1()
  }

  const setDistanceInAdvance=(e)=>{
    e.preventDefault()
    const data = {
      'distance_in_advance': distance
    }
  
    dispatch(updateProfile(data))
    // handleCloseModal()
  }

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
   setModalOpen(true);
 };
 
 const handleCloseModal = () => {
   setModalOpen(false);
 };
  const [modalOpen1, setModalOpen1] = useState(false);
  const handleOpenModal1 = () => {
   setModalOpen1(true);
 };
 const handleCloseModal1 = () => {
  setModalOpen1(false);
};

  const setCententDistance=()=>{
   return ( <form onSubmit={setDistanceInAdvance} >

    <input className='form-control mb-2' onChange={(e)=>{setDistance(e.target.value)}} value={distance} placeholder={`${t("Distance in advance")} : ${current_profile.distance_in_advance} ${t("KM")}`}/>
    <button className='btnOutline form-control' type='submit' style={{color:"white"}}>{t("submit")}</button>
   </form>)
  }

  const setCententDays=()=>{
   return( <form onSubmit={setDaysInAdvance} >

    <input className='form-control mb-2' onChange={(e)=>{setDays(e.target.value)}} value={days} placeholder={`${t("Days in advance")} : ${current_profile.days_in_advance} days`}/>
    <button className='btnOutline form-control' type='submit' style={{color:"white"}}>{t("submit")}</button>
   </form>)
  }


  return (

    <section className='general'>
   
<button type="button" class="btnOutline m-4" onClick={handleOpenModal1}>
{t("Days in advance")} : {current_profile.days_in_advance} {t("Day")}
</button>



<button type="button" class="btnOutline m-4"onClick={handleOpenModal}>
 {t("Distance in advance")} : {current_profile.distance_in_advance} Km

</button>

<Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setCententDistance}/>
<Modal2   mode={mode} isOpen={modalOpen1} onClose={handleCloseModal1}  setContent={setCententDays}/>


     
    </section>
  )
}
