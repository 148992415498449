import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import './Accueil.css'
import { useTranslation } from 'react-i18next'
import i18next from "i18next";
import cookies from 'js-cookie'

export const Accueil = ({languages,ip_address}) => {
    const {t}=useTranslation()

      // hendeling language change and orientaition

  const [currentLanguageCode,setCureentLanguagesCode]=useState(cookies.get('i18next') || 'en')
  const currentLanguage=languages.find(l => l.code===currentLanguageCode)
  useEffect(()=>{
    document.body.dir=currentLanguage.dir || 'ltr'


   },[currentLanguage])

   ////////////////////end

/////////////////////////////////// contact message

const [sender_name,setSender_name]=useState('')
    const [ email,setEmail]=useState('')
    const [message,setMessage]=useState('')

    const onSubmit=async(e)=>{
        e.preventDefault()
        const formdata= new FormData()
        formdata.append('sender_name',sender_name)
        formdata.append("email",email)
        formdata.append("message",message)
        sendContact(formdata)
    }


    const sendContact=async(data)=>{
        const request=await fetch(`${ip_address}/api/contact`,{
            method:'post',
            body:data
        })

        switch(request.status){
            case 201:
                const response=await request.json() 
                alert(response.message)
                break

            case 400:
                alert('message is not sent \n please try again')
                break
        }
    }

/////////////////////////////////// fin contact message
  return (
    <div>
      <div>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top" style={{width:"100%",marginLeft:0}}>
        <div class="container">
            <a href="#" class="navbar-brand"><img src="/media/logo.svg" alt="" style={{width:80}}/></a>
            <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#myMenu1">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="myMenu1">
                <ul class="navbar-nav ml-auto">
                <li className='dropdown nav-item' style={{ "display": "flex", "justifyContent": "flex-start", "alignItems": "center" }}>
          <a data-bs-toggle="dropdown" className="dropdown-toggle nav-link" style={{"cursor":"pointer"}}><span className={`fi fi-${currentLanguage.country_code}`} style={{"marginRight":"auto"}}></span></a>  
            <ul className='dropdown-menu'>
              <div  style={{display: 'flex',flexDirection: 'column',alignItems: 'flex-start',padding: '10px'}}>
            {languages.map(({ code, name, country_code }) => (
                <li key={country_code}>
                  <a  onClick={() => {
                    i18next.changeLanguage(code);
                    setCureentLanguagesCode(code)
                    }} style={{"cursor":"pointer"}}>
                      <span className={`fi fi-${country_code}`} style={{"marginRight":"auto"}}></span> {name}
                  </a>
                </li>
              ))}</div>
            </ul>
          </li>
                    <li class="nav-item"><Link to={'/login'} className='nav-link'>{t("Login")}</Link></li>
                    <li class="nav-item"><Link to={'/register'} className='nav-link'>{t("subscribe")}</Link></li>
                    <li class="nav-item"><a href="#share" class="nav-link">{t("contact us")}</a></li>
                </ul>
            </div>
        </div>
    </nav>
  
    <section id="branding">
        <div class="dark-overlay">
            <div class="container py-5 text-white text-center">
                <div class="row">
                    <div class="col">
                        <h2 class="display-6 responsive">{t("WELLCOM")} BFDRIVE</h2>
                        <p  id="test1">{t("Manage your Expenses")}</p>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <a href="#" class="btn btn-danger">{t("my channel")}</a>
            </div>
        </div>
    </section>

    <section id="owasp" class="py-5 bg-light">
        <div class="container">
            <div class="row">
                <div class="col-md-8 text-center">
                    <h4 style={{fontFamily:'Airstrike'}}>BFDRIVE {t("in One Word")}</h4>
                    <p>{t("Introducing our all-in-one vehicle expenses management website! Streamline your fleet's financials effortlessly with our user-friendly platform. Track fuel consumption, maintenance costs, and more in real-time. Optimize your  fleet's performance and save on expenses. Our website is SEO-optimized to ensure maximum visibility, attracting more potential clients. Stay ahead in the  competitive market with our comprehensive vehicle management solution.  Take control of your fleet's finances today!")}
                    </p>
                </div>
                <div class="col-md-4 text-center" >
                <img src="/media/logo.png" class="img-fluid rounded-circle img1" alt="" style={{width:300,height:250}}/>
                    
                </div>
            </div>
        </div>
    </section>
  
    <section id="netsec" class="py-5 bg-danger">
        <div class="container">
            <div class="row">
                <div class="col-md-4 text-center">
                    <img src="/media/car2.jpg" class="img-fluid rounded-circle img2" alt=""  style={{width:300,height:250}} />
                </div>
                <div class="col-md-8 py-5 text-white text-center">
                    <h4 style={{fontFamily:"Airstrike"}}>{t("Manage your car for free")} </h4>
                    <p>{t("Experience hassle-free car expense management with our complimentary single car plan! Take full advantage of our user-friendly platform to track fuel costs, maintenance expenses, and more for one vehicle. Stay organized and in control of your car's financials with ease. Sign up now and enjoy the benefits of efficient car expense management at no cost!")}
                    </p>
                </div>
                
            </div>
        </div>
    </section>
     
     <section id="netmon" class="py-5 bg-light">
        <div class="container">
            <div class="row">
                <div class="col-md-8 text-center">
                    <h4 style={{fontFamily:'Airstrike'}}>{t("Manage you fleet")}</h4>
                    <p>{t("Optimizing a fleet of vehicles brings cost savings, enhanced productivity, and improved safety. Real-time tracking, data-driven decisions, and maintenance optimization ensure efficiency and customer satisfaction. Stay compliant with regulations while reducing environmental impact. Fleet management is a valuable investment for businesses seeking streamlined operations and a competitive edge.")}
                    </p>
                </div>
                <div class="col-md-4 text-center">
                <img src="/media/fleet.jpg" class="img-fluid rounded-circle img1" alt="" style={{width:300,height:250}} />
                </div> 
            </div>
        </div>
    </section>
   
    <section id="share" class="my-0" >
        <div class="dark-overlay">
        <div class="container">
            <form onSubmit={onSubmit}>
                <h3 class="text-center text-white pt-4 display-4 responsive"> {t("Keep in Touch")}</h3>
                    <div class="row">
                            <div class="col-md-6 my-3">
                                <input type="text" name="sender_name" id="firstname" class="form-control" placeholder={t("firstname")}  onChange={(e)=>setSender_name(e.target.value)} value={sender_name}  required/>
                            </div>
                            <div class="col-md-6 my-3">
                                <input type="email" name="email" class="form-control" placeholder={t("email")} onChange={(e)=>setEmail(e.target.value)} value={email}  required/>
                            </div>
                            <div class="col-md-12 my-3">
                            <textarea className={`form-control`} placeholder="message" onChange={(e)=>setMessage(e.target.value)} value={message}  required />
                            </div>
                            <div class="col-md my-3">
                                <button type="submit" class="btn btn-danger btn-lg form-control">{t("valider")}</button>
                            </div>
                    </div>
            </form>
        </div>
    </div>
    </section>
 
    <footer class="py-3 bg-dark" id="foo">
        <div class="container text-center text-white">
            <h3>copyright&copy;2021</h3>
        </div>
    </footer>

    
    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js" integrity="sha384-ZMP7rVo3mIykV+2+9J3UJ46jBk0WLaUAdn689aCwoqbBJiSnjAK/l8WvCWPIPm49" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js" integrity="sha384-ChfqqxuZUCnJSK3+MXmPNIyE6ZbWh2IMqE241rYiqJxyMiZ6OW/JmZQ5stwEULTy" crossorigin="anonymous"></script>
</div>
     
    </div>
  )
}
