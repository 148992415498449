import React,{useState} from 'react'
import '../../stylings/Styles.css'
import { useSelector } from 'react-redux'
import { Link,useNavigate } from 'react-router-dom'
import {AiFillDashboard} from 'react-icons/ai'
import { Modal } from '../Modals/Modal'
import { useTranslation } from 'react-i18next'


// import 'boxicons'

export const FooterBar = ({currentLanguage}) => {

  const {t}=useTranslation()
    const navigate=useNavigate()
    const [showModal, setShowModal] = useState("none")
    const mode=useSelector(state=>state.auth.mode)



    // show or hide the modal

    const toggleShow = () => {
        
        if (showModal === "none") {
        
          setShowModal("block")
        } else {
          setShowModal("none")
        }
      }

      // hide modal after choice
      const surf=(url)=>{
        navigate(url)
        setShowModal('none')

      }

    
         
      if (showModal=='block') {
        document.getElementById('myModalAdd').addEventListener("click",toggleShow);
      }
        //  else {
        //   document.removeEventListener("click", toggleShow);
        // }
      
 

      const setContent_add=()=>{
        return(<ul className="list-group ">
        <li className={` ${mode}`} style={{display:'block' ,padding:"0.5rem 1rem"}}><a onClick={()=>surf('/refulingform')} className="modalButtons"><img src={`https://web.bfdrive.ma/media/icons/gas-pump.svg`} style={{"width":"30px","height":"100%",marginRight:5}}/><span className={`ModalForms-${mode}`} style={{marginRight:5}}>{t("Refueling")}</span></a></li>
        <li className={` ${mode}`} style={{display:'block' ,padding:"0.5rem 1rem"}}><a onClick={()=>surf('/serviceform')} className="modalButtons" ><img src={`https://web.bfdrive.ma/media/icons/wrench.svg`} style={{"width":"30px","height":"100%",marginRight:5}}/><span className={`ModalForms-${mode}`} style={{marginRight:5}}>{t("Service")}</span></a></li>
        <li className={` ${mode}`} style={{display:'block' ,padding:"0.5rem 1rem"}}><a onClick={()=>surf('/expenseform')} className="modalButtons"><img src={`https://web.bfdrive.ma/media/icons/credit-card.svg`} style={{"width":"30px","height":"100%",marginRight:5}}/><span className={`ModalForms-${mode}`} style={{marginRight:5}}>{t("Expense")}</span></a></li>
        <li className={` ${mode}`} style={{display:'block' ,padding:"0.5rem 1rem"}}><a onClick={()=>surf('/incomeform')} className="modalButtons"><img src={`https://web.bfdrive.ma/media/icons/wallet.svg`} style={{"width":"30px","height":"100%",marginRight:5}}/><span className={`ModalForms-${mode}`} style={{marginRight:5}}>{t("Income")}</span></a></li>
        <li className={` ${mode}`} style={{display:'block' ,padding:"0.5rem 1rem"}}><a onClick={()=>surf('/routeform')} className="modalButtons"><img src={`https://web.bfdrive.ma/media/icons/road.svg`} style={{"width":"30px","height":"100%",marginRight:5}}/><span className={`ModalForms-${mode}`} style={{marginRight:5}}>{t("Route")}</span></a></li>
        <li className={` ${mode}`} style={{display:'block' ,padding:"0.5rem 1rem"}}><a onClick={()=>surf('/reminderForm')} className="modalButtons"><img src={`https://web.bfdrive.ma/media/icons/stopwatch.svg`} style={{"width":"30px","height":"100%",marginRight:5}}/><span className={`ModalForms-${mode}`} style={{marginRight:5}}>{t("Reminder")}</span></a></li>
      </ul>)
      }
    return (
        <footer className={`footer fixed-bottom bg-${mode}`} >

         
                    <ul className='footer-menu' style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',listStyleType:'none'}}>
                        <li className='icons' style={{color:"black"}}><Link to='/dashboard/overview'><AiFillDashboard className='bx' style={{color:"#BD3136",fontSize:30,textDecoration:'none'}}/></Link></li>
                        <li className='icons'><Link to='/timeline'><i class='bx bxs-dashboard' style={{color:"#BD3136",fontSize:30,textDecoration:'none'}}></i></Link></li>
                        <li className='icons'><Link to='/reminder'><i class='bx bx-stopwatch' style={{color:"#BD3136",fontSize:30,textDecoration:'none'}}></i></Link></li>
                        <li className='icons'><Link to='/repport'><i class='bx bx-line-chart' style={{color:"#BD3136",fontSize:30,textDecoration:'none'}}></i></Link></li>
                        <li className='icons'><a onClick={()=>toggleShow()} style={{ "cursor": "pointer" }}><i class='bx bxs-plus-circle' style={{color:"#BD3136",fontSize:30,textDecoration:'none'}}></i></a> </li>
                        <li className='nav-link icons'><Link to='/parametres/plans'><i class='bx bxs-cog' style={{color:"#BD3136",fontSize:30,textDecoration:'none'}}></i></Link></li>
                    </ul>
    

                    <Modal modal={'Add'} mode={mode} showModal={showModal} toggleShow={toggleShow} setContent={setContent_add} currentLanguage={currentLanguage}/>
        </footer>
    )


}
