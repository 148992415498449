import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import "./Dashboard.css"
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {  Outlet } from 'react-router-dom';
import { DashboradCard } from './DashboradCards';
import { useSelector } from 'react-redux';







export const Dashboard = ({ip_address,currentLanguage}) => {

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDate;
  };
  const {t}=useTranslation()
  const [start_time,setStart_time]=useState((Date.parse("1970-12-01T00:00")/ 1000))
  const [end_time,setEnd_time]=useState(Date.parse(new Date())/1000)
  const [start_time1,setStart_time1]=useState("2018-12-01T00:00")
  const [end_time1,setEnd_time1]=useState(getCurrentDate())  
  const mode= useSelector(state=>state.auth.mode)


  const startTime=(val)=>{
    setStart_time((Date.parse(val)/ 1000))
    setStart_time1(val)
  }

  const endTime=(val)=>{
    setEnd_time((Date.parse(val)/ 1000))
    setEnd_time1(val)
  }

 


    
  return (
   <section className={`all_sections mt-4 ${currentLanguage.dir || 'ltr'}`}>
    <div id={`sub-dashboard`} className={`content-${mode}`} style={{margin:"auto"}}>
      <div className={`dashboard-filter_${mode} m-3`}>
    <div className='row m-3' >
    <div class="col-md-6" style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
      <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
      <label for="inputZip" class="form-label" style={{fontSize:12,fontWeight:"bold",marginBottom:0}}>{t("Start time")}</label>
       <input type="datetime-local" class={`form-control ${mode}`} id="start_time" onChange={(e)=>startTime(e.target.value)} value={start_time1} />
       </div>
      </div>
      <div class="col-md-6" style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
      <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
      <label for="inputZip" class="form-label" style={{fontSize:12,fontWeight:"bold",marginBottom:0}}>{t("End time")}</label>
       <input type="datetime-local" class={`form-control ${mode}`} id="end_time" onChange={(e)=>endTime(e.target.value)} value={end_time1} />
       </div>
      </div>
    </div>
    </div>
     <div className='row m-4' style={{width:"90%"}}>
     <div className='col-md-4 mt-1'>
        <DashboradCard link={'/dashboard/overview'} icon={'vehicle'} title={t('Overview')} color={'green'} mode={mode}/>
      </div>
   
      <div className='col-md-4 mt-1'>
        <DashboradCard link={'/dashboard/situation'} icon={'storage'} title={t('Situation')} color={'darkblue'} mode={mode}/>
      </div>
      <div className='col-md-4 mt-1'>
        <DashboradCard link={'/dashboard/Reminders&Routes'} icon={'routes'} title={t('Reminders/Routes')} color={'gray'} mode={mode}/>
      </div>

     
     </div>
     <div className='row m-auto' style={{minHeight:'300px',width:'90%',marginTop:'20px'}}>

        <div className='col' style={{marginInline:"auto"}}>
        <Outlet context={[start_time,end_time,start_time1,end_time1]} />
        {/* */}
        </div>
     </div>
</div>
   </section>
  )
}
