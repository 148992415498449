import React,{useState,useEffect} from 'react'
import { useOutletContext,useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TableRowReminder } from './TableRowReminder'
import { TableRowRoute } from './TableRowRoute'


export const DashboardGeneral2 = ({ip_address}) => {

    const {t}=useTranslation()
    const[start_time,end_time]=useOutletContext() 
    const navigate=useNavigate()
    const [reminders,setReminders]=useState([])
    const [routes,setRoutes]=useState([])
    const mode=useSelector(state=>state.auth.mode)
    const vehicule_id=useSelector(state=>state.vehicules.item.id)
    const profile=useSelector(state=>state.auth.profile)
    const [color,setColor]=useState("")
    const time_format=profile.time_format
    const currency_format=profile.currency_format

   
      



    useEffect(()=>{
        const getDashboardItems=async()=>{
          const formdata=new FormData()
          formdata.append("start_time",start_time)
          formdata.append("end_time",end_time)
          formdata.append("pk",vehicule_id)
          const data=await fetchDashboradItems(formdata)
          setReminders(data.reminder)
          setRoutes(data.route)
          console.log(data)
    
        }
    
        getDashboardItems()
    
      },[vehicule_id,start_time,end_time])
    
      const fetchDashboradItems=async(data)=>{
        const request=await fetch(`${ip_address}/api/dashboard`, {
          method: 'POST',
          headers:{
              "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
          },
          body: data,
         
      },)
    
      console.log(request)
      const data1=await request.json()
      return data1
      }

      // delete route 

      const deleteRoute=async(url)=>{

        const action=await fetch(url,{
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            method:'DELETE',
         })
         const status=action.status

         switch(status){
            case 204:
              const new_items=routes.filter(item=>Object.values(item)[0].url !== url)
               setRoutes(new_items)
                break
            case 403:
                alert('this is not your item')
                break
         
     }  
 }

//  delete reminder

// const deleteRimeder=async (url)=>{
//   const request =await fetch(url,{
//     headers:{
//         "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
//     },
//     method:'DELETE',
//  })
//  const status=request.status
//  switch(status){
//     case 204:
//       console.log('reminder deleted')
//       const new_items=reminders.filter(item=>Object.values(item)[0].url !== url)
//        setReminders(new_items)
//         break
//     case 403:
//         alert('this is not your item')
//         break
 
// }  


// }

const dat=(url)=>{
  const data=url.split('/')
  return `/edit_reminder/${data[data.length -2]}`


} 
  return (
    <div  style={{width:"90%"}}>
      <div style={{fontFamily:"AirStrike",fontSize:20}}>{t("Reminders")}</div>
        <div className='row'>
   
        
        <table className={`table graphs_${mode} text-${mode}`} >
    <thead>
      <tr>
       
        <th scope="col" > <span  className={`text-${mode}`}> {t("Type")}</span></th>
        <th scope="col" className='removable'> <span  className={`text-${mode}`}>{t("Date")}</span> </th>
        <th scope="col"  className='removable'> <span  className={`text-${mode}`}>{t("Odometer")}</span> </th>
        <th scope="col" > <span  className={`text-${mode}`}>{t("Expires")}</span> </th>
        <th scope="col" > <span  className={`text-${mode}`}>{t("Actions")}</span> </th>
      </tr>
    </thead>
    <tbody>
    {reminders.map((reminder,index)=>(<TableRowReminder index={index} type={Object.values(reminder)[0].type} 
    date={Object.values(reminder)[0].creation_date} odometre={Object.values(reminder)[0].odometre_rappel} mode={mode}
    expires={Object.values(reminder)[0].rappel_date} url={Object.values(reminder)[0].url} reminders={reminders} setReminders={setReminders}/>
    
//     <tr key={index}>
        
//         <td>{Object.values(reminder)[0].type}</td>
//         <td  className='removable'>{(dateFormat(Object.values(reminder)[0].creation_date,`${time_format}  HH:MM`))}</td>
        
//         <td className='removable'>{Object.values(reminder)[0].odometre_rappel?Object.values(reminder)[0].odometre_rappel:'-'}</td>
//         <td>{Object.values(reminder)[0].rappel_date?calculateTimeDifference(Object.values(reminder)[0].rappel_date):'-'}</td>
//         <td>
//             {/* <button className='btn btn-danger'>delete</button>
//             <button className='btn btn-success'>modify</button> */}

// <div style={{"display":"flex","justifyContent":"center"}}>
//         <IconContext.Provider value={{ style: { color: "red", fontSize: "20px","marginRight":"20px",cursor:"pointer" }, className: "global-class-name" }} >
//                           <div onClick={()=>deleteRimeder(Object.values(reminder)[0].url)}>
//                           <MdDeleteForever/>
//                           </div>
//                         </IconContext.Provider>
             
//             <IconContext.Provider value={{ style: { color: "green", fontSize: "20px",cursor:"pointer" }, className: "global-class-name" }}>
//                           <div onClick={()=>navigate(dat(Object.values(reminder)[0].url))}>
//                           <FaPen/>
//                           </div>
//                         </IconContext.Provider>
//            </div>
//         </td>
//       </tr>
      
      ))}
   
    </tbody>
  </table>
        </div>
        {/* <div className='row'>
        <div className='col-6'>
            {t("users")}/{t("vehicule")}
        </div>
        <div className='col-6'>
            {t("curent")} {t("vehicule")}
        </div>
        </div> */}
        <div style={{fontFamily:"AirStrike",fontSize:20,marginTop:60}}>{t("Routes")}</div>
        <div className='row'>
        <table class={`table graphs_${mode} text-${mode}`} >
  <thead>
    <tr>
      <th scope="col">{t("Road")}</th>
      <th scope="col">{t("Distance")}</th>
      <th scope="col" className='removable'>{t("By highway")}</th>
      <th scope="col">{t("Handle")}</th>
    </tr>
  </thead>
  <tbody>
  {routes.map((route,index)=><TableRowRoute index={index} mode={mode} origin={Object.values(route)[0].origin} destination={Object.values(route)[0].destination}
  end_odometre={Object.values(route)[0].end_odometre} start_odometre={Object.values(route)[0].start_odometre} autoRoute={Object.values(route)[0].autoRoute}
  prixAuto_route={Object.values(route)[0].prixAuto_route} url={Object.values(route)[0].url} deleteRoute={deleteRoute}/>
  //  <tr >
  //       <th scope="row" key={index}>{Object.values(route)[0].origin}<ImArrowRight/>{Object.values(route)[0].destination}</th>
  //       <td>{Object.values(route)[0].end_odometre - Object.values(route)[0].start_odometre}</td>
  //       <td className='removable'>{Object.values(route)[0].autoRoute?`par autoroute à ${Object.values(route)[0].prixAuto_route} ${currency_format}`:'par voie normale'}</td>
  //       <td>
  //           <div style={{"display":"flex","justifyContent":"center"}}>
  //       <IconContext.Provider value={{ style: { color: "red", fontSize: "20px","marginRight":"20px",cursor:"pointer" }, className: "global-class-name" }} >
  //                         <div onClick={()=>deleteRoute(Object.values(route)[0].url)}>
  //                         <MdDeleteForever/>
  //                         </div>
  //                       </IconContext.Provider>
             
  //           <IconContext.Provider value={{ style: { color: "green", fontSize: "20px",cursor:"pointer" }, className: "global-class-name" }}>
  //                         <div onClick={()=>navigate(`/edit_route/${((Object.values(route)[0].url).split('/'))[((Object.values(route)[0].url).split('/')).length -2]}`)}>
  //                         <FaPen/>
  //                         </div>
  //                       </IconContext.Provider>
  //          </div>
  //            </td>
  //     </tr>
      )}
  </tbody>
</table>
        </div>
    </div>
  )
}
