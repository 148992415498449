import React,{useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { loadUser } from '../../actions/auth'
import { Landing } from '../Landing'
// import {First} from '../FirstApp/First'
import { MainApp } from '../FirstApp/MainApp'
import { Waiting } from './Waiting'
import { Register } from '../Registrations/Register/Register'




export const PrivateRoute = () => {
   
    const auth = useSelector((state) => state.auth)
   

    const dispatch=useDispatch()
  

    
     const languages = [
      {
        code: 'fr',
        name: 'Français',
        country_code: 'fr',
        dir: 'ltr',
      },
      {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'ma',
      },
  
      {
        code: 'en',
        name: 'english',
        dir: 'ltr',
        country_code: 'us',
      }
    ]


 
    useEffect(()=>{
  
     dispatch(loadUser(auth.access_token))
     },[auth.isLoading])


  return (
<div>
  {auth.isLoading ?<Waiting />:(auth.isAuthenticated ?<MainApp access_token={auth.access_token} languages={languages}/> : <Landing languages={languages}/>)}

   </div>
     

) }
export default PrivateRoute
