import { FETCH_VEHICULES,SET_VEHICULE,ADD_VEHICULE, DELETE_VEHICULE,UPDATE_VEHICULE ,UPDATE_OWN_VEHICULE, VEHICULE_PARTIAL_UPDATED,LOGOUT_VEHICULE} from "../actions/types";

const initialState={
    items:[],
    item:{},
    choosed_item:localStorage.getItem('choosed_Vehicule')
}

export default  function(state=initialState ,action){
    
    switch( action.type){
        case FETCH_VEHICULES:
            console.log('fetching vehicules')
            const data= action.payload.results
            if(data)
           { localStorage.setItem('choosed_Vehicule', data[0].vehicule_name)
            return {
                ...state,
                items:data,
                item:data[0],
                choosed_item:data[0].vehicule_name
            }}else{
                return {
                    ...state,
                }
            }
        
        case SET_VEHICULE:
            console.log('fetching vehicules')
            localStorage.setItem('choosed_Vehicule',action.payload.vehicule_name)
            return {
                ...state,
                item:action.payload,
                choosed_item:action.payload.vehicule_name
            }

        case ADD_VEHICULE:
            
            console.log('adding vehicules')
           
            localStorage.setItem('choosed_Vehicule',action.payload.vehicule_name)
            return {
                ...state,
                items:[...state.items,action.payload],
                item:action.payload,
                choosed_item:action.payload.vehicule_name
            }
        case UPDATE_VEHICULE:
        
            console.log('updating vehicule')
            
            // localStorage.setItem('choosed_Vehicule',action.payload.vehicule_name)
            return {
                ...state,
                items:[...state.items.filter(item=>item.id!==action.payload.id),action.payload],
                item:action.payload,
                choosed_item:action.payload.vehicule_name
            }
        case LOGOUT_VEHICULE:
    
        console.log('loging OUT vehicule')
        
        // localStorage.setItem('choosed_Vehicule',action.payload.vehicule_name)
        return { items:[],
            item:{},
            choosed_item:localStorage.removeItem('choosed_Vehicule')
        }

            case UPDATE_OWN_VEHICULE:
        
            console.log('updating own vehicule')
            
            // localStorage.setItem('choosed_Vehicule',action.payload.vehicule_name)
            return {
                ...state,
                items:[...state.items.filter(item=>item.id!==action.payload.id),action.payload],
                item:action.payload,
                choosed_item:action.payload.vehicule_name
            }
        case DELETE_VEHICULE:
        
        console.log('DELETING vehicules')
        if(state.item.id===action.payload && state.items.length!==1){
            console.log('is the current')
        
        return {
            ...state,
            items:state.items.filter(item=>item.id!==action.payload),
            item:state.items[0],
            choosed_item:state.items[0].vehicule_name


        }
        }else if(state.items.length===1){
            localStorage.setItem('choosed_Vehicule','')
            return {
                ...state,
                items:state.items.filter(item=>item.id!==action.payload),
                item:{},
                choosed_item:""
            }

        }else{
            console.log('is not the current')
            return {
                ...state,
                items:state.items.filter(item=>item.id!==action.payload)

            }
        }
    
        default:
            return state;
        

    }
}