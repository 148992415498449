import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {MdDeleteForever} from 'react-icons/md'
import { FaPen } from 'react-icons/fa'
import { IconContext } from "react-icons"
import {ImArrowRight} from 'react-icons/im'
import { useTranslation } from 'react-i18next'


export const DashboardRoutes = ({ip_address}) => {
  const {t}=useTranslation()
  const mode=useSelector(state=>state.auth.mode)
  const vehicule_id = useSelector((state) => state.vehicules.item.id)
  const time_format=useSelector(state=>state.auth.profile.time_format)
  const currency_format=useSelector(state=>state.auth.profile.currency_format)
  const [sorted_items, setSorted_items] = useState([])

  useEffect(() => {
    const fetchMyObjects = async () => {
      const myItems = await getMyRoutes(vehicule_id)
      setSorted_items(myItems)
      console.log(sorted_items)

    }

    fetchMyObjects()

  }, [vehicule_id])

  const getMyRoutes = async (vehicule_id) => {
    const raw_data = await fetch(`${ip_address}/app/api/ownroutes/${vehicule_id}/`, {

      headers: {
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
      }
    })
    const data = await raw_data.json()
    const liste = data
 
    console.log(liste)
    return liste
  }

const deleteRoute=async(url)=>{
   
 
    
        const action=await fetch(url,{
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            method:'DELETE',
         })
         const status=action.status

         switch(status){
            case 204:
              const new_items=sorted_items.filter(item=>item.url!==url)
               setSorted_items(new_items)
                break
            case 403:
                alert('this is not your item')
                break
         
     }  
 }
  return (
    <div>
      <h5 style={{margin:'auto',fontFamily:'AirStrike'}}>manage my routes</h5>
      <table class={`table text-${mode}`}>
  <thead>
    <tr>
      <th scope="col">route</th>
      <th scope="col">distance</th>
      <th scope="col">par autoroute</th>
      <th scope="col">Handle</th>
    </tr>
  </thead>
  <tbody>
  {sorted_items?sorted_items.map((route,index)=> <tr >
        <th scope="row" key={index}>{route.origin}<ImArrowRight/>{route.destination}</th>
        <td>{route.end_odometre - route.start_odometre}</td>
        <td>{route.autoRoute?`par autoroute à ${route.prixAuto_route} ${currency_format}`:'par voie normale'}</td>
        <td>
            <div style={{"display":"flex","justifyContent":"center"}}>
        <IconContext.Provider value={{ style: { color: "red", fontSize: "20px","marginRight":"20px",cursor:"pointer" }, className: "global-class-name" }} >
                          <div onClick={()=>deleteRoute(route.url)}>
                          <MdDeleteForever/>
                          </div>
                        </IconContext.Provider>
             
            <IconContext.Provider value={{ style: { color: "green", fontSize: "20px",cursor:"pointer" }, className: "global-class-name" }}>
                          <div onClick={()=>alert('to do later')}>
                          <FaPen/>
                          </div>
                        </IconContext.Provider>
           </div>
             </td>
      </tr>):'nothing'}
  </tbody>
</table>
    </div>
  )
}
