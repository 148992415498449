import React, { useEffect,useState } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { UPDATE_PROFILE } from '../../../../actions/types'
import { Modal } from '../../Modals/Modal'
import { Modal2 } from '../../Modals/Modal2'
import { useTranslation } from 'react-i18next'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from 'react-icons'


export const ServicesChoices = ({mode}) => {
  const{t}=useTranslation()
    const [services,setServices]=useState([])
    const [service,setService]=useState('')
    const [showModal, setShowModal] = useState("none")
    const dispatch=useDispatch()
    const current_profile = useSelector((state) => state.auth.profile)
    useEffect(() => {

        setServices(JSON.parse(current_profile.services))


    }, [])

        ////////////////////////////:handle click outside model
////////////////// chooseType is the id of the input to
/////////////////////// avoid hidding the modal when typing in the input

const [modalOpen, setModalOpen] = useState(false);

const handleOpenModal = () => {
  setModalOpen(true);
};

const handleCloseModal = () => {
  setModalOpen(false);
};


    const toggleShow = () => {
      if (showModal === "none") {
        setShowModal("block")
      } else {
        setShowModal("none")
      }
    }

  const updateService=async(e)=>{
        e.preventDefault()
        services.push(service)  
       
        // const data=JSON.stringify(fuel_types)
        const data={
            'services':JSON.stringify(services)
        }
        handleCloseModal()
        dispatch(updateProfile(data))
        
  }


  const updateService2=async(service)=>{
    const new_services = services.filter((type) => type !== service)
    setServices(new_services) 
   
    // const data=JSON.stringify(fuel_types)
    const data={
        'services':JSON.stringify(new_services)
    }

    dispatch(updateProfile(data))
    
}
  
  const updateProfile=(data)=>{

    return function(dispatch){
  fetch(current_profile.url, {
    headers: {
        'content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    },
      method: 'PATCH',
      body:  JSON.stringify(data),
    
  },

  ).then(resp=>resp.json()).then(profile=>{if(profile.hasOwnProperty('avatar')){
            dispatch({
                type:UPDATE_PROFILE,
                payload:profile
            })
  }else{
    alert('something went wrong pleaze try again later')
  }})
}
  }

  const setContent_service=()=>{
    return(<form onSubmit={updateService}>
    <input type='text' placeholder='name' className='form-control mb-2' onChange={(e) => { setService(e.target.value) }} value={service} />
    <button type='submit' className='btnOutline form-control'>{t("Submit")}</button>
  </form>)
  }




////////////////////////////////end 
  

  return (
    
    <div className='typesChoices'>
    <div className='typesChoices-content'>

      <h3 className={`strike-${mode}`}>{t("Services")}</h3>

      <button className="btnOutline" onClick={() => handleOpenModal()}>{t("Add")} {t("Service")}</button>


      <Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setContent_service}/>
    </div>

    <ul class={`list-group mt-5 choices-list-${mode}`}  style={{}}>
      {services.map((type, index) => <li key={index} class={`list-group-item choices-list-${mode}`} style={{ "display": "flex", "justifyContent": "space-between", "alignItems": "center","width":"100%" }}>{type}<span onClick={() => updateService2(type)} style={{ "cursor": "pointer" }}>
      <IconContext.Provider value={{ style: { fontSize: "20px",color:"red" }, className: "global-class-name" }}>
                    <div>
                     <MdDeleteForever />
                    </div>
                  </IconContext.Provider>
        </span></li>)}

    </ul>

  </div>
  )
}
