import React,{useState} from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch,useSelector } from 'react-redux';
import {BsEyeFill} from 'react-icons/bs'
import { IconContext } from "react-icons"
import { toggleVisibility,setEye } from '../../Registrations/Register/Register';
import { CREATE_MESSAGES, GET_ERRORS} from '../../../actions/types'

const ChangePass = ({ip_address}) => {
  const {t}=useTranslation()
  const mode=useSelector(state=>state.auth.mode)
    const [old_pass, setOld_pass] = useState('');
    const [new_pass, setNew_pass] = useState('');
    const [new_pass_conf, setNew_pass_conf] = useState('');
    const [visibilité1, setVisibilité1] = useState('password')
    const [visibilité, setVisibilité] = useState('password')
    const [visibilité2, setVisibilité2] = useState('password')
    const [valid, setValid] = useState(false)
    const [confirm, setConfirm] = useState(false)

    const dispatch=useDispatch()

    const handleResetPassword = (e) => {
      e.preventDefault()
      // Perform validation on the email (you can use a library like 'validator' for this)
      if (!new_pass || !new_pass_conf) {
        alert('Please ');
        return;
      }else{
  
      // Here you can make an API call to your server to send the password reset email
      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint for password reset in your server
      const formdata=new FormData()
      formdata.append('old_password',old_pass)
      formdata.append('new_password',new_pass)
      fetch(`${ip_address}/api/change-password`, {
        method: 'PATCH',
        headers: {
        //   'Content-Type': 'application/json',
          "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
        },
        body: formdata,
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response from the server, e.g., show a success message or an error message
          dispatch({
            type:CREATE_MESSAGES,
            payload:`${t(data.message)}`,
        })
        })
        .catch((error) => {
          // Handle any errors that occurred during the API call
          dispatch({
            type:GET_ERRORS,
            payload:{
                msg:error,
                status:400
            }
        })
        });}
    };

    const handlePassword = (e) => {
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const isValid = passwordPattern.test(e.target.value);
        setNew_pass(e.target.value)
        setValid(isValid)
      }
    
    
      const handlePasswordConfirm = (e) => {
        setNew_pass_conf(e.target.value)
        switch (e.target.value) {
          case new_pass:
            setConfirm(true)
            
            break
          default:
            setConfirm(false)
            break
        }
    
      }
  
    return (

      <div id='loginPage' className='container' >

{/* <div className='row' id='registerButton'> */}
  {/* <div className='col-md-8' style={{width:"60%"}}>

  </div> */}
{/* <div className='col-md-4'>
<div >
<Link className="btn btn-info" to="/login" ><span id='register_text'>{t("Login")}</span></Link>
</div>
</div> */}
{/* <div className='col-md-8'></div> */}
{/* </div> */}
<div className='row' style={{width:"100%"}}>
<div className='col-md-3'>

</div>
<div className='col'>
<div className={`bg-${mode}`} id='loginForm' >
{/* <div className='row'>
<div className='col'>
  <img src={`${ip_address}/media/logo.png`} id='logo' />
  </div>
  </div> */}
  <div className='row pt-5'>
    <div className='col'>
    <h5 style={{fontFamily:'Airstrike',color:"gray"}}>{t("Change Password")}</h5>
    </div>
    </div>
  
  <div className='row' style={{"width": "60%","margin": "auto"}}>

  <div className='col mb-4' >
  <form onSubmit={handleResetPassword}  style={{"width":"100%"}}>
    {/*  */}
    
  <div className="mb-3">
  <div  style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
    <div onClick={()=>toggleVisibility(visibilité,setVisibilité)} style={{height:50 ,display:'flex',alignItems:"center",justifyContent:"center",backgroundColor:'#fff',border:'1px solid #ced4da'}}>
                <IconContext.Provider value={{ style: { fontSize: "25px",marginInline:10}, className: "global-class-name" }}>
                    <div>
                    {setEye(visibilité)}
                    </div>
                  </IconContext.Provider>
                </div>
    <input type={visibilité} className="form-control" id="old_password" aria-describedby="emailHelp" onChange={(e)=>setOld_pass(e.target.value)} value={old_pass} placeholder={t('old password')} reauired/>
    </div>
  </div>
  {/*  */}

  <div className="mb-3">
  <div  style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
    <div onClick={()=>toggleVisibility(visibilité1,setVisibilité1)} style={{height:50 ,display:'flex',alignItems:"center",justifyContent:"center",backgroundColor:'#fff',border:'1px solid #ced4da'}}>
                <IconContext.Provider value={{ style: { fontSize: "25px",marginInline:10}, className: "global-class-name" }}>
                    <div>
                    {setEye(visibilité1)}
                    </div>
                  </IconContext.Provider>
                </div>
    <input type={visibilité1} className="form-control" style={valid ? { color: "green" } : { color: "red" }} id="new_password" aria-describedby="emailHelp2" onChange={(e)=>handlePassword(e)} value={new_pass} placeholder={t('new password')} required/>
   
  </div>
  {new_pass? (!valid ? <div id="Help" class="form-text" style={{ color: "red",fontSize:10 }}>{t("password is week")}</div> : <div id="Help" class="form-text" style={{ color: "green",fontSize:10 }}>{t("Password is valid")}</div>):<div id="Help" class="form-text" style={{ color: "gray",fontSize:10 }}>{t("Upper,lower case,digits and special caracters(!, @, #, $ ...)")}</div>}
  </div>
  {/*  */}
 
  <div className="mb-3">
  <div  style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
    <div onClick={()=>toggleVisibility(visibilité2,setVisibilité2)} style={{height:50 ,display:'flex',alignItems:"center",justifyContent:"center",backgroundColor:'#fff',border:'1px solid #ced4da'}}>
                <IconContext.Provider value={{ style: { fontSize: "25px",marginInline:10}, className: "global-class-name" }}>
                    <div>
                    {setEye(visibilité2)}
                    </div>
                  </IconContext.Provider>
                </div>
                <input type={visibilité2} className="form-control" style={confirm ? { color: "green" } : { color: "red" }} id="new_password_confirm" aria-describedby="emailHelp3" onChange={(e)=>handlePasswordConfirm(e)} value={new_pass_conf} placeholder={t('confirm the new password')} required/>
                </div>
   
               
  </div>
  {new_pass_conf && (!confirm ? <div id="Help" class="form-text" style={{ color: "red",fontSize:10 }}>{t("Password does not mutch")}.</div> : <div id="Help" class="form-text" style={{ color: "green",fontSize:10 }}>{t("Passwords mutch")}</div>)}
  <button type="submit" className="btnOutline form-control">{t("Submit")}</button>

    
</form>

    </div></div>
    </div>
</div>

</div>

    
</div>
   
    );
    
}




  export default ChangePass