import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { registerUser } from '../../../actions/rappelActions'
import FacebookLogin from 'react-facebook-login';
// import ReCAPTCHA from "react-google-recaptcha";
import '../Login/LoginIn.css'
import { Link, useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { googleRegister } from '../../../actions/auth'
import { useTranslation } from 'react-i18next'
import {BsEyeFill} from 'react-icons/bs'
import { IconContext } from "react-icons"
import { LanguageFeature } from '../LanguageFeature/LanguageFeature'
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import {LoginSocialFacebook,LoginSocialGoogle} from 'reactjs-social-login'
import {FacebookLoginButton,GoogleLoginButton} from 'react-social-login-buttons'
import { GoogleOAuthProvider,GoogleLogin } from '@react-oauth/google';
import {FiEye,FiEyeOff} from 'react-icons/fi'

export const toggleVisibility=(pass,setPass)=>{
  if(pass==='password'){
    console.log(pass)
    setPass('text')
  }
  if(pass==='text'){
    console.log(pass)
    setPass('password')
  }
}

export const setEye=(visibilité)=>{
  switch(visibilité){
    case 'text':
      return <FiEye/>
    case 'password':
      return <FiEyeOff />
  }
}


export const Register = ({ ip_address,languages }) => {
  const { t } = useTranslation()

  const [username, setUsername] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [phone_id, setPhone_id] = useState('')
  const [password, setPassword] = useState('')
  const [captchaValue, setCaptchaValue] = useState(null)
  const [visibilité1, setVisibilité1] = useState('password')
  const [visibilité2, setVisibilité2] = useState('password')
  const [valid, setValid] = useState(false)
  const [isValid, setIsValid] = useState(true);
  const [confirm, setConfirm] = useState(false)
  const [password_confirmation, setPassword_confirmation] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleCallbackResponse = (response) => {
    console.log(response)
    const userObject = jwt_decode(response.credential)
    console.log(userObject)
    localStorage.setItem('googlePic', userObject.picture)
    dispatch(googleRegister(userObject.jti, userObject.email, userObject.given_name))

  }

  const registerFunction=(response)=>{
    // console.log(response)
    // const userObject=jwt_decode(response.credential)
    // console.log(userObject)
    localStorage.setItem('googlePic',response.picture)
    dispatch(googleRegister(response.access_token,response.email,response.name.replace(" ","_")))
  
  }

  useEffect(() => {
    /* global google*/
    google.accounts.id.initialize({
      client_id: "440145441936-cad9sapqd83lsuu8fqavfohechb032ln.apps.googleusercontent.com",
      callback: handleCallbackResponse
    });
    google.accounts.id.renderButton(
      document.getElementById("google_SignUn"),
      { theme: "outline", size: "large" }
    )
  }, [])
  const onSubmit_register = async (e) => {
   if(captchaValue){ e.preventDefault()
    const formdata = new FormData()
    formdata.append("username", `${firstname}_${lastname}_${phone}`)
    formdata.append("password", password)
    formdata.append("phone", phone_id+phone)
    formdata.append("firstname", firstname)
    formdata.append("lastname", lastname)
    formdata.append("email", email)
    formdata.append("password_confirmation", password_confirmation)
    if(confirm && isValid){dispatch(registerUser(formdata))
              navigate('/register1')
              }else{if(!confirm){alert(t('passwords don\'t not much'))}else if(!!isValid){alert(t('phone number must contain only nine digits'))}
                
              }}
  }

  const handlePassword = (e) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const isValid = passwordPattern.test(e.target.value);
    setPassword(e.target.value)
    setValid(isValid)
  }


  const handlePasswordConfirm = (e) => {
    setPassword_confirmation(e.target.value)
    switch (e.target.value) {
      case password:
        setConfirm(true)
        
        break
      default:
        setConfirm(false)
        break
    }

  }
  const handlePhoneNumberInput = (e) => {
    const inputValue = e.target.value;
    // Define a regular expression for a valid phone number format
    const phoneRegex = /^\d{9}$/; // This example assumes a 10-digit phone number

    // Check if the input value matches the regular expression
    const isValidInput = phoneRegex.test(inputValue);

    // Update the state based on whether the input is valid
    setIsValid(isValidInput);
    setPhone(inputValue);
  };


  const responseFacebook=(response)=>{
    console.log(response)
   }


  return (
<div id='loginPage' className='container' >

<div className='row' id='registerButton'>
  {/* <div className='col-md-8' style={{width:"60%"}}>

  </div> */}
<div className='col-md-4'>
<div >
<Link className="btn btn-info" to="/login" ><span id='register_text'>{t("login")}</span></Link>
</div>
</div>
<LanguageFeature languages={languages}/>
{/* <div className='col-md-8'></div> */}
</div>
<div className='row' style={{width:"100%"}}>
<div className='col-md-3'>

</div>
<div className='col-md-6'>
<div id='register_form' >
        <div className='row'>
          <div className='col' >
            <img src={`${ip_address}/media/logo.png`} id='logo' />
          </div>
        </div>
        <div className='row' style={{marginTop:-20,width:"90%"}}>
        
          <div className='col'>
            <form onSubmit={onSubmit_register} className='mb-3'>
              <div className="mb-2">

                <input type="text" className="form-control" id="firstname" onChange={(e) => setFirstname(e.target.value)} value={firstname} placeholder={t("firstname")} required />

              </div>
              <div className="mb-2">

                <input type="text" className="form-control" id="lastname" onChange={(e) => setLastname(e.target.value)} value={lastname} placeholder={t("lastname")} required />

              </div>
              <div className="mb-2">

                <input type="email" className="form-control" id="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={t("Email Address")} />
              </div>
    

              <div className="mb-2">

              <div class="row">
  <div class="col-4">
    {/* <input type="text" class="form-control"id="phone_number_id"  /> */}
    <select class="form-control" style={{height:"100%"}} aria-label="Default select example" onChange={(e) => setPhone_id(e.target.value)} value={phone_id} placeholder={t("Phone Number_id")} required>
  {/* <option selected>select</option> */}
  <option value="+212" selected>+212</option>

</select>
  </div>
  <div class="col-8">
    <input type="text" class="form-control" style={{marginLeft:"22px"}} id="phone_number" onChange={(e) => handlePhoneNumberInput(e)} value={phone} placeholder={t("Phone Number")} required />
   

  </div>
</div>
<div style={{marginLeft:170,width:"200px"}}>
{phone? (!isValid ? <div id="Help" class="form-text" style={{ color: "red",fontSize:10 }}>{t("Phone number is not valid")}</div> : <div id="Help" class="form-text" style={{ color: "green",fontSize:10 }}>{t("Phone number is valid")}</div>):<div id="Help" class="form-text" style={{ color: "gray",fontSize:10 }}>{t("must containe nine digits")}</div>}

</div>
             

              </div>
              <div className="mb-2">
                <div  style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <div onClick={()=>toggleVisibility(visibilité1,setVisibilité1)} style={{height:50 ,display:'flex',alignItems:"center",justifyContent:"center",backgroundColor:'#fff',border:'1px solid #ced4da'}}>
                <IconContext.Provider value={{ style: { fontSize: "25px",marginInline:10}, className: "global-class-name" }}>
                    <div>
                    {setEye(visibilité1)}
                    </div>
                  </IconContext.Provider>
                </div>
                <input type={visibilité1} className="form-control" id="password" style={valid ? { color: "green" } : { color: "red" }} onChange={(e) => handlePassword(e)} value={password} placeholder={t("Password")} required />
              
                
                </div>
                {password? (!valid ? <div id="Help" class="form-text" style={{ color: "red",fontSize:10 }}>{t("password is week")}</div> : <div id="Help" class="form-text" style={{ color: "green",fontSize:10 }}>{t("Password is valid")}</div>):<div id="Help" class="form-text" style={{ color: "gray",fontSize:10 }}>{t("Upper,lower case,digits and special caracters(!, @, #, $ ...)")}</div>}
              </div>
              <div className="mb-2">
              <div  style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div onClick={()=>toggleVisibility(visibilité2,setVisibilité2)} style={{height:50 ,display:'flex',alignItems:"center",justifyContent:"center",backgroundColor:'#fff',border:'1px solid #ced4da'}}>
                <IconContext.Provider value={{ style: { fontSize: "25px",marginInline:10}, className: "global-class-name" }}>
                    <div>
                    {setEye(visibilité2)}
                    </div>
                  </IconContext.Provider>
                </div>
                <input type={visibilité2} className="form-control" id="confirm password" style={confirm ? { color: "green" } : { color: "red" }} onChange={(e) => handlePasswordConfirm(e)} value={password_confirmation} placeholder={'Confirm Password'} required />
               
                </div>
              </div>
                  {/* <ReCAPTCHA
                  // size="compact"
                  sitekey="6LdjndgoAAAAAEYu0fB4KiljRUlxVzsAMKeUF5CV"
                  onChange={(value)=>setCaptchaValue(value)}
                /> */}

<GoogleReCaptchaProvider reCaptchaKey="6LdjndgoAAAAAEYu0fB4KiljRUlxVzsAMKeUF5CV">
    <GoogleReCaptcha onVerify={(value)=>setCaptchaValue(value)} />
  </GoogleReCaptchaProvider>
              <button type="submit" className="btn form-control login_btn" >{t("Submit")}</button>
              <a href='/privacy_policy' class="form-text" style={{ color: "red",fontSize:12 }}> Privacy policy</a>
              {/* <div className='form-control mt-3' id='google_SignUn'></div> */}
              {/* <GoogleOAuthProvider clientId="901171606422-gs4vgig6f37bl8pmpmat1agjjjaviaim.apps.googleusercontent.com">
    <GoogleLogin
  onSuccess={credentialResponse => {
    handleCallbackResponse(credentialResponse)
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/>
    </GoogleOAuthProvider> */}
              <LoginSocialGoogle
          client_id="901171606422-gs4vgig6f37bl8pmpmat1agjjjaviaim.apps.googleusercontent.com"
          // onLoginStart={onLoginStart}
          // redirect_uri={REDIRECT_URI}
          scope="openid profile email"
          // discoveryDocs="claims_supported"
          // access_type="offline"
          onResolve={({ provider, data }) => {
            console.log(provider,data)
            registerFunction(data)
          }}
          onReject={err => {
            console.log('google error',err);
          }}
        >
          <GoogleLoginButton />
        </LoginSocialGoogle>
              {/* <FacebookLogin
                  appId="2160546774305728"
                  autoLoad={true}
                  textButton="  Facebook"
                  fields="name,email,picture"
                  onClick={responseFacebook}
                  cssClass="btn btn-primary form-control mb-4 mt-2"
                  icon="fa-facebook" /> */}
                 
                 <LoginSocialFacebook
                 appId="1357775568185014"
                 onResolve={(response)=>{
                  console.log(response.data.email,response.data.accessToken)
                  dispatch(googleRegister(response.data.accessToken, response.data.email, response.data.name.replace(' ','_')+'_0000000000'))
                 }}
                 onReject={(error)=>{
                  console.log('there is error',error)
                 }}
                 >
                  <FacebookLoginButton/>
                 </LoginSocialFacebook>
            </form>
            
  
          </div>
        </div>
      </div>


</div>
<div className='col-md-3'>

</div>
</div>

    
</div>
  )
}


