import React from 'react'
import { useParams } from 'react-router-dom'
import { UpdateVehicules } from './UpdateVehicules'

export const UpdateVehiculeWrapper = ({ip_address}) => {
    const {id}=useParams()
  return (
    <UpdateVehicules id={id} ip_address={ip_address}/>
  )
}
