import React from 'react'
import dateFormat from "dateformat"
import { useTranslation } from 'react-i18next'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from "react-icons"
import {FaPen} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { TableRowReminder } from './TableRowReminder'



export const OneReminder = ({reminders,time_format,mode,deleteRimeder}) => {
  const {t}=useTranslation()
  const navigate=useNavigate()


  return (
    <table className={`table text-${mode}`}>
    <thead>
      <tr >
        <th scope="col" > <span  className={`text-${mode}`}>#</span> </th>
        <th scope="col" > <span  className={`text-${mode}`}> {t("Type")}</span></th>
        <th scope="col"   className='removable'> <span  className={`text-${mode}`}>{t("Date")}</span> </th>
        <th scope="col"   className='removable'> <span  className={`text-${mode}`}>{t("Odometre")}</span> </th>
        <th scope="col" > <span  className={`text-${mode}`}>{t("Expires")}</span> </th>
        <th scope="col" > <span  className={`text-${mode}`}>{t("Actions")}</span> </th>
      </tr>
    </thead>
    <tbody>
    {reminders.map((reminder,index)=>(<TableRowReminder index={index+1} mode={mode} type={reminder.type} 
    creation_date={reminder.creation_date} odometre_rappel={reminder.odometre_rappel} url={reminder.url}
    deleteRimeder={deleteRimeder} time_format={time_format} rappel_date={reminder.rappel_date}/>
    // <tr>
    //     <td scope="row">{index}</td>
    //     <td>{reminder.type}</td>
    //     <td className='removable'>{(dateFormat(reminder.creation_date,`${time_format}  HH:MM`))}</td>
        
    //     <td className='removable'>{reminder.odometre_rappel?reminder.odometre_rappel: '-'}</td>
    //     <td >{reminder.rappel_date?(dateFormat(reminder.rappel_date,`${time_format}  HH:MM`)):'-'}</td>
    //     <td >
    //    <div style={{display:'flex'}}>
    //    <IconContext.Provider value={{ style: { color: "red", fontSize: "20px","marginRight":"20px",cursor:"pointer" }, className: "global-class-name" }} >
    //                       <div onClick={()=>deleteRimeder(reminder.url)}>
    //                       <MdDeleteForever/>
    //                       </div>
    //                     </IconContext.Provider>
             
    //         <IconContext.Provider value={{ style: { color: "green", fontSize: "20px",cursor:"pointer" }, className: "global-class-name" }}>
    //                       <div onClick={()=>navigate(dat(reminder.url))}>
    //                       <FaPen/>
    //                       </div>
    //                     </IconContext.Provider>
    //    </div>
    //     </td>
    //   </tr>
      ))}
   
    </tbody>
  </table>
  )
}
