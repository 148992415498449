import React, {useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { UPDATE_PROFILE } from '../../../../actions/types'
import { Modal } from '../../Modals/Modal'
import { Modal2 } from '../../Modals/Modal2'
import { useTranslation } from 'react-i18next'
import {MdDeleteForever} from 'react-icons/md'
import { IconContext } from 'react-icons'

export const ExpenseChoices = ({mode,currentLanguage}) => {
  const {t}=useTranslation()
  const [expense_types, setExpense_types] = useState([])
  const [expense, setExpense] = useState('')
  // const [showModal, setShowModal] = useState("none")

  
  const dispatch = useDispatch()
  const current_profile = useSelector((state) => state.auth.profile)

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  useEffect(() => {

    setExpense_types(JSON.parse(current_profile.expense))


  }, [])



  // ///////////////////////////////////// UPDATE EXPENSE


  const updateExpense = async (e) => {
    e.preventDefault()
    expense_types.push(expense)
    const data = {
      'expense': JSON.stringify(expense_types)
    }
    console.log('this is', data)
     handleCloseModal()
    dispatch(updateProfile(data))

  }


    // ///////////////////////////////////// UPDATE EXPENSE


    const updateExpense2 = async (expense) => {
     
      const expenses = expense_types.filter((type) => type !== expense)
      setExpense_types(expenses)
      const data = {
        'expense': JSON.stringify(expenses)
      }

      dispatch(updateProfile(data))
  
    }

  // ///////////////////////////////////// UPDATE Profile

  const updateProfile = (data) => {

    return function (dispatch) {
      fetch(current_profile.url, {
        headers: {
          'content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        },
        method: 'PATCH',
        body: JSON.stringify(data),

      },

      ).then(resp => resp.json()).then(profile => {
        if (profile.hasOwnProperty('avatar')) {
          dispatch({
            type: UPDATE_PROFILE,
            payload: profile
          })
        } else {
          alert('something went wrong pleaze try again later')
        }
      })
    }
  }


const setContent_expense=()=>{
  return(<form onSubmit={updateExpense}>
  <input type='text' placeholder='name' id='chooseType' className='form-control mb-2' onChange={(e) => { setExpense(e.target.value)}} />
  <button type='submit' className='btnOutline form-control'>{t("Submit")}</button>
</form>)
}

 

  return (

    <div className='typesChoices'>
      <div className='typesChoices-content'>
        <h3 className={`strike-${mode}`}>{t("Types of expense")}</h3>




        <button className="btnOutline" onClick={() => handleOpenModal()}>{t("Add")} {t("Expense type")} </button>


        {/* <Modal modal={'expense'}  mode={mode} showModal={showModal}  setContent={setContent_expense}/> */}

        {/* testing outside click */}

        <Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setContent_expense}/>
      </div>



      <ul className={`list-group mt-5 choices-list-${mode}`} style={{width:"80%"}}>
        {expense_types.map((type, index) => <li key={index} className={`list-group-item choices-list-${mode}`} style={{ "display": "flex", "justifyContent": "space-between", "alignItems": "center" ,"width":"100%"}}>{t(type)}<span onClick={() => updateExpense2(type)} style={{ "cursor": "pointer" }}>
          

          <IconContext.Provider value={{ style: { fontSize: "20px",color:"red" }, className: "global-class-name" }}>
                    <div>
                     <MdDeleteForever />
                    </div>
                  </IconContext.Provider>
          </span></li>)}

      </ul>

    </div>
  )
}
