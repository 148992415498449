import React,{useState} from 'react'
import dateFormat from "dateformat"
import { useDispatch,useSelector } from 'react-redux'
import { UPDATE_PROFILE } from '../../../actions/types'
import { useTranslation } from 'react-i18next'
import { Modal2 } from '../Modals/Modal2'



export const translateDate=(formattedDate,t,current_dateFormat)=>{
  const daysOfWeekRegex = /\b(?:Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday)\b/i;
  const regexOfMonths = /\b(?:January|February|March|April|May|June|July|August|September|October|November|December)\b/i;
  const formats = [
    "d  mmmm  yyyy",
    "d-mmmm-yy",
    "d-mmmm-yyyy",
    "mmmm  d  yyyy",
    "mmmm  d  yy",
    "mmmm-d-yy",
    "mmmm-d-yyyy",
    "yyyy mmmm d",
    "yy mmmm d",
    "yyyy-mmmm-d"
]

console.log(formattedDate,t,current_dateFormat)

if(current_dateFormat==="dddd d mmmm yy"){
      
      const replacedDay = formattedDate.match(daysOfWeekRegex)
      console.log(replacedDay)
      const replacedMonth=formattedDate.match(regexOfMonths)
      const sentence=formattedDate.replace(replacedDay[0],t(replacedDay[0]))
      return (sentence.replace(replacedMonth[0],t(replacedMonth[0])))
}else if(formats.includes(current_dateFormat)){
  console.log('includes month')
      const replacedMonth = formattedDate.match(regexOfMonths)
      return (formattedDate.replace(replacedMonth[0],t(replacedMonth[0])))
}else{
  return formattedDate
}


}

export const Formats = ({digit_format}) => {
  const mode = useSelector(state => state.auth.mode)
  const {t}=useTranslation()
  const dispatch= useDispatch()
  const current_profile = useSelector((state) => state.auth.profile)
  const current_dateFormat = useSelector((state) => state.auth.profile.time_format)
  

  const formats = [
    "d  mmmm  yyyy"
    , "dddd d mmmm yy"
    , "d-mmmm-yy"
    , "d-mmmm-yyyy"



    , "d  mm  yyyy"
    , "d  mm  yy"
    , "d-mm-yy"
    , "d-mm-yyyy"


    , "d.mm.yyyy"
    , "d.mm.yy"
    , "d/mm/yy"
    , "d/mm/yyyy"


    , "mmmm  d  yyyy"
    , "mmmm  d  yy"
    , "mmmm-d-yy"
    , "mmmm-d-yyyy"



    , "mm  d  yyyy"
    , "mm  d  yy"
    , "mm-d-yy"
    , "mm-d-yyyy"


    , "mm.d.yyyy"
    , "mm.d.yy"
    , "mm/d/yy"
    , "mm/d/yyyy"


    , "yyyy mmmm d"
    , "yy mmmm d"
    , "mmmm-d-yy"
    , "yyyy-mmmm-d"



    , "yyyy mm d"
    , "yy mm d"
    , "yy-mm-d"
    , "yyyy-mm-d"


    , "yyyy.mm.d"
    , "yy.mm.d"
    , "yy/mm/d"
    , "yyyy/mm/d"
  ]

  const curencies = ["DOLLAR","DIRHAMS"]

  const float_formats={0:"Without digits",
                        2:"Add two decimal digits",
                      3:"Three decimal degitis"}
  const updateProfile = (data) => {

    return function (dispatch) {
      fetch(current_profile.url, {
        headers: {
          'content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        },
        method: 'PATCH',
        body: JSON.stringify(data),

      },

      ).then(resp => resp.json()).then(profile => {
        if (profile.hasOwnProperty('avatar')) {
          dispatch({
            type: UPDATE_PROFILE,
            payload: profile
          })
        } else {
          alert(t('Something went wrong pleaze try again later'))
        }
      })
    }
  }

  const setFormat=(format)=>{

    const data = {
      'time_format': format
    }
  
    dispatch(updateProfile(data))
  }

  const setCurrencyFormat=(format)=>{

    const data = {
      'currency_format': format
    }
  
    dispatch(updateProfile(data))
  }

 const setNumbersFormat=(format)=>{
  const data = {
    'digit_number': format
  }

  dispatch(updateProfile(data))
 }

 const [modalOpen, setModalOpen] = useState(false);
 const handleOpenModal = () => {
  setModalOpen(true);
};

const handleCloseModal = () => {
  setModalOpen(false);
};
 const [modalOpen1, setModalOpen1] = useState(false);
 const handleOpenModal1 = () => {
  setModalOpen1(true);
};

const handleCloseModal1 = () => {
  setModalOpen1(false);
};
 const [modalOpen2, setModalOpen2] = useState(false);
 const handleOpenModal2 = () => {
  setModalOpen2(true);
};

const handleCloseModal2 = () => {
  setModalOpen2(false);
};



 const setDateFormat=()=>{
  return (<div className='dateFotmat' style={{"height":"200px","overflowY":"scroll"}}>
  <ul style={{listStyle:'none'}}>
  {formats.map((format,index)=><li key={index} style={{borderBottom:"solid lightgray 1px",paddingInline:30,marginBottom:5,backgroundColor:'#d10019',}}><button className='btn' onClick={()=>{setFormat(format);handleCloseModal()}}  style={{color:"white"}}>{translateDate(dateFormat(new Date(),format),t,format)}</button> </li>)}
          </ul></div>)
 }

 const setcurrencyFormat=()=>{
  return ( <div className='dateFotmat'>
  <ul style={{listStyle:'none'}}>
    {curencies.map((currency,index)=><li key={index}  style={{borderBottom:"solid lightgray 1px",paddingInline:30,marginBottom:5,backgroundColor:'#d10019',}}><button className='btn' onClick={()=>{setCurrencyFormat(currency);handleCloseModal1()}} style={{color:"white"}}>{currency}</button> </li>)}
            </ul>
            </div>)
 }

 const setdigitFormat=()=>{
  return (  <div className='dateFotmat'>
     <ul style={{listStyle:'none'}}>
    {Object.keys(float_formats).map((format,index)=><li key={index}  style={{borderBottom:"solid lightgray 1px",paddingInline:30,marginBottom:5,backgroundColor:'#d10019',}}><button className='btn' onClick={()=>{setNumbersFormat(format);handleCloseModal2()}} style={{color:"white"}}>{float_formats[format]}</button> </li>)}
            </ul>
            </div>)
 }

  return (

    <section className='general'>
   
<button type="button" class="btnOutline m-4" onClick={handleOpenModal}>
{t("Date format")} : {translateDate(dateFormat(new Date(),current_dateFormat),t,current_dateFormat)}
</button>


{/* <button type="button" class="btnOutline m-4" onClick={handleOpenModal1}>
{t("currency")} : {current_profile.currency_format}

</button> */}


<button type="button" class="btnOutline m-4" onClick={handleOpenModal2}>

{t("Digit formats")} : {digit_format(4.6376)}
</button>
<Modal2   mode={mode} isOpen={modalOpen} onClose={handleCloseModal}  setContent={setDateFormat}/>
<Modal2   mode={mode} isOpen={modalOpen1} onClose={handleCloseModal1}  setContent={setcurrencyFormat}/>
<Modal2   mode={mode} isOpen={modalOpen2} onClose={handleCloseModal2}  setContent={setdigitFormat}/>
     
    </section>
  )
}
