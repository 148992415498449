import React, { useState, useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateVehicule } from '../../../../actions/rappelActions'
import './AddService.css'
import { CREATE_MESSAGES, GET_ERRORS } from '../../../../actions/types'
import { useTranslation } from 'react-i18next'
import { MdArrowDropDown,MdArrowDropUp } from "react-icons/md";


export const AddService = ({ip_address,currentLanguage}) => {
    const {t}=useTranslation()
    const mode=useSelector(state=>state.auth.mode)
    // const vehicules = useSelector(state => state.vehicules.items)
    const selected_vehicule= useSelector(state => state.vehicules.item.url)
    const selected_vehicule_id= useSelector(state => state.vehicules.item.id)
    const user=useSelector(state=>state.auth.user.url)
    const username=useSelector(state=>state.auth.user.username)
    const service_types = JSON.parse(useSelector(state => state.auth.profile.services))
    const reasons = JSON.parse(useSelector(state => state.auth.profile.raison))
    const places = JSON.parse(useSelector(state => state.auth.profile.places_choice))
    const payment_methods = JSON.parse(useSelector(state => state.auth.profile.payment_method))
    const current_odometre=useSelector(state=>state.vehicules.item.odometre )
    const token = useSelector(state => state.auth.token)
    // const conducteurs= useSelector(state => state.vehicules.item.driver)
    // const own= useSelector(state => state.auth.profile.url)
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const current_time=Date.now()/1000
    const [vehicule, setVehicule] = useState('')
    const [service_type, setService_type] = useState('')
    const [showOptional, setShowOptional] = useState('none')
    const [buttonTitle, setButtonTitle] = useState('More options')
    const [reason,setReason]=useState('')
    const [odometre, setOdometre] = useState(0)
 
    const [valeur, setValeur] = useState(0)
    // const [driver, setDriver] = useState('')
    // const [drivers, setDrivers] = useState('')
    const [payment_method,setPayment_method]=useState('')
    const [note, setNote] = useState('')
    const [timer,setTimer]=useState('')
   const [place,setPlace]=useState('')
    const [file, setFile] = useState(null)

    useEffect(()=>{
        
        setVehicule(selected_vehicule)
        if(service_types.length>0){
            setService_type(service_types[0])
        }
        if(payment_methods.length>0){
            setPayment_method(payment_methods[0])
        }

        if(places.length>0){
            setPlace(places[0])
        }else{
            setPlace(t('no choices available'))
        }
    
       },[selected_vehicule])

    const ajouterService=async(data)=>{

 
        if(Date.parse(new Date(timer))/1000 <= current_time)
       { const requestPost=await fetch(`${ip_address}/app/api/services/`,{
            method:'POST',
            headers:{
                "Authorization":`Bearer ${localStorage.getItem('access_token')}`,
            },
            body:data,

        },
        
        )
        console.log(requestPost)
        const resp=await requestPost.json()
        if(requestPost.status===201){
            if(current_odometre<odometre){
                const new_odometre=new FormData()
                new_odometre.append('odometre',odometre)
                dispatch(updateVehicule(selected_vehicule_id,new_odometre))
            }
            navigate('/timeline') // ne pas oublier de creer la page de history
            dispatch({
                type:CREATE_MESSAGES,
                payload:`${t("Service")} ${t("created success")}`,
            })
            // dispatch(fetchVehicules())
            return resp
        }else{
            dispatch({
                type:GET_ERRORS,
                payload:{
                    msg:resp.message,
                    status:requestPost.status
                }
            })
        }}else{
            dispatch({
                type:GET_ERRORS,
                payload:{
                    msg:t("future events are not allowed"),
                    status:400
                }
            })
        }
 
    }

        //////////////////////////////////// show or hide optional inputs


const toggleShowOptional = () => {
        
    if (showOptional === "none") {
    
      setShowOptional("block")
      setButtonTitle('Less options')
    } else {
      setShowOptional("none")
      setButtonTitle('More options')
    }
  }

//   end


    const onSubmit=async(e)=>{
        e.preventDefault()
        const formdata= new FormData()
        // formdata.append('driver',driver)
        formdata.append('type',service_type)
        formdata.append('odometre',odometre)
        formdata.append('value',valeur)
        formdata.append('writter',user)
        // formdata.append('username',username)
      
        formdata.append('vehicule',vehicule)
        if(timer){
            formdata.append('creation_date',timer)
        }
        if(file){
            formdata.append('attached_file',file)
        }
        if(reason){
            formdata.append('raison',reason)
        }
        if(payment_method){
            formdata.append('payment_method',payment_method)
        }
        if(note){
            formdata.append('note',note)
        }
        if (place) {
            formdata.append('place', place)
        }
        
        ajouterService(formdata)
      
        // ajouterRappel(formdata)
        // setReminder_choice('')
        // setReminder_type('')
        // setOdometre('')
        // setFile(null)
        // setNote('')
        // setVehicule('')
    }




    return (
        <section id='serviceForm' className={`all_sections ${currentLanguage.dir || 'ltr'} mt-4`}>

            <div className={`content-${mode} whitewhen${mode}`}>
                <div className='row'>
                    <div className='col m-4'>
                        <div className='title'>
                        <img src={`https://web.bfdrive.ma/media/icons/wrench.svg`} style={{"width":"30px","height":"100%"}}/> |
                            <span className='text Airstrike'>{t("Service")}</span>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className='row mt-5'>

                                <div className='col-md-4'>
                                    <label for="selectService" class="form-label required">{t("Service")} {t("type")}</label>
                                    <select class={`form-select ${mode}`} id='selectService' onChange={(e) => setService_type(e.target.value)} value={service_type} required>
                                    
                                        {service_types.map((type, index) => (<option key={index} value={type}>{type}</option>))}
                                    </select>
                                </div>

                                <div class="col-md-4">
                                    <label for="odometre" class="form-label required">{t("Odometer")}</label>

                                    <input type="number" class={`form-control ${mode}`} id="odometre" placeholder={t("Odometer")} onChange={(e) => setOdometre(e.target.value)} value={odometre} required />
                                    <div id="emailHelp" class="form-text">{t("Last odometre")}{current_odometre}</div>
                                </div>

                                <div class="col-md-4">
                                    <label for="inputZip" class="form-label required">{t("Service date")}</label>
                                    <input type="datetime-local" class={`form-control ${mode}`} id="inputZip" onChange={(e) => setTimer(e.target.value)} value={timer} />
                                </div>
                                </div>
                                <div className='row mt-3'>

                           
                                <div className='col-md-6'>
                                    <label for="selectPrice" class="form-label required">{t("Price")}</label>
                                    <input type='number' class={`form-control ${mode}`} id='selectPrice' onChange={(e) => setValeur(e.target.value)} value={valeur} required />

                                     
                                </div>
                                <div className='col-md-6'>
                                    <label for="selectpayment_method" class="form-label required">{t("Payment method")}</label>
                                    <select class={`form-select ${mode}`} id='selectpayment_method' onChange={(e) => setPayment_method(e.target.value)} value={payment_method} required>
                                    
                                        {payment_methods.map((method, index) => (<option key={index} value={method}>{method}</option>))}
                                    </select>
                                </div>
                            </div>
                            <div style={{fontSize:10}}><span style={{color:'red'}}>(*)</span>: {t("The above options are obligatory")}</div>
                                {/* Optional inputs */}
                                <div style={{display:showOptional}}>

                            <div className='row mt-3'>
                             
     
                                <div className='col-md-6'>
                                    <label for="selectplace" class="form-label">{t("Place")}</label>
                                    <select class={`form-select ${mode}`} id='selectplace' onChange={(e) => setPlace(e.target.value)} value={place} required>

                                        {places.map((pla, index) => (<option key={index} value={pla}>{pla}</option>))}
                                    </select>
                                </div>
                               
                                <div className='col-md-6'>
                                    <label for="selectResaon" class="form-label">{t("Add")} {t("Reason")}</label>
                                    <select class={`form-select ${mode}`} id='selectResaon' onChange={(e) => setReason(e.target.value)} value={reason} required>
                                    
                                        {reasons.map((raison, index) => (<option key={index} value={raison}>{raison}</option>))}
                                    </select>
                                </div>


                            </div>

                            <div className='row mb-3'>
                     

                          

                                <div class="col-md-6 mb-3" >
                                    <label for="textarea1" class="form-label">{t("Note")}</label>

                                    <textarea class={`form-control ${mode}`} id="textarea1" rows="1" placeholder={t('Add remark')} onChange={(e) => setNote(e.target.value)} value={note}></textarea>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <label for="formFileLg" class="form-label">{t("Attach file")}</label>
                                    <input class={`form-control ${mode}`} id="formFileLg" type="file" onChange={(e) => setFile(e.target.files[0])} />
                                </div>
                                </div>
                                </div>
                        {/*End optional inputs  */}
                        <a  onClick={toggleShowOptional} style={{cursor:"pointer",fontSize:16,fontWeight:'bold',color:"#1a5276",paddingTop:10,paddingBottom:10}}>{t(buttonTitle)}{buttonTitle==="More options"?<MdArrowDropDown />:<MdArrowDropUp/>}</a>
                        <div className='row mb-3'>
                                    <div class="col-12">
                                <button type="submit" class={`btn form-control`} style={{backgroundColor:"#36001C",color:'white'}}>{t("Add")} {t("Service")}</button>
                            </div>
                            </div>
                         

     
                        
                        </form>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg'>




                    </div>
                </div>
            </div>


        </section>
    )
}
