import React,{useEffect,useState} from 'react'
import { useSelector, useDispatch  } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ThreeDots } from 'react-loader-spinner'

import { USER_AUTHENTICATED,AUTH_ERROR } from '../../../actions/types'


export const Loading = ({token}) => {

    const dispatch=useDispatch()
    const navigate=useNavigate()
    useEffect(()=>{
        localStorage.setItem('access_token',token)
       check_user()

    },[])
  const check_user=async()=> {
     const config={
        headers:{
            'content-Type':'application/json'
        }
    }

    if (token || token==!"undefined"){
        config.headers['Authorization']=`Bearer ${token}`
        console.log(config)



   fetch(`https://web.bfdrive.ma/api/auth/user`,config)
     .then(res=>res.json())
    
     .then(user=>
        {if(user.hasOwnProperty('user')){
            localStorage.setItem('access_token',token)
            console.log("disoatching user is loautheticated")
            dispatch({
                type:USER_AUTHENTICATED,
                payload:user

            })
            navigate('/timeline')
        }else{

            dispatch({
                type:AUTH_ERROR
            })
            navigate('backToLogin')
        } }
        )}else{
    dispatch({
            type:AUTH_ERROR
        })
        navigate('backToLogin')
     }}
  return (
    <div style={{display:'flex',flexDirection:"column",alignItems:"center",justifyContent:"center",width:"100%",height:"100%"}}>
    <ThreeDots
  visible={true}
  height="80"
  width="80"
  color="#4fa94d"
  radius="9"
  ariaLabel="three-dots-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
    </div>  


  )
}
